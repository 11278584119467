// @flow

import humps from 'humps';

import formatCurrency from '~/utils/formatCurrency';
import type {
  Bid,
  Item,
  FulfillmentType,
  User,
  PaymentMethod,
} from '../types';

type CreateBid = {
  itemId: string,
  fulfillmentTypeId: ?number,
  amount: number,
  max?: ?number,
  bypassGiftCardBalance?: boolean,
}

type EditBid = {
  id: string,
  itemId: string,
  fulfillmentTypeId: ?number,
  max: ?number,
  bypassGiftCardBalance?: boolean,
}

type GlobalError = {
  heading: string,
  message: string[],
};

type ServerError = {
  type: string,
  message: string,
  result?: boolean,
};

export const parseErrors = (error: ServerError): GlobalError => {
  let newErrorMessage = error.message || '';
  newErrorMessage = newErrorMessage.replace('Bid not accepted.', '').replace('Outbid!', '');
  const errors = newErrorMessage.trim().split(/\.\s*/);

  return {
    type: error.type,
    heading: error.type === 'outbid' ? 'Outbid!' : 'Bid not accepted.',
    message: errors.slice(0, -1).map((e) => `${e}.`),
  };
};

export const placeBid = async (bid: CreateBid) => {
  const {
    itemId,
    ...bidAttributes
  } = bid;

  const response = await fetch(`/api/v1/items/${itemId}/bids`, {
    body: JSON.stringify(humps.decamelizeKeys(bidAttributes)),
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  const json = await response.json();

  if (json.type === 'outbid' || json.type === 'error') {
    return Promise.reject(parseErrors(json));
  } else {
    return Promise.resolve(json);
  }
};

export const updateBid = async (bid: EditBid) => {
  const { id, ...bidAttributes } = bid;

  const response = await fetch(`/api/v1/bids/${id}`, {
    body: JSON.stringify(humps.decamelizeKeys(bidAttributes)),
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  const json = await response.json();

  if (json.type === 'error') {
    return Promise.reject(parseErrors(json));
  } else {
    return Promise.resolve(json);
  }
};

export const bidIncrement = (highBidAmount: number): number => {
  if (highBidAmount < 10) return 1;
  if (highBidAmount < 25) return 2;
  if (highBidAmount < 100) return 5;
  if (highBidAmount < 250) return 10;
  if (highBidAmount < 1000) return 25;
  if (highBidAmount < 5000) return 50;
  if (highBidAmount < 10000) return 100;
  if (highBidAmount < 25000) return 250;
  if (highBidAmount < 50000) return 500;
  if (highBidAmount < 100000) return 1000;
  return 2000;
};

export const nextBidMinimum = (highBidAmount: number, minimumBidAmount: number): number => {
  if (minimumBidAmount > highBidAmount) {
    return parseInt(minimumBidAmount, 10);
  }
  return highBidAmount + bidIncrement(highBidAmount);
};

export const calculateAmount = (bid: Bid, item: Item): number => {
  if (bid.id) {
    return item.highBidAmount;
  } else {
    return nextBidMinimum(item.highBidAmount, item.minimumBidAmount);
  }
};

export const calculateMax = (bid: Bid): ?number => {
  // "auto bid" field is optional, so we don't want to show a max value
  // unless explicitly set by the bidder
  return bid.max && bid.amount && bid.max > bid.amount ? bid.max : null;
};

const deliverableAddresses = (user: User) => {
  return user.addresses.filter((a) => (a.isDeliverable));
};

export const hasDeliverableAddress = (user: User) => {
  const addresses = deliverableAddresses(user);
  return addresses && addresses.length > 0;
};

export const calculateFulfillmentDefault = (
  bid: Bid,
  item: Item,
  user: User,
): ?FulfillmentType => {
  if (bid.fulfillmentType) return bid.fulfillmentType;

  if (item.fulfillmentTypes.length !== 1) return null;

  const ft = item.fulfillmentTypes[0];
  if (ft.type === 'shipping') {
    return hasDeliverableAddress(user) ? ft : null;
  } else {
    return ft;
  }
};

export const calculateCurrentAddressId = (bidShippingAddress: ?Object, user: User): ?string => {
  if (bidShippingAddress && bidShippingAddress.id) return bidShippingAddress.id;

  const addresses = deliverableAddresses(user);

  if (!addresses || addresses.length === 0) return null;

  const primaryAddress = addresses.find((a) => { return a.isPrimary; });

  if (primaryAddress) {
    return primaryAddress.id;
  } else {
    return addresses[0].id;
  }
};

export const calculateCurrentPaymentMethod = (bid: Bid, user: User): ?PaymentMethod => {
  const { paymentMethod } = bid;

  if (paymentMethod) {
    return paymentMethod;
  } else {
    const { paymentMethods } = user;
    const eligibleMethods = paymentMethods.filter((pm) => (pm.aasmState === 'active'));
    return eligibleMethods.find((pm) => { return pm.isPrimary; }) || eligibleMethods[0];
  }
};

export const validateBid = (bid: Bid, item: Item): Array<any> => {
  const requiredAmount = nextBidMinimum(item.highBidAmount, item.minimumBidAmount);
  let hasErrors = false;
  const errors: Object = {
    errors: null,
    confirmationError: false,
    amountErrors: [],
    maxErrors: [],
    fulfillmentErrors: [],
  };

  if (bid.isConfirmed === false) {
    errors.errors = {
      heading: 'Bid not accepted.',
      message: ['Confirm your bid by checking the box above.'],
    };
    errors.confirmationError = true;
    hasErrors = true;
  }

  if (
    (bid.amount == null) ||
    (bid.id == null && bid.amount != null && bid.amount < requiredAmount) ||
    (bid.max === null && item.typeName !== 'buy_now')
  ) {
    errors.amountErrors.push(`Bid is too low. Must be ${formatCurrency(requiredAmount)} or more.`);
    hasErrors = true;
  }

  if (bid.amount != null && bid.max != null && bid.max < bid.amount) {
    errors.maxErrors.push('Must be higher than your bid.');
    hasErrors = true;
  }

  return [hasErrors, errors];
};
