// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';
import Collapse, { Panel } from 'rc-collapse';
import ContentSvgIcon from '~/admin/shared/svgs/ContentSvgIcon';

type Props = {
  className?: string,
  children?: Node,
  header?: Node | string,
  defaultActiveKey?: string,
  nonScrollContent?: Node | string,
  collapsable?: boolean,
  maxHeight?: number | string,
};

export class FilterGroup extends Component<Props> {
  static defaultProps = {
    maxHeight: 120,
  };

  renderToggleIcon = ({ isActive }: { isActive: boolean }) => {
    const iconName = isActive ? 'ic_remove_24px' : 'ic_add_24px';
    return (
      <i>
        <ContentSvgIcon
          icon={iconName}
          className="icon"
          style={{
            transform: `rotate(${isActive ? 0 : -90}deg)`,
            transition: 'transform .2s ease-out',
          }}
        />
      </i>
    );
  };

  render() {
    const {
      className,
      header,
      nonScrollContent,
      children,
      collapsable,
      maxHeight,
      defaultActiveKey,
    } = this.props;

    const classes = classNames(
      'filter-bar__filter-group',
      { 'filter-bar__content--not-collapsable': !collapsable },
      className
    );

    if (collapsable) {
      return (
        <Collapse
          prefixCls="filter-collapse"
          className={classes}
          expandIcon={this.renderToggleIcon}
          defaultActiveKey={defaultActiveKey}
        >
          <Panel header={header} headerClass="filter-bar__filter-header">
            {nonScrollContent}
            <div className="filter-bar__filter-group--scroll" style={{ maxHeight }}>
              {children}
            </div>
          </Panel>
        </Collapse>
      );
    }

    return (
      <div className={classes}>
        <div className="filter-bar__filter-header">{header}</div>
        {children}
      </div>
    );
  }
}
