// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';

import { Icon } from '~/public/shared/components';
import formatCurrency from '~/utils/formatCurrency';
import ModalBidHistory from './ModalBidHistory';
import CountUpCurrency from './CountUpCurrency';
import * as ModalActions from '../actions/modal';

import type { Bid, Item, User } from '../types';

type Props = {
  bid: Bid,
  item: Item,
  user: ?User,
  openModal: (string) => void,
  itemDetailContext?: boolean,
};

class BidStatus extends Component<Props> {
  handleOpenBidHistoryModal = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.openModal('bidHistory');
  }

  renderLabelTag = (text, classes) => {
    return (
      <span className={classes}>{text}</span>
    );
  }

  renderLabel = (itemEnded: boolean, isHighBidder: boolean, bidsCount: number, minimumBidAmount: number, typeName: ?string) => {
    const labelClassNames = classNames(
      'bid-status__label',
      'u-b',
      {
        'bid-status__label--winning': itemEnded && isHighBidder,
      },
    );

    if (typeName === 'buy_now') {
      return;
    }

    if (bidsCount === 0 && minimumBidAmount > 1) {
      return this.renderLabelTag('Opening Bid', labelClassNames);
    } else if (itemEnded && isHighBidder) {
      return this.renderLabelTag('Your Winning Bid!', labelClassNames);
    } else if (itemEnded) {
      return this.renderLabelTag('Winning Bid', labelClassNames);
    } else {
      return this.renderLabelTag('Current Bid', labelClassNames);
    }
  }

  render() {
    const {
      itemDetailContext,
      bid,
      item,
      user,
    } = this.props;
    const {
      aasmState,
      bidderIds,
      highBidUserId,
      highBidAmount,
      minimumBidAmount,
      bidsCount,
      typeName,
    } = item;
    const itemEnded = ['sold', 'unsold', 'invoiced', 'paid'].includes(aasmState);
    const isBidder = user != null && bidderIds.includes(user.id);
    const isHighBidder = user != null && highBidUserId === user.id;
    const isOutbid = !itemEnded && isBidder && !isHighBidder;
    const showMaxBid = bid.max && bid.max > highBidAmount && !itemEnded;
    const messageClasses = classNames(
      'bid-status__message',
      {
        'item-detail__bid-status-group item-detail__bid-status-group--message': itemDetailContext,
      }
    );

    return (
      <Fragment>
        <div className={itemDetailContext && 'item-detail__bid-status-group'}>
          {this.renderLabel(itemEnded, isHighBidder, bidsCount, minimumBidAmount, typeName)}
          <div className="bid-status__details">
            {isHighBidder &&
              <span className="bid-status__badge bid-status__badge--winning">
                <Icon
                  icon={itemEnded ? 'trophy' : 'star-outline'}
                  className="bid-status__badge-icon"
                />
              </span>
            }
            {isOutbid &&
              <span className="bid-status__badge bid-status__badge--outbid">
                <Icon icon="blocked" className="bid-status__badge-icon" />
              </span>
            }
            <span className="bid-status__amount qa-bid-amount">
              {bidsCount === 0 && minimumBidAmount > 1 &&
                <CountUpCurrency amount={minimumBidAmount} />
              }
              {!(bidsCount === 0 && minimumBidAmount > 1) &&
                <CountUpCurrency amount={highBidAmount} />
              }
            </span>
            {typeName !== 'buy_now' &&
              <button type="button" onClick={this.handleOpenBidHistoryModal} className="bid-status__history-link qa-bid-history"><strong>{item.bidsCount}</strong> Bid{item.bidsCount !== 1 && 's'}</button>
            }
            <ModalBidHistory />
          </div>
        </div>
        {((!itemEnded && isHighBidder) || showMaxBid || isOutbid) &&
          <div className={messageClasses}>
            {(!itemEnded && isHighBidder) &&
              <div className="bid-status__label bid-status__label--winning">You’re Winning!</div>
            }
            {showMaxBid &&
              <div>Auto Bid: up to <strong>{formatCurrency(bid.max)}</strong></div>
            }
            {isOutbid &&
              <div className="bid-status__label bid-status__label--outbid">You’ve been outbid!</div>
            }
          </div>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = ({ item: { item }, user: { user }, bid }) => ({ item, user, bid });
const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...ModalActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(BidStatus);
