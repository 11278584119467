// @flow

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const PaymentMethodsQuery = gql`
query fetchPayMethods {
    currentUser {
        id
        paymentMethods {
          nodes {
            id
            aasmState
            primary
            last4
            nameOnCard
            cardType
            paymentMethodType
            expMonth
            expYear
          }
        }
    }
}
`;

type PaymentMethod = {
  id: number,
  assmState: string,
  primary: boolean,
  last4: string,
  nameOnCard: string,
  cardType: string,
  paymentMethodType: string,
  expMonth: string,
  expYear: string,
}

type PaymentMethodConnection = {
  nodes: [PaymentMethod],
};

type CurrentUser = {
  id: string,
  paymentMethods: PaymentMethodConnection,
};

type Props = {
  loading: boolean,
  error: string,
  currentUser: CurrentUser,
  refetch: () => {},
};

const mapPaymentMethodTypeId = (paymentMethodType: string) => {
  switch (paymentMethodType) {
    case 'Credit Card':
      return 1;
    case 'Cash':
      return 2;
    case 'eCheck':
      return 3;
    default:
      return 0;
  }
};

const transformCurrentUser = (currentUser: CurrentUser) => {
  if (currentUser) {
    return currentUser.paymentMethods.nodes.map((pm: PaymentMethod) => {
      return {
        ...pm,
        id: parseInt(pm.id, 10),
        expiry: `${pm.expMonth}/${pm.expYear}`,
        isPrimary: pm.primary,
        paymentMethodTypeId: mapPaymentMethodTypeId(pm.paymentMethodType),
      };
    });
  }
};

export const withPaymentMethods = graphql(PaymentMethodsQuery, {
  props: ({
    data: {
      refetch,
      loading,
      currentUser,
      error,
    },
  }: { data: Props}) => {
    return {
      loading,
      paymentMethods: transformCurrentUser(currentUser),
      error,
      refetch,
    };
  },
});
