// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import URI from 'urijs';
import { Card } from '../Card';
import { LoadingDots } from '~/public/shared/components';
import formatThousandsToK from '~/utils/formatThousandsToK';
import ClipboardInput from '../ClipboardInput';

const STATUS_COUNT_QUERY = gql`
  query sellerItemStatusCounts {
    sellerItemStatusCounts {
      processing
      preview
      live
      ended
      omitted
    }
    sellerPendingItemStatusCounts {
      created
      converted
    }
  }
`;

const CURRENT_USER_QUERY = gql`
  {
    currentUser {
      id
    }
  }
`;

type ItemStatusLink = {
  key: string,
  label: string,
  count: string | number,
};

export const Items: StatelessFunctionalComponent<{}> = () => {
  const renderItemStatus = ({ key, label, count }: ItemStatusLink) => {
    const isZero = count.toString() === '0';
    return (
      <li className="dashboard-status-counts__item">
        {isZero &&
          <span className="dashboard-status-counts__item-count">0</span>}
        {!isZero && key !== 'created' &&
          <Link to={`/items?statuses[]=${key}`} className="dashboard-status-counts__item-count">{formatThousandsToK(count)}</Link>}
        {!isZero && key === 'created' &&
          <Link
            to="/items/submitted"
            className="dashboard-status-counts__item-count"
          >
            {formatThousandsToK(count)}
          </Link>}
        {label}
      </li>
    );
  };

  return (
    <Card
      header={
        <>
          Your Items
          <Link to="/items" className="u-pull-right">View All</Link>
        </>
      }
    >
      <Query query={STATUS_COUNT_QUERY}>
        {({ data }) => {
          const itemStatusPlaceholders = {
            created: '?',
            converted: '?',
            processing: '?',
            preview: '?',
            live: '?',
            ended: '?',
            omitted: '?',
          };

          const counts = { ...itemStatusPlaceholders, ...data.sellerItemStatusCounts };
          const pendingCounts = { ...itemStatusPlaceholders, ...data.sellerPendingItemStatusCounts };

          return (
            <ul className="dashboard-status-counts">
              {renderItemStatus({
                key: 'created',
                label: 'Pending',
                count: pendingCounts.created,
              })}
              {renderItemStatus({
                key: 'processing',
                label: 'Processing',
                count: counts.processing,
              })}
              {renderItemStatus({
                key: 'preview',
                label: 'Preview',
                count: counts.preview,
              })}
              {renderItemStatus({
                key: 'live',
                label: 'Live',
                count: counts.live,
              })}
              {renderItemStatus({
                key: 'ended',
                label: 'Ended',
                count: counts.ended,
              })}
              {renderItemStatus({
                key: 'omitted',
                label: 'Omitted',
                count: counts.omitted,
              })}
            </ul>
          );
        }}
      </Query>
      <div>
        <label>Promote Your Live Items:</label>
        <div className="input-group">
          <Query query={CURRENT_USER_QUERY}>
            {({ loading, error, data }) => {
              if (loading || error) {
                return <LoadingDots />;
              }

              const shareUrl = URI().path('browse').query({ seller_id: data.currentUser.id }).toString();

              return (
                <ClipboardInput value={shareUrl} />
              );
            }}
          </Query>
        </div>
      </div>
    </Card>
  );
};
