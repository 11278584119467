import React from "react";

interface CheckboxProps {
  label: string;
  helperText: string;
  checked: boolean;
  className?: string,
  onChange(checked: boolean): any;
}

export const Checkbox: React.SFC<CheckboxProps> = ({
  label,
  helperText,
  checked,
  className,
  onChange,
}) => {

  const handleChange = (event) => {
    onChange(!checked);
  };

  return (
    <div className={'checkbox ' + className}>
      <label className="boolean optional">
        <input className="checkbox__input boolean optional"
          checked={checked}
          onChange={handleChange}
          type="checkbox"
        />{label}
      </label>
      <small className="form-group__hint u-ml3">{helperText}</small>
    </div>
  );
}

export default Checkbox;
