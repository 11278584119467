// @flow

import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';
import Layout from '../components/Layout';
import {
  Contact,
  Contracts,
  Items,
  Payments,
} from '../components/DashboardWidgets';

const CURRENT_USER_QUERY = gql`
  {
    currentUser {
      id
      firstName

      authorizedSelfLister
    }
  }
`;

type Props = {};

class DashboardPage extends Component<Props> {
  componentDidMount() {
    document.title = 'Seller Dashboard | EBTH';
  }

  getGreetingTime = (currentTime: moment) => {
    if (!currentTime || !currentTime.isValid()) { return 'Hello'; }

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    const currentHour = parseFloat(currentTime.format('HH'));

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return 'Good afternoon';
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return 'Good evening';
    }
    // Between dawn and noon
    return 'Good morning';
  };

  render() {
    return (
      <Layout>
        <h1 className="u-mb2">
          <Query query={CURRENT_USER_QUERY}>
            {({ loading, error, data }) => {
              if (loading || error) {
                return '\xa0';// &nbsp;
              }
              return `${this.getGreetingTime(moment())}, ${data.currentUser.firstName}.`;
            }}
          </Query>
        </h1>

        <div className="row">
          <div className="col-xs-12 col-md-6 u-mb2">
            <Items />
          </div>
          <div className="col-xs-12 col-md-6 u-mb2">
            <Contact />
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6 u-mb2">
            <Contracts />
          </div>
          <div className="col-xs-12 col-md-6 u-mb2">
            <Payments />
          </div>
        </div>
      </Layout>
    );
  }
}

export { DashboardPage };
