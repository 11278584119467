// @flow

import React, { Component } from 'react';
import { Modal } from './Modal';

import { Button } from '../Button';
import TextInput from '~/admin/shared/components/TextInput';
import TextareaInput from '~/admin/shared/components/TextareaInput';
import { Spinner } from '~/admin/shared/components';

type Props = {
  onDataChange: (any, string, { role: string, value: string }[]) => void,
  barcode: string,
  originalDescription: ?string,
};

type State = {
  isUpcOpen: boolean,
  isAutofillOpen: boolean,
  upc: string,
  error: string,
  loading: boolean,
  item: any,
  barcode: string,
};

class UpcLookupModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isUpcOpen: false,
      isAutofillOpen: false,
      upc: '',
      item: {},
      loading: false,
      error: '',
      barcode: props.barcode,
    };
  }

  confirmUpc = () => {
    this.setState({ loading: true });
    // 653569183945 - test UPC code provided for initial dev and testing

    fetch('/v3/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `query upcLookup($upc: String!) {
          upcLookup(upc: $upc) {
            title
            width
            height
            length
            description
            manufacturer
            brand
            model
            color
            gender
            material
            pattern
            releaseDate
            size
            author
            publisher
          }
        }`,
        variables: {
          upc: this.state.upc,
        },
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.errors) {
          this.setState({ error: result.errors[0].message, loading: false });
          if (window.analytics !== undefined) {
            window.analytics.track('UPC Scanned', {
              barcode: this.props.barcode,
              upc: this.state.upc,
            });
          }
          return;
        }
        this.setState({ item: result.data.upcLookup, isUpcOpen: false, isAutofillOpen: true, loading: false });
        if (window.analytics !== undefined) {
          window.analytics.track('UPC Scanned', {
            barcode: this.props.barcode,
            upc: this.state.upc,
            upc_info: result.data.upcLookup,
          });
        }
      });
  };

  // helper funtion to check if string values are nil
  isValidValue = (data: any) => {
    if (data !== null && data !== '') {
      return data;
    }
  };

  // check if values are nil, if not - adds to item data as expected
  confirmAutofill = () => {
    const itemData = {};
    const bookData = [];

    // title
    if (this.isValidValue(this.state.item.title)) {
      itemData.name = this.state.item.title;
    }
    // width
    if (this.isValidValue(this.state.item.width)) {
      itemData.width = this.state.item.width;
    }
    // height
    if (this.isValidValue(this.state.item.height)) {
      itemData.height = this.state.item.height;
    }
    // length
    if (this.isValidValue(this.state.item.length)) {
      itemData.depth = this.state.item.length;
    }
    // description
    if (this.isValidValue(this.state.item.description)) {
      itemData.description = (this.props.originalDescription) ?
        `${this.props.originalDescription} ${this.state.item.description}` : this.state.item.description;
    }

    // book info
    if (this.isValidValue(this.state.item.author)) {
      bookData.push({ role: 'Author', value: this.state.item.author });
    }

    if (this.isValidValue(this.state.item.publisher)) {
      bookData.push({ role: 'Publisher', value: this.state.item.publisher });
    }


    // the items below are to be used for filling in category variants and attributes
    // variantString below is used for property autofill
    let variantString = '';

    // manufacturer
    if (this.isValidValue(this.state.item.manufacturer)) {
      variantString += ` ${this.state.item.manufacturer}`;
    }
    // brand
    if (this.isValidValue(this.state.item.brand)) {
      variantString += ` ${this.state.item.brand}`;
    }
    // model
    if (this.isValidValue(this.state.item.model)) {
      variantString += ` ${this.state.item.model}`;
    }
    // color
    if (this.isValidValue(this.state.item.color)) {
      variantString += ` ${this.state.item.color}`;
    }
    // gender
    if (this.isValidValue(this.state.item.gender)) {
      variantString += ` ${this.state.item.gender}`;
    }
    // meterial
    if (this.isValidValue(this.state.item.material)) {
      variantString += ` ${this.state.item.material}`;
    }
    // pattern
    if (this.isValidValue(this.state.item.pattern)) {
      variantString += ` ${this.state.item.pattern}`;
    }
    // release_date
    if (this.isValidValue(this.state.item.release_date)) {
      variantString += ` ${this.state.item.release_date}`;
    }
    // size
    if (this.isValidValue(this.state.item.size)) {
      variantString += ` ${this.state.item.size}`;
    }

    this.props.onDataChange(itemData, variantString, bookData);

    this.setState({ isAutofillOpen: false });
  };

  rejectUPC = () => {
    this.setState({ isUpcOpen: false });
  };

  rejectAutofill = () => {
    this.setState({ isAutofillOpen: false });
  };

  modalOpen = () => {
    this.setState({ isUpcOpen: true });
    this.setState({ upc: '' });
    this.setState({ error: '' });
  };

  updateStateObject = (objectName: string, value: string) => {
    const data = this.state.item;
    data[objectName] = value;
    this.setState({ item: data });
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && this.state.upc !== '') { // enter
      e.preventDefault();
      if (this.state.isUpcOpen) {
        this.confirmUpc();
      } else if (this.state.isAutofillOpen) {
        this.confirmAutofill();
      }
    }
    if (e.keyCode === 27) { // esc
      e.preventDefault();
      this.rejectUPC();
      this.rejectAutofill();
    }
  }

  updateUpcValue = (value: string) => {
    const startLength = this.state.upc.length;
    Promise.resolve()
      .then(() => { this.setState({ upc: value }); })
      .then(() => {
        if (value.length > 7 && startLength === 0) {
          this.confirmUpc();
        }
      });
  }

  render() {
    return (
      <div className="js-modal-parent">
        <Button
          onClick={this.modalOpen}
          type="button"
          buttonStyle="success"
          className="u-block u-full-width u-mb2"
        >
          Click To Enter UPC
        </Button>
        <Modal isOpen={this.state.isUpcOpen}>
          <div className="modal-box">
            <div className="u-text-center">
              <h2>Scan or Enter the UPC</h2>
              <TextInput
                id="upcEnter"
                onChange={(value) => this.updateUpcValue(value)}
                value={this.state.upc}
                onKeyDown={this.handleKeyDown}
                autoFocus
              />
              <h5 hidden={!this.state.error}>This UPC does NOT match database entries</h5>

              {this.state.loading && (
                <div className="u-text-center">
                  <Spinner label="Searching..." />
                </div>
              )}

              <Button
                buttonStyle="secondary-inverse"
                size="jumbo"
                className="qa-modal-reject u-ml1 u-mr1"
                onClick={this.rejectUPC}
              >
                Cancel
              </Button>
              <Button
                buttonStyle="success"
                size="jumbo"
                className="qa-modal-confirm u-ml1 u-mr1"
                onClick={this.confirmUpc}
                disabled={!this.state.upc}
              >
                Confirm UPC
              </Button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.isAutofillOpen}>
          <div className="modal-box long wide">
            <div className="u-text-center">
              <h2>Confirm UPC Lookup Data</h2>
              <TextInput
                id="apiBarcode"
                label={'Barcode'}
                onChange={(value) => this.updateStateObject('barcode_number', value)}
                value={this.state.upc}
                onKeyDown={this.handleKeyDown}
                autoFocus
              />
              {this.state.item.title !== '' &&
                <TextInput
                  id="apiTitle"
                  label={'Title'}
                  onChange={(value) => this.updateStateObject('title', value)}
                  value={this.state.item.title}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.author !== '' &&
                <TextInput
                  id="apiAuthor"
                  label={'Author'}
                  onChange={(value) => this.updateStateObject('author', value)}
                  value={this.state.item.author}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.publisher !== '' &&
                <TextInput
                  id="apiPublisher"
                  label={'Publisher'}
                  onChange={(value) => this.updateStateObject('publisher', value)}
                  value={this.state.item.publisher}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.model !== '' &&
                <TextInput
                  id="apiModel"
                  label={'Model'}
                  onChange={(value) => this.updateStateObject('model', value)}
                  value={this.state.item.model}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.model !== '' &&
                <TextInput
                  id="apiManufacturer"
                  label={'Manufacturer'}
                  onChange={(value) => this.updateStateObject('manufacturer', value)}
                  value={this.state.item.manufacturer}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.brand !== '' &&
                <TextInput
                  id="apiBrand"
                  label={'Brand'}
                  onChange={(value) => this.updateStateObject('brand', value)}
                  value={this.state.item.brand}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.color !== '' &&
                <TextInput
                  id="apiColor"
                  label={'Color'}
                  onChange={(value) => this.updateStateObject('color', value)}
                  value={this.state.item.color}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.gender !== '' &&
                <TextInput
                  id="apiGender"
                  label={'Gender'}
                  onChange={(value) => this.updateStateObject('gender', value)}
                  value={this.state.item.gender}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.material !== '' &&
                <TextInput
                  id="apiMaterial"
                  label={'Material'}
                  onChange={(value) => this.updateStateObject('material', value)}
                  value={this.state.item.material}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.pattern !== '' &&
                <TextInput
                  id="apiPattern"
                  label={'Pattern'}
                  onChange={(value) => this.updateStateObject('pattern', value)}
                  value={this.state.item.pattern}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.size !== '' &&
                <TextInput
                  id="apiSize"
                  label={'Size'}
                  onChange={(value) => this.updateStateObject('size', value)}
                  value={this.state.item.size}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.width !== '' &&
                <TextInput
                  id="apiWidth"
                  label={'Width'}
                  onChange={(value) => this.updateStateObject('width', value)}
                  value={this.state.item.width}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.height !== '' &&
                <TextInput
                  id="apiHeight"
                  label={'Height'}
                  onChange={(value) => this.updateStateObject('height', value)}
                  value={this.state.item.height}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.length !== '' &&
                <TextInput
                  id="apiLength"
                  label={'Length'}
                  onChange={(value) => this.updateStateObject('length', value)}
                  value={this.state.item.length}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.releaseDate !== '' &&
                <TextInput
                  id="apiReleaseDate"
                  label={'Release Date'}
                  onChange={(value) => this.updateStateObject('release_date', value)}
                  value={this.state.item.releasedate}
                  onKeyDown={this.handleKeyDown}
                />
              }
              {this.state.item.description !== '' &&
                <TextareaInput
                  id="apiDescription"
                  label={'Description'}
                  rows="5"
                  onChange={(value) => this.updateStateObject('description', value)}
                  value={this.state.item.description}
                  onKeyDown={this.handleKeyDown}
                />
              }
              <Button
                buttonStyle="secondary-inverse"
                size="jumbo"
                className="qa-modal-reject u-ml1 u-mr1"
                onClick={this.rejectAutofill}
              >
                Cancel
              </Button>
              <Button
                buttonStyle="success"
                size="jumbo"
                className="qa-modal-confirm u-ml1 u-mr1"
                onClick={this.confirmAutofill}
              >
                Add Data
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export { UpcLookupModal };
