import React from 'react';
import classNames from 'classnames';

function SidebarLink(props) {
  const {
    text,
    link,
  } = props;

  const active = window.location.pathname.includes(link);

  const liClasses = classNames(
    'sidebar-nav__item',
    { 'sidebar-nav__item--active': active },
  );

  return (
    <li className={liClasses}>
      <a
        className="sidebar-nav__link"
        href={link}
      >
        {text}
      </a>
    </li>
  );
}

export default SidebarLink;
