// @flow
import type { FeatureFlagsType } from '~/admin/shared/utils/FeatureFlags';

export const LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS';
export type LoadFeatureFlagsAction = {
  type: 'LOAD_FEATURE_FLAGS',
  featureFlags: FeatureFlagsType,
};

export type Actions =
  LoadFeatureFlagsAction;

// eslint-disable-next-line
export const loadFeatureFlags = (featureFlags: FeatureFlagsType): LoadFeatureFlagsAction => (
  {
    type: LOAD_FEATURE_FLAGS,
    featureFlags,
  }
);
