// @flow

import React, { Component } from 'react';
import SaleHeader from './components/SaleHeader';
import type { SaleStates } from '~/public/shared/types/Sale';

type Props = {
  saleId: ID,
  state: SaleStates,
  saleCode: string,
  name: string,
  descriptionHtml: ?string,
  itemCount: number,
  saleEndsAt: ?string,
  twitterShareUrl: string,
  facebookShareUrl: string,
  pinterestShareUrl: string,
  emailShareUrl: string,
  mainImageUrl: string,
  addToCalendarUrl?: string,
  isFollowingSale: boolean,

  // Analytics props for following a sale
  saleType: string,
  regionId: ID,
  daysRemaining: number,
};

type State = {
  isFollowingSale: boolean,
};

class Application extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFollowingSale: this.props.isFollowingSale,
    };
  }

  trackFollowSale = () => {
    if (!window.analytics) return;

    window.analytics.track('Follow Sale', {
      sale_id: this.props.saleId,
      sale_code: this.props.saleCode,
      sale_type: this.props.saleType,
      region_id: this.props.regionId,
      item_count: this.props.itemCount,
      days_remaining: this.props.daysRemaining,
    });
  }

  requestNotificationPermissions = () => {
    if (!window.appboy) return;

    window.appboy.logCustomEvent('prime-for-push');
  }

  followSale = async () => {
    try {
      const response = await fetch(`/sales/${this.props.saleId}/toggle_follow`, {
        credentials: 'include',
        headers: {
          // Tell rails this is an XHR request, so it doesn't redirect us
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      if (response.ok) {
        this.trackFollowSale();
        this.requestNotificationPermissions();
        this.setState({ isFollowingSale: true });
      } else if (response.status === 401) {
        // When the user is not logged in, show the sign up modal
        $('#sign-up').modal('open');
      }
    } catch (error) {
      console.error(error);
    }
  }

  unfollowSale = async () => {
    try {
      await fetch(`/sales/${this.props.saleId}/toggle_follow`, {
        credentials: 'include',
        headers: {
          // Tell rails this is an XHR request, so it doesn't redirect us
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      this.setState({ isFollowingSale: false });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const saleEndsAt = this.props.saleEndsAt ? new Date(this.props.saleEndsAt) : null;

    return (
      <SaleHeader
        saleCode={this.props.saleCode}
        state={this.props.state}
        name={this.props.name}
        descriptionHtml={this.props.descriptionHtml}
        itemCount={this.props.itemCount}
        daysRemaining={this.props.daysRemaining}
        saleEndsAt={saleEndsAt}
        mainImageUrl={this.props.mainImageUrl}
        twitterShareUrl={this.props.twitterShareUrl}
        facebookShareUrl={this.props.facebookShareUrl}
        pinterestShareUrl={this.props.pinterestShareUrl}
        emailShareUrl={this.props.emailShareUrl}
        addToCalendarUrl={this.props.addToCalendarUrl}
        onFollowSale={this.followSale}
        onUnfollowSale={this.unfollowSale}
        isFollowingSale={this.state.isFollowingSale}
        getReferenceTime={window.EBTH.timesync.now}
      />
    );
  }
}

export default Application;
