// @flow

import type { ItemProperty } from '~/admin/cataloging/types';

type PropertyMap = {
  [string]: Array<ItemProperty>,
};

export default function buildItemProperties(properties: Array<ItemProperty>): PropertyMap {
  return properties.reduce((acc, property) => {
    const propId = (property.categoryPropertySetId) ? `${property.propertyId}_${property.categoryPropertySetId}` : property.propertyId;
    if (acc[propId]) {
      acc[propId] = [...acc[propId], property];
    } else {
      acc[propId] = [property];
    }
    return acc;
  }, {});
}
