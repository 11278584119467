
// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { Match, RouterHistory } from 'react-router';
import { compose, withApollo } from 'react-apollo';
import { type ApolloError, type ApolloClient } from 'apollo-client';

import * as CommentsActions from '~/admin/shared/actions/comments';
import { displayNotice } from '~/admin/shared/actions/notice';
import {
  startScreen, cancelScreen, completePhotoEditingItem, enqueuePhotoEditingItem,
} from '~/admin/shared/actions/screen';
import ItemLoadingComponent from '~/admin/shared/ItemLoadingComponent';
import UploadProgress from '~/admin/photo_editing/components/UploadProgress';

import PhotoGridContainer from '../containers/PhotoGridContainer';
import { ItemYankedModal, ItemLiveModal } from '~/admin/shared/components';

import DeletePhotoMutation, { type DeletePhotoFn } from '~/admin/photo_editing/mutations/DeletePhotoMutation';
import ReorderPhotosMutation, { type ReorderPhotosFn } from '~/admin/photo_editing/mutations/ReorderPhotosMutation';
import UploadTargetContainer from '../containers/UploadTargetContainer';
import { type ConnectionType, type AttachmentType, type PhotographyItem } from '~/admin/photo_editing/types';

type Props = {
  // passed via react-router render
  match: Match,
  history: RouterHistory,

  // passed via redux connect HOC
  displayNotice: typeof displayNotice,
  closeComments: typeof CommentsActions.closeComments,
  openComments: typeof CommentsActions.openComments,
  startScreen: typeof startScreen,
  completePhotoEditingItem: typeof completePhotoEditingItem,
  enqueuePhotoEditingItem: typeof enqueuePhotoEditingItem,
  cancelScreen: typeof cancelScreen,

  // passed via withItem HOC
  loading: boolean,
  item: PhotographyItem,
  error: ApolloError,
  refetch: () => Promise<any>,

  // passed via withApollo HOC
  client: ApolloClient,
};

type State = {
  photosUploading: boolean,
};

class PhotoUpload extends Component<Props, State> {
  state = {
    photosUploading: false,
  };

  // componentDidUpdate(prevProps: Props) {
  //   const { item, history } = this.props;

  //   // If prevProps has an item, means this is getting hit multiple times. We only want
  //   // to run this the very first time the page receives an item.
  //   if (!prevProps.item && item) {
  //     if (item.aasmState === 'voided') {
  //       this.props.history.push('/admin/photography');
  //       this.props.displayNotice({
  //         message: `This item has been voided: ${item.yankReason ||
  //           'No reason'}. Voided items cannot be processed further.`,
  //         type: 'danger',
  //       });
  //     } else {
  //       this.props.startScreen({
  //         history,
  //         itemName: item.barcode,
  //         itemId: item.id,
  //         taskType: 'PHOTO_EDITING',
  //         queueable: true,
  //       });
  //     }
  //   }
  // }

  editPhoto = (photoId: ID) => {
    this.props.history.push(`/admin/photography/photo/${photoId}`);
  };

  handleDeletePhoto = (deletePhoto: DeletePhotoFn, refetch: Function) => async (photoId: ID) => {
    try {
      await deletePhoto({ id: photoId });
      refetch();
    } catch (error) {
      this.props.displayNotice({
        type: 'danger',
        message: `Error while deleting photo: ${error.toString()}`,
      });
    }
  };

  handleReorderSuccess = () => {
    this.props.displayNotice({
      type: 'success',
      message: 'Photos reordered successfully.',
      autoDismiss: true,
    });
  };

  handleReorderError = (errorMessage: string) => {
    this.props.displayNotice({
      type: 'danger',
      message: `Could not reorder photos: ${errorMessage}`,
    });
  };

  onDropRejected = () => {
    this.props.displayNotice({
      type: 'danger',
      message: 'The file type is not supported. Please use a .jpeg',
      autoDismiss: true,
    });
  };

  handleReorderPhotos = (item: PhotographyItem, reorderPhotosMutation: ReorderPhotosFn) => (
    reorderedAttachments: ConnectionType<AttachmentType>
  ) => {
    reorderPhotosMutation({
      itemId: item.id,
      attachments: reorderedAttachments,
    });
  };

  handleCompletePhotoEditingTask = (item: PhotographyItem) => {
    // If there's no task, just go back to the item in admin
    if (item.openTasks.length === 0) {
      this.props.history.location.state = {
        windowPath: `/admin/items/${item.id}/attachments`,
      };
    }

    this.props.completePhotoEditingItem({
      client: this.props.client,
      history: this.props.history,
      itemId: item.id,
      itemName: item.barcode,
    });
  };

  handleEnqueuePhotoEditingTask = (item: PhotographyItem) => {
    this.props.enqueuePhotoEditingItem({
      client: this.props.client,
      history: this.props.history,
      itemId: item.id,
      itemName: item.barcode,
      queueId: null,
    });
  };

  handleConfirmationResult = (result: boolean) => {
    if (!result) {
      this.props.cancelScreen();
      this.props.history.replace('/admin/photography/queues');
    }
  };

  handleUploading = (uploading: boolean) => {
    this.setState({
      photosUploading: uploading,
    });
  }

  // renderActionsFooter = (item: PhotographyItem, isReorderPhotosInFlight) => {
  //   const isLive = item.aasmState === 'for_sale';
  //   const disableEnqueueReason = isLive ? 'Cannot queue live items' : null;
  //   const missingTask = (item.openTasks.length === 0);

  //   return (
  //     <ActionsBar
  //       blockActions={isReorderPhotosInFlight}
  //       onCompletePhotoEditing={() => this.handleCompletePhotoEditingTask(item)}
  //       onReturnToQueue={() => this.handleEnqueuePhotoEditingTask(item)}
  //       disableEnqueueButtonReason={disableEnqueueReason}
  //       missingTask={missingTask}
  //     />
  //   );
  // };

  // renderHoldAlert = (item: PhotographyItem) => {
  //   if (item.aasmState !== 'held') {
  //     return null;
  //   }

  //   return (
  //     <Alert
  //       className="u-m1"
  //       type="danger"
  //       message={<strong>This item is on hold: {item.yankReason || 'No reason'}.</strong>}
  //       icon={<ActionSvgIcon icon="ic_info_24px" className="section-notice__icon icon" />}
  //     />
  //   );
  // };

  renderItem = ({
    item, deletePhoto, refetch, reorderPhotosMutation, isReorderPhotosInFlight, handleUploading,
  }) => {
    return (
      <UploadTargetContainer itemId={item.id} photosUploading={handleUploading}>
        {({ getRootProps }) => (
          <div className="l-page-form" {...getRootProps()}>

            <PhotoGridContainer
              itemId={item.id}
              attachments={item.attachments}
              deletePhoto={this.handleDeletePhoto(deletePhoto, refetch)}
              editPhoto={this.editPhoto}
              reorderPhotos={this.handleReorderPhotos(item, reorderPhotosMutation)}
              isReorderPhotosInFlight={isReorderPhotosInFlight}
              photosUploading={handleUploading}
            />

          </div>
        )}
      </UploadTargetContainer>
    );
  };

  render() {
    const {
      error, loading, item, refetch,
    } = this.props;

    if (error) return <div>There was an error loading the item: {error.toString()}</div>;

    if (loading) return <ItemLoadingComponent />;

    const { photosUploading } = this.state;
    const setUploadLoading = this.handleUploading;

    if (item) {
      return (
        <React.Fragment>
          <ReorderPhotosMutation
            onSuccess={this.handleReorderSuccess}
            onError={this.handleReorderError}
          >
            {(reorderPhotosMutation, { loading: isReorderPhotosInFlight }) => (
              <DeletePhotoMutation>
                {(deletePhoto) => this.renderItem({
                  item: item,
                  deletePhoto,
                  refetch,
                  reorderPhotosMutation,
                  isReorderPhotosInFlight,
                  handleUploading: setUploadLoading,
                })
                }
              </DeletePhotoMutation>
            )}
          </ReorderPhotosMutation>
          <ItemYankedModal item={item} onResult={this.handleConfirmationResult} />
          <ItemLiveModal item={item} onResult={this.handleConfirmationResult} />
          <UploadProgress isLoading={photosUploading} />
        </React.Fragment>
      );
    }

    return <div>Something went very, very wrong</div>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...CommentsActions,
      displayNotice,
      startScreen,
      cancelScreen,
      completePhotoEditingItem,
      enqueuePhotoEditingItem,
    },
    dispatch
  );
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  withApollo
)(PhotoUpload);
