// @flow

import { pick } from 'lodash';
import { applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

import * as AnalyticsActions from '~/admin/shared/actions/analytics';
import * as NoticeActions from '~/admin/shared/actions/notice';
import * as CommentsActions from '~/admin/shared/actions/comments';
import * as FeatureFlagActions from '~/admin/shared/actions/featureFlags';
import * as AppRevisionActions from '~/admin/shared/actions/appRevision';
import * as SettingsActions from '~/admin/shared/actions/settings';
import * as ScreenActions from '~/admin/shared/actions/screen';
import * as MerchandisingActions from '~/admin/shared/actions/merchandising';
import * as PendingItemsActions from '~/admin/pending_items/actions';
import * as CatalogingActions from '~/admin/shared/actions/cataloging';

import analytics from '~/admin/shared/reducers/analytics';
import notice from '~/admin/shared/reducers/notice';
import comments from '~/admin/shared/reducers/comments';
import featureFlags from '~/admin/shared/reducers/featureFlags';
import appRevision from '~/admin/shared/reducers/appRevision';
import settings from '~/admin/shared/reducers/settings';
import screen from '~/admin/shared/reducers/screen';
import merchandising from '~/admin/shared/reducers/merchandising';
import pendingItems from '~/admin/pending_items/reducers';
import cataloging from '~/admin/shared/reducers/cataloging';

import createStoreWithLocalStorageCache from './createStoreWithLocalStorageCache';

const createStore = createStoreWithLocalStorageCache('ebth:admin:store', (store) => {
  return pick(store, ['settings', 'cataloging', 'propertyManagement']);
});

function devToolEnhancer() {
  // The list of actions in the Redux tools
  const actionCreators = {
    ...AnalyticsActions,
    ...NoticeActions,
    ...CommentsActions,
    ...FeatureFlagActions,
    ...AppRevisionActions,
    ...SettingsActions,
    ...ScreenActions,
    ...MerchandisingActions,
    ...PendingItemsActions,
    ...CatalogingActions,
  };

  if (window.devToolsExtension) {
    return window.devToolsExtension({ actionCreators });
  } else {
    return (noop) => noop;
  }
}

export default function configureStore(otherReducers: any) {
  const reducers = combineReducers({
    analytics,
    notice,
    comments,
    featureFlags,
    appRevision,
    settings,
    screen,
    merchandising,
    pendingItems,
    cataloging,
    ...otherReducers,
  });

  const enhancer = compose(
    applyMiddleware(thunk),
    devToolEnhancer()
  );

  const store = createStore(reducers, {}, enhancer);

  return store;
}
