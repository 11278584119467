// @flow

import PubNub from 'pubnub';
import type { Listener } from 'pubnub';
import type { ItemChange } from '../types';

const parseDate = (value: any): ?Date => {
  const type = typeof value;

  if (type === 'string' || type === 'object') {
    return new Date(value);
  } else {
    return null;
  }
};

class SaleEvents {
  pubnub: PubNub

  listener: Listener

  constructor(pubnub: PubNub) {
    this.pubnub = pubnub;
  }

  listen = (saleId: string, onItemChange: (ItemChange) => void): () => void => {
    const listener = {
      message: ({ actualChannel, message }) => {
        const id = actualChannel.split('_')[1];

        onItemChange({
          id,
          aasmState: String(message.aasm_state),
          bidsCount: Number(message.bids_count),
          highBidAmount: Number(message.high_bid_amount),
          minimumBidAmount: Number(message.minimum_bid_amount),
          isExtended: Boolean(message.extended),
          saleEndsAt: parseDate(message.sale_ends_at),
        });
      },
    };

    this.pubnub.addListener(listener);

    this.pubnub.subscribe({
      channelGroups: [`sale_${saleId}`],
    });

    const unsubscribe = () => {
      this.pubnub.removeListener(this.listener);

      this.pubnub.unsubscribe({
        channelGroups: [`sale_${saleId}`],
      });
    };

    return unsubscribe;
  }
}

export default SaleEvents;
