// @flow

import { decamelizeKeys } from 'humps';

const apiURL = '/value-estimator';

type SubmissionInput = {|
  email: string,
  zip: string,
  description: string,
  firstName: string,
  lastName: string,
  attachments: Array<{ name: string, uploadKey: string }>,
|};

export default async (input: SubmissionInput): Promise<{ errors: { [string]: Array<string> } }> => {
  const body = decamelizeKeys({ estimateRequestForm: input });
  const opts = {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
  };

  const response = await fetch(apiURL, opts);
  if (response.ok) {
    return { errors: {} };
  } else {
    const { errors }: { errors: { [string]: Array<string> } } = await response.json();
    return { errors };
  }
};
