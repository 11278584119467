// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';

export type RadioOption<T> = {
  label: string | Node,
  value: T,
  disabled?: boolean,
  tooltip?: ?string,
  labelClassName?: string,
};

type Props<T> = {
  id: string,
  className?: string,
  horizontalGroup?: boolean,
  onChange: (newValue: T) => void,
  options: RadioOption<T>[],
  value: T,
};

export class Radio<U> extends Component<Props<U>> {
  handleChange = (option: RadioOption<U>) => (_event: SyntheticEvent<HTMLInputElement>) => {
    this.props.onChange(option.value);
  };

  // HACK: why is this RadioOptions<any>? IDK, but it makes flow happy
  renderCheckboxOption = (option: RadioOption<any>, index: number) => {
    const key = `${this.props.id}__${index}`;
    const isSelected = this.props.value === option.value;
    const classes = classNames('custom-control', 'custom-control--radio', this.props.className);
    const labelClasses = classNames('custom-control__label', option.labelClassName);

    return (
      <label key={key} htmlFor={key} title={option.tooltip} className={classes}>
        <input
          id={key}
          type="radio"
          name={this.props.id}
          disabled={option.disabled}
          className="custom-control__input"
          onChange={this.handleChange(option)}
          checked={isSelected}
          value={option.label}
        />
        {option.label && <div className={labelClasses}>{option.label}</div>}
      </label>
    );
  };

  render() {
    const classes = classNames(
      'u-mb0',
      { 'form-control__input': !this.props.horizontalGroup },
      { 'custom-control-group': this.props.horizontalGroup }
    );

    return <div className={classes}>{this.props.options.map(this.renderCheckboxOption)}</div>;
  }
}
