// @flow

import { LOAD_FEATURE_FLAGS } from '../actions/featureFlags';
import FeatureFlags from '../utils/FeatureFlags';

import type { Actions } from '../actions/featureFlags';


export type FeatureFlagState = FeatureFlags;


export default (state: FeatureFlagState = new FeatureFlags({}), action: Actions): FeatureFlags => {
  switch (action.type) {
    case LOAD_FEATURE_FLAGS:
      return new FeatureFlags(action.featureFlags);
    default:
      return state;
  }
};
