// @flow

import { ApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

import { MerchItemsFragment } from '../queries/ItemSearchQuery';

const MUTATION = gql`
  mutation AddItemsToSale($input: AddItemsToSaleInput!) {
    addItemsToSale(input: $input) {
      items {
        ...merchItemsFields
      }
    }
  }
  ${MerchItemsFragment}
`;

export type AddItemsToSaleParams = {
  saleId: ID,
  itemIds: ID[],
}

export default function (client: ApolloClient) {
  return async (itemMoveParams: AddItemsToSaleParams) => (
    client.mutate({
      mutation: MUTATION,
      variables: { input: itemMoveParams },
      fetchPolicy: 'no-cache',
    })
  );
}
