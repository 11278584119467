// @flow

import React, { PureComponent } from 'react';
import { Icon, Tooltip } from '~/public/shared/components';
import type { SingleSelectOption } from '../types';

type Props = {
  option: SingleSelectOption,
  checked: boolean,
  onChange: Function,
  isModalContext: boolean,
}

class FilterSingleSelectItem extends PureComponent<Props> {
  render() {
    const {
      onChange,
      checked,
      option,
      isModalContext,
    } = this.props;
    const {
      id,
      name,
      description,
      count,
    } = option;
    const showCount = count != null;

    return (
      <li className="items-filter__item">
        <input
          name="status"
          id={id}
          value={id}
          onChange={onChange}
          checked={checked}
          type="radio"
          className="items-filter-item__input"
        />
        <label
          htmlFor={id}
          className="items-filter-item__label items-filter-item__label--radio"
        >
          {name}
        </label>
        { description !== '' && !isModalContext &&
          <Tooltip content={description} placement="right">
            <Icon icon="info" className="items-filter-item__info" />
          </Tooltip>
        }
        { showCount &&
          <span className="items-filter-item__count">{count}</span>
        }
        { isModalContext &&
          <div className="items-filter-item__info-mobile">
            {description}
          </div>
        }
      </li>
    );
  }
}

export default FilterSingleSelectItem;
