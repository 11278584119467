// @flow

import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import type { Location, RouterHistory, Match } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TimesyncContext from '../contexts/TimesyncContext';
import { ItemHeader } from '../components/ItemHeader';
import { ItemListing } from '../components/ItemListing';
import Layout from '../components/Layout';
import {
  Badge,
  Button,
  Icon,
  LoadingDots,
  TimeRemaining,
} from '~/public/shared/components';
import type { Item } from '../types';

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
};

const ITEM_QUERY = gql`
  query item($id: ID!) {
    item(id: $id) {
      id
      name
      barcode
      saleStartsAt
      saleEndsAt
      aasmState
      highBidAmount
      bidsCount
      saleId
      mainAttachment {
        imageUrl
        isInternal
      }
      quickpicAttachment {
        imageUrl
      }
      contract {
        number
        commissionRate
      }
      processingLocation {
        displayName
      }
      publicItemPath
      processStatus
      yankReasonDisplayName
      yankReasonDescription
      yankReasonDisplayName,
      yankReasonExternalDisplayName
    }
  }
`;

class ItemDetailPage extends Component<Props> {
  componentDidMount() {
    document.title = 'Items | Seller Dashboard';
  }

  renderStatus = (item: Item) => {
    const {
      processStatus,
      saleStartsAt,
      saleEndsAt,
      // yankReasonDisplayName,
      yankReasonDescription,
      yankReasonExternalDisplayName,
      aasmState,
    } = item;
    let badgeStyle;
    let badgeTooltip;
    let statusText;

    const isLive = processStatus === 'live';

    if (isLive) {
      badgeStyle = 'positive';
    } else if ((['processing', 'preview'].includes(processStatus))) {
      badgeStyle = 'neutral';
    } else if (processStatus === 'omitted') {
      badgeStyle = 'warning';
    }

    if (yankReasonExternalDisplayName) {
      const description = yankReasonDescription ? <div className="u-mt1">{yankReasonDescription}</div> : null;
      badgeTooltip = <Fragment><strong>{yankReasonExternalDisplayName}</strong>{description}</Fragment>;
    }

    if (processStatus === 'ended') {
      statusText = moment(saleEndsAt).format('M/DD/YY');
    } else if (!['omitted', 'live'].includes(processStatus) && saleStartsAt) {
      statusText = `Starts ${moment(saleStartsAt).format('M/DD/YY')}`;
    }

    return (
      <Fragment>
        <Badge
          status={badgeStyle}
          infoTooltip={badgeTooltip}
          style={{ textTransform: 'capitalize' }}
          className={(statusText || isLive) ? 'u-mr1' : ''}
        >
          {processStatus}
        </Badge>
        {isLive &&
          <TimesyncContext.Consumer>
            {(timesync) => (
              <TimeRemaining
                extended={aasmState === 'extended'}
                endsAt={moment(saleEndsAt)}
                aasmState={aasmState}
                getReferenceTime={timesync.now}
              />
            )}
          </TimesyncContext.Consumer>
        }
        {statusText &&
          <span className="u-text-muted u-no-wrap">{statusText}</span>
        }
      </Fragment>
    );
  }

  maybeRenderMarketplaceLink = (item: Item) => {
    const { processStatus, publicItemPath } = item;

    if (['omitted', 'processing'].includes(processStatus)) return null;

    return (
      <div className="u-text-right">
        <a
          href={publicItemPath}
          className="btn btn--secondary"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="open-in-new" className="btn__icon" />
          {processStatus === 'preview' ? 'Preview' : 'View'} In Marketplace
        </a>
      </div>
    );
  }

  maybeRenderStartTime = (item: Item) => {
    const { saleStartsAt } = item;

    if (saleStartsAt) {
      return (
        <tr>
          <td className="u-color-gray-400">Auction {Date.parse(saleStartsAt) < Date.now() ? 'Started' : 'Starts'}</td>
          <td>{moment(saleStartsAt).format('M/DD/YY @ h:mma')} EST</td>
        </tr>
      );
    }
  }

  maybeRenderEndTime = (item: Item) => {
    const { processStatus, saleEndsAt } = item;
    let labelText;

    if (processStatus === 'ended') {
      labelText = 'Auction Ended At';
    } else if (saleEndsAt) {
      labelText = 'Auction Ends At';
    }

    if (labelText) {
      return (
        <tr>
          <td className="u-color-gray-400">Auction {processStatus === 'ended' ? 'Ended' : 'Ends'}</td>
          <td>{moment(saleEndsAt).format('M/DD/YY @ h:mma')} EST</td>
        </tr>
      );
    }
  }

  render() {
    const gridStyle = '100px 3fr 2fr 1fr';
    const { history, match } = this.props;
    const { params: { itemNumber: id } } = match;
    const variables = { id };

    return (
      <Layout>
        <div className="u-mb2">
          <Button
            buttonStyle="text"
            buttonSize="lg"
            onClick={() => { history.goBack(); }}
          >
            <Icon icon="chevron-left" className="btn__icon" />
            Back
          </Button>
        </div>

        <ItemHeader gridStyle={gridStyle} />

        <Query query={ITEM_QUERY} variables={variables}>
          {({ loading, data }) => {
            const { item } = data;

            if (loading) {
              return (
                <div className="u-mt8 u-text-center">
                  <LoadingDots />
                </div>
              );
            }

            const {
              barcode,
              contract,
              processingLocation,
              bidsCount,
              processStatus,
            } = item;

            const showBidInfo = ['live', 'ended'].includes(processStatus);
            const showProcessingLocation = processingLocation && !['ended', 'omitted'].includes(processStatus);

            return (
              <div className="expandable-listing expandable-listing--expanded">
                <ItemListing gridStyle={gridStyle} item={item} />
                <div className="expandable-listing__details">
                  {this.maybeRenderMarketplaceLink(item)}
                  <table className="table table--beta u-m0">
                    <tbody>
                      <tr>
                        <td className="u-color-gray-400">ID</td>
                        <td>{barcode}</td>
                      </tr>
                      {this.maybeRenderStartTime(item)}
                      {this.maybeRenderEndTime(item)}
                      {showBidInfo &&
                        <tr>
                          <td className="u-color-gray-400">Bids</td>
                          <td>{showBidInfo ? bidsCount : <span className="u-text-muted">—</span>}</td>
                        </tr>
                      }
                      <tr>
                        <td className="u-color-gray-400">Contract</td>
                        <td><Link to={`/contracts/${contract.number}`}>{contract.number}</Link></td>
                      </tr>
                      {showProcessingLocation &&
                        <tr>
                          <td className="u-color-gray-400">Location</td>
                          <td>{processingLocation.displayName}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export { ItemDetailPage };
