import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../components/Sidebar';
import CategoryTiles from '../components/CategoryTiles';

import * as ItemsActions from '../actions/items';

class CategoriesContainer extends Component {
  componentDidMount() {
    const { pathCategorySlug, resetFilters, fetchItems, isLoading, filters } = this.props;

    // Clear the selected category path so it goes back to root
    if (pathCategorySlug && !isLoading) {
      resetFilters();
      fetchItems();
    } else if ((!filters || filters.length === 0) && !isLoading) {
      // Nothing has been loaded, trigger a fetch
      fetchItems();
    }
  }

  render() {
    const { filters, appliedParams } = this.props;

    // Only include the category tree filter
    const categoryFilter = filters.filter((filter) => filter.component === 'category');

    return (
      <div className="layout items-layout" id="top">
        <aside className="items-layout__sidebar">
          <Sidebar filters={categoryFilter} appliedParams={appliedParams} />
        </aside>
        <main className="items-layout__body">
          <CategoryTiles />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  filters: store.items.filters,
  isLoading: store.items.isLoading,
  appliedParams: store.items.appliedParameters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItems: () => dispatch(ItemsActions.fetchItems({})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesContainer);
