// @flow

import { Component } from 'react';


type Props = {
  children: ?any,
  milliseconds: number,
}

type State = {
  shouldDisplay: boolean,
};


class DisplayAfter extends Component<Props, State> {
  state = {
    shouldDisplay: false,
  };

  componentWillMount() {
    this.clear();

    this.timeout = setTimeout(() => {
      this.setState({
        shouldDisplay: true,
      });
    }, this.props.milliseconds);
  }

  componentWillUnmount() {
    this.clear();
  }

  timeout: ?TimeoutID

  clear() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    if (this.state.shouldDisplay) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

export default DisplayAfter;
