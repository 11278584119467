// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

type Props = {
  id: string,
  label: string,
  onChange: (string) => void,
  placeholder?: string,
  value: string,
  helperText: string,
  errors?: Array<string>,
};


class TextAreaInput extends Component<Props> {
  static defaultProps = {
    value: '',
  };

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  }

  render() {
    const {
      id,
      label,
      placeholder,
      helperText,
      errors,
      ...otherProps
    } = this.props;

    const hasErrors = errors && errors.length > 0;
    const wrapperClasses = classNames(
      'form-group form-group--large',
      {
        'is-errored': hasErrors,
      }
    );

    return (
      <div className={wrapperClasses}>
        <label className="form-group__label" htmlFor={id}>{label}</label>
        <textarea
          id={id}
          className="form-group__textarea"
          placeholder={placeholder}
          {...otherProps}
          onChange={this.handleChange}
        />
        { helperText && <span className="u-text-muted">{helperText}</span> }
        {
          errors && hasErrors &&
            <div className="form-group__error">{errors.join(', ')}</div>
        }
      </div>
    );
  }
}

export default TextAreaInput;
