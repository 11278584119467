// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import type { ComponentType } from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

type Props = {
  errors?: Array<string>,
  helperText?: string,
  label?: string,
  labelHelper?: string,
  priorityLabel?: string,
  id: string,
};

export default function basicInputWrapper<T: *>(
  WrappedComponent: ComponentType<T>
): ComponentType<T> {
  class BasicInputWrapper extends Component<T & Props> {
    static defaultProps = {
      errors: [],
      helperText: '',
      labelHelper: '',
      priorityLabel: '',
    };

    render() {
      const {
        errors,
        helperText,
        id,
        label,
        labelHelper,
        priorityLabel,
        ...otherProps
      } = this.props;

      const hasErrors = errors && errors.length > 0;
      const wrapperClasses = classNames(
        'form-control',
        {
          'is-errored': hasErrors,
        }
      );

      return (
        <div className={wrapperClasses}>
          { (label || labelHelper || priorityLabel) &&
            <label htmlFor={id} className="form-control__label">
              {label}
              { labelHelper &&
                <span className="form-control__label-helper">({labelHelper})</span>
              }
              { priorityLabel &&
                <span className="form-control__priority">{priorityLabel}</span>
              }
            </label>
          }

          <WrappedComponent id={id} {...otherProps} />

          { !!helperText &&
            <span className="form-control__helper">{helperText}</span>
          }

          { errors && hasErrors &&
            <div className="form-control__error">{errors.join(', ')}</div>
          }
        </div>
      );
    }
  }

  BasicInputWrapper.displayName = `BasicInputWrapper(${getDisplayName(WrappedComponent)})`;
  return BasicInputWrapper;
}
