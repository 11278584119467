// @flow

import React, { Component } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { Button, Icon, Tooltip } from '~/public/shared/components';
import type { AttachmentType } from '~/admin/photo_editing/types';
import ImageZoom from 'react-medium-image-zoom';

type Props = {
  photo: AttachmentType,
  deletePhoto: (photoId: ID) => any,
  editPhoto: (photoId: ID) => any,
};

class PhotoCard extends Component<Props> {
  handleEditPhoto = () => {
    this.props.editPhoto(this.props.photo.id);
  }

  handleDeletePhoto = () => {
    this.props.deletePhoto(this.props.photo.id);
  }

  renderContent(photo: AttachmentType) {
    return (
      <div className="image-container">
        <ImageZoom
          image={{
            src: `${photo.thumbnailUrl}`,
            alt: 'Zoom',
            title: 'Zoom',
          }}
        />
      </div>
    );
  }

  renderTakenBy(photo: AttachmentType) {
    const { createdBy } = photo;

    if (createdBy) {
      return (
        <span title={createdBy.displayName}>
          <em className="u-text-muted">Taken by </em>
          {createdBy.displayName}
        </span>
      );
    } else {
      return (
        <span>
          No photographer found
        </span>
      );
    }
  }

  renderEditedBy(photo: AttachmentType) {
    const { createdAt, updatedAt, updatedBy } = photo;

    if (createdAt === updatedAt) {
      return (
        <span>
          Unedited
        </span>
      );
    } else if (updatedBy) {
      return (
        <span title={updatedBy.displayName}>
          <em className="u-text-muted">Edited by </em>
          {updatedBy.displayName}
        </span>
      );
    } else {
      return (
        <span>
          No photo editor found
        </span>
      );
    }
  }

  renderHeader(_photo: AttachmentType) {
    return (
      <div className="header">
        <Button
          onClick={this.handleDeletePhoto}
          buttonSize="extra-sm"
          className="u-m0"
          data-title="Delete"
          type="button"
        >
          <Tooltip
            content="Remove Image"
            placement="bottom"
          >
            <Icon
              icon="trash"
              size="18"
            />
          </Tooltip>
        </Button>
      </div>
    );
  }

  render() {
    const { photo } = this.props;

    return (
      <div id={`photo-${photo.id}`} className="image-card">
        {this.renderHeader(photo)}
        {this.renderContent(photo)}
      </div>
    );
  }
}

// Export undecorated component for compatability with shallow rendering in tests
export { PhotoCard as BasePhotoCard };

export default SortableElement(PhotoCard);
