import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PlaidHelper from '~/utils/PlaidHelper';
import { Flash, LoadingDots } from '~/public/shared/components';

class PaymentMethodPlaidForm extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    this.plaidHelper = new PlaidHelper({
      environment: props.plaidEnvironment,
      onSuccess: this.onPlaidSuccess,
    });

    this.state = {
      institutions: null,
      selectedIndex: null,
      query: '',
      queryInFlight: false,
    };
  }

  onPlaidSuccess = (publicToken, metadata) => {
    this.props.onPlaidSuccess(publicToken, metadata);
  }

  handleLinkAccount = (id) => {
    this.plaidHelper.openPlaidModal(id, this.onPlaidSuccess);
  }

  handleListNavigation = (e) => {
    const { institutions } = this.state;
    let { selectedIndex } = this.state;

    if (institutions === null || institutions.length === 0) {
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();

      if (selectedIndex === null) {
        selectedIndex = 0;
      } else if (selectedIndex + 1 < institutions.length) {
        selectedIndex += 1;
      }

      this.setState({ selectedIndex });
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();

      if (selectedIndex === null) {
        selectedIndex = 0;
      } else if (selectedIndex > 0) {
        selectedIndex -= 1;
      }

      this.setState({ selectedIndex });
    } else if (e.key === 'Enter') {
      e.preventDefault();

      if (selectedIndex !== null) {
        const selectedInstitution = this.state.institutions[selectedIndex];
        this.handleLinkAccount(selectedInstitution.id);
      }
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleUpdateQuery = (e) => {
    const query = e.target.value;
    this.setState({
      query,
      queryInFlight: true,
      selectedIndex: null,
    });
    this.searchInstitutions();
  }

  removeKeyboardSelection = () => {
    this.setState({ selectedIndex: null });
  }

  searchInstitutions = debounce(async () => {
    const { query } = this.state;

    if (query === '') {
      this.setState({
        institutions: null,
        query: '',
        queryInFlight: false,
      });
    } else {
      try {
        const response = await this.plaidHelper.search(query);
        const jsonBody = await response.json();
        const institutions = jsonBody.institutions.map(
          (i) => ({ id: i.institution_id, name: i.name })
        );

        this.setState({
          institutions,
          queryInFlight: false,
        });
      } catch (_err) {
        // TODO: handle comms error
      }
    }
  }, 250, { maxWait: 1000 })

  renderInstitution = ({ id, name }, index) => {
    const isSelected = (index === this.state.selectedIndex);
    const classes = classNames(
      'type-ahead__search-result',
      {
        'is-selected': isSelected,
      }
    );

    return (
      <div key={id} className={classes} onClick={() => this.handleLinkAccount(id)}>
        {name}
      </div>
    );
  }

  renderInstitutions = () => {
    const { institutions, query, queryInFlight } = this.state;

    if (queryInFlight) {
      return (
        <div className="type-ahead__content">
          <div className="type-ahead__searching">
            <LoadingDots className="u-mr2" /> Searching
          </div>
        </div>
      );
    } else if (institutions === null) {
      return (
        <div className="type-ahead__empty" />
      );
    } else if (institutions.length === 0) {
      return (
        <div className="type-ahead__content">
          <div className="type-ahead__search-result">No results for <strong>{query}</strong></div>
        </div>
      );
    } else {
      return (
        <div className="type-ahead__content" onMouseEnter={this.removeKeyboardSelection}>
          {institutions.map(this.renderInstitution)}
        </div>
      );
    }
  }

  render() {
    const { query } = this.state;
    const { isSaving, paymentMethod } = this.props;
    const { errors } = paymentMethod.base;

    if (isSaving) {
      return (
        <div className="box u-pb4 u-text-center">
          <h5>
            <LoadingDots className="u-mr2" /> Just a minute, adding your new payment method
          </h5>
        </div>
      );
    } else {
      return (
        <div>
          <h5>
            An easy way to pay is by linking your checking or savings account to EBTH
          </h5>

          <ol>
            <li>Get started by searching below for you bank.</li>
            <li>Click your bank and log in using your bank credentials. </li>
            <li>Finally, select the account you want to connect to EBTH.</li>
          </ol>

          <p>
            Can’t find your bank? Check back soon! We will be adding more ways to connect your
            account.
          </p>

          {(errors.length > 0) &&
            <Flash
              flashStyle="error"
              showIcon
              className="u-mb2"
            >
              {errors.join(' ')}
            </Flash>
          }

          <div className="type-ahead">
            <input
              className="input type-ahead__input"
              type="text"
              value={query}
              onChange={this.handleUpdateQuery}
              onKeyDown={this.handleListNavigation}
              onKeyPress={this.handleKeyPress}
              placeholder="Begin by searching for your bank"
            />

            {this.renderInstitutions()}
          </div>
        </div>
      );
    }
  }
}

PaymentMethodPlaidForm.propTypes = {
  onPlaidSuccess: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  plaidEnvironment: PropTypes.string.isRequired,
};

PaymentMethodPlaidForm.defaultProps = {};

export default PaymentMethodPlaidForm;
