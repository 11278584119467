// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button, Icon } from '~/public/shared/components';
import FilterLocation from './FilterLocation';
import FilterMultiSelect from './FilterMultiSelect';
import FilterSingleSelect from './FilterSingleSelect';
import FilterTreeCategoryMobile from './FilterTreeCategoryMobile';
import FilterWrapper from './FilterWrapper';
import { findLeaf } from '../utils/tree';
import { MULTI_SELECT, SINGLE_SELECT, LOCATION } from '../utils/constants';
import { nextAppliedParams, paramsToUrl } from '../utils/url';
import type {
  Filter,
  Sorts,
  Pages,
} from '../types';

type Props = {
  filters: Array<Filter>,
  sorts: Sorts,
  pages: Pages,
  isLoading: boolean,
  selectedCategorySlug: ?string,
  history: Object,
  match: Object,
  onCloseFilterModal: () => void,
  filterCounts: Object,
  appliedParams: Object,
};

type State = {
  isShowingCategoryFrame: boolean,
  mobileWindowHeight: number,
};

class Modal extends Component<Props, State> {
  state = {
    isShowingCategoryFrame: false,
    mobileWindowHeight: 0,
  }

  componentDidMount() {
    if (document.documentElement && document.body) {
      const { documentElement, body } = document;
      body.classList.add('body-modal-open');
      documentElement.classList.add('html-modal-open');
    }
    // iOS Safari shows a "Smart App Banner" outside of the DOM, the following
    // events ensure that the modal window height matches the viewable window
    // height and doesn't prevent the footer from being hidden behind the
    // browser chrome
    if (/iPhone|iPod/.test(navigator.userAgent)) {
      this.setIosWindowHeight();
      window.addEventListener('touchmove', this.handleMobileScroll);
    }
    document.addEventListener('keydown', this.closeModalOnEsc);
  }

  componentWillUnmount() {
    if (document.documentElement && document.body) {
      const { documentElement, body } = document;
      body.classList.remove('body-modal-open');
      documentElement.classList.remove('html-modal-open');
    }
    if (/iPhone|iPod/.test(navigator.userAgent)) {
      window.removeEventListener('touchmove', this.handleMobileScroll);
    }
    document.removeEventListener('keydown', this.closeModalOnEsc);
  }

  modalMask: ?HTMLDivElement;

  getIOSWindowHeight = (): number => {
    // Get zoom level of mobile Safari
    // We use width, instead of height, because there are no vertical toolbars
    if (document.documentElement) {
      const zoomLevel = document.documentElement.clientWidth / window.innerWidth;
      // window.innerHeight returns height of the visible area.
      // We multiply it by zoom and get out real height.
      return window.innerHeight * zoomLevel;
    }
    return 0;
  };

  // You can also get height of the toolbars that are currently displayed
  getHeightOfIOSToolbars = (): number => {
    const tH = (window.orientation === 0 ? window.screen.height : window.screen.width)
                - this.getIOSWindowHeight();
    return tH > 1 ? tH : 0;
  };

  setIosWindowHeight = (): void => {
    const visibleWindowHeight = window.screen.height - this.getHeightOfIOSToolbars();
    this.setState({
      mobileWindowHeight: visibleWindowHeight,
    });
  }

  handleMobileScroll = (): void => {
    this.setIosWindowHeight();
  }

  handleCloseFilterModal = (): void => {
    this.props.onCloseFilterModal();
    this.setState({
      isShowingCategoryFrame: false,
    });
  }

  closeModalOnEsc = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.handleCloseFilterModal();
    }
  }

  handleModalMaskClick = (e: SyntheticEvent<HTMLDivElement>): void => {
    if (e.target === this.modalMask) {
      this.handleCloseFilterModal();
    }
  }

  handleToggleCategoryFrame = (): void => {
    this.setState({
      isShowingCategoryFrame: !this.state.isShowingCategoryFrame,
    });
  }

  handleSortChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const { appliedParams } = this.props;

    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        sort: value,
        page: 1,
      };
    });
    const url = paramsToUrl(nextParams);

    this.props.history.push(url);
  };

  renderSorts = () => {
    const { sorts, appliedParams } = this.props;
    const { data, preset } = sorts;
    const selectedSort = appliedParams.sort || preset;

    if (data.length === 0) {
      return null;
    }

    return (
      <FilterWrapper title="Sort By">
        <select onChange={this.handleSortChange} value={selectedSort} className="items-filter__select">
          {
            data.map(
              (sort) => <option value={sort.id} key={sort.id}>{sort.name}</option>
            )
          }
        </select>
      </FilterWrapper>
    );
  }

  renderFiltersFrame = () => {
    const { filters, appliedParams } = this.props;
    let selectedCategorySlug;
    let selectedCategory;
    const categoryFilter = filters.find((filter) => filter.component === 'category');
    const categoriesWithItems = Object.keys(this.props.filterCounts.category);

    if (categoryFilter) {
      selectedCategorySlug = (appliedParams.category) ? appliedParams.category.fullSlugPath : '';

      if (selectedCategorySlug) {
        selectedCategory = findLeaf(categoryFilter.data, selectedCategorySlug);
      }
    }

    const selectedCategoryName = selectedCategory ? selectedCategory.name : 'All Categories';

    return (
      <div className="items-modal__frame">
        {this.renderSorts()}

        {(categoriesWithItems.length > 0 || selectedCategorySlug) &&
          <FilterWrapper title="Category">
            <button
              className="items-filter__nav-btn"
              onClick={this.handleToggleCategoryFrame}
              type="button"
            >
              {selectedCategoryName}
              <Icon icon="chevron-right" className="items-filter__nav-btn-icon" />
            </button>
          </FilterWrapper>
        }

        {filters.map((filter) => {
          switch (filter.type) {
            case MULTI_SELECT:
              return (
                <FilterMultiSelect filter={filter} key={filter.component} isModalContext appliedParams={appliedParams} />
              );
            case SINGLE_SELECT:
              return (
                <FilterSingleSelect filter={filter} key={filter.component} isModalContext appliedParams={appliedParams} />
              );
            case LOCATION:
              return (
                <FilterLocation filter={filter} key={filter.component} isModalContext appliedParams={appliedParams} />
              );
            default:
              return null;
          }
        })}
      </div>
    );
  }

  renderCategoriesFrame = () => {
    const categoryFilter = this.props.filters.find((f) => f.component === 'category');

    if (!categoryFilter) { return null; }

    return (
      <div className="items-modal__frame items-modal__frame--categories">
        <FilterTreeCategoryMobile filter={categoryFilter} appliedParams={this.props.appliedParams} />
      </div>
    );
  }

  render() {
    const { pages, isLoading } = this.props;
    const inlineHeightStyle = (this.state.mobileWindowHeight > 0)
      ? { height: this.state.mobileWindowHeight }
      : {};
    const viewItemsBtnText = `View ${pages.totalItems.toLocaleString()} Item${pages.totalItems === 1 ? '' : 's'}`;
    return (
      <div className="items-modal">
        <div className="items-modal__mask" onClick={this.handleModalMaskClick} ref={(div) => { this.modalMask = div; }}>
          <div className="items-modal__window" style={inlineHeightStyle}>
            <div className="items-modal__header">
              <div className="items-modal__header-cell items-modal__header-cell--left">
                {this.state.isShowingCategoryFrame &&
                  <button
                    className="items-modal__header-action"
                    onClick={this.handleToggleCategoryFrame}
                    aria-label="Back"
                    type="button"
                  >
                    <Icon icon="chevron-left" className="items-modal__header-action-icon" />
                  </button>
                }
              </div>
              <div className="items-modal__header-cell">
                {this.state.isShowingCategoryFrame ? 'Category' : 'Filters'}
              </div>
              <div className="items-modal__header-cell items-modal__header-cell--right">
                <button
                  className="items-modal__header-action"
                  onClick={this.handleCloseFilterModal}
                  aria-label="Close"
                  type="button"
                >
                  <Icon icon="close" className="items-modal__header-action-icon" />
                </button>
              </div>
            </div>
            <div className="items-modal__body">
              {this.state.isShowingCategoryFrame ?
                this.renderCategoriesFrame() :
                this.renderFiltersFrame()
              }
            </div>
            <div className="items-modal__footer">
              <Button
                buttonDisplay="block"
                buttonStyle="primary"
                className="items-modal__btn"
                onClick={this.handleCloseFilterModal}
                inFlight={isLoading}
              >
                {viewItemsBtnText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    filters,
    sorts,
    pages,
    isLoading,
    filterCounts,
    appliedParameters: appliedParams,
  } = state.items;

  return {
    filters,
    sorts,
    pages,
    isLoading,
    filterCounts,
    appliedParams,
  };
};

export default withRouter(connect(mapStateToProps)(Modal));
