// @flow

import React, { Component } from 'react';
import type { Node } from 'react';

import ClickToEditInput from '../ClickToEditInput';

type ItemType = {
  +barcode?: string,
  name: ?string,
  sale?: ?{
    id: string,
    saleCode?: ?string,
    name: ?string,
  },
  contract?: ?{
    id: string,
    number: string,
  },
  bin: ?{
    barcode: string,
  },
  lowValueProcessing: ?boolean,
  propertiesVersion: number,
};

type Props = {
  children?: Node,
  item: ItemType,
  onNameChange: (value: string) => any,
};

export class ItemHeader extends Component<Props> {
  renderItemName = () => {
    const { item, onNameChange } = this.props;

    return (
      <div>
        <ClickToEditInput
          className="qa-item-name-input"
          placeholder="Enter an Item Title"
          maxLength={80}
          defaultValue={item.name}
          value={item.name}
          onChange={onNameChange}
        />
      </div>
    );
  };

  render() {
    const { children, item } = this.props;

    return (
      <header className="page-header">
        <div className="page-header__title">{this.renderItemName()}</div>

        <div className="page-header__meta">
          <div className="page-header__details">
            {item.barcode && (
              <div className="page-header__detail">
                <span className="data-label u-mr1">Item # </span>
                <span className="u-text-mono u-text-no-wrap">{item.barcode}</span>
              </div>
            )}

            {item.sale && (
              <div className="page-header__detail">
                <span className="data-label u-mr1">Sale </span>
                <span className="u-text-mono u-text-no-wrap">
                  {item.sale.saleCode} {item.sale.name}
                </span>
              </div>
            )}

            {item.contract && (
              <div className="page-header__detail">
                <span className="data-label u-mr1">Contract</span>
                <span className="u-text-mono u-text-no-wrap">{item.contract.number}</span>
              </div>
            )}

            {item.bin && (
              <div className="page-header__detail">
                <span className="data-label u-mr1">Bin</span>
                <span className="u-text-mono u-text-no-wrap">{item.bin.barcode}</span>
              </div>
            )}
            {!item.bin && (
              <div className="page-header__detail">
                <span className="data-label u-mr1 u-text-danger">NOT IN BIN</span>
              </div>
            )}
            {item.lowValueProcessing && (
              <div className="page-header__detail">
                <span className="data-label u-mr1 u-text-no-wrap">COLLECTED ITEM</span>
              </div>
            )}
            <div className="page-header__detail">
              <span className="data-label u-mr1 u-text-no-wrap">Item Version: {item.propertiesVersion}</span>
            </div>
          </div>
          <div className="page-header__actions">{children}</div>
        </div>
      </header>
    );
  }
}
