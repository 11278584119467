// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import classNames from 'classnames';
import { Icon, Tooltip } from '~/public/shared/components';

export const InputField: StatelessFunctionalComponent<{
  errors?: Array<string>,
  label: string,
  name: string,
  onChange: Function,
  value: ?string,
  inputType?: string,
  autocomplete?: string,
  floatedLabel?: boolean,
  fieldIcon?: string,
  iconToolTip?: string,
}> = ({
  errors = [],
  label,
  name,
  onChange,
  value,
  inputType = 'text',
  autocomplete = null,
  floatedLabel = false,
  fieldIcon = null,
  iconToolTip = null,
  ...otherProps
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const hasErrors = (errors.length > 0);

  const groupClassNames = classNames(
    'form-group',
    {
      'form-group--with-icon': fieldIcon,
      'form-group--show-floated-label': (floatedLabel && (value !== '' || inputType === 'date')),
      'form-group--error': hasErrors,
    }
  );

  const labelClassNames = classNames(
    'form-group__label',
    {
      'form-group__label--floated': floatedLabel,
    }
  );

  return (
    <div className={groupClassNames}>
      <label className={labelClassNames} htmlFor={name}>{label}</label>
      <input
        className="form-control__input input"
        id={name}
        type={inputType}
        placeholder={floatedLabel ? label : null}
        value={value}
        onChange={handleChange}
        autoComplete={autocomplete}
        {...otherProps}
      />
      {!!fieldIcon && !iconToolTip &&
        <Icon icon={fieldIcon} className={`input-icon input-icon--${fieldIcon}`} />
      }
      {!!fieldIcon && !!iconToolTip &&
        <Tooltip content={iconToolTip} placement="top-end">
          <Icon icon={fieldIcon} className={`input-icon input-icon--${fieldIcon}`} />
        </Tooltip>
      }
      {hasErrors &&
        <div className="form-group__error">{errors.join(',')}</div>
      }
    </div>
  );
};
