// @flow

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import CategoryTile from './CategoryTile';

type Props = {
  boutiques: Array<Object>,
};

const categories = (boutiques) => {
  const categories_array = [];
  boutiques.forEach((boutique) => {
    categories_array.push({ id: boutique.id, name: boutique.name, parentId: null, originalMerchandisingImage: `https://ebth-com-production.imgix.net/${boutique.merchandising_image_uid}`, fullSlugPath: `browse?boutique_id=${boutique.id}` });
  });
  return categories_array;
};

class BoutiqueTiles extends Component<Props> {
  render() {
    const gridClasses = classNames('items-grid');

    return (
      <Fragment>
        <div className={gridClasses}>
          {categories(this.props.boutiques).map((category) => {
            return (
              <CategoryTile key={category.id} category={category} />
            );
          })}
        </div>
        <hr className="hr hr--lg" />
      </Fragment>
    );
  }
}

export default withRouter(BoutiqueTiles);
