// @flow

import humps from 'humps';
import React, { Component, Fragment } from 'react';
import { Button, LoadingDots, InputField } from '~/public/shared/components';
import { addressToString, userAddressIdToObject } from '../../utils/addressHelpers';
import { hasDeliverableAddress } from '../../utils/bid';
import { FeatureFlagConsumer } from '../../../dashboard/contexts/FeatureFlagContext';
import type {
  Bid,
  Item,
  FulfillmentType,
  User,
} from '../../types';

type Props = {
  bidNew: Bid,
  item: Item,
  inFlight: boolean,
  error: ?string,
  fulfillmentType: FulfillmentType,
  onFulfillmentTypeSelect: (FulfillmentType) => void,
  isSelected: boolean,
  user: User,
  changePane: (string) => void,
};

type State = {
  phoneNumber: string,
  phoneNumberError: ?string,
};

class ShippingBid extends Component<Props, State> {
  state = {
    phoneNumber: '',
    phoneNumberError: null,
  };

  placeBid = async () => {
    if (this.state.phoneNumber) {
      this.setState({
        phoneNumberError: null,
      });
      const response = await fetch('/api/v1/user/phone-number', {
        body: JSON.stringify(humps.decamelizeKeys({
          home_phone: this.state.phoneNumber,
        })),
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      const json = await response.json();

      const state = {};
      switch (response.status) {
        case 200:
          this.props.user.internationalIneligible = json.internationalIneligible;
          this.props.onFulfillmentTypeSelect(this.props.fulfillmentType);
          break;
        case 422:
          state.phoneNumberError = 'Please enter a valid phone number';
          break;
        default:
          state.phoneNumberError = json.error;
          break;
      }
      this.setState(state);
    }
  }

  renderNoDeliverableAddresses = () => {
    return (
      <Fragment>
        <div className="form-group__error">
          You do not have a deliverable address on file. Please update to select&nbsp;shipping.
        </div>
        <br />
        <a
          href="/users/addresses"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--secondary btn--extra-sm"
        >
          Update Address
        </a>
      </Fragment>
    );
  }

  renderPhoneNumber = () => {
    const { user } = this.props;
    return (
      <div className="bid-fulfillment-options__require-phone-number">
        <span className="bid-fulfillment-options__require-phone-number-warning">
          { user.internationalIneligible }
        </span>
        <div className="bid-fulfillment-options__require-phone-number-row">
          <InputField
            label="Phone Number"
            floatedLabel
            name="phone"
            value={this.state.phoneNumber}
            autocomplete="tel-national"
            onChange={(value) => {
              this.setState({
                phoneNumber: value,
              });
            }}
          />
          <Button
            onClick={this.placeBid}
            buttonStyle="primary-important"
            buttonSize="extra-sm"
            className="bid-fulfillment-options__require-phone-number-button"
          >
            Submit
          </Button>
        </div>
        { this.state.phoneNumberError &&
          <span className="bid-fulfillment-options__require-phone-number-warning">
            {this.state.phoneNumberError}
          </span>
        }
      </div>
    );
  }

  renderDisclaimer = () => {
    /* eslint-disable quotes */
    return `It is not possible to provide shipping quotes for international shipments\
      until after the close of the auction. If you win and the shipping cost\
      exceeds $175, we will provide a quote for the fulfillment cost for your approval\
      before we process shipping on the invoice.`;
    /* eslint-enable quotes */
  };

  render() {
    const {
      bidNew,
      fulfillmentType,
      fulfillmentType: {
        id,
        label,
      },
      onFulfillmentTypeSelect,
      isSelected,
      inFlight,
      error,
      changePane,
      user,
    } = this.props;
    const inputKey = `fulfillment-${id}`;
    const deliverable = hasDeliverableAddress(user);
    const fullAddress = bidNew.address && userAddressIdToObject(bidNew.address.id, user);

    return [(
      <tr className="bid-fulfillment-options__option" key={'fulfillment-row'}>
        <td className="bid-fulfillment-options__option-cell">
          <input
            name="fulfillment_option"
            id={inputKey}
            value={id}
            type="radio"
            className="bid-fulfillment-options__option-input"
            onChange={() => { onFulfillmentTypeSelect(fulfillmentType); }}
            checked={isSelected}
            disabled={!deliverable}
          />
          <label htmlFor={inputKey} className="bid-fulfillment-options__option-label bid-fulfillment-options__option-label--selectable qa-fulfillment-shipping">
            {label}
            <div className="bid-fulfillment-options__option-cost">
              {inFlight &&
                <LoadingDots />
              }
              { bidNew.shippingQuote ? bidNew.shippingQuote.quote : '\u00A0'}
            </div>
          </label>
        </td>
        <td className="bid-fulfillment-options__option-cell">
          <div className="bid-fulfillment-options__shipping-details">
            <div className="bid-fulfillment-options__shipping-details-description">
              {!deliverable &&
                this.renderNoDeliverableAddresses()
              }
              {(deliverable && bidNew.address) &&
                addressToString(fullAddress)
              }
              {error &&
                <div className="form-group__error qa-shipping-quote-error">
                  {error}
                </div>
              }
              {fullAddress && fullAddress.country !== 'US' && (
                <FeatureFlagConsumer>
                  {(featureFlags) => (

                    <div>
                      { !featureFlags.internationalShippingQuotes &&
                        <div className="bid-fulfillment-options__shipping-quote-disclaimer u-mt1">
                          {this.renderDisclaimer()}
                        </div>
                      }
                      { featureFlags.internationalShippingQuotes && user.internationalIneligible && this.renderPhoneNumber() }
                      <a
                        href="https://support.ebth.com/s/article/Does-EBTH-ship-outside-the-Continental-United-States"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        About international shipping
                      </a>
                    </div>

                  )}
                </FeatureFlagConsumer>
              )}

            </div>
            {(deliverable && bidNew.address) &&
              <div className="bid-fulfillment-options__action">
                <Button
                  onClick={() => changePane('address')}
                  buttonStyle="secondary"
                  buttonSize="extra-sm"
                  className="qa-edit-shipping-quote-address"
                >
                  edit
                </Button>
              </div>
            }
          </div>
        </td>
      </tr>
    ),
    ...bidNew.fulfillmentErrors.map((fulfillmentError) => {
      return (
        <tr key={'fulfillment-error-row'}>
          <td colSpan={2} className="bid-fulfillment-options__fulfillment-error">
            { fulfillmentError }
          </td>
        </tr>
      );
    }),
    ];
  }
}

export default ShippingBid;
