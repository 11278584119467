// @flow

import {
  FETCH_QUOTE,
  FETCH_QUOTE_SUCCESS,
  FETCH_QUOTE_FAILURE,
  RESET_QUOTE,
} from '../actions/shippingQuote';

import type { ShippingQuoteState } from '../types';
import type { Actions } from '../actions/shippingQuote';

const initialState = {
  inFlight: false,
  error: null,
  shippingQuote: null,
};

const shippingQuoteReducer = (
  state: ShippingQuoteState = initialState,
  action: Actions
): ShippingQuoteState => {
  switch (action.type) {
    case RESET_QUOTE:
      return {
        ...state,
        shippingQuote: null,
      };
    case FETCH_QUOTE:
      return {
        ...state,
        inFlight: true,
        isError: false,
      };
    case FETCH_QUOTE_SUCCESS:
      return {
        ...state,
        inFlight: false,
        error: null,
        shippingQuote: action.shippingQuote,
      };
    case FETCH_QUOTE_FAILURE:
      return {
        ...state,
        inFlight: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default shippingQuoteReducer;
