// @flow

import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import type { Node } from 'react';

const DELETE_PHOTO_MUTATION = gql`
mutation deleteAttachment($input: DeleteAttachmentInput!) {
  photo: deleteAttachment(input: $input) {
    attachment {
      id
    }
  }
}
`;

export type DeletePhotoParams = {
  id: ID,
};

export type DeletePhotoFn = (deletePhotoParams: DeletePhotoParams) => any;

type Props = {
  children: (DeletePhotoFn) => Node,
};

class DeletePhotoMutation extends Component<Props> {
  deletePhoto = (deletePhotoMutation: Function) => (deletePhotoParams: DeletePhotoParams) => {
    return deletePhotoMutation({
      variables: {
        input: {
          attachmentId: deletePhotoParams.id,
        },
      },
    });
  }

  render() {
    return (
      <Mutation mutation={DELETE_PHOTO_MUTATION}>
        {
          (deletePhotoMutation) => {
            return this.props.children(this.deletePhoto(deletePhotoMutation));
          }
        }
      </Mutation>
    );
  }
}

export default DeletePhotoMutation;
