// @flow

import React from 'react';
import NavigationSvgIcon from '~/admin/shared/svgs/NavigationSvgIcon';

export const ModalHeader = ({
  title,
  close,
  closeText = 'Close Modal',
}: {
  title: string,
  closeText: string,
  close: (any) => null,
}) => (
  <div className="modal-header">
    {title && <p>{title}</p>}
    <a
      className="title-tooltip title-tooltip--left"
      data-title={closeText}
      onClick={() => close({ open: false })}
    >
      <NavigationSvgIcon
        icon="ic_close_24px"
        className="comment-drawer__header-icon icon"
      />
    </a>
  </div>
);

export default ModalHeader;
