// @flow

import React, { Component } from 'react';
import Select, { Creatable } from 'react-select';
import classNames from 'classnames';
import basicInputWrapper from './basicInputWrapper';

export type SelectOption<T> = {
  label: string,
  value: T,
};

type Props<T> = {
  options: Array<T>,
  value: ?T,
  allowCreate?: boolean,
  onChange: (?T, metadata: { isNewOption: boolean }) => void,
  className?: string,
  id: string,
}

class SelectInput<T> extends Component<Props<T>> {
  static defaultProps = {
    allowCreate: false,
    className: '',
    onChange: (_value, _metadata) => {},
    options: [],
  };

  handleChange = (selectOption: T) => {
    // react-select SelectComponent sends up an empty array when the keyboard
    // is used to clear the selection. For some reason it is using popValue
    // internally, which always assumes an array.
    if (!selectOption) {
      this.props.onChange(null, { isNewOption: false });
    } else if (this.props.allowCreate) {
      // flow gets complicated here but if we're allowing create we have to
      // have label/value and they have to be strings
      const opt = ((selectOption: any): SelectOption<string>);
      const isNewOption = opt.value === opt.label;
      this.props.onChange(selectOption, { isNewOption });
    } else {
      this.props.onChange(selectOption, { isNewOption: false });
    }
  }

  render() {
    const {
      allowCreate,
      className,
      id,
      ...otherProps
    } = this.props;

    const SelectComponent = allowCreate ? Creatable : Select;
    const selectClassName = classNames(
      'custom-select',
      className,
    );

    return (
      <div id={id} className="form-control__input">
        <SelectComponent
          {...otherProps}
          className={selectClassName}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default basicInputWrapper(SelectInput);
