import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import PaymentMethodHelper from '~/public/shared/utils/PaymentMethodHelper';
import refreshPage from '~/utils/refreshPage';
import PaymentMethodSvgSprite from '~/public/svgs/PaymentMethodSvgSprite';
import {
  Badge,
  Button,
  Icon,
  Flash,
} from '~/public/shared/components';

class PaymentMethod extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      errors: null,
      isPrimaryInFlight: false,
    };
  }

  handleDelete = async (_evt) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this payment method?')) {
      try {
        const { id, paymentMethodTypeId } = this.props.paymentMethod;
        const response = await PaymentMethodHelper.destroy(id);

        if (response.ok || response.status === 401) {
          if (window.analytics) {
            window.analytics.track('Payment Method Deleted', {
              payment_method_id: id,
              payment_method_type: { 1: 'credit card', 2: 'cash', 3: 'echeck' }[paymentMethodTypeId] || 'other',
            });
          }

          refreshPage();
        } else {
          const responseBody = await response.json();
          this.setState({ errors: responseBody.errors.base.join(' ') });
        }
      } catch (_err) {
        this.setState({ errors: 'Network error, please try again.' });
      }
    }
  }

  handleMakePrimary = async (_evt) => {
    this.setState({ isPrimaryInFlight: true });

    try {
      const response = await PaymentMethodHelper.makePrimary(this.props.paymentMethod.id);

      if (response.ok || response.status === 401) {
        refreshPage();
      } else {
        const responseBody = await response.json();
        this.setState({
          isPrimaryInFlight: false,
          errors: responseBody.errors.base.join(' '),
        });
      }
    } catch (_err) {
      this.setState({
        isPrimaryInFlight: false,
        errors: 'Network error, please try again.',
      });
    }
  }

  renderActions = ({ canMakePrimary }) => {
    const { paymentMethod, showActions } = this.props;

    if (paymentMethod.isPrimary || showActions === false) return null;

    return (
      <div className="personal-info__actions">
        {(!paymentMethod.isPrimary
          && !paymentMethod.isExpired
          && !paymentMethod.isDeclined
          && !paymentMethod.isPrepaid
          && canMakePrimary) &&
          this.renderMakePrimaryButton()
        }
        {this.renderDeleteButton()}
      </div>
    );
  }

  renderMakePrimaryButton = () => {
    const { isPrimaryInFlight } = this.state;

    return (
      <Button
        onClick={this.handleMakePrimary}
        buttonStyle="secondary"
        buttonSize="extra-sm"
        className="u-mr1 qa-make-primary"
        inFlight={isPrimaryInFlight}
      >
        Make Primary
      </Button>
    );
  }

  renderDeleteButton = () => {
    return (
      <Button
        onClick={this.handleDelete}
        buttonDisplay="icon-only"
        buttonStyle="secondary-danger"
        buttonSize="extra-sm"
        className="qa-delete-payment"
        title="Delete payment method"
      >
        <Icon icon="trash" className="btn__icon" />
      </Button>
    );
  }

  renderErrors = () => {
    const { errors } = this.state;

    if (!errors) return null;

    return (
      <Flash
        flashStyle="error"
        showIcon
        className="u-mb2"
        heading="Cannot delete payment method"
      >
        {errors}
      </Flash>
    );
  }

  renderStatus = () => {
    const { isDeclined, isExpired, isPrimary } = this.props.paymentMethod;
    let status;

    if (isExpired) {
      status = (
        <Badge status="negative" className="personal-info__label-item">Expired</Badge>
      );
    } else if (isDeclined) {
      status = (
        <Badge status="negative" className="personal-info__label-item">Declined</Badge>
      );
    }

    return (
      <div className="personal-info__item personal-info__label">
        {isPrimary &&
          <Badge status="positive" className="personal-info__label-item">Primary</Badge>
        }
        {status}
      </div>
    );
  }

  renderEcheck = () => {
    const { cardType, last4 } = this.props.paymentMethod;

    return (
      <div className="personal-info personal-info--inline">
        <div className="personal-info__item personal-info__title">
          <PaymentMethodSvgSprite
            icon="payment_bank"
            className="personal-info__icon"
          />
          {cardType} account ending in {last4}
        </div>
        {this.renderStatus()}
        {this.renderActions({ canMakePrimary: true })}
      </div>
    );
  }

  getCardTypeIcon = (cardType) => {
    if (!cardType) return 'payment_generic_card';
    const typeParam = cardType.toLowerCase().replace(' ', '_');

    switch (typeParam) {
      case 'american_express':
      case 'visa':
      case 'discover':
      case 'mastercard':
        return `payment_${typeParam}`;
      default:
        return 'payment_generic_card';
    }
  }

  renderCreditCard = () => {
    const {
      cardType,
      last4,
      expiry,
      isExpired,
      id,
    } = this.props.paymentMethod;

    return (
      <div className={`personal-info personal-info--inline qa-payment-method-${id}`}>
        <div className="personal-info__item personal-info__title">
          <PaymentMethodSvgSprite
            icon={this.getCardTypeIcon(cardType)}
            className="personal-info__icon"
          />
          {cardType || 'Account'} ending in {last4}
        </div>
        <div className="personal-info__item personal-info__detail">
          {isExpired ? 'Expired' : 'Expires'} {expiry}
        </div>
        {this.renderStatus()}
        {this.renderActions({ canMakePrimary: true })}
      </div>
    );
  }

  renderCash = () => {
    return (
      <div className="personal-info personal-info--inline">
        <div className="personal-info__item personal-info__title">
          <PaymentMethodSvgSprite
            icon="payment_cash"
            className="personal-info__icon"
          />
          Cash
        </div>
        {this.renderStatus()}
        {this.renderActions({ canMakePrimary: false })}
      </div>
    );
  }

  renderGiftCard = () => {
    // TODO: We are using nameOnCard to map the gift card balance amount
    const {
      nameOnCard,
      id,
    } = this.props.paymentMethod;

    return (
      <div className={`personal-info personal-info--inline qa-payment-method-${id}`}>
        <div className="personal-info__item personal-info__title">
          <PaymentMethodSvgSprite
            icon="payment_generic_card"
            className="personal-info__icon"
          />
          EBTH Gift Card - Balance ${parseFloat(nameOnCard).toFixed(2)}
        </div>
        {this.renderStatus()}
        {this.renderActions({ canMakePrimary: true })}
      </div>
    );
  }

  render() {
    let contentRenderFunction;

    switch (this.props.paymentMethod.paymentMethodTypeId) {
      case 1:
        contentRenderFunction = this.renderCreditCard;
        break;
      case 2:
        contentRenderFunction = this.renderCash;
        break;
      case 3:
        contentRenderFunction = this.renderEcheck;
        break;
      case 4:
        contentRenderFunction = this.renderGiftCard;
        break;
      default:
        contentRenderFunction = () => null;
    }

    return (
      <Fragment>
        {this.renderErrors()}
        {contentRenderFunction()}
      </Fragment>
    );
  }
}

PaymentMethod.propTypes = {
  paymentMethod: PropTypes.shape({
    expiry: PropTypes.string,
    id: PropTypes.string.isRequired,
    isDeclined: PropTypes.bool.isRequired,
    isExpired: PropTypes.bool.isRequired,
    isPrimary: PropTypes.bool.isRequired,
    last4: PropTypes.string,
    nameOnCard: PropTypes.string,
    cardType: PropTypes.string,
    paymentMethodTypeId: PropTypes.number.isRequired,
  }).isRequired,
  showActions: PropTypes.bool,
};

PaymentMethod.defaultProps = {
  expiry: '',
  last4: '',
  nameOnCard: '',
  cardType: '',
  showActions: false,
};

export default PaymentMethod;
