// @flow

import React, { type StatelessFunctionalComponent } from 'react';

export const Statistic: StatelessFunctionalComponent<{
  value: any,
  label: string,
}> = ({
  value,
  label,
}) => {
  return (
    <div className="statistic">
      <div className="statistic__value">{value}</div>
      <div className="statistic__label">{label}</div>
    </div>
  );
};
