// @flow

import React, { Component } from 'react';
import CountUp from 'react-countup';

type Props = {
  amount: number,
};

type State = {
  previousAmount: number,
};

class HighBid extends Component<Props, State> {
  state = {
    previousAmount: 0,
  }

  componentWillReceiveProps({ amount }: Props) {
    if (this.props.amount !== amount) {
      // Never count down to an amount, always count up.
      const previousAmount = amount < this.props.amount ? 0 : this.props.amount;
      this.setState({ previousAmount });
    }
  }

  render() {
    return (
      <CountUp start={this.state.previousAmount} end={this.props.amount} prefix="$" separator="," />
    );
  }
}

export default HighBid;
