// @flow

import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import type { ComponentType } from 'react';

import submitRequest from '../queries/submitEstimateRequest';

import type { AppState } from '../reducer';
import type { EstimateRequest, PhotoStatus } from '../types';

type UploadedPhoto = {
  name: string,
  uploadKey: string,
  mimeType: string,
  extension: string,
  status: PhotoStatus,
};

type State = {
  isSubmitting: boolean,
};

type InternalProps = {
  photos: Array<UploadedPhoto>,
  estimateRequest: EstimateRequest,
  onSubmit: () => any,
};

export default function submitRequestContainer<T: *>(
  WrappedComponent: ComponentType<T>
): ComponentType<T & { canSubmit: boolean, onSubmit: () => any, isSubmitting: boolean }> {
  class SubmitRequestContainer extends Component<T & InternalProps, State> {
    state = {
      isSubmitting: false,
    }

    onSubmit = async () => {
      const attachments = this.props.photos.map((photo: UploadedPhoto) => {
        const {
          name,
          uploadKey,
          extension,
          mimeType,
        } = photo;
        return {
          name,
          uploadKey,
          extension,
          mimeType,
        };
      });

      const requestToSubmit = {
        ...this.props.estimateRequest,
        attachments,
      };

      // unforunately can't really test this because it's a quick toggle on and off
      this.setState({ isSubmitting: true });
      await submitRequest(requestToSubmit);
      this.setState({ isSubmitting: false });
      this.props.onSubmit();
    }

    render() {
      const { photos, ...otherProps } = this.props;

      const isUploading = photos.some(({ status }: { status: PhotoStatus }) => {
        return status === 'UPLOADING';
      });

      const canSubmit: boolean = !!(
        !this.state.isSubmitting &&
        photos.length &&
        !isUploading
      );

      return (
        <WrappedComponent
          {...otherProps}
          canSubmit={canSubmit}
          isSubmitting={this.state.isSubmitting}
          onSubmit={this.onSubmit}
        />
      );
    }
  }

  type ReduxState = { photos: { valid: Array<UploadedPhoto> }, priceEstimatorTool: AppState };
  type ReduxProps = { estimateRequest: EstimateRequest };

  const mapStateToProps = ({ photos, priceEstimatorTool }: ReduxState): ReduxProps => {
    return {
      estimateRequest: priceEstimatorTool.estimateRequest,
      photos: photos.valid,
    };
  };

  const connector = connect(mapStateToProps);
  return compose(connector)(SubmitRequestContainer);
}
