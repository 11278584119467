import gql from 'graphql-tag';

export const PROPERTY_QUERY = gql`
  query fetchProperties($categoryId: ID, $version: Int) {
  properties(categoryId: $categoryId, priorities: [NORMAL IMPORTANT REQUIRED], version: $version) {
    id
    name
    fieldType
    priority
    variants {
      id
      name
    }
  }
}
`;
