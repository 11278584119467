// @flow

import URI from 'urijs';

import type { Address } from '../types';

const baseUrl = 'https://maps.google.com/maps';
const addressMapUrl = (address: Address): string => (
  URI(baseUrl).query({
    q: `${address.line1}, ${address.city}, ${address.state} ${address.zip} ${address.country}`,
  }).toString()
);

export default addressMapUrl;
