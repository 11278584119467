// @flow

export const OPEN_MODAL = 'OPEN_MODAL';
export type OpenModalAction = {
  type: 'OPEN_MODAL',
  id: string,
};

export const CLOSE_MODAL = 'CLOSE_MODAL';
export type CloseModalAction = {
  type: 'CLOSE_MODAL',
  id: string,
};

export type Actions = (
  | OpenModalAction
  | CloseModalAction
);

export const openModal = (id: string) => {
  return {
    type: 'OPEN_MODAL',
    id,
  };
};

export const closeModal = (id: string) => {
  return {
    type: 'CLOSE_MODAL',
    id,
  };
};
