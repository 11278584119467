import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { useMutation } from 'react-apollo-hooks';
import { Link } from 'react-router-dom';
import ItemThumbnail from '~/public/dashboard/components/ItemThumbnail';
import { Badge, Button } from '~/public/shared/components';
import RowActions from '~/public/shared/components/RowActions/RowActions';

import type { Item } from '~/public/dashboard/types';
import ConfirmationModal from '~/shared/components/Modal/ConfirmationModal';
import { DELETE_PENDING_ITEM_MUTATION } from '~/shared/graphql/PendingItem/PendingItemMutations';

export const SubmittedItemListing: React.SFC<{
  item: Item,
  gridStyle: string,
  link: string,
  onItemDelete: () => void
}> = ({
  item,
  gridStyle,
  link,
  onItemDelete,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [
    deletePendingItem,
    deletePendingItemRequest,
  ] = useMutation(DELETE_PENDING_ITEM_MUTATION);

  const {
    id,
    aasmState,
    barcode,
    mainImage,
    quickpicAttachment,
    name,
    processStatus,
    createdAt,
  } = item;

  const renderStatus = () => {

    let badgeTooltip;
    let statusText;

    return (
      <Fragment>
        <Badge
          status={aasmState === 'created' ? null : 'neutral'}
          infoTooltip={badgeTooltip}
          style={{ textTransform: 'capitalize' }}
          // className={(statusText || isLive) ? 'u-mr1' : ''}
        >
          {aasmState === 'created' ? 'pending' : 'processed'}
        </Badge>

        {statusText &&
          <span className="u-text-muted u-no-wrap">{statusText}</span>
        }
      </Fragment>
    );
  };

  let attachment = null;
  let showTempBadge = false;

  if (mainImage) {
    attachment = mainImage;
  } else if (quickpicAttachment) {
    attachment = quickpicAttachment;
    showTempBadge = true;
  }

  const actions = () => {
    return (
      <div className="u-mt4">
        <Button
          onClick={() => setDeleteModalOpen(true)}
          buttonDisplay="block"
          buttonSize="sm"
        >
          Delete Item
        </Button>
      </div>
    )
  };

  const onConfirm = () => {
    return deletePendingItem({
      variables: {
        input: {
          id: id
        }
      }
    }).then(() => {
      setDeleteModalOpen(false);
      // Refresh page to get new set of items
      onItemDelete()
    });
  }

  return (
    <div className="index-listing__data-row">
      <Link to={link} className="expandable-listing__link">
        <div style={{ display: 'grid', gridTemplateColumns: gridStyle, alignItems: 'center' }}>
          <span className="index-listing__cell index-listing__cell--thumbnail">
            <ItemThumbnail attachment={attachment} showTempBadge={showTempBadge} alt={name} />
          </span>
          <span className="index-listing__cell">
            <strong>{name}</strong>
            <div className="u-text-muted">{barcode}</div>
          </span>
          <span className="index-listing__cell">{renderStatus()}</span>
          <span className="index-listing__cell u-text-muted">{moment(createdAt).format('MMM DD, YYYY @ h:ss A')}</span>
        </div>
      </Link>
      <RowActions>
        {actions()}
      </RowActions>
      {deleteModalOpen &&
        <ConfirmationModal
          isModalOpen={deleteModalOpen}
          message={<p>Are you sure you want to delete submitted item <strong>{`${item.name}`}</strong>?</p>}
          onConfirm={onConfirm}
          onReject={() => setDeleteModalOpen(false)}
        />
      }
    </div>
  );
};

export default SubmittedItemListing;
