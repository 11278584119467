export default function transformErrorsToChanges(errors) {
  const changes = {};

  Object.keys(errors).forEach((key) => {
    if (Array.isArray(errors[key])) {
      changes[key] = { errors: { $set: errors[key] } };
    } else {
      changes[key] = transformErrorsToChanges(errors[key]);
    }
  });

  return changes;
}
