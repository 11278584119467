import React, { createContext } from 'react';
import { Query } from 'react-apollo';
import { User } from '~/shared/interfaces';
import { USER_SESSION_QUERY } from '~/shared/graphql/User/queries/UserQuery';
import { LoadingDots } from '~/public/shared/components';
import { useQuery } from 'react-apollo-hooks';
import { ApolloError } from 'apollo-client';

export interface UserContextProps {
  user: User,
  loading: boolean,
  error: ApolloError
}

const context = createContext<Partial<UserContextProps>>({});
export const UserProvider = context.Provider;
export const UserConsumer = context.Consumer;

export const UserContext: React.FC = ({ children }) => {
  const { data, loading, error = null } = useQuery(USER_SESSION_QUERY)

  if (loading) return <LoadingDots className="u-center" />;
  if (error) return <h2>`Error! ${error.message}`</h2>;
  return (
    <UserProvider value={{ user: data.currentUser, loading, error}}>
      {children}
    </UserProvider>
  );
}

export default context;
