// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import ImageZoom from 'react-medium-image-zoom';
import { Icon } from '~/public/shared/components';
import imageUrl from '~/utils/imageUrl';
import type { Attachment } from '../types';

const tempBadgeParams = {
  mark64: 'aHR0cDovL2Fzc2V0cy5pbWdpeC5uZXQvfnRleHQ_dHh0cGFkPTE1JnR4dHNpemU9MjAmdz05MCZiZz03MDAwMDAwMCZ0eHRjb2xvcj1mZmYmdHh0Zm9udD1IZWx2ZXRpY2FOZXVlLU1lZGl1bSZ0eHQ2ND1WRVZOVUE9PQ',
  markpad: 0,
  markalign: 'bottom left',
  markscale: 40,
};

type Options = {
  showTempBadge: boolean,
};

const imageThumbnailUrl = (attachment, options: Options = { showTempBadge: false }) => {
  const params = {
    w: 200,
    h: 200,
    q: 30,
    fit: 'crop',
    ...(options.showTempBadge ? tempBadgeParams : {}),
  };

  return imageUrl(attachment.imageUrl, params);
};

const imageZoomUrl = (attachment, options: Options = { showTempBadge: false }) => {
  const params = {
    w: 1024,
    h: 1024,
    q: 75,
    fit: 'crop',
    ...(options.showTempBadge ? tempBadgeParams : {}),
  };

  return imageUrl(attachment.imageUrl, params);
};

const ItemThumbnail: StatelessFunctionalComponent<{
  attachment: ?Attachment,
  showTempBadge: boolean,
  alt: string,
}> = ({
  attachment,
  showTempBadge,
  alt,
}) => {
  if (!attachment) {
    return (
      <div className="img-thumb">
        <Icon icon="camera" className="img-thumb__img" />
      </div>
    );
  }

  const thumbnailUrl = imageThumbnailUrl(attachment, { showTempBadge });
  const zoomUrl = imageZoomUrl(attachment, { showTempBadge });

  return (
    <div className="img-thumb" onClick={(e) => { e.preventDefault(); }}>
      <ImageZoom
        image={{
          src: thumbnailUrl,
          alt: alt,
          className: 'img-thumb__img',
          title: 'Enlarge Image',
        }}
        zoomImage={{ src: zoomUrl }}
      />
    </div>
  );
};

export default ItemThumbnail;
