// @flow
/* eslint-disable react/button-has-type */

import React, { Component } from 'react';
import classNames from 'classnames';

type Props = {
  children: ?any, // Children is a dumpster fire, see: https://github.com/facebook/flow/issues/1964
  className: string,
  modifier: string,
  type?: 'button' | 'submit' | 'reset',
};

export class MicroButton extends Component<Props> {
  static defaultProps = {
    children: null,
    className: '',
    modifier: 'default',
    type: 'button',
  };

  render() {
    const {
      children, className, modifier, ...otherProps
    } = this.props;

    const classes = classNames('micro-button', `micro-button--${modifier}`, className);

    return (
      <button className={classes} {...otherProps}>
        {children}
      </button>
    );
  }
}
