// @flow

import React, { type StatelessFunctionalComponent, Fragment } from 'react';

export const PayableAddress: StatelessFunctionalComponent<{
  name: string,
  attention?: string,
  street: string,
  floorSuite?: string,
  city: string,
  state: string,
  zip: string,
  country?: string,
}> = ({
  name,
  attention,
  street,
  floorSuite,
  city,
  state,
  zip,
  country,
}) => {
  return (
    <address>
      {name}
      {attention &&
        <Fragment>
          <br />ATTN: {attention}
        </Fragment>
      }
      <br />{street}
      {floorSuite &&
        <Fragment>
          <br />{floorSuite}
        </Fragment>
      }
      <br />{city}, {state} {zip}
      {country &&
        <Fragment>
          <br />{country}
        </Fragment>
      }
    </address>
  );
};
