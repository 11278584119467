// @flow

const formatCurrency = (
  amount: ?number,
  { showZeroes = false }: { showZeroes: boolean } = {}
): string => {
  // For zero, null, undef always return $0, no matter what showZeroes is set to.
  if (!amount) return '$0';

  const asCurrency = amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return showZeroes ? asCurrency : asCurrency.split('.00')[0];
};

export default formatCurrency;
