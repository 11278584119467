// @flow
import type { Address, User } from '../types';

export const addressToString = (address: ?Address): string => {
  if (!address) return '';
  return `${address.line1}${address.line2 ? ` ${address.line2}` : ''} ${(address.city && address.state) ? `${address.city}, ${address.state}` : ''} ${address.zip}`;
};

export const userAddressIdToObject = (id: string, user: ?User): ?Address => {
  if (!user) return null;
  return user.addresses.find((address) => address.id === id);
};
