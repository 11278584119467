// @flow

import classNames from 'classnames';
import React, { Component } from 'react';

import ZoomImage from '~/admin/shared/components/ZoomImage';

export type CarouselImageType = {
  thumbnailUrl: ?string,
  zoomUrl: ?string,
  alt: string,
  isInternal: boolean,
};


type Props = {
  image: CarouselImageType,
  isZoomed: boolean,
  isActive: boolean,
  onZoomChanged: (boolean) => any,
};

export class CarouselImage extends Component<Props> {
  render = () => {
    const { image, isActive, isZoomed } = this.props;
    const classes = classNames('image-carousel__slide', {
      'is-active': isActive,
    });

    return (
      <li className={classes}>
        {image.isInternal && <span className="image-carousel__slide-label">QuickPIC</span>}

        {image.thumbnailUrl && image.zoomUrl ? (
          <ZoomImage
            isZoomed={isZoomed}
            thumbnailUrl={image.thumbnailUrl}
            zoomUrl={image.zoomUrl}
            alt={image.alt}
            onZoomChanged={this.props.onZoomChanged}
            className="image-carousel__image"
          />
        ) : (
          <span className="image-carousel__image">Loading...</span>
        )}
      </li>
    );
  };
}
