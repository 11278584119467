// @flow

import Cookies from 'js-cookie';

import type { ShippingQuote } from '../types';

export const FETCH_QUOTE = 'FETCH_QUOTE';
export type FetchQuoteAction = {
  type: 'FETCH_QUOTE',
};

export const FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS';
export type FetchQuoteSuccessAction = {
  type: 'FETCH_QUOTE_SUCCESS',
  shippingQuote: ShippingQuote,
};

export const FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE';
export type FetchQuoteFailureAction = {
  type: 'FETCH_QUOTE_FAILURE',
  error: string,
};

export const RESET_QUOTE = 'RESET_QUOTE';
export type ResetQuoteAction = {
  type: 'RESET_QUOTE',
};

export type Actions = (
  | FetchQuoteAction
  | FetchQuoteSuccessAction
  | FetchQuoteFailureAction
  | ResetQuoteAction
);


export const fetchQuote = ({ itemId, addressId, zip }: { itemId: string, addressId?: string, zip?: string }): Thunk<void> => {
  return async (dispatch) => {
    if (!addressId) {
      Cookies.set('user_zip_code', zip, { expires: 365 });
    }
    const fallBackError = addressId ? 'We encountered an issue getting a quote for this address. Please try a different address.' : 'Could not retrieve shipping quote';
    dispatch({
      type: FETCH_QUOTE,
    });

    try {
      const queryParams = addressId ? `address_id=${addressId}` : `to=${zip || ''}`;
      const response = await fetch(`/api/v1/items/${itemId}/shipping_quotes?${queryParams}`);
      const json = await response.json();

      if (response.status === 200) {
        Cookies.set('user_zip_code', zip, { expires: 365 });
        dispatch({
          type: FETCH_QUOTE_SUCCESS,
          shippingQuote: {
            id: json.id,
            quote: json.formatted_total,
            zip: json.zip,
          },
        });
      } else if (response.status === 400 && json.error) {
        dispatch({
          type: FETCH_QUOTE_FAILURE,
          error: json.error,
        });
      } else {
        dispatch({
          type: FETCH_QUOTE_FAILURE,
          error: fallBackError,
        });
      }
    } catch (e) {
      console.error(e);
      dispatch({
        type: FETCH_QUOTE_FAILURE,
        error: fallBackError,
      });
    }
  };
};

export const resetQuote = () => {
  return {
    type: 'RESET_QUOTE',
  };
};
