// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Icon } from '~/public/shared/components';
import ModalDeliveryOptions from '../components/ModalDeliveryOptions';
import FulfillmentTypes from '../components/FulfillmentTypes';
import * as ModalActions from '../actions/modal';

import type { Item } from '../types';

type Props = {
  item: Item,
  openModal: (string) => void,
};

class DeliveryDetailsContainer extends Component<Props> {
  handleOpenFulfillmentInfoModal = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.openModal('deliveryOptions');
  }

  render() {
    const { item } = this.props;
    const { aasmState } = item;
    const endedStates = ['sold', 'unsold', 'invoiced', 'paid'];
    const ended = endedStates.includes(aasmState);

    return (
      <Fragment>
        {!ended &&
          <div className="item-detail__well qa-delivery-options u-mb3">
            <h3 className="item-detail__well-heading u-flex w-100">
              <span>Delivery Options</span>
              <button type="button" onClick={this.handleOpenFulfillmentInfoModal} className="item-detail__well-heading-link ml-auto">
                <Icon icon="info" size="18" className="u-mr1" />More Info
              </button>
              <ModalDeliveryOptions />
            </h3>
            <FulfillmentTypes fulfillmentTypes={item.fulfillmentTypes} />
          </div>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = ({ item: { item } }) => ({ item });
const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...ModalActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetailsContainer);
