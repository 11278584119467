// @flow

export type PhotoUploadResult = { url: string, photoKey: string };

type DirectUploaderClient = {
  getPhotoUploadUrl: (File) => Promise<PhotoUploadResult>,
};

class DirectPhotoUploader {
  client: DirectUploaderClient

  constructor(client: DirectUploaderClient) {
    this.client = client;
  }

  submitPhoto = async (url: string, file: File): Promise<{ ok: boolean }> => {
    return fetch(url, {
      method: 'put',
      body: file,
    });
  }

  uploadFile = async (file: File): Promise<{ uploadKey: string, url: string }> => {
    const { url, photoKey } = await this.client.getPhotoUploadUrl(file);
    const { ok } = await this.submitPhoto(url, file);
    if (!ok) {
      throw new Error('There was a problem uploading');
    }

    return {
      uploadKey: photoKey,
      url,
    };
  }
}

export default DirectPhotoUploader;
