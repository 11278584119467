import update from 'immutability-helper';
import { get } from 'lodash';
import refreshPage from '~/utils/refreshPage';
import transformErrorsToChanges from '~/utils/transformErrorsToChanges';
import PaymentMethodHelper from '~/public/shared/utils/PaymentMethodHelper';

const NEW_PAYMENT_OPTION = {
  id: 'new_payment_method',
};

const PICKUP_PAYMENT_OPTION = {
  isSpecialPaymentOption: true,
  label: 'Pay at pickup',
  id: 'pickup',
};

const WIRE_TRANSFER_PAYMENT_OPTION = {
  isSpecialPaymentOption: true,
  label: 'Pay with a wire transfer',
  id: 'wire_transfer',
};

function parseErrorsFromRails(errors) {
  const parsedErrors = {};

  // Abuse base errors until there's a reason to make a distinction.
  parsedErrors.base = get(errors, 'payment_method', []);

  return parsedErrors;
}

function applyServerErrors(buyerInvoice, serverErrors) {
  const changes = transformErrorsToChanges(parseErrorsFromRails(serverErrors));

  changes.hasErrors = { $set: true };

  if (serverErrors.base) {
    changes.base = { errors: { $set: serverErrors.base } };
  }

  return update(buyerInvoice, changes);
}

function availablePaymentOptions(paymentMethods, options = {}) {
  let paymentOptions = [];

  if (options.requireWireTransfer) {
    paymentOptions.push(WIRE_TRANSFER_PAYMENT_OPTION);
  } else {
    // Don't modify passed in payment methods
    paymentOptions = paymentOptions.concat(paymentMethods);
  }

  if (options.ableToPayAtPickup) {
    paymentOptions.push(PICKUP_PAYMENT_OPTION);
  }

  if (!options.requireWireTransfer) {
    paymentOptions.push(NEW_PAYMENT_OPTION);
  }

  return paymentOptions;
}

async function applyGiftCard({ buyerInvoiceId, giftCardNumber }) {
  let result = null;

  try {
    const response = await fetch(`/api/v1/buyer_invoices/${buyerInvoiceId}/gift_card`, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        gift_card_number: giftCardNumber,
      }),
    });

    const responseBody = await response.json();

    if (response.status === 401) {
      refreshPage();
    } else if (response.ok) {
      result = {
        success: true,
        amountDue: responseBody.amount_due,
      };
    } else {
      let error = null;
      if (responseBody.errors.base) {
        [error] = responseBody.errors.base;
      } else if (responseBody.errors.gift_card_number) {
        error = `Gift card ${responseBody.errors.gift_card_number[0]}`;
      } else {
        error = 'Unknown error';
      }

      result = {
        success: false,
        error: error,
      };
    }
  } catch (_err) {
    // Communication error
    result = {
      success: false,
      error: 'Network error, please try again.',
    };
  }

  return result;
}

function isSpecialPaymentOption(paymentOptionId) {
  return paymentOptionId === WIRE_TRANSFER_PAYMENT_OPTION.id ||
         paymentOptionId === PICKUP_PAYMENT_OPTION.id;
}

async function savePayment(buyerInvoiceId, buyerInvoice) {
  const { paymentOption } = buyerInvoice;
  const result = {
    success: false,
    pollForStatus: false,
    buyerInvoice: buyerInvoice,
    redirectPath: null,
  };

  if (isSpecialPaymentOption(paymentOption)) {
    result.success = true;
    result.redirectPath = paymentOption === WIRE_TRANSFER_PAYMENT_OPTION.id ?
      `/users/invoices/${buyerInvoiceId}/wire_transfer_instructions` :
      `/users/invoices/${buyerInvoiceId}/pickup_payment_instructions`;
  } else {
    try {
      const response = await fetch(`/api/v1/buyer_invoices/${buyerInvoiceId}/payment`, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment_method_id: buyerInvoice.paymentOption,
        }),
      });

      const responseBody = await response.json();
      if (response.status === 422) {
        buyerInvoice = update(buyerInvoice, {
          hasErrors: { $set: true },
          base: { errors: { $set: ['The payment attempt failed due to an issue with our network. Please allow a few minutes to pass and then try again.'] } },
        });
        result.buyerInvoice = buyerInvoice;
      } else if (response.status === 401) {
        refreshPage();
      } else if (response.ok) {
        result.success = true;
        result.pollForStatus = true;
        result.redirectPath = responseBody.redirect_path;
      } else {
        result.buyerInvoice = applyServerErrors(buyerInvoice, responseBody.errors);
      }
    } catch (_err) {
      // Communication error
      buyerInvoice = update(buyerInvoice, {
        hasErrors: { $set: true },
        base: { errors: { $set: ['Network error, please try again.'] } },
      });
      result.buyerInvoice = buyerInvoice;
    }
  }

  return result;
}

function saveCoupon(buyerInvoiceId, _coupon) {
  return fetch(`/api/v1/buyer_invoices/${buyerInvoiceId}/coupon`, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  });
}

function saveGiftCard(buyerInvoiceId, _giftCard) {
  return fetch(`/api/v1/buyer_invoices/${buyerInvoiceId}/gift_card`, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  });
}

function checkStatus(buyerInvoiceId) {
  return fetch(`/api/v1/buyer_invoices/${buyerInvoiceId}/status`, {
    method: 'get',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

function initialize(addresses, paymentOption = {}, requireWireTransfer = false) {
  const buyerInvoice = {
    hasErrors: false,
    base: {
      errors: [],
    },
    paymentOption: requireWireTransfer ? 'wire_transfer' : paymentOption.id,
    paymentMethod: PaymentMethodHelper.initialize(addresses),
  };

  buyerInvoice.paymentMethod.isAddingPaymentMethod = false;

  return buyerInvoice;
}

function validate(buyerInvoice) {
  const errors = { base: { errors: { $set: [] } } };
  let hasErrors = false;

  if (buyerInvoice.paymentMethod.isAddingPaymentMethod) {
    const updatedPaymentMethod = PaymentMethodHelper.validate(buyerInvoice.paymentMethod);

    if (updatedPaymentMethod.hasErrors) {
      hasErrors = true;
    }

    errors.paymentMethod = { $set: updatedPaymentMethod };
  } else if (buyerInvoice.paymentOption === null) {
    errors.base = { errors: { $set: ['Select a payment method'] } };
    hasErrors = true;
  }

  errors.hasErrors = { $set: hasErrors };

  return update(buyerInvoice, errors);
}
// This functionality was moved from BuyerInvoiceForm and kept for future purposes
// TokenOptions should be: useVantiv (boolean), paypageId (Int), payPageURL (string)
const handleInvoiceSavePayment = async (buyerInvoice, tokenOptions) => {
  // 1. Validate everything client-side first
  buyerInvoice = this.validate(buyerInvoice);

  if (buyerInvoice.hasErrors) {
    this.setState({ buyerInvoice });
    return;
  }

  this.setState({ formState: 'SAVING' });

  // 2. Get a token if user is adding new payment method
  try {
    if (buyerInvoice.paymentMethod.isAddingPaymentMethod) {
      buyerInvoice.paymentMethod = await PaymentMethodHelper.getToken(
        buyerInvoice.paymentMethod,
        this.paymentForm,
        tokenOptions
      );

      // 3. Stop if we picked up some errors from tokenization
      if (buyerInvoice.paymentMethod.hasErrors) {
        this.setState({
          buyerInvoice,
          formState: 'WAITING_FOR_INPUT',
        });
        return;
      }

      // 4. Save the payment method
      buyerInvoice.paymentMethod = await PaymentMethodHelper.savePaymentMethod(
        buyerInvoice.paymentMethod
      );

      // 5. Update invoice w/ new payment method id
      if (buyerInvoice.paymentMethod.hasErrors) {
        this.setState({
          buyerInvoice,
          formState: 'WAITING_FOR_INPUT',
        });
        return;
      } else {
        if (window.analytics) {
          window.analytics.track('Payment Method Added', {
            payment_method_id: buyerInvoice.paymentMethod.id.value,
            payment_method_type: 'credit card',
            was_marked_primary: buyerInvoice.paymentMethod.primary.value,
          });

          if (buyerInvoice.paymentMethod.address.isAddingAddress) {
            window.analytics.track('Address Added', {
              address_id: buyerInvoice.paymentMethod.address.id.value,
              context: 'buyer_invoices_page',
              was_marked_primary: false, // User can't set address as primary in this context
            });
          }
        }

        buyerInvoice.paymentOption = buyerInvoice.paymentMethod.id.value;
      }
    }

    // 6. Save the payment to the invoice
    this.savePayment(buyerInvoice.id, buyerInvoice);
  } catch (_err) {
    buyerInvoice = update(
      buyerInvoice,
      { hasErrors: { $set: true }, base: { errors: { $set: ['Network error, please try again.'] } } }
    );
    this.setState({
      buyerInvoice,
      formState: 'WAITING_FOR_INPUT',
    });
  }
};

export default {
  NEW_PAYMENT_OPTION,
  PICKUP_PAYMENT_OPTION,
  WIRE_TRANSFER_PAYMENT_OPTION,
  availablePaymentOptions,
  applyGiftCard,
  savePayment,
  saveCoupon,
  saveGiftCard,
  checkStatus,
  initialize,
  validate,
  handleInvoiceSavePayment,
};
