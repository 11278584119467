// @flow

import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import { Badge, Button, Icon } from '~/public/shared/components';

import type { User, Bid, Address } from '../types';

type Props = {
  user: User,
  bid: Bid,
  changePane: (string) => void,
  onAddressChange: (Object) => void,
};

class PaneSelectAddress extends Component<Props> {
  returnToSelectFulfillment = () => {
    this.props.changePane('fulfillment');
  }

  handleAddressSelection = (a: Address) => {
    if (!a.isDeliverable) return null;

    this.props.onAddressChange(a);
  }

  renderAddress = (a: Address) => {
    const { id, isDeliverable } = a;
    const { address } = this.props.bid;
    const unavailable = !isDeliverable;

    const optionClasses = classNames(
      'bid-options__option',
      `qa-address-${id}`,
      {
        'bid-options__option--unavailable': unavailable,
        'bid-options__option--selected': address && (address.id === id),
      }
    );

    return (
      <div
        key={id}
        className={optionClasses}
        onClick={() => { this.handleAddressSelection(a); }}
        title={unavailable ? 'Unavailable for selection' : 'Select for shipment'}
      >
        <div className="bid-options__option-body">
          {a.name &&
            <Fragment>
              <strong>{a.name}</strong>
              <br />
            </Fragment>
          }
          {a.line1}
          {a.line2 &&
            <Fragment>
              <br />
              {a.line2}
            </Fragment>
          }
          <br />
          {a.city &&
            <Fragment>{a.city}, </Fragment>
          }
          {a.state} {a.zip}
          <br />{a.countryName}
        </div>
        {unavailable &&
          <Badge status="negative" className="u-ml2">Undeliverable</Badge>
        }
      </div>
    );
  }

  render() {
    const { user } = this.props;

    return (
      <Fragment>
        <h3 className="bid-modal__headline">Shipping Address</h3>

        <div className="bid-options">
          {user.addresses.map(this.renderAddress)}
          <a
            href="/users/addresses"
            target="_blank"
            rel="noopener noreferrer"
            className="bid-options__option bid-options__option--link"
          >
            Add Address
          </a>
        </div>
        <Button
          onClick={this.returnToSelectFulfillment}
          buttonDisplay="block"
          buttonStyle="secondary"
          className="u-mt4"
        >
          <Icon icon="chevron-left" className="btn__icon" />
          Back
        </Button>
      </Fragment>
    );
  }
}

export default PaneSelectAddress;
