// @flow

import { DISPLAY_NOTICE, DISMISS_NOTICE, REMOVE_NOTICE } from '../actions/notice';
import type { Actions, NoticeMessage } from '../actions/notice';

export type NoticeState = ?NoticeMessage;

const notice = (state: NoticeState = null, action: Actions): NoticeState => {
  switch (action.type) {
    case DISPLAY_NOTICE:
      return action.notice;
    case DISMISS_NOTICE:
      return state ? {
        ...state,
        active: false,
      } : null;
    case REMOVE_NOTICE:
      return null;
    default:
      return state;
  }
};

export default notice;
