// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Icon } from '~/public/shared/components';
import { followSearch, unFollowSearch } from '../actions/items';

type AppliedParamsType = {
  q: ?any,
  category: ?any,
  sort: ?any,
  miles: ?any,
  zip_code: ?any,
  status: ?any,
  delivery_options: ?any,
  days_left: ?any,
};

type SearchDataType = {
  q: ?string,
  category_id: ?number,
  sort: ?string,
  miles: ?number,
  zip_code: ?string,
  status: ?string,
  delivery_options: ?any,
  days_left: ?number,
};

type Props = {
  keyword: ?string,
  followSearch: (Object) => void,
  unFollowSearch: (number) => void,
  savedSearches: Array<Object>,
  appliedParams: ?AppliedParamsType,
  searchData: ?SearchDataType,
};

type State = {
  btnText: string,
  isHoveringOnFollow: boolean, // Prevents the unfollow state from immedietely showing on follow
};

class SearchFollowBtn extends Component<Props, State> {
  state = {
    btnText: 'Save This Search',
    isHoveringOnFollow: false,
  }

  componentDidMount() {
    this.setInitialButtonState();
  }

  componentWillReceiveProps(nextProps: Props) {
    const prevId = this.getFollowedSearchId(
      this.props.appliedParams,
      this.props.savedSearches
    );

    const nextId = this.getFollowedSearchId(
      nextProps.appliedParams,
      nextProps.savedSearches
    );

    if (prevId !== nextId) {
      if (nextId != null) {
        this.setState({
          btnText: 'Saved',
          isHoveringOnFollow: true,
        });
      } else {
        this.setState({
          btnText: 'Save This Search',
          isHoveringOnFollow: false,
        });
      }
    }
  }

  setInitialButtonState = () => {
    const { appliedParams, savedSearches } = this.props;
    const isFollowing = this.getFollowedSearchId(appliedParams, savedSearches) != null;

    this.setState({
      btnText: isFollowing ? 'Saved' : 'Save This Search',
      isHoveringOnFollow: false,
    });
  }

  handleMouseEnter = () => {
    const { appliedParams, savedSearches } = this.props;
    const isFollowing = this.getFollowedSearchId(appliedParams, savedSearches) != null;

    if (isFollowing) {
      this.setState({
        btnText: 'Unsave',
      });
    }
  }

  handleMouseLeave = () => {
    this.setInitialButtonState();
  }

  handleClick = (e: SyntheticEvent<>): void => {
    e.preventDefault();
    const { keyword, appliedParams, savedSearches } = this.props;
    const followedSearchId = this.getFollowedSearchId(appliedParams, savedSearches);
    if (followedSearchId) {
      this.props.unFollowSearch(followedSearchId);
    } else {
      const searchData: SearchDataType = {
        q: null,
        category_id: null,
        sort: null,
        miles: null,
        zip_code: null,
        status: null,
        delivery_options: null,
        days_left: null,
      };
      if (appliedParams && appliedParams.category) {
        searchData.category_id = appliedParams.category.id;
      }
      if (appliedParams && appliedParams.sort && appliedParams.sort !== 'recommended') {
        searchData.sort = appliedParams.sort;
      }
      if (appliedParams && appliedParams.miles && appliedParams.zip_code) {
        searchData.miles = appliedParams.miles;
        searchData.zip_code = appliedParams.zip_code;
      }
      if (appliedParams && appliedParams.status) {
        searchData.status = appliedParams.status;
      }
      if (keyword) {
        searchData.q = keyword;
      }
      if (appliedParams && appliedParams.delivery_options) {
        searchData.delivery_options = appliedParams.delivery_options;
      }
      if (appliedParams && appliedParams.days_left) {
        searchData.days_left = Array.isArray(appliedParams.days_left) ? appliedParams.days_left[0] : appliedParams.days_left;
      }

      this.props.followSearch(searchData);
    }
  }

  areArraysEqual = (arr1, arr2) => {
    let isEqual = true;
    if (Array.isArray(arr1) && Array.isArray(arr2)) {
      arr1.forEach((a) => {
        if (!arr2.includes(a)) {
          isEqual = false;
        }
      });
      if (arr1.length !== arr2.length) {
        isEqual = false;
      }
    } else if (arr1 !== arr2) {
      isEqual = false;
    }

    return isEqual;
  }

  getFollowedSearchId = (appliedParams: ?AppliedParamsType, savedSearches: Array<Object>) => {
    // Check if applied days_left is an array or a single value, also check for null so flow is happy
    let applied_days_left = null;
    if (appliedParams && appliedParams.days_left != null) {
      if (Array.isArray(appliedParams.days_left)) {
        [applied_days_left] = appliedParams.days_left;
      } else {
        applied_days_left = appliedParams.days_left;
      }
    }
    const currentSearch = savedSearches.find((search) => {
      if (!search.value || !appliedParams) return false;

      const hasDeliveryOptions = appliedParams.delivery_options != null;
      const searchHasDeliveryOptions = search.value.delivery_options != null;
      const deliveryOptionsEqual = hasDeliveryOptions && searchHasDeliveryOptions
        ? this.areArraysEqual(appliedParams.delivery_options, search.value.delivery_options)
        : !hasDeliveryOptions && !searchHasDeliveryOptions;

      let searchCatEqualToAppliedCat = false;
      if (appliedParams.category != null) {
        searchCatEqualToAppliedCat = search.value.category_id === appliedParams.category.id;
      } else if (!search.value.category_id) {
        searchCatEqualToAppliedCat = true;
      }

      return (
        appliedParams.q === search.value.q &&
        appliedParams.status === search.value.status &&
        appliedParams.zip_code === search.value.zip_code &&
        deliveryOptionsEqual &&
        applied_days_left === search.value.days_left &&
        searchCatEqualToAppliedCat
      );
    });

    return currentSearch ? currentSearch.id : undefined;
  };

  render() {
    const { appliedParams, savedSearches } = this.props;
    const isFollowing = this.getFollowedSearchId(appliedParams, savedSearches) != null;
    const { isHoveringOnFollow, btnText } = this.state;
    const btnClassNames = classNames(
      'btn btn--sm items-page-heading__follow-btn',
      {
        'btn--follow': !isFollowing,
      },
      {
        'btn--following items-page-heading__follow-btn--following': isFollowing,
      },
      {
        'cancel-hover-style': isHoveringOnFollow,
      }
    );
    const btnTitle = isFollowing ? 'Remove this search' : 'Get notified when new results match this search';
    const btnIcon = isFollowing ? 'bookmark' : 'bookmark-outline';

    return (
      <button
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={btnClassNames}
        title={btnTitle}
        type="button"
      >
        <Icon icon={btnIcon} className="btn__icon" />
        {btnText}
      </button>
    );
  }
}

const mapStateToProps = (state) => {
  const { savedSearches } = state.items;
  return { savedSearches };
};

const mapDispatchToProps = {
  followSearch,
  unFollowSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFollowBtn);
