import React from 'react';
import { Icon } from '~/public/shared/components';
import classNames from 'classnames';

export function SectionNotice(props) {
  const {
    text,
    type,
    className,
  } = props;

  const sectionClass = classNames(
    'section-notice',
    type || null,
    className
  );
  return (
    <div className={sectionClass}>
      <div>
        <Icon
          icon="info"
        />
      </div>
      <div>
        <p>{text}</p>
      </div>
    </div>
  );
}
