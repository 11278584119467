// @flow

const initialState = {
  url: '',
  params: {},
  filters: [],
};

const FILTER_MUTLI_SELECT = 'multi_select';

const routeReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case 'UPDATE_ROUTE_PAYLOAD': {
      const { params, response } = action.payload;
      const updatedParams = Object.assign({}, state.params);
      if (
        Object.keys(state.params).length === 0 &&
        response.path_property_variant
      ) {
        response.filters.forEach((filter) => {
          // If this route supplies a path_property_variant, set the initial filter as selected
          if (
            Object.keys(response.path_property_variant).includes(filter.query)
          ) {
            const filterOption = filter.data.find(
              (option) => option.id === response.path_property_variant[filter.query]
            );
            if (filterOption) {
              filter.selected = [filterOption];

              if (!updatedParams[filter.query]) {
                updatedParams[filter.query] = [filterOption.id];
              }
            }
          }
        });
      }

      Object.keys(state.params).forEach((paramKey) => {
        response.filters.forEach((filter) => {
          if (filter.query === paramKey) {
            if (updatedParams[paramKey] instanceof Array) {
              // mark all params as selectedFilters
              updatedParams[paramKey].forEach((paramValue) => {
                const filterOption = filter.data.find(
                  (option) => option.id === paramValue
                );
                if (filterOption) {
                  let selected = [];

                  // add existing selected filters
                  const filterIndex = state.filters.findIndex(
                    (stateFilter) => stateFilter.query === filter.query
                  );
                  if (filterIndex > -1 && state.filters[filterIndex].selected) {
                    selected = [...state.filters[filterIndex].selected];
                  }

                  if (filter.selected) {
                    selected = [...filter.selected];

                    // Check if option is already selected to prevent a recursive loop
                    if (selected.indexOf(filterOption) < 0) {
                      selected.push(filterOption);
                    }

                    filter.selected = selected;
                  } else {
                    filter.selected = [filterOption];
                  }
                }
              });
            }
          }
        });
      });

      // Move information across location filter if needed
      //
      // Since we're modifying th response, the new response won't have our modifications,
      // so we'ld like to keep those as responses come in
      const currentLocFilter =
        state.filters && state.filters.find((f) => f.component === 'location');
      if (currentLocFilter) {
        const index = response.filters.findIndex(
          (f) => f.component === 'location'
        );
        if (index > -1) {
          // Copy over `selected`, `filtering`, `selectedZipCode`, and `selectedMileage`
          response.filters[index] = {
            ...response.filters[index],
            selected: currentLocFilter.selected,
            filtering: currentLocFilter.filtering,
            selectedZip: currentLocFilter.selectedZip,
            selectedMileage: currentLocFilter.selectedMileage,
          };
        }
      }

      if (params.path_slug) {
        updatedParams.path_slug = params.path_slug;
      }

      return {
        ...state,
        filters: response.filters,
        params: updatedParams,
      };
    }

    case 'SET_PARAM_FILTERS': {
      const { payload } = action;
      const params = Object.assign({}, state.params, payload.params);

      // Set the parsedParams
      if (payload.params.parseParams) {
        Object.keys(payload.params.parsedParams).forEach((key) => {
          params[key] = payload.params.parsedParams[key].split(',');
        });
      }

      // Set the query parameters
      if (payload.params.queryParams) {
        Object.keys(payload.params.queryParams).forEach((key) => {
          const parts = payload.params.queryParams[key].split(',');
          params[key] = parts.length > 1 ? parts : parts[0];
        });
      }

      if (payload.params.pathParams) {
        Object.keys(payload.params.pathParams).forEach((key) => {
          params[key] = payload.params.pathParams[key];
        });
      }

      if (action.payload.params.path_slug) {
        params.path_slug = action.payload.params.path_slug;
      }

      // The sale slug needs to be converted to an id
      if (params.sale_slug) {
        const [sale_id] = params.sale_slug.split('-');
        params.sale_id = sale_id;
        delete params.sale_slug;
      }

      return {
        ...state,
        params,
      };
    }

    case 'UPDATE_SELECTED_FILTERS': {
      let filterIndex;

      const filter = Object.assign(
        {},
        state.filters.find((stateFilter, index) => {
          if (action.payload.filter.query === stateFilter.query) {
            filterIndex = index;
            return true;
          }
          return false;
        })
      );

      if (!filter) {
        return {
          ...state,
        };
      }

      // Created selected property if doesn't already exists
      // filters come from API respones, no selected values exist on load
      if (!filter.selected) {
        filter.selected = [];
      } else {
        filter.selected = [...filter.selected];
      }

      // Find original filter value and keep track of it's index to replace later
      const filterValue = filter.data.find(
        (dataValue) => dataValue.id === action.payload.value
      );
      const selectedIndex = filter.selected.indexOf(filterValue);
      if (filter.type === FILTER_MUTLI_SELECT) {
        if (action.payload.state && filterValue && selectedIndex < 0) {
          filter.selected.push(filterValue);
        } else {
          filter.selected.splice(selectedIndex, 1);
        }
      } else if (selectedIndex < 0) {
        // Check if value is already selected, add if not
        // all other inputs just add the payload
        filter.selected.push(action.payload);
      } else {
        filter.selected.splice(selectedIndex, 1);
      }

      state.filters[filterIndex] = filter;

      // reassign values to prevent views from not rendering
      const params = Object.assign({}, state.params);
      const updatedFilters = [];

      state.filters.forEach((stateFilter) => {
        // reassign values to prevent views from not rendering
        stateFilter = Object.assign({}, stateFilter);
        updatedFilters.push(stateFilter);

        // set params of selected filters
        if (stateFilter.selected) {
          params[stateFilter.query] = stateFilter.selected.map(
            (selectedFilter) => selectedFilter.id
          );
        }
      });

      return {
        ...state,
        params,
        filters: updatedFilters,
      };
    }

    // Updates the location filter
    case 'UPDATE_LOCATION': {
      const stateFilters = state.filters;
      if (!stateFilters) {
        return state;
      }
      const filters = [...stateFilters];
      const params = Object.assign({}, state.params);

      const index = filters.findIndex((f) => f.component === 'location');
      if (index < 0) {
        return state;
      }

      const filter = { ...filters[index], ...action.payload };

      // If it's filtering, then adjust the `selected` field here
      if (
        filter.filtering &&
        filter.selectedZip &&
        filter.selectedZip.length === 5
      ) {
        filter.selected = {
          miles: filter.selectedMileage,
          zip_code: filter.selectedZip,
        };

        params.miles = filter.selectedMileage;
        params.zip_code = filter.selectedZip;
      } else {
        filter.selected = null;
        params.miles = [];
        params.zip_code = [];
      }

      filters[index] = filter;

      return {
        ...state,
        params,
        filters,
      };
    }
    // This case just map reduces over a set of action payloads
    case 'MULTIPLE_ACTIONS':
      return action.payloads.reduce(
        (acc, payload) => routeReducer(acc, payload),
        state
      );

    case 'UPDATE_ROUTE_URL':
      return {
        ...state,
        url: action.payload,
      };

    case 'RESET_FILTERS':
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};

export default routeReducer;
