// @flow

import { type Dispatch as ReduxDispatch } from 'redux';

import { type State } from '~/admin/shared/reducers/analytics';
import buildAnalytics from '~/vendor/analytics.js-loader';
import config from 'config';
import analytics from '~/admin/shared/analytics';

type GetState = () => { analytics: State };

type ObjectWithId = {|
  id: ID,
|};

export type AnalyticsClient = {
  track: (eventName: string, params: Object) => any,
};

export interface SessionEventAction {
  type: 'CREATE_SESSION';
  sessionId: number;
}

export interface EventAction {
  type: 'TRACK_EVENT';
  name: string;
  sessionId: number;
  data: {};
}

export type Action = SessionEventAction | EventAction;

const getClient = (): AnalyticsClient => {
  return (
    analytics.client ||
    buildAnalytics({
      skipPageCall: true,
      ...config.segment,
    })
  );
};

const generateSessionId = () => {
  const randomValues = new Uint32Array(1);
  window.crypto.getRandomValues(randomValues);
  return randomValues[0];
};

const getSessionIdFromState = (state: $Call<GetState>) => {
  return state.analytics ? state.analytics.sessionId : null;
};

export const ensureSession = ({ reset = false }: { reset: boolean } = {}) => async (
  dispatch: ReduxDispatch<SessionEventAction>,
  getCurrentState: GetState
) => {
  let sessionId = getSessionIdFromState(getCurrentState());

  if (!sessionId || reset === true) {
    sessionId = generateSessionId();

    dispatch({
      type: 'CREATE_SESSION',
      sessionId,
    });
  }
};

export const trackEvent = (eventName: string, eventData: {}) => (
  dispatch: ReduxDispatch<EventAction>,
  getCurrentState: GetState
) => {
  ensureSession()(dispatch, getCurrentState);

  const sessionId = getSessionIdFromState(getCurrentState());

  getClient().track(eventName, {
    ...eventData,
    sessionId,
  });

  const event = {
    type: 'TRACK_EVENT',
    name: eventName,
    data: eventData,
    sessionId,
  };

  dispatch(event);
};

// ---------- CATALOGING
export const itemCatalogingCompleted = ({ id: itemId }: ObjectWithId) => trackEvent('Item Cataloging Completed', { itemId });
export const itemCatalogingSaved = ({ id: itemId }: ObjectWithId) => trackEvent('Item Cataloging Saved', { itemId });
export const itemCatalogingStarted = ({ id: itemId }: ObjectWithId) => trackEvent('Item Cataloging Started', { itemId });
export const itemCatalogingPreviewed = ({ id: itemId }: ObjectWithId) => trackEvent('Item Cataloging Previewed', { itemId });
export const itemCatalogingCategoryChanged = (params: {
  item: ObjectWithId,
  oldCategory: ?ObjectWithId,
  newCategory: ?ObjectWithId,
}) => trackEvent('Item Cataloging Category Changed', params);

// ---------- PACKING
export const itemPackingBlocked = ({ id: itemId }: ObjectWithId) => trackEvent('Item Packing Blocked', { itemId });
export const itemPackingCompleted = ({ id: itemId }: ObjectWithId) => trackEvent('Item Packing Completed', { itemId });
export const itemPackingStarted = ({ id: itemId }: ObjectWithId) => trackEvent('Item Packing Started', { itemId });

// ---------- PHOTO EDITING
export const itemPhotoEditingCompleted = ({ id: itemId }: ObjectWithId) => trackEvent('Item Photo Editing Completed', { itemId });
export const itemPhotoEditingStarted = ({ id: itemId }: ObjectWithId) => trackEvent('Item Photo Editing Started', { itemId });

// ---------- SHIPPING
export const parcelShippingStarted = ({ id: parcelId }: ObjectWithId) => trackEvent('Parcel Shipping Started', { parcelId });
export const parcelShippingLabelPrinted = (
  { id: itemId }: ObjectWithId,
  { id: parcelId }: ObjectWithId
) => trackEvent('Parcel Shipping Label Printed', { itemId, parcelId });

// ---------- MISC
export const itemCreatedByBarcode = (barcode: string) => trackEvent('Item Created By Barcode', { barcode });
export const itemUnassigned = ({
  item: { id: itemId },
  state,
}: {
  item: ObjectWithId,
  state: string,
}) => trackEvent('Item Unassigned', { itemId, state });
export const itemApproved = ({ id: itemId }: ObjectWithId) => trackEvent('Item Approved', { itemId });
export const resetSession = () => ensureSession({ reset: true });
