import update from 'immutability-helper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, LoadingDots } from '~/public/shared/components';
import BuyerInvoiceHelper from '~/public/buyer_invoices/utils/BuyerInvoiceHelper';
import PaymentMethodHelper from '~/public/shared/utils/PaymentMethodHelper';

async function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

class BuyerInvoiceForm extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    const paymentMethodTypes = PaymentMethodHelper.paymentMethodTypes();

    this.paymentMethodTypes = paymentMethodTypes;

    const paymentOptions = BuyerInvoiceHelper.availablePaymentOptions(props.paymentMethods, {
      requireWireTransfer: props.requireWireTransfer,
      ableToPayAtPickup: props.ableToPayAtPickup,
    });

    const paymentOption = paymentOptions.find(
      (option) => option.id === String(props.buyerInvoicePaymentId)
    );


    const buyerInvoice = BuyerInvoiceHelper.initialize(
      props.addresses,
      paymentOption,
      props.requireWireTransfer
    );

    this.state = {
      buyerInvoice,
      paymentOptions,
      // type formState =
      //  | 'WAITING_FOR_INPUT' // Filling out the form
      //  | 'SAVING' // Saving a payment method and/or invoice adjustment
      //  | 'PROCESSING_PAYMENT' // Sidekiq job to process payment is running
      formState: 'WAITING_FOR_INPUT',
      paymentMethodType: paymentMethodTypes[0],
      addresses: props.addresses,
    };
  }

  pollForStatus = async (buyerInvoiceId, redirectPath, tries = 4) => {
    this.setState({ formState: 'PROCESSING_PAYMENT' });

    try {
      let { buyerInvoice } = this.state;
      const response = await BuyerInvoiceHelper.checkStatus(buyerInvoiceId);
      const status = await response.json();

      if (status.successful === true) {
        window.location = redirectPath;
      } else if (status.void === true) {
        const notes = status.notes || '';
        buyerInvoice = update(
          buyerInvoice, {
            hasErrors: { $set: true },
            paymentOption: { $set: null },
            base: { errors: { $set: [`${notes} Please try a different payment method.`] } },
          }
        );
        this.setState({ buyerInvoice, formState: 'WAITING_FOR_INPUT' });
      } else if (tries > 0) {
        await delay(5000);
        return this.pollForStatus(buyerInvoiceId, redirectPath, tries - 1);
      } else {
        window.location.reload();
      }
    } catch (e) {
      await delay(5000);
      return this.pollForStatus(buyerInvoiceId, redirectPath, tries - 1);
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { buyerInvoiceId } = this.props;
    let { buyerInvoice } = this.state;

    // 1. Validate everything client-side first
    buyerInvoice = BuyerInvoiceHelper.validate(buyerInvoice);

    if (buyerInvoice.hasErrors) {
      this.setState({ buyerInvoice });
      return;
    }

    this.setState({ formState: 'SAVING' });

    try {
      // 2. Save the payment to the invoice
      this.savePayment(buyerInvoiceId, buyerInvoice);
    } catch (_err) {
      buyerInvoice = update(
        buyerInvoice,
        { hasErrors: { $set: true }, base: { errors: { $set: ['Network error, please try again.'] } } }
      );
      this.setState({
        buyerInvoice,
        formState: 'WAITING_FOR_INPUT',
      });
    }
  }

  savePayment = async (buyerInvoiceId, buyerInvoice) => {
    try {
      const response = await BuyerInvoiceHelper.savePayment(buyerInvoiceId, buyerInvoice);

      if (response.success) {
        if (response.pollForStatus) {
          this.pollForStatus(buyerInvoiceId, response.redirectPath);
        } else {
          window.location = response.redirectPath;
        }
      } else {
        this.setState({
          buyerInvoice: response.buyerInvoice,
          formState: 'WAITING_FOR_INPUT',
        });
      }
    } catch (_err) {
      buyerInvoice = update(
        buyerInvoice,
        { hasErrors: { $set: true }, base: { errors: { $set: ['Network error, please try again.'] } } }
      );
      this.setState({
        buyerInvoice,
        formState: 'WAITING_FOR_INPUT',
      });
    }
  }

  renderPendingMessage = () => {
    return (
      <div className="box u-pb4 u-text-center">
        <h5>
          <LoadingDots className="u-mr2" />Processing your payment
        </h5>
      </div>
    );
  }

  renderCheckoutButton = () => {
    const {
      formState,
      buyerInvoice,
      paymentMethodType,
    } = this.state;
    const isSubmitting = formState !== 'WAITING_FOR_INPUT';
    const { isAddingPaymentMethod } = buyerInvoice.paymentMethod;
    const { requireWireTransfer } = this.props;

    if (isAddingPaymentMethod && paymentMethodType.value === 'plaid') {
      return (
        <div className="box">
          After successfully linking your bank account, you will be able to check out by
          selecting it from the available payment options.
        </div>
      );
    } else {
      return (
        <>
          <div className="u-flex u-flex-apart">
            <Button
              type="button"
              style={{ flexGrow: 1 }}
              buttonStyle="primary-important btn--sm-block u-ml1 u-mr1"
              buttonSize="xl"
              onClick={() => window.history.back()}
            >
              Back
            </Button>
            <Button
              type="submit"
              style={{ flexGrow: 1 }}
              buttonStyle="primary-important btn--sm-block u-ml1 u-mr1"
              buttonSize="xl"
              inFlight={isSubmitting}
              disabled={requireWireTransfer}
            >
              {requireWireTransfer ? 'Requires Wire Transfer' : 'Checkout'}
            </Button>
          </div>
          { buyerInvoice.hasErrors &&
            <div>
              {buyerInvoice.base.errors.map((err) => {
                return (
                  <div className="flash flash--error u-mt2 u-ml1 u-mr1">
                    <div className="flash__body">{err}</div>
                  </div>);
              })}
            </div>
          }
        </>
      );
    }
  }

  renderCheckout = () => {
    return (
      <div>
        <form
          onSubmit={this.handleSubmit}
          ref={(paymentForm) => { this.paymentForm = paymentForm; }}
        >
          {this.renderCheckoutButton()}
        </form>
      </div>
    );
  }

  render() {
    switch (this.state.formState) {
      case 'PROCESSING_PAYMENT':
        return this.renderPendingMessage();
      default:
        return this.renderCheckout();
    }
  }
}

BuyerInvoiceForm.propTypes = {
  // PropTypes does not detect usage within async functions
  // Issue: https://github.com/yannickcr/eslint-plugin-react/issues/1053
  // eslint-disable-next-line react/no-unused-prop-types
  ableToPayAtPickup: PropTypes.bool.isRequired,
  buyerInvoiceId: PropTypes.number.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  requireWireTransfer: PropTypes.bool.isRequired,

  addresses: PropTypes.array,
  paypageId: PropTypes.string.isRequired,
  paypageUrl: PropTypes.string.isRequired,
  plaidEnvironment: PropTypes.string.isRequired,
  useVantiv: PropTypes.bool.isRequired,
};

BuyerInvoiceForm.defaultProps = {
  addresses: [],
};

export default BuyerInvoiceForm;
