// @flow

import React, { type StatelessFunctionalComponent } from 'react';

export const PaymentHeader: StatelessFunctionalComponent<{
  gridStyle: string,
}> = ({
  gridStyle,
}) => {
  return (
    <div className="index-listing__heading-row u-mt2" style={{ gridTemplateColumns: gridStyle }}>
      <span className="index-listing__cell index-listing__cell--heading">Invoice</span>
      <span className="index-listing__cell index-listing__cell--heading">Date</span>
      <span className="index-listing__cell index-listing__cell--heading">Status</span>
      <span className="index-listing__cell index-listing__cell--heading u-text-right">Amount</span>
    </div>
  );
};
