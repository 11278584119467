// @flow

import type { ItemChange } from '../types';

export const UPDATE_ITEM = 'UPDATE_ITEM';

export type UpdateItemAction = {
  type: 'UPDATE_ITEM',
  itemChange: ItemChange,
};

export type Actions = UpdateItemAction;

export const updateItem = (itemChange: ItemChange): UpdateItemAction => {
  return {
    type: 'UPDATE_ITEM',
    itemChange,
  };
};
