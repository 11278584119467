/* globals jQuery */

import {
  flashMessageDisplayed,
  followItemIntentDisplayed,
} from '../actions/items';

export const handleStoreChange = (store) => (() => {
  const state = store.getState().items;

  if (state.flashMessage) {
    window.EBTH.flash.show(state.flashMessage, 'error');
    store.dispatch(flashMessageDisplayed());
  }

  if (state.unsuccessfulFollowedItemId) {
    window.EBTH.intents.set('follow_this_item', { id: state.unsuccessfulFollowedItemId });
    jQuery('#sign-up').modal('open');
    store.dispatch(followItemIntentDisplayed());
  }

  if (state.followSearchFailure === true) {
    window.EBTH.flash.show(state.followSearchFailureMessage, 'error');
    store.dispatch({ type: 'FOLLOW_SEARCH_FAILURE_ALERTED' });
  }

  if (state.unfollowSearchFailure === true) {
    window.EBTH.flash.show(state.items.followSearchFailureMessage, 'error');
    store.dispatch({ type: 'UNFOLLOW_SEARCH_FAILURE_ALERTED' });
  }

  if (state.followSearchLoggedOut) {
    window.EBTH.intents.set('follow_this_search', { params: state.followSearchLoggedOut });
    jQuery('#sign-up').modal('open');
    store.dispatch({ type: 'FOLLOW_SEARCH_LOGGED_OUT_FAILURE_ALERTED' });
  }
});
