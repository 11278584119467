// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import classNames from 'classnames';

export const Spinner: StatelessFunctionalComponent<{
  className?: string,
  absolute?: boolean,
  inverse?: boolean,
  label?: string,
}> = ({
  className, absolute, inverse, label,
}) => {
  const hasLabel = !!label;
  const spinnerClassName = classNames('spinner', {
    'spinner--absolute': !!absolute,
    'spinner--inverse': !!inverse,
    'spinner--pre-text': hasLabel,
  });

  return (
    <div className={className}>
      <span className={spinnerClassName} />
      {hasLabel && <span>{label}</span>}
    </div>
  );
};
