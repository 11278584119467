// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import formatCurrency from '~/utils/formatCurrency';
import moment from 'moment';
import { PaymentStatus } from './PaymentStatus';

export const PaymentListing: StatelessFunctionalComponent<{
  invoice: Object,
  gridStyle: string,
}> = ({
  invoice,
  gridStyle,
}) => {
  return (
    <div className="index-listing__data-row index-listing__data-row--no-thumbnail" style={{ gridTemplateColumns: gridStyle }}>
      <span className="index-listing__cell"><strong>{invoice.number}</strong></span>
      <span className="index-listing__cell">{moment(invoice.createdAt).format('M/DD/YYYY')}</span>
      <span className="index-listing__cell">
        <PaymentStatus aasmState={invoice.aasmState} paymentDate={invoice.paymentDate} />
      </span>
      <span className="index-listing__cell">
        <h4>{formatCurrency(invoice.totalToSeller, { showZeroes: true })}</h4>
      </span>
    </div>
  );
};
