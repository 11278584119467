// @flow

import gql from 'graphql-tag';
import { ApolloClient } from 'react-apollo';

import { MerchItemsFragment } from '../queries/ItemSearchQuery';

const MUTATION = gql`
  mutation RemoveItemsFromSale($input: RemoveItemsFromSaleInput!) {
    removeItemsFromSale(input: $input) {
      items {
        ...merchItemsFields
      }
    }
  }
  ${MerchItemsFragment}
`;

export type RemoveItemsFromSaleParams = {
  itemIds: ID[],
}

export default function (client: ApolloClient) {
  return async (itemMoveParams: RemoveItemsFromSaleParams) => (
    client.mutate({
      mutation: MUTATION,
      variables: { input: itemMoveParams },
      fetchPolicy: 'no-cache',
    })
  );
}
