import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '~/admin/shared/components';
import ContentSvgIcon from '~/admin/shared/svgs/ContentSvgIcon';

export const ContentExpand = (props) => {
  const [open, toggle] = useState(false);
  const contentClasses = classNames(
    'content-block',
    {
      open: open, closed: !open,
    }
  );
  const {
    expandText,
    collapseText,
    children,
  } = props;
  return (
    <div className="expandable-content">
      <Button
        onClick={() => toggle(!open)}
        buttonStyle="link"
        className="u-pr0"
        icon={
          <ContentSvgIcon
            icon={open ? 'ic_remove_24px' : 'ic_add_24px'}
            className="button__icon button__icon--pre-text"
          />
        }
      >{open ? collapseText : expandText}
      </Button>
      <div className={contentClasses}>{children}</div>
    </div>
  );
};

export default ContentExpand;
