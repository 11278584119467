// @flow

import React, { Component, type Node } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export type LinkPathType = 'internal' | 'external';

type Props = {
  to: string,
  type?: LinkPathType,
  children?: Node,
};

export class Link extends Component<Props> {
  static defaultProps = {
    type: 'internal',
  };

  render() {
    switch (this.props.type) {
      case 'external': {
        // eslint-disable-next-line no-unused-vars
        const { type, to, ...aProps } = this.props;
        return (
          <a href={to} {...aProps}>
            {this.props.children}
          </a>
        );
      }
      case 'internal': {
        // eslint-disable-next-line no-unused-vars
        const { type, ...linkProps } = this.props;
        return <RouterLink {...linkProps}>{this.props.children}</RouterLink>;
      }
      default:
        return null;
    }
  }
}
