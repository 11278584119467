// @flow

import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

import bid from '../reducers/bid';
import bidNew from '../reducers/bidNew';
import config from '../reducers/config';
import item from '../reducers/item';
import modal from '../reducers/modal';
import shippingQuote from '../reducers/shippingQuote';
import shippingQuoteBid from '../reducers/shippingQuoteBid';
import user from '../reducers/user';

function devToolEnhancer() {
  // The list of actions in the Redux tools
  const actionCreators = {
  };

  if (window.devToolsExtension) {
    return window.devToolsExtension({ actionCreators });
  } else {
    return (noop) => noop;
  }
}

export default function configureStore() {
  const reducers = combineReducers({
    bid,
    bidNew,
    config,
    item,
    modal,
    shippingQuote,
    shippingQuoteBid,
    user,
  });

  const enhancer = compose(
    applyMiddleware(thunk),
    devToolEnhancer()
  );

  const store = createStore(reducers, {}, enhancer);

  return store;
}
