// @flow

import { type Dispatch as ReduxDispatch } from 'redux';

export type UploadModalAction = {
  id: string,
  type: 'UPLOAD_MODAL_TOGGLE',
  open: boolean
};

export type DownloadModalAction = {
  id: string,
  type: 'DOWNLOAD_MODAL_TOGGLE',
  open: boolean
}

type ActionProps = {
  open: boolean,
}

type ItemActionProps = {
  open: boolean,
  itemId: string
}

export const toggleUploadModal = ({ open }: ActionProps) => (dispatch: ReduxDispatch) => {
  dispatch({ type: 'UPLOAD_MODAL_TOGGLE', open });
};

export const toggleDownloadModal = ({ open }: ActionProps) => (dispatch: ReduxDispatch) => {
  dispatch({ type: 'DOWNLOAD_MODAL_TOGGLE', open });
};

export const toggleItemDetailsModal = ({
  open,
  itemId,
}: ItemActionProps) => (dispatch: ReduxDispatch) => {
  dispatch({ type: 'ITEM_DETAILS_MODAL_TOGGLE', open, id: itemId });
};

export const onItemUploadSuccess = ({ id }: { id: string }) => (dispatch: ReduxDispatch) => {
  dispatch({ type: 'ITEM_UPLOAD_SUCCESS', id });
};

export const onItemUploadComplete = () => (dispatch: ReduxDispatch) => {
  dispatch({ type: 'ITEM_UPLOAD_COMPLETE' });
};
