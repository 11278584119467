// @flow

import React, { Component } from 'react';
import basicInputWrapper from './basicInputWrapper';

type Props = {
  id: string,
  onChange: (string) => void,
  type?: string,
  value: string,
};

class TextInput extends Component<Props> {
  static defaultProps = {
    type: 'text',
    value: '',
  };

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  }

  render() {
    const {
      id,
      ...otherProps
    } = this.props;

    return (
      <input
        id={id}
        className="form-control__input form-input"
        {...otherProps}
        onChange={this.handleChange}
      />
    );
  }
}

export default basicInputWrapper(TextInput);
