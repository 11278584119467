// @flow

import React, { Component } from 'react';
import basicInputWrapper from './basicInputWrapper';

type Props = {
  id: string,
  onChange: (string) => void,
  value: ?string,
};

class TextareaInput extends Component<Props> {
  handleChange = (event: SyntheticInputEvent<HTMLTextAreaElement>) => {
    this.props.onChange(event.target.value);
  }

  render() {
    const {
      id,
      value,
      ...otherProps
    } = this.props;

    return (
      <textarea
        id={id}
        className="form-control__input"
        {...otherProps}
        onChange={this.handleChange}
        value={value || ''}
      />
    );
  }
}

export default basicInputWrapper(TextareaInput);
