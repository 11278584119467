// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Shipping from '../../components/fulfillment_types/Shipping';
import * as ShippingQuoteActions from '../../actions/shippingQuote';

const mapStateToProps = ({ shippingQuote, item: { item } }) => ({ ...shippingQuote, item });

const mapDispatchToProps = (dispatch) => bindActionCreators(ShippingQuoteActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Shipping);
