import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '~/public/shared/components';

function ContentExpand(props) {
  const [open, toggle] = useState(false);
  const contentClasses = classNames(
    'content-block',
    {
      open: open, closed: !open,
    }
  );
  const blockClasses = classNames(
    'expandable-content',
    {
      'w-50': props.reducedSize,
    }
  );
  return (
    <div className={blockClasses}>
      <Button
        onClick={() => toggle(!open)}
        buttonDisplay="link"
        buttonSize="sm"
      >{open ? props.collapseText : props.expandText}
      </Button>
      <p className={contentClasses}>{props.content}</p>
    </div>
  );
}

export default ContentExpand;
