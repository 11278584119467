import update from 'immutability-helper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckboxField from '~/public/shared/components/CheckboxField';
import CreditCardFormFields from '~/public/shared/components/CreditCardFormFields';
import { Flash } from '~/public/shared/components';
import SelectAddressForm from '~/public/shared/components/SelectAddressForm';

class PaymentMethodCreditCardForm extends Component {
  handleChange = (key) => {
    return (change) => {
      const { paymentMethod } = this.props;
      const newPaymentMethod = update(paymentMethod, { [key]: { value: { $set: change } } });
      this.props.onPaymentMethodChange(newPaymentMethod);
    };
  }

  handleAddressChange = (address) => {
    const { paymentMethod } = this.props;
    const newPaymentMethod = update(paymentMethod, { address: { $set: address } });
    this.props.onPaymentMethodChange(newPaymentMethod);
  }

  handlePaymentMethodChange = (paymentMethod) => {
    this.props.onPaymentMethodChange(paymentMethod);
  }

  renderBaseError = () => {
    const { errors } = this.props.paymentMethod.base;

    if (errors.length === 0) return null;

    return (
      <Flash
        flashStyle="error"
        showIcon
        className="u-mb2"
      >
        {errors.join(',')}
      </Flash>
    );
  }

  renderVantivFields = () => {
    const { number, cvv } = this.props.paymentMethod;

    if (!this.props.useVantiv) return null;

    return (
      <div>
        <input type="hidden" id="vantiv-account-num" value={number.value} />
        <input type="hidden" id="vantiv-cvv" value={cvv.value} />
      </div>
    );
  }

  render() {
    const { addresses, paymentMethod } = this.props;
    paymentMethod.paymentMethodTypeId = 1;

    return (
      <div>
        <div className="l-form-grid u-mt8 u-mb4">
          {this.renderVantivFields()}
          {this.renderBaseError()}
          <CreditCardFormFields
            paymentMethod={paymentMethod}
            onChange={this.handlePaymentMethodChange}
          />
        </div>
        <div className="card u-mb4">
          <div className="card__heading">
            <span className="u-mr2 u-ib">{addresses.length === 0 ? 'Add a Billing Address' : 'Select a Billing Address'}</span>
            {paymentMethod.address.hasErrors &&
              <div className="error-text">{paymentMethod.address.id.errors.join(',')}</div>
            }
          </div>
          <div className="card__body">
            <SelectAddressForm
              addresses={addresses}
              field={paymentMethod.address}
              onChange={this.handleAddressChange}
            />
          </div>
        </div>

        <div className="u-mb4">
          <CheckboxField
            label="Make this your primary payment method"
            name="primary"
            onChange={this.handleChange('primary')}
            {...paymentMethod.primary}
          />
        </div>

        <div className="l-form-grid">
          <div className="l-form-grid__row">
            <div className="l-form-grid__item l-form-grid__item--9-col">
              <p className="u-text-muted">
                <em>
                  Some credit cards and banks require a $1 authorization fee for validation
                  purposes. This authorization is temporary and will be removed from your account
                  within 7 business days.
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PaymentMethodCreditCardForm.propTypes = {
  onPaymentMethodChange: PropTypes.func.isRequired,
  addresses: PropTypes.array.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  useVantiv: PropTypes.bool.isRequired,
};

PaymentMethodCreditCardForm.defaultProps = {
};

export default PaymentMethodCreditCardForm;
