// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '../components/Modal';
import * as ModalActions from '../actions/modal';

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = (dispatch) => bindActionCreators(ModalActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
