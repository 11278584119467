// @flow

export default (start: Date, end: Date): string => {
  if (start > end) {
    return '';
  }

  const distanceInSeconds = Math.floor((end - start) / 1000);
  const distanceInMinutes = Math.round(distanceInSeconds / 60);

  if (distanceInSeconds <= 60) {
    return `${distanceInSeconds} seconds`;
  }

  if (distanceInSeconds < 90) { // 1.5 minutes
    return '1 minute';
  }

  if (distanceInMinutes <= 120) {
    return `${distanceInMinutes} minutes`;
  }

  if (distanceInMinutes <= 1440) {
    return `${Math.round(distanceInMinutes / 60)} hours`;
  }

  if (distanceInMinutes < 2160) { // 1.5 days
    return '1 day';
  }

  if (distanceInMinutes <= 43200) {
    return `${Math.round(distanceInMinutes / 1440)} days`;
  }

  if (distanceInMinutes < 65700) { // 1.5 months
    return '1 month';
  }

  if (distanceInMinutes <= 525960) {
    return `${Math.round(distanceInMinutes / 43200)} months`;
  }

  if (distanceInMinutes < 788400) { // 1.5 years
    return '1 year';
  }

  return `${Math.round(distanceInMinutes / 525960)}  years`;
};
