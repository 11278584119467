// @flow

import type {
  User,
  Item,
  Config,
  Bid,
} from '../types';

export const BOOTSTRAP = 'BOOTSTRAP';

export type BootstrapAction = {
  type: 'BOOTSTRAP',
  item: Item,
  bid: Bid,
  user: ?User,
  config: Config,
};

export type Actions = BootstrapAction;

export const bootstrap = (
  {
    item,
    bid,
    user,
    config,
  }: {
    item: Item,
    bid: Bid,
    user: ?User,
    config: Config
  }
): BootstrapAction => {
  return {
    type: 'BOOTSTRAP',
    user,
    item,
    bid,
    config,
  };
};
