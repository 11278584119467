// @flow

import gql from 'graphql-tag';
import { ApolloClient } from 'react-apollo';

const MUTATION = gql`
  mutation approveAutoMerchSale($saleId: ID!) {
    approveAutoMerchSale(input: { id: $saleId }) {
      approved
      sale {
        id
        name
        saleCode
        saleStartsAt
        completionDue
        isConsignmentSelect
        isAutoMerch
        canBeApproved
        isApproved
      }
    }
  }
`;

export type ApproveAutoMerchSaleParams = {
  saleId: ID,
}

export default function (client: ApolloClient) {
  return async (variables: ApproveAutoMerchSaleParams) => (
    client.mutate({
      mutation: MUTATION,
      variables,
    })
  );
}
