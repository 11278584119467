// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';

import { displayNotice } from '~/admin/shared/actions/notice';

import PhotoUploadDropArea from '../components/PhotoUploadDropArea';
import PhotoUploadDisplay from '../components/PhotoUploadDisplay';
import {
  addPhotos,
  removePhoto,
  uploadPhotos,
} from '../actions';
import type { Photo } from '../types';

type Props = {
  addPhotos: (Array<Photo>) => any,
  displayNotice: typeof displayNotice,
  uploadPhotos: (Array<Photo>) => any,
  removePhoto: (Photo) => any,
  photos: Array<Photo>,
};

class PhotoUploadDropAreaContainer extends Component<Props> {
  onDrop = (photos: Array<Photo>) => {
    const totalPhotos = this.props.photos.length + photos.length;
    if (totalPhotos <= 20) {
      this.props.addPhotos(photos);
      this.props.uploadPhotos(photos);
    } else {
      this.props.displayNotice({
        message: 'You can upload a maximum of 20 photos',
        type: 'danger',
      });
    }
  }

  render() {
    const photoNodes = this.props.photos.map((photo: Photo) => {
      return (
        <PhotoUploadDisplay
          key={photo.localKey}
          photo={photo}
          onDelete={this.props.removePhoto}
        />
      );
    });

    return (
      <PhotoUploadDropArea onDrop={this.onDrop}>
        {photoNodes}
      </PhotoUploadDropArea>
    );
  }
}

type ReduxState = { photos: { valid: Array<Photo>, invalid: Array<Photo> } };
const mapStateToProps = ({ photos }: ReduxState): { photos: Array<Photo> } => {
  const { valid, invalid } = photos;
  return { photos: [...valid, ...invalid] };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  addPhotos,
  displayNotice,
  removePhoto,
  uploadPhotos,
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose(connector)(PhotoUploadDropAreaContainer);
