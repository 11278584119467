// @flow
import classNames from 'classnames';
import React, { Component } from 'react';

import NavigationSvgIcon from '~/admin/shared/svgs/NavigationSvgIcon';
import { CarouselImage, type CarouselImageType } from './CarouselImage';

const nextIndex = (currentIndex: number, totalCount: number) => (currentIndex + 1) % totalCount;

const previousIndex = (currentIndex: number, totalCount: number) => {
  return (totalCount + currentIndex - 1) % totalCount;
};

type Props = {
  images: Array<CarouselImageType>,
  className?: string,
};

type State = {
  activeIndex: number,
  isZoomed: boolean,
};

const defaultState = {
  activeIndex: 0,
  isZoomed: false,
};

export class Carousel extends Component<Props, State> {
  static defaultProps = {
    className: '',
  };

  state = defaultState;

  onNext = () => {
    this.setState({
      activeIndex: nextIndex(this.state.activeIndex, this.props.images.length),
      isZoomed: false,
    });
  };

  onPrevious = () => {
    this.setState({
      activeIndex: previousIndex(this.state.activeIndex, this.props.images.length),
      isZoomed: false,
    });
  };

  onSelectIndex = (index: number) => () => {
    this.setState({
      activeIndex: index,
      isZoomed: false,
    });
  };

  handleZoomChanged = (nextZoomState: boolean) => {
    this.setState({
      isZoomed: nextZoomState,
    });
  };

  renderEmptyState = () => <div className="u-p2">No image available</div>;

  renderNav = (images: Array<CarouselImageType>) => (
    <nav className="image-carousel__nav">
      <button type="button" className="image-carousel__advance" onClick={this.onPrevious}>
        <NavigationSvgIcon icon="ic_chevron_left_24px" className="icon" />
      </button>

      <div className="image-carousel__dots">
        <span>
          {this.state.activeIndex + 1}/{images.length}
        </span>
      </div>

      <button type="button" className="image-carousel__advance" onClick={this.onNext}>
        <NavigationSvgIcon icon="ic_chevron_right_24px" className="icon" />
      </button>
    </nav>
  );

  renderCarousel = () => {
    const { images } = this.props;
    const classes = classNames('image-carousel', this.props.className);
    const selectedImage = images[this.state.activeIndex];

    return (
      <div className={classes}>
        <div className="image-carousel__window">
          <ul className="image-carousel__slides">
            {images.map((image) => {
              const isActive = image === selectedImage;
              return (
                <CarouselImage
                  key={image.thumbnailUrl}
                  image={image}
                  isZoomed={isActive && this.state.isZoomed}
                  isActive={isActive}
                  onZoomChanged={this.handleZoomChanged}
                />
              );
            })}
          </ul>
        </div>

        {images.length > 1 && this.renderNav(images)}
      </div>
    );
  };

  render = () => (this.props.images.length === 0 ? this.renderEmptyState() : this.renderCarousel());
}
