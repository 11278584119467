import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RadioGroupField extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.value);
  }

  renderRadioButton = ({ label, value }) => {
    const { name, value: selectedValue } = this.props;
    const key = `${name}_${value}`;

    return (
      <label key={key} htmlFor={key} className="custom-control custom-control--radio custom-control--toggle">
        <input
          className="custom-control__input"
          type="radio"
          id={key}
          name={name}
          value={value}
          checked={value === selectedValue}
          onChange={this.handleChange}
        />
        <div className="custom-control__label">{label}</div>
      </label>
    );
  }

  render() {
    const { options } = this.props;
    let className = this.props.className || '';

    className += ' custom-control-group';

    return (
      <div className={className}>
        {options.map(this.renderRadioButton)}
      </div>
    );
  }
}

RadioGroupField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

RadioGroupField.defaultProps = {
  className: '',
};

export default RadioGroupField;
