// @flow

import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import classNames from 'classnames';

import type { ComponentType } from 'react';

import type { AppState } from '../reducer';
import type { EstimateRequest } from '../types';

type Props = {
  estimateRequest: EstimateRequest,
  slideNumber: number,
  activeSlide: number,
};

type State = { priceEstimatorTool: AppState };
type ReduxProps = { estimateRequest: EstimateRequest, activeSlide: number };

export default function questionSlide<T: *>(
  WrappedComponent: ComponentType<T>
): ComponentType<$Diff<T, { estimateRequest: EstimateRequest }>> {
  class QuestionSlide extends Component<T & Props> {
    render() {
      const {
        slideNumber,
        activeSlide,
        estimateRequest,
        ...otherProps
      } = this.props;

      const classes = classNames({
        'value-estimator__slide': true,
        'value-estimator__slide--active': slideNumber === activeSlide,
        'value-estimator__slide--complete': slideNumber < activeSlide,
      });

      return (
        <form className={classes}>
          <WrappedComponent estimateRequest={estimateRequest} {...otherProps} />
        </form>
      );
    }
  }

  const mapStateToProps = ({ priceEstimatorTool }: State): ReduxProps => {
    return priceEstimatorTool;
  };

  const connector = connect(mapStateToProps);
  return compose(connector)(QuestionSlide);
}
