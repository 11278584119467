// @flow

import React, { Component, type Node } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

// $FlowFixMe
import { PENDING_ITEM } from '~/shared/graphql/PendingItem/queries/PendingItemQuery';

const CREATE_ATTACHMENTS_MUTATION = gql`
mutation createAttachments($input: CreateAttachmentsInput!) {
  createAttachments(input: $input) {
    attachments {
      id
      imageUrl
      thumbnailUrl
      isInternal

      createdAt
      createdBy {
        displayName
      }

      updatedAt
      updatedBy {
        displayName
      }
    }
  }
}
`;

type Props = {
  setPhotosUploading: (boolean) => {},
  onError: (any) => any,
  children: (createAttachments: Function) => Node,
};

class CreateAttachmentsMutation extends Component<Props> {
  createAttachments = (createAttachmentsMutation: Function) => (itemId: string, files: [File]) => {
    // Create array to hold files and a completed total
    let done = 0;
    const total = files.length;
    const readFiles = new Array(total);

    // Set as loading
    const { setPhotosUploading } = this.props;
    setPhotosUploading(true);

    // Create a function to check how many are done, and run mutation if needed
    const onLoad = () => {
      // Only contine if all of the reads are done
      if (done < total) {
        return;
      }

      createAttachmentsMutation({
        variables: {
          input: {
            id: itemId,
            images: readFiles.map((contents) => contents.toString()),
            type: 'PendingItem',
          },
        },
        update(store, { data: { createAttachments } }) {
          setPhotosUploading(false);

          const data = store.readQuery({ query: PENDING_ITEM, variables: { id: itemId } });
          // Update the data in the store
          data.pendingItem.attachments.edges = createAttachments.attachments.map((att) => {
            return {
              node: att,
              __typename: 'AttachmentEdge',
            };
          });

          store.writeQuery({ query: PENDING_ITEM, variables: { id: itemId }, data });
        },
      });
    };

    // Run through each and read the file in
    files.forEach((file, index) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const contents = reader.result;

        // Add it to the array and increment the done count
        readFiles[index] = contents;
        done += 1;

        onLoad();
      }, false);

      reader.readAsDataURL(file);
    });
  }

  onError = (error: any) => {
    // Turn off the loader
    this.props.setPhotosUploading(false);

    let errorStr;
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorStr = error.graphQLErrors[0].message;
    } else {
      errorStr = error.networkError;
    }

    this.props.onError(errorStr);
  }

  render() {
    return (
      <Mutation mutation={CREATE_ATTACHMENTS_MUTATION} onError={this.onError}>
        {
          (createAttachmentsMutation) => {
            return this.props.children(this.createAttachments(createAttachmentsMutation));
          }
        }
      </Mutation>
    );
  }
}

export default CreateAttachmentsMutation;
