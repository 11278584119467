// @flow

import {
  SET_RUNNING_APP_REVISION,
  UPDATE_APP_REVISION,
  SNOOZE_APP_REVISION,
  WAKE_APP_REVISION,
} from '../actions/appRevision';
import type { Actions } from '../actions/appRevision';


export type AppRevisionState = {
  running: ?string,
  current: ?string,
  snoozed: boolean,
};

export const INITIAL_STATE = ({
  running: null,
  current: null,
  snoozed: false,
}: AppRevisionState);


export default (state: AppRevisionState = INITIAL_STATE, action: Actions): AppRevisionState => {
  switch (action.type) {
    case SET_RUNNING_APP_REVISION:
      return {
        ...state,
        running: action.revision,
        current: action.revision,
      };
    case UPDATE_APP_REVISION:
      return {
        ...state,
        current: action.revision,
      };
    case SNOOZE_APP_REVISION:
      return {
        ...state,
        snoozed: true,
      };
    case WAKE_APP_REVISION:
      return {
        ...state,
        snoozed: false,
      };
    default:
      return state;
  }
};
