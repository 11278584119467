// @flow

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import type { Location, RouterHistory, Match } from 'react-router';
import Layout from '../components/Layout';
import { ContractHeader } from '../components/ContractHeader';
import { ContractListing } from '../components/ContractListing';
import { Button, LoadingDots } from '~/public/shared/components';
import ContractsQuery from '../queries/ContractsQuery';

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
};

class ContractsPage extends Component<Props> {
  componentDidMount() {
    document.title = 'Contracts | Seller Dashboard';
  }

  render() {
    const gridStyle = 'calc(80px + 1rem) 1fr 1fr 1fr 1fr';

    return (
      <Layout>
        <h1 className="u-mb2">Contracts</h1>

        <ContractsQuery>
          {({
            contracts,
            loading,
            initialLoading,
            hasNextPage,
            loadMore,
          }) => {
            if (initialLoading) {
              return (
                <div className="u-mt8 u-text-center">
                  <LoadingDots />
                </div>
              );
            }

            return (
              <Fragment>
                <ContractHeader gridStyle={gridStyle} />

                {contracts.map((contract) => {
                  return (
                    <div key={contract.id} className="expandable-listing">
                      <Link to={`${this.props.match.url}/${contract.number}`} className="expandable-listing__link">
                        <ContractListing contract={contract} gridStyle={gridStyle} />
                      </Link>
                    </div>
                  );
                })}

                {hasNextPage &&
                  <div className="u-mt2 u-text-center">
                    <Button
                      buttonStyle="secondary"
                      inFlight={loading}
                      onClick={loadMore}
                    >
                      Load More
                    </Button>
                  </div>
                }
              </Fragment>
            );
          }}
        </ContractsQuery>
      </Layout>
    );
  }
}

export { ContractsPage };
