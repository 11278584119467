// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import classNames from 'classnames';

export const ProgressBar: StatelessFunctionalComponent<{
  className: ?string,
  percent: number,
}> = ({ className, percent }) => {
  const classes = classNames('progress-bar', className);

  const style = {
    width: `${percent || 0}%`,
  };

  return (
    <div className={classes}>
      <div className="progress-bar__fill" style={style}>
        <div className="progress-bar__percentage">{percent || 0}%</div>
      </div>
    </div>
  );
};
