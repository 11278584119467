import { useQuery } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';

export const GET_TEXT_PREFS = gql`
  query getTextPrefs {
    currentUser {
      mobilePhone
      seller
      notificationPreference {
        receiveOutbidSms
        receiveSaleEndingSms

        receiveMarketingEmails
        receiveSalesEmails
        receiveKeywordEmails
        receiveEndingSoonEmails
        receiveSellerEmails
      }
    }
  }
`;

export interface GetPrefsData {
  mobilePhone?: string;
  seller: boolean;

  // Text updates
  receiveOutbidSms: boolean;
  receiveSaleEndingSms: boolean;

  // Email updates
  receiveMarketingEmails: boolean;
  receiveSalesEmails: boolean;
  receiveKeywordEmails: boolean;
  receiveEndingSoonEmails: boolean;
  receiveSellerEmails: boolean;
}

export interface GetPrefsResponse {
  loading: boolean;
  error?: any;
  data?: any;
  refetch?: any;
  client: ApolloClient<any>;
}

function mapData(data: any): GetPrefsData {
  if (!data) {
    return null;
  }

  return {
    mobilePhone: data.currentUser.mobilePhone,
    seller: data.currentUser.seller,
    receiveSaleEndingSms: data.currentUser.notificationPreference.receiveSaleEndingSms,
    receiveOutbidSms: data.currentUser.notificationPreference.receiveOutbidSms,
    receiveMarketingEmails: data.currentUser.notificationPreference.receiveMarketingEmails,
    receiveSalesEmails: data.currentUser.notificationPreference.receiveSalesEmails,
    receiveKeywordEmails: data.currentUser.notificationPreference.receiveKeywordEmails,
    receiveEndingSoonEmails: data.currentUser.notificationPreference.receiveEndingSoonEmails,
    receiveSellerEmails: data.currentUser.notificationPreference.receiveSellerEmails,
  };
}

export const getPrefs = () => {
  const { loading, error, data, refetch, client } = useQuery(GET_TEXT_PREFS, {
    fetchPolicy: 'network-only',
  });

  return {
    loading: loading,
    error: error,
    data: mapData(data),
    refetch: refetch,
    client: client,
  };
};
