// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import formatCurrency from '~/utils/formatCurrency';
import moment from 'moment';

export const PaymentItemListing: StatelessFunctionalComponent<{
  item: Object,
  gridStyle: string,
}> = ({
  item,
  gridStyle,
}) => {
  const {
    barcode,
    highBidAmount,
    commissionTotal,
    saleEndsAt,
    aasmState,
    name,
    mainAttachment,
    quickpicAttachment,
  } = item;

  const estimatedYield = formatCurrency(
    (highBidAmount - commissionTotal),
    { showZeroes: true }
  );

  const salePrice = formatCurrency(
    highBidAmount,
    { showZeroes: false }
  );

  const formattedSaleEndDate = moment(saleEndsAt).format('M/DD/YYYY');

  let statusDetails = 'Awaiting Payment';

  if (aasmState === 'paid') {
    statusDetails = 'Scheduled';
  }

  let attachement: Object = {};

  if (mainAttachment) {
    attachement = mainAttachment;
  } else if (quickpicAttachment) {
    attachement = quickpicAttachment;
  }

  const { imageUrl = '' } = attachement;

  return (
    <div
      className="index-listing__data-row pay-item"
      style={{ gridTemplateColumns: gridStyle }}
    >
      <span className="index-listing__cell u-flex u-align-items-center">
        <img alt="" className="item-img-thumb" src={imageUrl} />
        <div>
          <strong>{name}</strong>
          <div className="u-text-muted">{barcode}</div>
        </div>
      </span>
      <span className="index-listing__cell">
        <span className="label">Sold On</span>
        <span>{formattedSaleEndDate}</span>
      </span>
      <span className="index-listing__cell">
        <span className="label">Status</span>
        {statusDetails &&
          <span className="u-text-muted u-no-wrap">{statusDetails}</span>
        }
      </span>
      <span className="index-listing__cell">
        <span className="label">Winning Bid</span>
        <h4>{salePrice}</h4>
      </span>
      <span className="index-listing__cell">
        <span className="label">Estimated Yield</span>
        <h4>{estimatedYield}</h4>
      </span>
    </div>
  );
};
