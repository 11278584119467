// @flow

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Icon, Tooltip } from '~/public/shared/components';
import type { MultiSelectOption } from '../types';


type Props = {
  option: MultiSelectOption,
  onChange: Function,
  checked: boolean,
  component: string,
  isModalContext: boolean,
  linkLocation: string,
}

class FilterMultiSelectItem extends PureComponent<Props> {
  render() {
    const {
      onChange,
      component,
      checked,
      option,
      isModalContext,
      linkLocation,
    } = this.props;
    const {
      id,
      name,
      description,
      count,
    } = option;
    const showCount = count != null;
    const htmlId = `${component}-${id}`;
    const labelClassNames = classNames(
      'items-filter-item__label',
      {
        'items-filter-item__label--zero-count': count === 0,
      },
    );

    return (
      <li className="items-filter-item">
        <input
          id={htmlId}
          value={id}
          onChange={onChange}
          checked={checked}
          type="checkbox"
          className="items-filter-item__input"
        />
        <label htmlFor={htmlId} className={labelClassNames}>
          { showCount &&
            <span className="items-filter-item__count">{count}</span>
          }
          {linkLocation ? (
            <Link to={linkLocation}>{name}</Link>
          ) : (
            name
          )
          }
          {}
          { description !== '' && !isModalContext &&
            <Tooltip content={description} placement="right">
              <Icon icon="info" className="items-filter-item__info" />
            </Tooltip>
          }
        </label>
        { isModalContext &&
          <div className="items-filter-item__info-mobile">
            {description}
          </div>
        }
      </li>
    );
  }
}

export default FilterMultiSelectItem;
