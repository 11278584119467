// @flow

import classNames from 'classnames';
import React, { Component } from 'react';

import EditorSvgIcon from '~/admin/shared/svgs/EditorSvgIcon';

type State = {
  isEditing: boolean,
};

type Props = {
  className?: string,
  placeholder: string,
  onChange?: (value: string) => any,
  onCommitChange?: () => any,
  value: ?string,
  maxLength: ?number,
};

class ClickToEditInput extends Component<Props, State> {
  static defaultProps = {
    className: (null: ?string),
    placeholder: '',
    maxLength: (null: ?number),
  };

  state = {
    isEditing: false,
  };

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event.currentTarget.value);
    }
  }

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13) { // enter
      event.preventDefault();

      this.stopEditing();
    }
  }

  handleBlur = (_: SyntheticFocusEvent<HTMLInputElement>) => {
    this.stopEditing();
  }

  startEditing = () => {
    this.setState({ isEditing: true });
  }

  stopEditing = () => {
    this.setState({ isEditing: false });

    if (this.props.onCommitChange) {
      this.props.onCommitChange();
    }
  }

  renderEditView = () => {
    return (
      <input
        autoFocus
        maxLength={this.props.maxLength}
        placeholder={this.props.placeholder}
        value={this.props.value || ''}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        className="click-to-edit__input qa-click-to-edit-input"
      />
    );
  }

  renderTextView = () => {
    let { value } = this.props;

    if (!value) {
      // TODO: JOSH make this look less like a real title, more placeholdery
      value = <span className="click-to-edit__placeholder">{this.props.placeholder}</span>;
    }

    return (
      <div onClick={this.startEditing} className="click-to-edit__text">
        {value}
        <div className="click-to-edit__indicator">
          <EditorSvgIcon icon="ic_mode_edit_24px" className="click-to-edit__icon qa-click-to-edit-button" />
          Edit
        </div>
      </div>
    );
  }

  render() {
    const shouldRenderEditView = this.state.isEditing;
    const classes = classNames(
      'click-to-edit',
      this.props.className
    );

    return (
      <div className={classes}>
        {
          shouldRenderEditView ?
            this.renderEditView() :
            this.renderTextView()
        }
      </div>
    );
  }
}

export default ClickToEditInput;
