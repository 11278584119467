import React from 'react';
import classNames from 'classnames';
import { Icon, Tooltip } from '~/public/shared/components';
import { CardElement } from '@stripe/react-stripe-js';

export const StripeCard = ({
  errors = [],
  label,
  name,
  value,
  floatedLabel = false,
  fieldIcon = null,
  iconToolTip = null,
}) => {
  const hasErrors = errors.length > 0;

  const groupClassNames = classNames('form-group', {
    'form-group--with-icon': fieldIcon,
    'form-group--show-floated-label': floatedLabel && value !== '',
    'form-group--error': hasErrors,
  });

  const labelClassNames = classNames('form-group__label', {
    'form-group__label--floated': floatedLabel,
  });

  return (
    <div className={groupClassNames}>
      <label className={labelClassNames} htmlFor={name}>
        {label}
      </label>

      <CardElement
        className="form-control__input input"
        id="stripe_card_element"
        options={{
          hidePostalCode: true,
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />

      {!!fieldIcon && !iconToolTip && (
        <Icon
          icon={fieldIcon}
          className={`input-icon input-icon--${fieldIcon}`}
        />
      )}
      {!!fieldIcon && !!iconToolTip && (
        <Tooltip content={iconToolTip} placement="top-end">
          <Icon
            icon={fieldIcon}
            className={`input-icon input-icon--${fieldIcon}`}
          />
        </Tooltip>
      )}
      {hasErrors && <div className="form-group__error">{errors.join(',')}</div>}
    </div>
  );
};
