import React, { Component } from 'react';
import classNames from 'classnames';
import CategoryLink from './CategoryLink';
import FilterWrapper from './FilterWrapper';
import { findSubTree, stripEmpties } from '../utils/tree';

class FilterTreeCategory extends Component {
  shouldComponentUpdate(nextProps) {
    return !nextProps.isLoading;
  }

  renderTree = (treeItems, checked, appliedParams) => {
    return (
      treeItems.map((item) => {
        const isSelected = checked === item.fullSlugPath;
        const renderChildren = isSelected && item.children.length > 0;
        const itemClasses = classNames(
          'items-filter-tree__item',
          {
            'items-filter-tree__item--current': isSelected,
          }
        );

        return [
          <li key={item.pathSlug} className={itemClasses}>
            <CategoryLink category={item} className="items-filter-tree__item-link" appliedParams={appliedParams}>
              {item.shortName}
            </CategoryLink>
            { renderChildren &&
              <ul className="items-filter-tree items-filter-tree--children">
                {this.renderTree(item.children, checked, appliedParams)}
              </ul>
            }
          </li>,
          !isSelected && this.renderTree(item.children, checked, appliedParams),
        ];
      })
    );
  }

  render() {
    const { filter, appliedParams } = this.props;
    const { data, name } = filter;
    const checked = (appliedParams && appliedParams.category) ? appliedParams.category.fullSlugPath : null;

    let categories;
    if (checked) {
      categories = findSubTree(data, checked);
    } else {
      categories = data.map((tree) => ({ ...tree, children: [] }));
    }

    categories = stripEmpties(categories, this.props.filterCounts.category, checked);

    if (categories && categories.length === 0) return null;

    const listClasses = classNames(
      'items-filter-tree',
      {
        'items-filter-tree--has-selection': checked,
      }
    );

    return (
      <FilterWrapper title={name}>
        <ul className={listClasses}>
          {checked &&
            <li className="items-filter-tree__item">
              <CategoryLink className="items-filter-tree__item-link" appliedParams={appliedParams}>
                All Categories
              </CategoryLink>
            </li>
          }
          {this.renderTree(categories, checked, appliedParams)}
        </ul>
      </FilterWrapper>
    );
  }
}

export default FilterTreeCategory;
