import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ExpandButton extends Component {
  handleClick = (_evt) => {
    this.props.onClick(!this.props.expanded);
  }

  render() {
    const { text, expanded, topSpacing } = this.props;

    const btnClassNames = classNames(
      'qa-expand-button',
      'expand-btn',
      {
        'is-expanded': expanded,
        'u-mt2': topSpacing,
      }
    );

    return (
      <button type="button" className={btnClassNames} onClick={this.handleClick}>
        <svg className="expand-btn__icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
          <path className="expand-btn__icon-closed" fill="#aaaaaa" d="M16,14 L23,14 L23,16 L16,16 L16,23 L14,23 L14,16 L7,16 L7,14 L14,14 L14,7 L16,7 L16,14 Z M0,15 C0,6.71572875 6.71390727,0 15,0 C23.2842712,0 30,6.71390727 30,15 C30,23.2842712 23.2860927,30 15,30 C6.71572875,30 0,23.2860927 0,15 Z M2,15 C2,22.1812804 7.82029825,28 15,28 C22.1812804,28 28,22.1797017 28,15 C28,7.81871963 22.1797017,2 15,2 C7.81871963,2 2,7.82029825 2,15 Z" />
          <path className="expand-btn__icon-open" fill="#000000" d="M0,15 C0,6.71572875 6.71390727,0 15,0 C23.2842712,0 30,6.71390727 30,15 C30,23.2842712 23.2860927,30 15,30 C6.71572875,30 0,23.2860927 0,15 Z M2,15 C2,22.1812804 7.82029825,28 15,28 C22.1812804,28 28,22.1797017 28,15 C28,7.81871963 22.1797017,2 15,2 C7.81871963,2 2,7.82029825 2,15 Z M7,14 L23,14 L23,16 L7,16 L7,14 Z" />
        </svg>
        {text}
      </button>
    );
  }
}

ExpandButton.propTypes = {
  text: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ExpandButton.defaultProps = {
  expanded: false,
};

export default ExpandButton;
