// @flow

import { type IValidator } from '~/admin/shared/utils/Validator';
import { type Action } from '../actions/screen/common';

export type State = {
  completeLoading: ?boolean,
  saveLoading: ?boolean,
  saveDraftLoading: ?boolean,
  sendToQueueLoading: ?boolean,
  validator: ?IValidator,
  timeoutId?: TimeoutID,
  taskType: string | null,
  itemId: ID | null,
  newItem: boolean,
};

const initialState: State = {
  completeLoading: null,
  saveLoading: null,
  saveDraftLoading: null,
  sendToQueueLoading: null,
  validator: null,
  itemId: null,
  taskType: null,
  newItem: false,
};

const cancelTimeout = (state: State) => {
  clearTimeout(state.timeoutId);

  return { timeoutId: undefined };
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'START':
      return {
        ...state,
        taskType: action.taskType,
        itemId: action.itemId,
        newItem: action.newItem,
      };

    case 'CANCEL':
      return {
        ...state,
        itemId: null,
        taskType: null,
      };

    case 'COMPLETE':
      return {
        ...state,
        ...cancelTimeout(state),
        completeLoading: action.loading,
      };

    case 'SAVE':
      return {
        ...state,
        saveLoading: action.loading,
      };

    case 'SAVE_DRAFT':
      return {
        ...state,
        saveDraftLoading: action.loading,
      };

    case 'SEND_TO_QUEUE':
      return {
        ...state,
        ...cancelTimeout(state),
        sendToQueueLoading: action.loading,
      };

    case 'VALIDATE_FORM':
      return {
        ...state,
        validator: action.validator,
      };

    case 'CANCEL_TIMEOUT':
      return {
        ...state,
        ...cancelTimeout(state),
      };

    case 'START_TIMEOUT':
      return {
        ...state,
        // cancel any previous timeout (this shouldnt happen in practice)
        ...cancelTimeout(state),
        timeoutId: action.timeoutId,
      };

    default:
      return state;
  }
};
