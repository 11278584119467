// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import type { DraggableData } from 'react-draggable';


type Coords = {
  x: number,
  y: number,
};

type Dimensions = {
  height: number,
  width: number,
};

type BoundingBox = {
  left: number,
  right: number,
  top: number,
  bottom: number,
};


const getElementDimensions = (element: ?HTMLElement): Dimensions => {
  if (element) {
    return {
      height: element.offsetHeight,
      width: element.offsetWidth,
    };
  } else {
    return {
      height: 0,
      width: 0,
    };
  }
};


type Props = {
  alt: string,
  thumbnailUrl: string,
  zoomUrl: string,
  className: string,
  isZoomed: boolean,
  onZoomChanged: (nextZoomState: boolean) => any,
};

type State = {
  zoomCoords: Coords,
  allowResize: boolean,
  loadingZoomedImage: boolean,
};


class ZoomImage extends Component<Props, State> {
  state = {
    zoomCoords: { x: 0, y: 0 },
    allowResize: true,
    loadingZoomedImage: true,
  }

  container: ?HTMLElement

  thumbnailImage: ?HTMLElement

  zoomedImage: ?HTMLElement

  zoomBoundingBox = (): BoundingBox => {
    const containerDimensions = getElementDimensions(this.container);
    const zoomedDimensions = getElementDimensions(this.zoomedImage);

    const minY = containerDimensions.height - zoomedDimensions.height;
    const maxY = 0;
    const minX = containerDimensions.width - zoomedDimensions.width;
    const maxX = 0;

    return {
      left: minX,
      right: maxX,
      top: minY,
      bottom: maxY,
    };
  }

  // Ensures that the zoomCoordinates are within the bounding box
  setZoomCoordinates = (coords: Coords) => {
    const boundingBox = this.zoomBoundingBox();
    const x = Math.max(Math.min(coords.x, boundingBox.right), boundingBox.left);
    const y = Math.max(Math.min(coords.y, boundingBox.bottom), boundingBox.top);
    this.setState({ zoomCoords: { x, y } });
  }

  // === THE EVENT LIFECYCLE ===
  //
  // Understanding how the events here fire is pretty important to understanding how the
  // `allowResize` flag is important.
  //
  // --- Clicking the mouse
  //   stopDrag -> click
  //
  // --- Dragging the mouse, ending inside of the image
  //   startDrag -> drag* -> stopDrag -> click
  //
  // --- Dragging the mouse, ending outside of the image
  //   startDrag -> drag* -> stopDrag
  //
  // After a drag, we do not want to un-zoom the image, so we need to block the click event
  // from doing that. However, you cannot rely on the click event flipping the flag, because
  // releasing the mouse button does not fire the click event on our image.

  handleClick = (event: SyntheticMouseEvent<HTMLElement>) => {
    if (!this.state.allowResize) return;

    this.props.onZoomChanged(!this.props.isZoomed);

    // Native functions for offset do not handle the transform to center the image. Have
    // to use jQuery to get the correct value.
    const offset = $(this.thumbnailImage).offset();
    const clickX = event.pageX - offset.left;
    const clickY = event.pageY - offset.top;

    const containerDimensions = getElementDimensions(this.container);
    const thumbnailDimensions = getElementDimensions(this.thumbnailImage);
    const zoomedDimensions = getElementDimensions(this.zoomedImage);

    const heightScale = zoomedDimensions.height / thumbnailDimensions.height;
    const widthScale = zoomedDimensions.width / thumbnailDimensions.width;

    // Translate thumbnail coords to zoomed coords by scaling
    let zoomX = clickX * widthScale;
    let zoomY = clickY * heightScale;

    // Center in viewport
    zoomX -= containerDimensions.height / 2;
    zoomY -= containerDimensions.width / 2;

    // Invert the zoom coordinates to match the css translation of the image
    this.setZoomCoordinates({ x: -1 * zoomX, y: -1 * zoomY });
  }

  handleDrag = (_event: Event, position: DraggableData) => {
    this.setState({ allowResize: false });
    this.setZoomCoordinates(position);
  }

  handleStopDrag = () => {
    // Use setTimeout to unlock resize after the click event is fired
    setTimeout(() => this.setState({ allowResize: true }), 250);
  }

  handleWheel = (event: SyntheticWheelEvent<HTMLImageElement>) => {
    if (!this.props.isZoomed) return;

    // This stops the main content from scrolling, and gestures like "back"
    event.preventDefault();

    const { deltaX, deltaY } = event;
    const { x: oldX, y: oldY } = this.state.zoomCoords;
    this.setZoomCoordinates({
      x: oldX - deltaX,
      y: oldY - deltaY,
    });
  }

  handleZoomedImageLoaded = () => {
    this.setState({ loadingZoomedImage: false });
  }

  render = () => {
    const containerClassNames = classNames(
      'zoom-image',
      {
        'zoom-image--zoomed': this.props.isZoomed,
      }
    );

    return (
      <div
        className={containerClassNames}
        onClick={this.handleClick}
        ref={(container) => { this.container = container; }}
      >
        <img
          alt={this.props.alt}
          className={this.props.className}
          ref={(ref) => { this.thumbnailImage = ref; }}
          src={this.props.thumbnailUrl}
        />

        {
          (this.state.loadingZoomedImage && this.props.isZoomed) &&
            <div className="image-carousel__overlay">
              <div className="spinner spinner--inverse" />
            </div>
        }

        <Draggable
          position={this.state.zoomCoords}
          onDrag={this.handleDrag}
          onStop={this.handleStopDrag}
          bounds={this.zoomBoundingBox()}
        >
          <img
            className="zoom-image__full-image"
            draggable={false} // https://github.com/mzabriskie/react-draggable/issues/69
            alt={this.props.alt}
            ref={(ref) => { this.zoomedImage = ref; }}
            src={this.props.zoomUrl}
            onWheel={this.handleWheel}
            onLoad={this.handleZoomedImageLoaded}
          />
        </Draggable>
      </div>
    );
  }
}

export default ZoomImage;
