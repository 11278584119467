// @flow

import React, { type StatelessFunctionalComponent, type Node } from 'react';
import classNames from 'classnames';

type ButtonStyle =
  | 'none'
  | 'default'
  | 'default-inverse'
  | 'success'
  | 'success-inverse'
  | 'danger'
  | 'danger-inverse'
  | 'secondary'
  | 'secondary-inverse'
  | 'link'
  | 'grayed-out';

type ButtonSize = 'standard' | 'compact' | 'jumbo';

type ButtonModifier = 'fill-width';

type IconPosition = 'right' | 'left';

const stylesWithInverseSpinners = new Set(['default', 'success', 'danger', 'secondary']);

export const Button: StatelessFunctionalComponent<{
  children?: Node,
  className?: string,
  isWaiting?: boolean,
  buttonStyle?: ButtonStyle,
  size?: ButtonSize,
  modifier?: ButtonModifier,
  disabled?: boolean,
  icon?: Node,
  iconPosition?: IconPosition,
  type?: 'button' | 'submit' | 'reset',
}> = ({
  children,
  className,
  isWaiting = false,
  buttonStyle = 'default',
  size = 'standard',
  iconPosition = 'left',
  modifier,
  disabled,
  icon,
  type = 'button',
  ...otherProps
}) => {
  const isDisabled = disabled || isWaiting;

  const buttonClasses = classNames(
    'button',
    `button--${buttonStyle || ''}`,
    `button--${size || ''}`,
    { [`button--${modifier || ''}`]: !!modifier },
    className,
    {
      'button--waiting': isWaiting,
    }
  );
  const spinnerClasses = classNames('button__spinner', 'spinner', 'spinner--absolute', {
    'spinner--inverse': stylesWithInverseSpinners.has(buttonStyle),
  });

  return (
    // eslint-disable-next-line react/button-has-type
    <button className={buttonClasses} disabled={isDisabled} type={type} {...otherProps}>
      {!isWaiting && icon && iconPosition === 'left' && icon}
      {isWaiting && <span className={spinnerClasses} />}
      {children}
      {!isWaiting && icon && iconPosition === 'right' && icon}
    </button>
  );
};
