// @flow

import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';
import thunk from 'redux-thunk';

import * as NoticeActions from '~/admin/shared/actions/notice';
import * as FeatureFlagActions from '~/admin/shared/actions/featureFlags';
import featureFlags from '~/admin/shared/reducers/featureFlags';
import notice from '~/admin/shared/reducers/notice';

import photos from './reducers/photos';
import priceEstimatorTool from './reducer';

function devToolEnhancer() {
  // The list of actions in the Redux tools
  const actionCreators = {
    ...NoticeActions,
    ...FeatureFlagActions,
  };

  if (window.devToolsExtension) {
    return window.devToolsExtension({ actionCreators });
  } else {
    return (noop) => noop;
  }
}

export default function configureStore() {
  const reducers = combineReducers({
    notice,
    featureFlags,
    priceEstimatorTool,
    photos,
  });

  const enhancer = compose(
    applyMiddleware(thunk),
    devToolEnhancer()
  );

  const store = createStore(reducers, {}, enhancer);

  return store;
}
