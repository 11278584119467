// @flow

import { SET_ITEM_TIMER, SET_TIMER_ENABLED, SET_SELECTED_ITEM_CURATED } from '../actions/cataloging';
import type { Actions, CatalogingState } from '../actions/cataloging';

const DEFAULT_STATE = ({
  itemTimerEnabled: true,
  itemsCurated: {},
  items: {},
}: CatalogingState);

const cataloging = (state: Object = {}, action: Actions): CatalogingState => {
  switch (action.type) {
    case SET_TIMER_ENABLED:
      return {
        ...DEFAULT_STATE,
        ...state,
        itemTimerEnabled: action.payload,
      };
    case SET_SELECTED_ITEM_CURATED:
      return {
        ...DEFAULT_STATE,
        ...state,
        itemsCurated: Object.assign({}, { [action.payload.item.id]: action.payload.item.lowValueProcessing }),
      };
    case SET_ITEM_TIMER:
      // Only set item timer if id is available
      if (action.payload.id) {
        return {
          ...DEFAULT_STATE,
          ...state,
          items: Object.assign({}, { [action.payload.id]: action.payload.time }),
        };
      } else {
        return {
          ...DEFAULT_STATE,
          ...state,
        };
      }
    default:
      return {
        ...DEFAULT_STATE,
        ...state,
      };
  }
};

export default cataloging;
