// @flow

// $FlowFixMe
import React, { type StatelessFunctionalComponent, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '~/public/shared/components';

export const Card: StatelessFunctionalComponent<{
  className?: string,
  children: ?any,
  header?: ?any,
  footer?: ?any,
  accordion?: Boolean,
  open?: Boolean,
  optionalText?: string,
  noPadding?: Boolean,
}> = ({
  className,
  children,
  header,
  footer,
  accordion,
  open,
  optionalText,
  noPadding,
}) => {
  const [toggle, setToggle] = useState(open);
  const classes = classNames(
    'card',
    className,
    accordion ? 'accordion' : null,
    toggle ? 'open' : null,
  );
  const contentClasses = classNames(
    'card__content',
    noPadding ? 'u-p0' : null,
  );
  return (
    <div className={classes}>
      {header &&
        <div className="card__header">
          { accordion ?
            <button
              type="button"
              onClick={() => setToggle(!toggle)}
            >
              <Icon
                icon={toggle ? 'remove' : 'add'}
                className="btn__icon"
              />
              {header}
            </button>
            :
            <>
              {header}
            </>}
          {optionalText &&
            <p className="optional-text">{optionalText}</p>}
        </div>}
      <div className={contentClasses}>{children}</div>
      {footer &&
        <div className="card__footer">
          {footer}
        </div>}
    </div>
  );
};
