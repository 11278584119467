// @flow

import React, { type StatelessFunctionalComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LoadingDots } from '~/public/shared/components';
import moment from 'moment';
import { Card } from '../Card';
import { ContractStatusBadge } from '../ContractStatusBadge';
import { ContractThumbnail } from '../ContractThumbnail';
import ContractsQuery from '../../queries/ContractsQuery';
import type { Contract } from '../../types';

export const Contracts: StatelessFunctionalComponent<{}> = () => {
  const renderSingleContract = (contract: Contract) => {
    return (
      <table className="table table--beta u-mb0">
        <tbody>
          <tr>
            <td className="u-color-gray-400">ID</td>
            <td>
              <Link to={`/contracts/${contract.number}`} title="View contrct details"><strong>{contract.number}</strong></Link>
            </td>
          </tr>
          <tr>
            <td className="u-color-gray-400">Signed Date</td>
            <td>{moment(contract.createdAt).format('M/DD/YYYY')}</td>
          </tr>
          <tr>
            <td className="u-color-gray-400">Total Items</td>
            <td>
              <Link
                to={`/items?contracts[]=${contract.number}`}
                title="View contract items"
              >
                {contract.itemsCount}
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderMultipleContracts = (contracts: Contract[]) => {
    return (
      <table className="table table--beta u-mb0">
        <tbody>
          {contracts.map((contract) => {
            return (
              <tr key={contract.id}>
                <td style={{ width: '100px' }}>
                  <ContractThumbnail items={contract.popularItems} />
                </td>
                <td>
                  <Link to={`/contracts/${contract.number}`} title="View contrct details"><strong>{contract.number}</strong></Link>
                  <div className="u-text-muted">{contract.itemsCount} {contract.itemsCount === 1 ? 'item' : 'items'}</div>
                </td>
                <td>
                  <ContractStatusBadge
                    contractId={contract.id}
                    aasmState={contract.aasmState}
                  />
                </td>
                <td>{moment(contract.createdAt).format('M/DD/YYYY')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <ContractsQuery first={5}>
      {({
        contracts,
        initialLoading,
      }) => {
        const hasMultipleContracts = contracts.length > 1;
        const cardTitle = hasMultipleContracts ? 'Recent Contracts' : 'Your Contract';

        if (initialLoading) {
          return (
            <div className="u-text-center"><LoadingDots /></div>
          );
        }

        if (contracts.length === 0) {
          return (
            <div className="u-text-center u-text-muted">No contracts yet.</div>
          );
        }

        return (
          <Card
            header={
              <Fragment>
                {cardTitle}
                {hasMultipleContracts ?
                  <Link to="/contracts" className="u-pull-right">View All</Link>
                  : <Link to={`/contracts/${contracts[0].number}`} className="u-pull-right">View Details</Link>
                }
              </Fragment>
            }
          >
            {hasMultipleContracts ?
              renderMultipleContracts(contracts)
              : renderSingleContract(contracts[0])
            }
          </Card>
        );
      }}
    </ContractsQuery>
  );
};
