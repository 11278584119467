import { saveState } from '../utils/cache';

const handleAction = (store, next, action, _options) => {
  const result = next(action);

  if (action.meta && action.meta.cache) {
    const {
      params,
      items,
      filters,
      sorts,
      pages,
      filterCounts,
    } = store.getState().items;
    const toCache = {
      items,
      filters,
      sorts,
      pages,
      filterCounts,
    };

    saveState(params, toCache);
  }

  return result;
};

export default (options = {}) => {
  return (store) => (next) => (action) => handleAction(store, next, action, options);
};
