import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { create } from 'timesync';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext';
import configureStore from '~/admin/shared/store/configureStore';
import TimesyncContext from './contexts/TimesyncContext';
import {
  ContractDetailPage,
  ContractsPage,
  DashboardPage,
  ItemDetailPage,
  ItemDetailPageUserDataProvided,
  ItemsPage,
  PaymentDetailPage,
  PaymentsPage,
  StatsPage,
  SubmittedItemListPage,
} from './pages';

import SubmittedEditPage from '~/shared/pages/SellerDashboard/SubmittedItemEdit.tsx';
import { UserContext } from '~/shared/contexts/UserContext.tsx';
import ProtectedRoute from '~/shared/components/Route/ProtectedRoute.tsx';

const store = configureStore();

const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
});

const client = new ApolloClient({
  assumeImmutableResults: true,
  link: httpLink,
  cache: new InMemoryCache({ freezeResults: true }),
});

const timesync = create({
  server: '/timesync',
  interval: 600000,
});

const selfListerAccessControl = (user) => user.authorizedSelfLister;

class Application extends Component {
  render() {
    const { featureFlags, lookerDashboardUrl } = this.props;
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <FeatureFlagProvider value={featureFlags}>
              <TimesyncContext.Provider value={timesync}>
                <UserContext>
                  <BrowserRouter basename="/dashboard">
                    <Switch>
                      <Route exact path="/" component={DashboardPage} />
                      <Route exact path="/items" component={ItemsPage} />

                      <ProtectedRoute path="/items/submitted" accessControl={selfListerAccessControl} exact>
                        <SubmittedItemListPage />
                      </ProtectedRoute>
                      <ProtectedRoute path="/items/submitted/:pendingItemId" accessControl={selfListerAccessControl}>
                        <SubmittedEditPage featureFlags={featureFlags} />
                      </ProtectedRoute>
                      {featureFlags.sellerProvidedItemData ?
                        <Route exact path="/items/:itemNumber" render={(props) => <ItemDetailPageUserDataProvided {...props} featureFlags={featureFlags} />} /> :
                        <Route exact path="/items/:itemNumber" component={ItemDetailPage} />
                      }
                      {featureFlags.sellerData &&
                        <Route exact path="/stats" render={(props) => <StatsPage {...props} lookerDashboardUrl={lookerDashboardUrl} />} />
                      }
                      <Route exact path="/contracts" component={ContractsPage} />
                      <Route exact path="/contracts/:contractNumber" component={ContractDetailPage} />
                      <Route exact path="/payments" component={PaymentsPage} />
                      <Route exact path="/payments/:invoiceNumber" component={PaymentDetailPage} />
                    </Switch>
                  </BrowserRouter>
                </UserContext>
              </TimesyncContext.Provider>
            </FeatureFlagProvider>
          </ApolloHooksProvider>
        </ApolloProvider>
      </Provider>
    );
  }
}

export default Application;
