// @flow

import React, { Fragment } from 'react';
import { type RouterHistory } from 'react-router';
import { type ApolloClient } from 'apollo-client';
import { type Dispatch as ReduxDispatch } from 'redux';

import { promiseDispatcher, returnPath } from './common';
import { itemUnassigned } from '../analytics';
import { displayNotice } from '../notice.js';
import { cancelScreen } from './cancelScreenActions';

import { enqueueTask } from '~/admin/shared/mutations/EnqueueTaskMutation';
import { endTask } from '~/admin/shared/mutations/EndTaskMutation';

type SupportedTaskType = 'CATALOGING' | 'PHOTO_EDITING' | 'PACKING';

const messages = {
  CATALOGING: {
    success: (itemName: string) => `Item ${itemName} was sent to the Cataloging queues.`,
    failure: (itemName: string) => `Item ${itemName} could not be sent to the Cataloging queues.`,
  },
  PHOTO_EDITING: {
    success: (itemName: string) => `Item ${itemName} was sent to the Photo editing queues.`,
    failure: (itemName: string) => `Item ${itemName} could not be sent to the Photo editing queues.`,
  },
  PACKING: {
    success: (itemName: string) => `Item ${itemName} was sent to the Packing queues.`,
    failure: (itemName: string) => `Item ${itemName} could not be sent to the Packing queues.`,
  },
};

const redirections = {
  CATALOGING: {
    afterEnqueued: () => '/admin/cataloging/queues',
  },
  PHOTO_EDITING: {
    afterEnqueued: () => '/admin/photography/queues',
  },
  PACKING: {
    afterEnqueued: () => '/admin/packing/queues',
  },
};

type ActionProps = {|
  taskType: SupportedTaskType,

  client: ApolloClient,
  history: RouterHistory,

  itemId: ID,
  itemName: string,

  queueId: ?string,
|};

const onSuccess = (
  {
    taskType, itemId, history, itemName,
  }: ActionProps,
  dispatch: ReduxDispatch
) => {
  switch (taskType) {
    case 'CATALOGING':
      dispatch(itemUnassigned({ item: { id: itemId }, state: 'cataloging' }));
      break;
    case 'PHOTO_EDITING':
      dispatch(itemUnassigned({ item: { id: itemId }, state: 'photo_editing' }));
      break;
    case 'PACKING':
      dispatch(itemUnassigned({ item: { id: itemId }, state: 'packing' }));
      break;
    // no default
  }

  // redirect before showing notification
  history.push(returnPath(history) || redirections[taskType].afterEnqueued());

  dispatch(cancelScreen());

  dispatch(
    displayNotice({
      message: messages[taskType].success(itemName),
      type: 'success',
      autoDismiss: true,
    })
  );
};

const onFailure = ({ taskType, itemName }: ActionProps, dispatch: ReduxDispatch, e: Error) => {
  dispatch(
    displayNotice({
      message: (
        <Fragment>
          {messages[taskType].failure(itemName)}
          <br />
          {e.message}
        </Fragment>
      ),
      type: 'danger',
    })
  );
};

const enqueueItem = (params: ActionProps) => (dispatch: ReduxDispatch) => {
  const { taskType, client, itemId, queueId } = params;

  // current task must end before enqueing another
  const xhr = endTask(client)({
    input: {
      itemId,
      taskType,
      reason: 'DRAFT',
    },
  }).then(() => enqueueTask(client)({
    input: {
      itemId,
      taskType,
      queueId,
    },
  }));

  dispatch(promiseDispatcher('SEND_TO_QUEUE')({ xhr }));

  xhr.then(() => onSuccess(params, dispatch)).catch((e: Error) => onFailure(params, dispatch, e));
};

export const enqueueCatalogingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(enqueueItem({ ...params, taskType: 'CATALOGING' }));
};

export const enqueuePhotoEditingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(enqueueItem({ ...params, taskType: 'PHOTO_EDITING' }));
};

export const enqueuePackingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(enqueueItem({ ...params, taskType: 'PACKING' }));
};
