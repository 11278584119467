// @flow

import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="72"
      viewBox="0 0 105 72"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a" d="M0 0H100V66.667H0z" />
        <path id="b" d="M0 0L24 0 24 24 0 24z" />
        <path id="c" d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" />
        <path id="e" d="M0 0H100V66.667H0z" />
        <path id="f" d="M0 0L24 0 24 24 0 24z" />
        <path id="g" d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g opacity=".365" transform="translate(5 5)">
          <use fill="#D3DDE6" xlinkHref="#a" />
          <path stroke="#708396" d="M0.5 0.5H99.5V66.167H0.5z" />
          <g transform="translate(38 21)">
            <mask id="d" fill="#fff">
              <use xlinkHref="#c" />
            </mask>
            <use fill="#000" fillRule="nonzero" xlinkHref="#c" />
            <path fill="#708396" d="M0 0H48V48H0z" mask="url(#d)" />
          </g>
        </g>
        <use fill="#D3DDE6" xlinkHref="#e" />
        <path stroke="#708396" d="M0.5 0.5H99.5V66.167H0.5z" />
        <g transform="translate(38 21)">
          <mask id="h" fill="#fff">
            <use xlinkHref="#g" />
          </mask>
          <use fill="#000" fillRule="nonzero" xlinkHref="#g" />
          <path fill="#708396" d="M0 0H48V48H0z" mask="url(#h)" />
        </g>
      </g>
    </svg>
  );
};
