import React, { Component } from 'react';

class AddressOption extends Component {
  render() {
    const { address, isSelected, onChange } = this.props;
    const htmlId = `address_${address.id}`;

    return (
      <label id={`qa-address-${address.id}`} className="custom-control custom-control--radio u-mb2" htmlFor={htmlId}>
        <input
          className="custom-control__input"
          type="radio"
          id={htmlId}
          name="address"
          value={address.id}
          checked={isSelected}
          onChange={() => { onChange(address); }}
        />
        <div className="custom-control__label">
          <div className="personal-info">
            {address.name &&
              <span className="personal-info__item personal-info__title">{address.name}</span>
            }
            <span className="personal-info__item personal-info__detail">{address.line1}</span>
            <span className="personal-info__item personal-info__detail">{address.line2}</span>
            <span className="personal-info__item personal-info__detail">{address.city}{address.city && ', '}{address.state} {address.zip}</span>
            <span className="personal-info__item personal-info__detail">{address.countryName}</span>
          </div>
        </div>
      </label>
    );
  }
}

export default AddressOption;
