// @flow

import { debounce } from 'lodash';
import React, { Component } from 'react';
import type { Node } from 'react';

import { Icon } from '~/public/shared/components';

type Props = {
  children: Node,
  collapsedHeight?: number,
};

type State = {
  expandedHeight: ?number,
  isExpanded: boolean,
  showMoreLess: boolean,
};

class Truncator extends Component<Props, State> {
  static defaultProps = {
    collapsedHeight: 120,
  }

  constructor() {
    super();
    this.debouncedUpdateContentHeight = debounce(this.updateContentHeight, 250);
  }

  state = {
    expandedHeight: null,
    isExpanded: false,
    showMoreLess: false,
  }

  componentDidMount() {
    this.updateContentHeight();
    window.addEventListener('resize', this.debouncedUpdateContentHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedUpdateContentHeight);
  }

  debouncedUpdateContentHeight: Function

  contentWrapper: ?HTMLDivElement

  truncationWrapper: ?HTMLDivElement

  handleShowMore = () => {
    this.setState({ isExpanded: true });
  }

  handleShowLess = () => {
    this.setState({ isExpanded: false });
  }

  isOpen = () => {
    return !this.state.showMoreLess || this.state.isExpanded;
  }

  updateContentHeight = () => {
    if (this.contentWrapper) {
      const expandedHeight = this.contentWrapper.offsetHeight;
      // Do not show more less if the collapsed height + more less button (~50px)
      // is taller than content
      const showMoreLess = (
        !!this.props.collapsedHeight
        && ((this.props.collapsedHeight + 50) <= expandedHeight)
      );

      this.setState({
        expandedHeight,
        showMoreLess,
      });
    }
  }

  truncationStyles = () => {
    // Wait until we have initialized to set any height
    if (!this.state.expandedHeight) return { height: 'auto', maxHeight: this.props.collapsedHeight };

    if (this.isOpen()) {
      return { height: this.state.expandedHeight };
    } else {
      return { height: this.props.collapsedHeight };
    }
  }

  renderMoreLessButton = () => {
    if (!this.state.showMoreLess) return null;

    if (this.state.isExpanded) {
      return (
        <button type="button" onClick={this.handleShowLess} className="truncator__action qa-less-button">
          <Icon icon="chevron-up" className="truncator__icon" />
          Show Less
        </button>
      );
    } else {
      return (
        <button type="button" onClick={this.handleShowMore} className="truncator__action qa-more-button">
          <Icon icon="chevron-down" className="truncator__icon" />
          Show More
        </button>
      );
    }
  }

  render() {
    let truncationClasses = 'truncator truncator--grey u-mb0';
    if (this.isOpen()) {
      truncationClasses += ' is-open';
    }

    return (
      <div className={truncationClasses}>
        <div ref={(ref) => { this.truncationWrapper = ref; }} style={this.truncationStyles()} className="truncator__content">
          <div ref={(ref) => { this.contentWrapper = ref; }}>
            {this.props.children}
          </div>
        </div>
        {this.renderMoreLessButton()}
      </div>
    );
  }
}

export default Truncator;
