// @flow

import { type Dispatch as ReduxDispatch } from 'redux';
import { type IValidator } from './common';

export const validateScreen = (validator: IValidator) => (dispatch: ReduxDispatch) => {
  dispatch({
    type: 'VALIDATE_FORM',
    validator: validator,
    errors: validator.errors,
  });
};
