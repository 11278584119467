// @flow

import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import moment from 'moment';
import DocumentTitle from 'react-document-title';

import PrimaryItemPhotos from '../components/PrimaryItemPhotos';
import HighBid from '../components/HighBid';
import TimeRemaining from '../components/TimeRemaining';
import withItem from '../queries/withItem';
import itemState from '../utils/itemState';

import type { Item, ItemState } from '../types';

type Props = {
  data: {
    item: Item,
    loading: boolean,
  },
  now: Date,
};

class PrimaryItem extends Component<Props> {
  renderStatus(state: ItemState) {
    if (state === 'extended') {
      return (
        <Fragment>
          <div className="primary-item__status primary-item__status--extended">
            Time Extended!
          </div>
          <div className="primary-item__status-line primary-item__status-line--extended" />
        </Fragment>
      );
    } else if (state === 'ending') {
      return (
        <Fragment>
          <div className="primary-item__status primary-item__status--ending">
            Ending
          </div>
          <div className="primary-item__status-line primary-item__status-line--ending" />
        </Fragment>
      );
    }
  }

  renderBottomPanel({ item, state }: { item: Item, state: ItemState }) {
    switch (state) {
      case 'ended':
        return (
          <div className="primary-item__ended">
            <div className="primary-item__ended-status">Ended</div>
            <div className="primary-item__ended-bid">
              <div className="data data--xlarge">
                <h3 className="data__label">Final Bid</h3>
                <div className="qa-high_bid_amount data__value">
                  <HighBid amount={item.highBidAmount} />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="primary-item__data">
            <div className="data data--large">
              <h3 className="data__label">Current Bid</h3>
              <div className="qa-high_bid_amount data__value">
                <HighBid amount={item.highBidAmount} />
              </div>
            </div>

            <div className="data data--large">
              <h3 className="data__label">Time Remaining</h3>
              <div className="data__value">
                <TimeRemaining until={moment(item.saleEndsAt)} />
              </div>
            </div>

            <div className="data data--large">
              <h3 className="data__label">Number of Bids</h3>
              <div className="data__value">{item.bidsCount}</div>
            </div>
          </div>
        );
    }
  }

  render() {
    if (this.props.data.loading) {
      return null;
    }

    const { item } = this.props.data;
    const state = itemState(item);

    return (
      <div className="primary-item qa-primary-item">
        <PrimaryItemPhotos item={item} />
        <DocumentTitle title={item.name} />

        <div className="primary-item__info">
          {this.renderStatus(state)}

          <header className="primary-item__header">
            <h1 className="primary-item__title">{item.name}</h1>
            <h4 className="primary-item__subtitle">{item.barcode}</h4>
          </header>

          {this.renderBottomPanel({ item, state })}
        </div>
      </div>
    );
  }
}

export default compose(
  withItem
)(PrimaryItem);
