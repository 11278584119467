// @flow

import { type Dispatch as ReduxDispatch } from 'redux';

// TODO: end task with discard state
export const cancelScreen = () => (dispatch: ReduxDispatch) => {
  dispatch({
    type: 'CANCEL_TIMEOUT',
  });

  dispatch({
    type: 'CANCEL',
  });
};
