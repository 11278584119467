// @flow

import { createStore } from 'redux';
import _ from 'lodash';

type StoreFilter = (Object) => (Object);

function loadState(localStorageKey: string) {
  try {
    const serializedState = localStorage.getItem(localStorageKey);
    if (serializedState) {
      return JSON.parse(serializedState);
    } else {
      return {};
    }
  } catch (_error) {
    return {};
  }
}

function saveState(localStorageKey: string, filter: StoreFilter, state: Object) {
  try {
    const serializedState = _.merge({}, loadState(localStorageKey), filter(state));
    localStorage.setItem(localStorageKey, JSON.stringify(serializedState));
  } catch (_error) {
    console.error('Error saving Redux state to local storage');
  }
}

const createStoreWithLocalStorageCache = (localStorageKey: string, filter: StoreFilter) => (
  (reducers: Function, initialState: Object, enhancers: Function) => {
    const savedState = loadState(localStorageKey);
    const store = createStore(
      reducers,
      {
        ...initialState,
        ...savedState,
      },
      enhancers
    );
    store.subscribe(() => {
      saveState(localStorageKey, filter, store.getState());
    });

    return store;
  }
);


export default createStoreWithLocalStorageCache;
