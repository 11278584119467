// @flow
/* eslint-disable react/no-danger */

import React from 'react';
import textile from 'textile-js';

type Props = {
  content: string,
};

export const Textile = ({ content, ...props }: Props) => {
  const htmlContent = textile(content);
  return <div {...props} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
