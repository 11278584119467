import update from 'immutability-helper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StripeCard, InputField } from '~/public/shared/components';

class CreditCardFormFields extends Component {
  handleChange = (key) => {
    return (change) => {
      const { paymentMethod } = this.props;
      const newPaymentMethod = update(paymentMethod, { [key]: { value: { $set: change } } });
      this.props.onChange(newPaymentMethod);
    };
  }

  render() {
    const { paymentMethod } = this.props;

    return (
      <div>
        <div className="l-form-grid__row">
          <div className="l-form-grid__item">
            <InputField
              label="Name On Card"
              floatedLabel
              name="name"
              autocomplete="cc-name"
              onChange={this.handleChange('name')}
              {...paymentMethod.name}
            />
          </div>
        </div>

        <div className="l-form-grid__row">
          <div className="l-form-grid__item">
            <StripeCard
              inputType="tel"
              label="Card Number"
              name="number"
              autocomplete="cc-number"
              fieldIcon="lock"
              floatedLabel
              onChange={this.handleChange('number')}
              {...paymentMethod.number}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreditCardFormFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  paymentMethod: PropTypes.object.isRequired,
};

export default CreditCardFormFields;
