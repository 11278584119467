// @flow

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { InView } from 'react-intersection-observer';
import type { Filter, Category } from '../types';
import * as ItemsActions from '../actions/items';

type Props = {
  category: Category,
  filter: Filter,
  setParams: (Object) => void,
};

class CategoryTile extends Component<Props> {
  render() {
    const { category } = this.props;
    const { originalMerchandisingImage, name, fullSlugPath } = category;

    return (
      <InView triggerOnce>
        {({ inView, ref }) => {
          const imgSrc = inView ? `${originalMerchandisingImage}&w=500&h=388&fit=crop&crop=&auto=format` : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

          return (
            <Link
              to={`/${fullSlugPath}`}
              className="featured-category__card card card--hero items-grid__item"
              innerRef={ref}
            >
              {originalMerchandisingImage &&
                <img src={imgSrc} alt="" className="card__background" />
              }
              <div className="card__body">
                <h3 className="featured-category__title"><span className="card__title-link items-category-tiles__tile-title-link">{name.replace(' and ', ' & ')}</span></h3>
              </div>
            </Link>
          );
        }}
      </InView>
    );
  }
}

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    setParams: (params) => dispatch(ItemsActions.setParams(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTile);
