export const PUBLIC_FLASH_MESSAGE_STORAGE_NAME = 'EBTHpublicFlashMessage';

export const flashPublicMessage = (message = '') => {
  window.EBTH.flash.show(message);
};

export const flashPublicMessageFromStorage = () => {
  const message = sessionStorage.getItem(PUBLIC_FLASH_MESSAGE_STORAGE_NAME);
  if (message) {
    flashPublicMessage(message);
    sessionStorage.removeItem(PUBLIC_FLASH_MESSAGE_STORAGE_NAME);
  }
};

export const setPublicMessageInStorage = (message = '') => {
  sessionStorage.setItem(PUBLIC_FLASH_MESSAGE_STORAGE_NAME, message);
};
