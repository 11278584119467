// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

type Props = {
  name: string,
  id?: string,
  value: ?number,
  className?: string,
  autoFocus?: boolean,
  disabled?: boolean,
  allowClearBidOnFocus?: boolean,
  didUserFocusBidAmount: boolean,
  isMaxBid?: boolean,
  title?: string,
  placeholder?: string,
  updateBid: (?number) => void,
  setDidUserFocusBidAmount: (boolean) => void,
  setIsMaxBid?: (boolean) => void,
};

type State = {
  isInitialFocus: boolean,
};

class BidInput extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInitialFocus: !!props.autoFocus,
    };
  }

  updateValues = (val: string) => {
    this.props.updateBid(val ? Number(val) : null);
  }

  handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    let newAmount = Number(this.props.value) || 0;

    if (e.currentTarget.value.includes('.') && e.keyCode === 190) {
      // .
      // Don't allow second period (".") to be entered, thus preventing the
      // numberMask from moving the decimal place
      e.preventDefault();
    } else if (e.keyCode === 38) {
      // up arrow
      e.preventDefault();
      newAmount += 1;
      this.updateValues(newAmount.toString());
    } else if (e.keyCode === 40) {
      // down arrow
      e.preventDefault();
      newAmount -= 1;
      this.updateValues(Math.max(0, newAmount).toString());
    }
  }

  handleOnChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const value: string = e.target.value || '';
    const cleanNumber = value.replace(/[$,]/g, '') || '';

    const [dollars, cents] = cleanNumber.split('.');

    let cleanCents = '';
    const hasDecimal = cents !== undefined;

    if (cents) {
      cleanCents = cents.replace(/[1-9]/g, '');
    }

    const cleanerNumber = `${dollars}${hasDecimal ? '.' : ''}${cleanCents}`;
    this.updateValues(cleanerNumber);
  }

  handleOnBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ isInitialFocus: false });

    // Strip decimals from value
    const value: string = e.target.value || '';
    const [dollars] = value.split('.');
    const cleanNumber = dollars.replace(/[$,]/g, '') || '';

    this.updateValues(cleanNumber);
  }

  handleUserFocusedBidAmount = () => {
    if (this.props.allowClearBidOnFocus && !this.props.didUserFocusBidAmount) {
      this.props.setDidUserFocusBidAmount(true);
      this.updateValues('');
    }
  }

  handleOnClick = () => {
    this.handleUserFocusedBidAmount();
  }

  handleOnFocus = () => {
    if (this.state.isInitialFocus) {
      this.handleUserFocusedBidAmount();
    }
  }

  render() {
    const {
      name,
      id,
      autoFocus,
      className,
      disabled,
      placeholder,
      title,
    } = this.props;
    const inputClasses = classNames(
      'input input--xl bid-input-form__input',
      className,
    );
    const numberMask = createNumberMask({
      prefix: '$',
      allowLeadingZeroes: false,
      allowDecimal: true,
      integerLimit: 9,
    });

    const stringValue = this.props.value ? this.props.value.toString() : '';

    // The combination of type, pattern, and novalidate are required to allow
    // currency formatting of the value WHILE ALSO rendering the proper numeric
    // keyboard on mobile devices
    return (
      <MaskedInput
        name={name}
        id={id}
        autoFocus={autoFocus}
        mask={numberMask}
        type="text"
        inputMode="numeric"
        pattern="\d*"
        noValidate
        autoComplete="off"
        value={stringValue}
        className={inputClasses}
        disabled={disabled}
        placeholder={placeholder}
        title={title}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
        onClick={this.handleOnClick}
        onFocus={this.handleOnFocus}
      />
    );
  }
}

export default BidInput;
