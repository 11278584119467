// @flow

import type { ConnectionType } from '~/types/graphql';

function nodesForConnection<NodeType>(
  graphqlConnectionObject: ConnectionType<NodeType>
): NodeType[] {
  return graphqlConnectionObject.edges.map((edge) => edge.node);
}

class Connection<NodeType> {
  nodes: NodeType[];

  constructor(graphqlConnectionObject: ConnectionType<NodeType>) {
    this.nodes = nodesForConnection(graphqlConnectionObject);
  }
}

export default Connection;
