// @flow

import type { BidChange } from '../types';

export const UPDATE_BID = 'UPDATE_BID';

export type UpdateBidAction = {
  type: 'UPDATE_BID',
  bid: BidChange,
};

export type Actions = UpdateBidAction;

export const updateBid = (bid: BidChange): UpdateBidAction => (
  {
    type: 'UPDATE_BID',
    bid,
  }
);
