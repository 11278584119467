// @flow

import React, { type StatelessFunctionalComponent, type Node, type ElementConfig } from 'react';
import classNames from 'classnames';

import { Card } from './Card';

type CardProps = ElementConfig<typeof Card>;

export const CardWithMicrobar: StatelessFunctionalComponent<CardProps & {
    microbarClassName?: string,
    actions?: Node,
    headerIsSticky?: boolean,
  }> = ({
    microbarClassName, header, actions, headerIsSticky, ...cardProps
  }) => {
    const microBarClasses = classNames(
      'micro-bar',
      'micro-bar--top',
      {
        'micro-bar--sticky': headerIsSticky,
      },
      microbarClassName
    );

    return (
      <Card
        {...cardProps}
        wrapHeader={false}
        header={
          <div className={microBarClasses}>
            <div className="micro-bar__content">{header}</div>
            {actions}
          </div>
        }
      />
    );
  };
