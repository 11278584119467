// @flow

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import itemState from '../utils/itemState';

const ItemQuery = gql`
  query ItemQuery($itemId: ID!) {
    item(id: $itemId) {
      id
      aasmState
      barcode
      name
      saleEndsAt
      saleId
      highBidAmount
      isExtended
      bidsCount
      bids {
        id
        amount
      }
      mainAttachment {
        id
        imageUrl
      }
      attachments(published: true) {
        edges {
          node {
            id
            imageUrl
          }
        }
      }
    }
  }
`;

const transforms = [
  (item) => ({ ...item, saleEndsAt: new Date(item.saleEndsAt) }),
  (item) => ({ ...item, state: itemState(item) }),
];

const transformItem = (item) => {
  if (item) {
    return transforms.reduce((i, transform) => transform(i), item);
  }
};

const withItem = graphql(ItemQuery, {
  options: ({ itemId }: { itemId: string }) => {
    return {
      variables: { itemId },
    };
  },
  props: ({ data: { loading, item } }) => ({
    data: {
      loading,
      item: transformItem(item),
    },
  }),
});

export default withItem;
