// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import type { Item } from '../types';

export const ContractThumbnail: StatelessFunctionalComponent<{
  items: Item[],
}> = ({
  items,
}) => {
  return (
    <div className="img-thumb">
      {items.map((item) => {
        if (!item.mainAttachment) { return; }

        return (
          <img
            key={item.id}
            src={item.mainAttachment.merchThumbnailUrl}
            alt={item.name}
            className="img-thumb__img img-thumb__img--tile"
          />
        );
      })}
    </div>
  );
};
