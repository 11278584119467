// @flow

import { displayNotice } from '~/admin/shared/actions/notice';

import PhotoUploadQueue from './util/PhotoUploadQueue';
import PhotoUploader from './util/PhotoUploader';

import type {
  EstimateRequestParams,
  Photo,
  PhotoProperties,
} from './types';

type Dispatch<T> = (T) => void; // flow won't enforce this without redux types, so making our own

export const UPDATE_ESTIMATE_REQUEST = 'UPDATE_ESTIMATE_REQUEST';
export type UpdateEstimateAction = {
  type: 'UPDATE_ESTIMATE_REQUEST',
  estimateRequest: EstimateRequestParams,
};

export const CHANGE_ACTIVE_SLIDE = 'CHANGE_ACTIVE_SLIDE';
export type ChangeActiveSlideAction = {
  type: 'CHANGE_ACTIVE_SLIDE',
  activeSlide: number,
};

export const CLEAR_ESTIMATE_REQUEST = 'CLEAR_ESTIMATE_REQUEST';
export type ClearEstimateRequestAction = {
  type: 'CLEAR_ESTIMATE_REQUEST'
};

export const ADD_PHOTOS = 'ADD_PHOTOS';
export type AddPhotosAction = {
  type: 'ADD_PHOTOS',
  photos: Array<Photo>,
};

export const REMOVE_PHOTO = 'REMOVE_PHOTO';
export type RemovePhotoAction = {
  type: 'REMOVE_PHOTO',
  localKey: number,
};

export const UPLOAD_PHOTOS = 'UPLOAD_PHOTOS';
export type UploadPhotosAction = {
  type: 'UPLOAD_PHOTOS',
  photos: Array<Photo>,
};

export const UPDATE_PHOTO = 'UPDATE_PHOTO';
export type UpdatePhotoAction = {
  type: 'UPDATE_PHOTO',
  localKey: number,
  properties: {
    imageUrl?: string,
    uploadKey?: string,
  },
};

export type PhotosActions = (
  | AddPhotosAction
  | RemovePhotoAction
  | UpdatePhotoAction
);

export const updateEstimateRequest = (params: EstimateRequestParams): UpdateEstimateAction => {
  return {
    type: UPDATE_ESTIMATE_REQUEST,
    estimateRequest: params,
  };
};

export const changeActiveSlide = (activeSlide: number): ChangeActiveSlideAction => {
  return {
    type: CHANGE_ACTIVE_SLIDE,
    activeSlide,
  };
};

export const addPhotos = (photos: Array<Photo>): AddPhotosAction => {
  return {
    type: ADD_PHOTOS,
    photos,
  };
};

export const removePhoto = (photo: Photo): RemovePhotoAction => {
  return {
    type: REMOVE_PHOTO,
    localKey: photo.localKey,
  };
};

export const updatePhoto = (photo: Photo, properties: PhotoProperties) => {
  return {
    type: UPDATE_PHOTO,
    localKey: photo.localKey,
    properties,
  };
};

export const uploadPhotos = (photos: Array<Photo>) => {
  return async (dispatch: Dispatch<*>) => {
    const uploadQueue = new PhotoUploadQueue({ uploadFn: PhotoUploader.uploadFile });
    uploadQueue.on('update', (photo: Photo, changes: Object) => {
      dispatch(updatePhoto(photo, changes));
    });
    uploadQueue.on('error', (e: Error) => {
      dispatch(displayNotice({ message: e.message, type: 'danger' }));
    });
    await uploadQueue.enqueue(photos);
  };
};
