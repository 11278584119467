// @flow
import React, { Component } from 'react';
import classNames from 'classnames';

import ActionSvgIcon from '~/admin/shared/svgs/ActionSvgIcon';
import AlertSvgIcon from '~/admin/shared/svgs/AlertSvgIcon';
import EditorSvgIcon from '~/admin/shared/svgs/EditorSvgIcon';
import NavigationSvgIcon from '~/admin/shared/svgs/NavigationSvgIcon';

import type { Photo } from '../types';

type Props = {
  photo: Photo,
  onDelete: (Photo) => any,
};

class PhotoUploadDisplay extends Component<Props> {
  handleDelete = () => {
    this.props.onDelete(this.props.photo);
  }

  renderImage = () => {
    const { imageUrl, status } = this.props.photo;

    if (status === 'INVALID_FILE_TYPE') {
      return (
        <div className="thumbnail">
          <div className="thumbnail__image">
            <EditorSvgIcon icon="ic_insert_drive_file_24px" className="icon icon--isolated icon--jumbo icon--faded" />
          </div>
        </div>
      );
    } else {
      const thumbnailClasses = classNames(
        'thumbnail',
        {
          'thumbnail--empty': !imageUrl,
        }
      );
      const imageClasses = classNames(
        'thumbnail__image',
        {
          'thumbnail__image--loading': !(this.props.photo.status === 'SUCCESS'),
        }
      );

      return (
        <div className={thumbnailClasses}>
          {
            imageUrl ?
              (<img src={imageUrl} alt="" className={imageClasses} />) :
              (
                <div className="thumbnail__image">
                  <EditorSvgIcon icon="ic_insert_photo_24px" className="icon icon--isolated icon--jumbo icon--faded" />
                </div>
              )
          }
        </div>
      );
    }
  }

  renderStatus = () => {
    switch (this.props.photo.status) {
      case 'SUCCESS':
        return (
          <div className="micro-bar__content">
            <ActionSvgIcon icon="ic_check_circle_24px" className="icon icon--pre-text icon--success" />
            Uploaded
          </div>
        );
      case 'UPLOAD_FAILURE':
        return (
          <div className="micro-bar__content">
            <AlertSvgIcon icon="ic_warning_24px" className="icon icon--pre-text icon--danger" />
            <span className="u-text-danger">Upload failed. Try again.</span>
          </div>
        );
      case 'INVALID_FILE_TYPE':
        return (
          <div className="micro-bar__content">
            <AlertSvgIcon icon="ic_warning_24px" className="icon icon--pre-text icon--danger" />
            <span className="u-text-danger">Not a jpeg or png</span>
          </div>
        );
      case 'FILE_TOO_LARGE':
        return (
          <div className="micro-bar__content">
            <AlertSvgIcon icon="ic_warning_24px" className="icon icon--pre-text icon--danger" />
            <span className="u-text-danger">Larger than 15MB</span>
          </div>
        );
      case 'WAITING':
        return (
          <div className="micro-bar__content">
            <span className="spinner spinner--dark spinner--pre-text" />
            Waiting to Upload
          </div>
        );
      default:
        return (
          <div className="micro-bar__content">
            <span className="spinner spinner--dark spinner--pre-text" />
            Uploading
          </div>
        );
    }
  }

  renderAction = () => {
    switch (this.props.photo.status) {
      case 'SUCCESS':
        return (
          <button
            onClick={this.handleDelete}
            className="qa-delete-button micro-bar__action title-tooltip title-tooltip--bottom"
            data-title="Delete"
            type="button"
          >
            <ActionSvgIcon icon="ic_delete_24px" className="icon icon--isolated" />
          </button>
        );
      case 'WAITING':
        return (
          <button
            onClick={this.handleDelete}
            className="qa-delete-button micro-bar__action title-tooltip title-tooltip--bottom"
            data-title="Delete"
            type="button"
          >
            <ActionSvgIcon icon="ic_delete_24px" className="icon icon--isolated" />
          </button>
        );
      default:
        return (
          <button
            onClick={this.handleDelete}
            className="qa-delete-button micro-bar__action title-tooltip title-tooltip--bottom"
            data-title="Dismiss"
            type="button"
          >
            <NavigationSvgIcon icon="ic_close_24px" className="icon icon--isolated" />
          </button>
        );
    }
  }

  render = () => {
    const { file } = this.props.photo;
    return (
      <div className="card">
        <div className="micro-bar micro-bar--top">
          {this.renderStatus()}
          {this.renderAction()}
        </div>
        <div className="card__content u-p0">
          {this.renderImage()}
        </div>
        <div className="card__footer">
          <span className="helper-text u-word-wrap">{file.name}</span>
        </div>
      </div>
    );
  }
}

export default PhotoUploadDisplay;
