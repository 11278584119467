// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ShippingBid from '../../components/fulfillment_types/ShippingBid';
import * as ShippingQuoteBidActions from '../../actions/shippingQuoteBid';

const mapStateToProps = ({
  shippingQuoteBid,
  item: { item },
  user: { user },
  bidNew,
}) => (
  {
    ...shippingQuoteBid,
    item,
    user,
    bidNew,
  }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ShippingQuoteBidActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShippingBid);
