// @flow

import PubNub from 'pubnub';
import type { Listener } from 'pubnub';
import type { ItemChange } from '../types';

const parseDate = (value: any): ?Date => {
  const type = typeof value;

  if (type === 'string' || type === 'object') {
    return new Date(value);
  } else {
    return null;
  }
};

class ItemEvents {
  pubnub: Object

  listener: Listener

  constructor(pubnub: PubNub) {
    this.pubnub = pubnub;
  }

  listen = (itemId: string, onItemChange: (ItemChange) => void): () => void => {
    const listener = {
      message: ({ channel, message }) => {
        const id = channel.split('_')[1];

        onItemChange({
          id,
          aasmState: message.aasm_state,
          bidderIds: message.bidder_ids.map((i) => String(i)),
          bidsCount: Number(message.bids_count),
          extended: Boolean(message.extended),
          highBidAmount: Number(message.high_bid_amount),
          minimumBidAmount: Number(message.minimum_bid_amount),
          highBidId: String(message.high_bid_id),
          highBidUserId: String(message.high_bid_user_id),
          saleEndsAt: parseDate(message.sale_ends_at),
          saleCode: message.sale_code,
        });
      },
    };

    this.pubnub.addListener(listener);

    this.pubnub.subscribe({
      channels: [`item_${itemId}`],
    });

    const unsubscribe = () => {
      this.pubnub.removeListener(this.listener);

      this.pubnub.unsubscribe({
        channels: [`item_${itemId}`],
      });
    };

    return unsubscribe;
  }
}

export default ItemEvents;
