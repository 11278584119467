// @flow

import React from 'react';
import { type RouterHistory } from 'react-router';
import { type Dispatch as ReduxDispatch } from 'redux';

import { type GetState, TIMEOUT_DELAY } from './common';
import { itemCatalogingStarted, itemPhotoEditingStarted, itemPackingStarted } from '../analytics';

type SupportedTaskType = 'CATALOGING' | 'PHOTO_EDITING' | 'PACKING';

const buildTimeoutMessage = (itemName: string, queueable: boolean) => (
  <span>
    <strong>Item {itemName}&#39;s task has expired.</strong>
    <p>
      All unsaved changes have been lost.
      {queueable
        ? ' To continue working you can rescan or re-assign the item from the queue.'
        : ' Rescan the item to continue working.'}
    </p>
  </span>
);

const getNextPagePath = (type: SupportedTaskType) => {
  switch (type) {
    case 'CATALOGING':
      return '/admin/cataloging';
    case 'PHOTO_EDITING':
      return '/admin/photography';
    // no default
  }

  // relative path results in no url change
  return '.';
};

type ActionProps = {|
  taskType: SupportedTaskType,

  history: RouterHistory,

  newItem?: boolean,
  itemName: string,
  itemId: ID,

  queueable?: boolean,
|};

export const startScreen = ({
  history,
  itemName,
  itemId,
  taskType,
  newItem,
  queueable = true,
}: ActionProps) => (dispatch: ReduxDispatch, getCurrentState: GetState) => {
  const id = setTimeout(() => {
    const state = getCurrentState().screen;
    // if the current item or task type has changed since the timeout started
    // then we have nothing to do here. (starting new timeouts cancel previous ones)
    if (state.itemId !== itemId || state.taskType !== taskType) {
      return;
    }

    history.push(getNextPagePath(taskType));

    dispatch({
      type: 'CANCEL_TIMEOUT',
    });

    dispatch({
      type: 'DISPLAY_NOTICE',
      notice: {
        key: 'timedout',
        autoDismiss: false,
        active: true,
        type: 'info',
        message: buildTimeoutMessage(itemName, queueable),
      },
    });
  }, TIMEOUT_DELAY);

  dispatch({
    type: 'START_TIMEOUT',
    timeoutId: id,
  });

  const { itemId: currentItemId, taskType: currentTaskType } = getCurrentState().screen || {};
  const isFreshSession =
    !currentItemId || !currentTaskType || currentTaskType !== taskType || currentItemId !== itemId;

  if (isFreshSession) {
    dispatch({
      type: 'START',
      itemId,
      taskType,
      loading: false,
      newItem,
    });

    switch (taskType) {
      case 'CATALOGING':
        dispatch(itemCatalogingStarted({ id: itemId }));
        break;
      case 'PHOTO_EDITING':
        dispatch(itemPhotoEditingStarted({ id: itemId }));
        break;
      case 'PACKING':
        dispatch(itemPackingStarted({ id: itemId }));
      // no default
    }
  } else {
    dispatch({
      type: 'START_CONTINUE',
      itemId,
      taskType,
      loading: false,
    });
  }
};
