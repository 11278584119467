// @flow

import React, { Fragment } from 'react';
import { type RouterHistory } from 'react-router';
import { type ApolloClient } from 'apollo-client';
import { type Dispatch as ReduxDispatch } from 'redux';

import { promiseDispatcher, returnPath } from './common';
import { displayNotice } from '../notice.js';
import { itemCatalogingCompleted, itemApproved } from '../analytics.js';
import { cancelScreen } from './cancelScreenActions';

import { approveItem as approveItemMutation } from '~/admin/shared/mutations/ApproveItemMutation';
import { endTask } from '~/admin/shared/mutations/EndTaskMutation';

type SupportedTaskType = 'CATALOGING';

type ActionProps = {|
  taskType: SupportedTaskType,

  client: ApolloClient,
  history: RouterHistory,

  itemId: ID,
  itemName: string,
|};

const messages = {
  CATALOGING: {
    success: (itemName: string) => `Cataloging item ${itemName} was completed and approved.`,
    failure: (itemName: string) => `Cataloging item ${itemName} could not be completed and approved.`,
  },
};

const redirections = {
  CATALOGING: {
    afterApproved: () => '/admin/cataloging/queues',
  },
};

const onSuccess = (
  {
    taskType, itemId, history, itemName,
  }: ActionProps,
  dispatch: ReduxDispatch
) => {
  switch (taskType) {
    case 'CATALOGING':
      dispatch(itemCatalogingCompleted({ id: itemId }));
      dispatch(itemApproved({ id: itemId }));
      break;
    // no default
  }

  // redirect before showing notification
  history.push(returnPath(history) || redirections[taskType].afterApproved());

  dispatch(cancelScreen());

  dispatch(
    displayNotice({
      message: messages[taskType].success(itemName),
      type: 'success',
      autoDismiss: true,
    })
  );
};

const onFailure = ({ taskType, itemName }: ActionProps, dispatch: ReduxDispatch, e: Error) => {
  dispatch(
    displayNotice({
      message: (
        <Fragment>
          {messages[taskType].failure(itemName)}
          <br />
          {e.message}
        </Fragment>
      ),
      type: 'danger',
    })
  );
};

const approveItem = (params: ActionProps) => (dispatch: ReduxDispatch) => {
  const { taskType, client, itemId } = params;

  const xhr = approveItemMutation(client)({
    input: {
      itemId,
    },
  }).then(() => endTask(client)({
    input: {
      itemId,
      taskType,
      reason: 'COMPLETED',
    },
  }));

  dispatch(promiseDispatcher('COMPLETE')({ xhr }));

  xhr.then(() => onSuccess(params, dispatch)).catch((e: Error) => onFailure(params, dispatch, e));
};

export const approveCatalogingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(approveItem({ ...params, taskType: 'CATALOGING' }));
};
