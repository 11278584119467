// @flow

import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import type { SelectOption } from '~/admin/shared/components/MultiSelectInput';

import MultiSelectInput, { formatMultiSelectValues } from '~/admin/shared/components/MultiSelectInput';

type Category = {
  id: string,
  lineage: string,
  measurementsRequired: string,
};

type Props = {
  categories: Array<Category>,
  onChange: (Array<Category>) => void,
  disabled: boolean,
  label: string,
  loading: boolean,
  value: Array<Category>,
  version: number,
};

const formatCategories = (categories: Array<Category>): Array<SelectOption<string>> => {
  return formatMultiSelectValues('lineage', 'id', categories);
};

export class MultiCategorySelect extends Component<Props> {
  onChange = (options: Array<SelectOption<string>>) => {
    const values: Array<Category> = this.props.categories.filter((category) => {
      return options.find((option) => option.value === category.id);
    });
    this.props.onChange(values);
  }

  render() {
    if (this.props.loading) {
      return null;
    } else {
      let placeholder: string = 'Browse Full List';
      if (this.props.disabled) {
        placeholder = 'Choose a primary category';
      }

      const {
        value,
        onChange, // eslint-disable-line no-unused-vars
        ...otherProps
      } = this.props;

      return (
        <MultiSelectInput
          {...otherProps}
          onChange={this.onChange}
          className="qa-multi-category-select"
          id="category"
          options={formatCategories(this.props.categories)}
          disabled={this.props.disabled}
          placeholder={placeholder}
          value={formatCategories(value)}
        />
      );
    }
  }
}

const withAttributionCategories = graphql(
  gql`
    query fetchCatalogingCategories($version: Int) {
      categories(forCataloging: true, version: $version) {
        id
        lineage
        measurementsRequired
      }
    }
  `,
  {
    options: ({ version }) => ({
      variables: {
        version: version,
      },
    }),
    props: ({ data: { loading, categories } }) => {
      return { loading, categories };
    },
  }
);

export default compose(
  withAttributionCategories
)(MultiCategorySelect);
