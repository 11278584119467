// @flow

import React, { type StatelessFunctionalComponent, type Node } from 'react';
import classNames from 'classnames';

export const Pill: StatelessFunctionalComponent<{
  className?: string,
  title?: string,
  children?: Node,
}> = ({ className, title, children }) => {
  const classes = classNames('pill', className);

  return (
    <div className={classes} title={title}>
      {children}
    </div>
  );
};
