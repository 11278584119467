// @flow

import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import type { Node } from 'react';
import type { PayableToAddress } from '../types';

const UPDATE_CONTRACT_PAYABLE_TO_MUTATION = gql`
  mutation UpdateContractPayableToAddress($input: UpdateContractPayableToAddressInput!) {
    updateContractPayableToAddress(input: $input) {
      contract {
        id
      }
    }
  }
`;

export type ContractPayableToParams = {
  contractId: ID,
  payableToAddress: PayableToAddress,
};

export type UpdateContractPayableToFn = (contractPayableToParams: ContractPayableToParams) => any;

type Props = {
  children: (UpdateContractPayableToFn) => Node,
};

class UpdateContractPayableToMutation extends Component<Props> {
  updatePayableTo = (updatePayableToMutation: Function) => (
    (contractPayableToParams: ContractPayableToParams) => {
      return updatePayableToMutation({
        variables: {
          input: {
            contractId: contractPayableToParams.contractId,
            payableToAddress: contractPayableToParams.payableToAddress,
          },
        },
      });
    }
  )

  render() {
    return (
      <Mutation mutation={UPDATE_CONTRACT_PAYABLE_TO_MUTATION}>
        {
          (updatePayableToMutation) => {
            return this.props.children(this.updatePayableTo(updatePayableToMutation));
          }
        }
      </Mutation>
    );
  }
}


export default UpdateContractPayableToMutation;
