import gql from 'graphql-tag';
import { PendingItemFragment } from "~/shared/graphql/PendingItem/fragments/PendingItem";

export const PENDING_ITEM = gql`
  query pendingItem($id: ID!) {
    pendingItem(id: $id) {
      ...PendingItemFragment
    }
  }

  ${PendingItemFragment}
`;
