// @flow

import React, { Component, Fragment } from 'react';

import ImageLoader from './ImageLoader';
import imgix from '../utils/imgix';

import type { Item } from '../types';

const mainImageSrc = (attachment) => imgix(attachment.imageUrl, { w: '500', h: '500', fit: 'crop' });
const secondaryImageSrc = (attachment) => imgix(attachment.imageUrl, { w: '300', h: '300', fit: 'crop' });

const PHOTO_COUNT = 4;

type Props = {
  item: Item,
};

class PrimaryItemPhotos extends Component<Props> {
  renderPhotos(areImagesLoaded: boolean) {
    if (!areImagesLoaded) {
      return (
        <div className="photos-empty-state">
          <svg className="photos-empty-state__logo" xmlns="http://www.w3.org/2000/svg" width="300" height="150" viewBox="0 0 300 150"><g fill="#DCDCDC"><path d="M193.4 83.3h-17.9v-7.6h10.9v-3.2h-10.9v-6.4h17.1v-3.2H172v23.6h21.4zM220.6 84.4c1.4-1.3 2.2-3.1 2.2-4.9 0-2.8-1.6-5-4-5.8 1.7-.9 2.8-2.8 2.8-4.8 0-1.4-.6-2.7-1.6-3.8-1.4-1.4-3.4-2.1-5.8-2.1h-13.6v23.6h13.9c2.7-.1 4.5-.7 6.1-2.2zm-2.7-2.3c-.8.8-2 1.2-3.4 1.2H204v-7.5h11c1.1 0 2.2.5 3 1.3.7.7 1 1.5 1 2.4 0 .9-.4 1.9-1.1 2.6zm-1.1-10.3c-.6.6-1.5 1-2.6 1h-10.1v-6.7h9.7c1.4 0 2.4.3 3.2 1.1.6.6.9 1.4.9 2.2-.1.9-.4 1.8-1.1 2.4zM227 66.1h8.9v20.4h3.6V66.1h8.9v-3.2H227zM272.8 72.1h-14.5v-9.2h-3.5v23.6h3.5V75.3h14.5v11.2h3.5V62.9h-3.5z" /><path d="M300 0H0v150h300V0zM148.1 146.3H3.7V3.7h144.4v142.6zm148.2 0H151.8V3.7h144.4v142.6h.1z" /><path d="M102.9 74c-4.9-4.9-6.3-12.4-3-18.6 1.1-2 1.6-4.4 1.3-7-.6-5.1-4.7-9.3-9.8-10-1.1-.2-2.1-.2-3.1-.1-3.4.4-6.8-.8-9.2-3.2l-2.4-2.4c-.4-.4-1-.4-1.3 0L73 35.1c-2.4 2.4-5.8 3.6-9.2 3.2-1-.1-2-.1-3.1.1-5.1.8-9.2 4.9-9.8 10-.3 2.5.2 4.9 1.3 7 3.2 6.1 1.9 13.7-3 18.5-.4.4-.4 1 0 1.3 4.9 4.9 6.3 12.4 3 18.5-1.1 2-1.6 4.4-1.3 7 .6 5.1 4.7 9.3 9.8 10 1.1.2 2.1.2 3.1.1 3.4-.4 6.8.8 9.2 3.2l2.4 2.4c.4.4 1 .4 1.3 0l2.4-2.4c2.4-2.4 5.8-3.6 9.2-3.2 1 .1 2.1.1 3.1-.1 5.1-.8 9.2-4.9 9.8-10 .3-2.5-.2-4.9-1.3-7-3.2-6.1-1.9-13.7 3-18.6.4-.1.4-.7 0-1.1zM80.5 86.3h-9.1l2.2-12.8c-1.8-.9-3.1-2.7-3.1-4.9 0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4 0 2.2-1.3 4-3.1 4.9l2.3 12.8z" /></g></svg>
        </div>
      );
    }

    const { item } = this.props;
    const attachments = item.attachments.edges.map((edge) => edge.node).splice(0, PHOTO_COUNT);

    return (
      <Fragment>
        <div className="photos__primary photos__container">
          <img alt="primary" src={mainImageSrc(item.mainAttachment)} />
        </div>

        {
          attachments.map((attachment) => (
            <div key={attachment.id} className="photos__secondary photos__container">
              <img alt="secondary" src={secondaryImageSrc(attachment)} />
            </div>
          ))
        }
      </Fragment>
    );
  }

  render() {
    const { item } = this.props;
    const attachments = item.attachments.edges.map((edge) => edge.node).splice(0, PHOTO_COUNT);
    const thumbnails = attachments.map((attachment) => secondaryImageSrc(attachment));
    const images = [
      ...thumbnails,
      mainImageSrc(item.mainAttachment),
    ];

    return (
      <div className="photos">
        <ImageLoader
          images={images}
          render={({ areImagesLoaded }) => this.renderPhotos(areImagesLoaded)}
        />
      </div>
    );
  }
}

export default PrimaryItemPhotos;
