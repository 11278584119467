// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FilterWrapper from './FilterWrapper';
import FilterDropdownSelectItem from './FilterDropdownSelectItem';
import { nextAppliedParams, paramsToUrl } from '../utils/url';
import type { Filter } from '../types';

type Props = {
  filter: Filter,
  isModalContext: boolean,
  appliedParams: Object,
  history: Object,
}

class FilterDropdownSelect extends Component<Props> {
  handleOnChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const { appliedParams } = this.props;
    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        days_left: value,
        page: 1,
      };
    });
    const url = paramsToUrl(nextParams);
    this.props.history.push(url);
  };

  render() {
    const { filter, isModalContext, appliedParams } = this.props;
    const { name, data } = filter;
    const selectedValue = appliedParams.days_left ? appliedParams.days_left[0] : '';

    // Check if the selectedValue is already in the data array based on id or name match
    const selectedValueExists = data.some((option) => option.id === selectedValue || option.name === `${selectedValue} days`);

    // No options and no selected value, do not render
    if (data.length === 0 && !selectedValue) {
      return null;
    }

    return (
      <FilterWrapper title={name}>
        <select
          className="items-filter"
          onChange={this.handleOnChange}
          value={selectedValue}
        >
          <option value="">No Selection</option>
          {data.map((option) => (
            <FilterDropdownSelectItem
              key={option.id}
              option={option}
              isModalContext={isModalContext}
            />
          ))}
          {!selectedValueExists && selectedValue && (
            <FilterDropdownSelectItem
              key={selectedValue}
              option={{ id: selectedValue, name: `${selectedValue} days` }}
              isModalContext={isModalContext}
            />
          )}
        </select>
      </FilterWrapper>
    );
  }
}

export default withRouter(FilterDropdownSelect);
