// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import FilterTreeCategory from './FilterTreeCategory';
import FilterMultiSelect from './FilterMultiSelect';
import FilterSingleSelect from './FilterSingleSelect';
import FilterLocation from './FilterLocation';
import FilterDropdownSelect from './FilterDropdownSelect';

import type { Filter } from '../types';
import {
  TREE,
  MULTI_SELECT,
  SINGLE_SELECT,
  LOCATION,
  DROPDOWN,
} from '../utils/constants';

type Props = {
  filters: Array<Filter>,
  appliedParams: Object,
  isLoading: boolean,
  filterCounts: Object,
};

class Sidebar extends Component<Props> {
  render() {
    const { filters, appliedParams, isLoading, filterCounts } = this.props;

    return (
      <div className="items-sidebar">
        {
          filters.map((filter) => {
            switch (filter.type) {
              case TREE:
                return (
                  <FilterTreeCategory
                    filter={filter}
                    key={filter.component}
                    appliedParams={appliedParams}
                    filterCounts={filterCounts}
                    isLoading={isLoading}
                  />
                );
              case MULTI_SELECT:
                return (
                  <FilterMultiSelect
                    filter={filter}
                    key={filter.component}
                    appliedParams={appliedParams}
                  />
                );
              case SINGLE_SELECT:
                return (
                  <FilterSingleSelect
                    filter={filter}
                    key={filter.component}
                    appliedParams={appliedParams}
                  />
                );
              case LOCATION:
                return (
                  <FilterLocation
                    filter={filter}
                    key={filter.component}
                    isModalContext={false}
                    appliedParams={appliedParams}
                  />
                );
              case DROPDOWN:
                return (
                  <FilterDropdownSelect
                    filter={filter}
                    key={filter.component}
                    isModalContext={false}
                    appliedParams={appliedParams}
                  />
                );
              default:
                return null;
            }
          })
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  filterCounts: store.items.filterCounts,
  isLoading: store.items.isLoading,
  appliedParams: store.items.appliedParameters,
});

export default connect(mapStateToProps)(Sidebar);
