// @flow

import classNames from 'classnames';
import React, { PureComponent } from 'react';
import type { MultiSelectOption } from '../types';

type Props = {
  option: MultiSelectOption,
  onChange: Function,
  checked: boolean,
  component: string,
}

class FilterMultiSelectColor extends PureComponent<Props> {
  render() {
    const {
      onChange,
      component,
      checked,
      option,
    } = this.props;
    const {
      id,
      name,
    } = option;

    const count = option.count || 0;
    const countString = count === 1 ? '1 item' : `${count.toString()} items`;
    const titleString = checked ? 'Remove filter' : countString;
    const inlineStyle = { backgroundColor: id };
    const hexString = id.replace('#', '');
    const htmlId = `${component}-${hexString}`;
    const spanClassNames = classNames(
      'items-filter-item__color-disc',
      {
        'items-filter-item__color-disc--zero-count': count === 0,
      }
    );

    return (
      <li className="items-filter-item items-filter-item--color">
        <input
          id={htmlId}
          value={id}
          onChange={onChange}
          checked={checked}
          type="checkbox"
          className="items-filter-item__input"
        />
        <label
          htmlFor={htmlId}
          className={`items-filter-item__color-label qa-sidebar-color-${hexString}`}
          title={titleString}
        >
          <span className={spanClassNames} style={inlineStyle}>{name}</span>
        </label>
      </li>
    );
  }
}

export default FilterMultiSelectColor;
