// @flow

import React, { Component, type Node } from 'react';
import { Mutation, type MutationFn, type ApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

import { type TaskType, type TaskEndReason } from '~/types/graphql';

const MUTATION = gql`
  mutation endTask($input: EndTaskInput!) {
    endTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export type Params = {
  input: {
    itemId: ID,
    taskType: TaskType,
    reason: TaskEndReason,
  },
};

export type MutateFn = MutationFn<any, Params>;

type Props = {
  children: (endTaskFn: MutateFn) => Node,
};

class EndTaskMutation extends Component<Props> {
  render() {
    return <Mutation mutation={MUTATION}>{(endTaskFn) => this.props.children(endTaskFn)}</Mutation>;
  }
}

export const endTask = (client: ApolloClient) => {
  return async (variables: Params) => client.mutate({
    mutation: MUTATION,
    variables,
  });
};

export default EndTaskMutation;
