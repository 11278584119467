// @flow
import { some } from 'lodash';

import type { FulfillmentType } from '../types';

export const isShippingAvailable = (fulfillmentTypes: Array<FulfillmentType>) => (
  some(fulfillmentTypes, { type: 'shipping' })
);

export const isShippingUnavailable = (fulfillmentTypes: Array<FulfillmentType>) => (
  !isShippingAvailable(fulfillmentTypes)
);

export const isPickupUnavailable = (fulfillmentTypes: Array<FulfillmentType>) => (
  !some(fulfillmentTypes, { type: 'pickup' })
  && !some(fulfillmentTypes, { type: 'warehouse' })
);
