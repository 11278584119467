import React, { useState } from 'react';

import Checkbox from './Checkbox';
import { LoadingDots } from '~/public/shared/components';
import {
  Button,
  Tooltip,
  Icon
} from '~/public/shared/components';

// Apollo queries and mutations
import { getPrefs, GET_TEXT_PREFS } from '../queries/preferences';
import updatePreferences from '../mutations/updatePreferences';

interface EmailUserPref {
  announcements: boolean;
  dailyDigest: boolean;
  savedSearchAlerts: boolean;
  endingSoonAlerts: boolean;
  sellerUpdates: boolean;
}

interface EmailPreferencesProps {
  className: string;
  seller: boolean;
  prefs: EmailUserPref;
  saving: boolean;
  error?: string;
  onUpdate(prefs: EmailUserPref): any;
}

const EmailPreferences: React.FC<EmailPreferencesProps> = ({
  className,
  prefs,
  seller,
  saving,
  error,
  onUpdate,
}) => {

  const [state, nextState] = useState({
    announcements: prefs.announcements,
    dailyDigest: prefs.dailyDigest,
    savedSearchAlerts: prefs.savedSearchAlerts,
    endingSoonAlerts: prefs.endingSoonAlerts,
    sellerUpdates: prefs.sellerUpdates,
  });

  // Creates a handler for each field
  const handleChange = (name: string) => {
    return (checked: Boolean) => {
      const upNext = {...state};
      upNext[name] = checked;

      // Push the next state
      nextState(upNext);
    }
  };

  const handleSave = () => {
    onUpdate(state);
  };

  const handleUnsubscribeAll = () => {
    const upNext = {
      announcements: false,
      dailyDigest: false,
      savedSearchAlerts: false,
      endingSoonAlerts: false,
      sellerUpdates: false,
    };

    nextState(upNext);
    onUpdate(upNext);
  };

  return (
    <div className={className}>
      <Checkbox
        label="Announcements & Promotions"
        helperText="Stay up to date with the latest promotions, discounts, events, and market updates."
        checked={state.announcements}
        onChange={handleChange('announcements')}
        className="u-mt2"
      />
      <Checkbox
        label="Daily Digest"
        helperText="Get a summary of the day's new, ending, and featured items in your inbox each morning."
        checked={state.dailyDigest}
        onChange={handleChange('dailyDigest')}
        className="u-mt2"
      />
      <Checkbox
        label="Saved Search Alerts"
        helperText="Be the first to know when we post new items that match your saved search criteria."
        checked={state.savedSearchAlerts}
        onChange={handleChange('savedSearchAlerts')}
        className="u-mt2"
      />
      <Checkbox
        label="Ending Soon Alerts"
        helperText="Get alerted when items or sales you're following are about to close."
        checked={state.endingSoonAlerts}
        onChange={handleChange('endingSoonAlerts')}
        className="u-mt2"
      />
      { seller && <Checkbox
        label="Seller Updates"
        helperText="Keep tabs on the status and performance of your items with periodic sale update emails."
        checked={state.sellerUpdates}
        onChange={handleChange('sellerUpdates')}
        className="u-mt2"
      /> }
      <p className="u-mt2 u-flex l-align-center">
        <Button
          buttonDisplay="link"
          onClick={handleUnsubscribeAll}
        >
          Unsubscribe From All Emails
        </Button>
        <Tooltip
          content="By clicking here, you will no longer receive General Announcements / Special Promotions and our Sale / Item Announcements. You will continue to receive bid and order-related emails."
          placement="bottom"
        >
          <Icon icon="help" className="bid__info" />
        </Tooltip>
      </p>
      <Button
        buttonStyle="primary"
        buttonSize="sm"
        onClick={handleSave}
        inFlight={saving}
      >
        Update Email Preferences
      </Button>
      { error }
    </div>
  );
}

const EmailUpdates: React.FC = () => {

  const { loading, data, error } = getPrefs();
  const [ updatePreferencesMn, { loading: prefSaving, error: savePrefError } ] = updatePreferences();

  if (loading) {
    return <LoadingDots />;
  }

  if (error) {
    return (<div>Error getting preferences</div>);
  }

  // Saves the user's email preferences
  const handleSave = ({
    announcements,
    dailyDigest,
    savedSearchAlerts,
    endingSoonAlerts,
    sellerUpdates,
  }) => {
    updatePreferencesMn({
      variables: {
        input: {
          receiveMarketingEmails: announcements,
          receiveSalesEmails: dailyDigest,
          receiveKeywordEmails: savedSearchAlerts,
          receiveEndingSoonEmails: endingSoonAlerts,
          receiveSellerEmails: sellerUpdates,
        },
      },
    });
  };

  const prefs = {
    announcements: data.receiveMarketingEmails,
    dailyDigest: data.receiveSalesEmails,
    savedSearchAlerts: data.receiveKeywordEmails,
    endingSoonAlerts: data.receiveEndingSoonEmails,
    sellerUpdates: data.receiveSellerEmails,
  };

  const errStr = (error) ? error.graphQLErrors[0].message : null;

  return (
    <section aria-labelledby="emailprefs">
      <h2 id="emailprefs">Email</h2>
      <EmailPreferences
        saving={prefSaving}
        className='u-mt2'
        prefs={prefs}
        seller={data.seller}
        onUpdate={handleSave}
        error={errStr} />
    </section>
  );
}

export default EmailUpdates;
