// @flow

import React, { type Node, type StatelessFunctionalComponent } from 'react';
import classNames from 'classnames';

type Props = {
  type?: 'info' | 'success' | 'warning' | 'danger',
  message?: string | Node,
  name?: string,
};

export const Status: StatelessFunctionalComponent<Props> = ({
  type, message, name,
}) => {
  const classes = classNames(
    'status',
    type,
  );

  return (
    <div className={classes}>
      <p>{ name }</p>
      <p>{ message }</p>
    </div>
  );
};
