// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Button } from '~/public/shared/components';
import * as ModalActions from '../actions/modal';

import type { Item, User } from '../types';

type Props = {
  item: Item,
  user: ?User,
  className?: string,
  openModal: (string) => void,
};

class BidButton extends Component<Props> {
  openBidModal = () => {
    this.props.openModal('bidModal');
  }

  buttonAttributes = () => {
    const { user, item, className } = this.props;

    if (!user) {
      return {
        buttonStyle: 'primary-important',
        className: classNames(className, 'qa-register'),
        'data-modal': '#sign-up',
        buttonText: 'Register To Bid',
      };
    }

    if (['recipient', 'follower'].includes(user.role)) {
      return {
        buttonStyle: 'primary-important',
        className: classNames(className, 'qa-register'),
        'data-modal': '#buyer-registration',
        buttonText: 'Register To Bid',
      };
    }

    if (user.paymentMethodsCount === 0) {
      return {
        buttonStyle: 'primary-important',
        className: classNames(className, 'qa-add-payment'),
        onClick: () => { window.location = '/users/payment_methods'; },
        buttonText: 'Add Credit Card',
      };
    }

    if (item.highBidUserId === user.id) {
      return {
        buttonStyle: 'primary-important',
        className: classNames(className, 'qa-edit-bid-btn'),
        onClick: this.openBidModal,
        buttonText: 'Edit Your Bid',
      };
    }

    if (item.bidderIds.includes(user.id)) {
      return {
        buttonStyle: 'primary-danger',
        className: classNames(className, 'qa-new-bid-btn'),
        onClick: this.openBidModal,
        buttonText: 'Place Another Bid',
      };
    }

    if (item.typeName === 'buy_now') {
      return {
        buttonStyle: 'primary-important',
        className: classNames(className, 'qa-new-bid-btn'),
        onClick: this.openBidModal,
        buttonText: 'Buy Now',
      };
    }

    return {
      buttonStyle: 'primary-important',
      className: classNames(className, 'qa-new-bid-btn'),
      onClick: this.openBidModal,
      buttonText: 'Place Bid',
    };
  }

  render() {
    const { item } = this.props;
    const { buttonText, ...attrs } = this.buttonAttributes();

    if (item.aasmState !== 'for_sale') return null;

    return (
      <Button
        name="button"
        buttonDisplay="block"
        buttonSize="lg"
        {...attrs}
      >
        {buttonText}
      </Button>
    );
  }
}

const mapStateToProps = ({ user: { user }, item: { item } }) => ({ user, item });
const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...ModalActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(BidButton);
