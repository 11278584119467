// @flow

import React, { Component } from 'react';

import PhotoCard from '~/public/dashboard/components/PhotoCard';
import UploadCard from './UploadCard';
import { SectionNotice } from '~/public/dashboard/components/SectionNotice';
import type { AttachmentType } from '~/admin/photo_editing/types';

type Props = {
  photos: Array<AttachmentType>,
  itemId: string,
  deletePhoto: (photoId: ID) => any,
  editPhoto: (photoId: ID) => any,
  showUpload?: boolean,
  photosUploading: (boolean) => any,
};

class PhotoGrid extends Component<Props> {
  showUpload() {
    if (this.props.showUpload) {
      return (<UploadCard itemId={this.props.itemId} photosUploading={this.props.photosUploading} />);
    }
    return null;
  }

  render() {
    return (
      <div className="l-gallery photo-upload-section">
        {this.showUpload()}
        {this.props.photos.length ?
          <div className="u-flex u-mb2 u-flex-wrap">
            {this.props.photos.map(
              (photo, index) => (
                <PhotoCard
                  index={index}
                  key={`photo-${photo.id}`}
                  photo={photo}
                  deletePhoto={this.props.deletePhoto}
                  editPhoto={this.props.editPhoto}
                />
              )
            )}
          </div>
          :
          <SectionNotice
            className="u-mb2"
            text="No photos added yet"
          />
        }
      </div>
    );
  }
}

export default PhotoGrid;
