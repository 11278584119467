// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import classNames from 'classnames';

export const SelectField: StatelessFunctionalComponent<{
  errors?: Array<string>,
  label: string,
  name: string,
  options: Array<Object>,
  onChange: Function,
  value: ?string,
  inputType?: string,
  autocomplete?: string,
  floatedLabel?: boolean,
}> = ({
  errors = [],
  label,
  name,
  onChange,
  value,
  options,
  autocomplete = null,
  floatedLabel = false,
  ...otherProps
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const renderOption = (
    {
      label: optionLabel,
      value: optionValue,
      isDefault = false,
      isDisabled = false,
    },
    index,
  ) => {
    return (
      <option
        value={optionValue}
        key={index}
        defaultValue={isDefault}
        disabled={isDisabled}
      >
        {/* Don't show "placholder" option value unless floated label */
          !floatedLabel && optionValue === '' ? '' : optionLabel
        }
      </option>
    );
  };

  const hasErrors = (errors.length > 0);

  const groupClassNames = classNames(
    'form-group',
    {
      'form-group--show-floated-label': (floatedLabel && value !== ''),
      'form-group--error': hasErrors,
    }
  );

  const labelClassNames = classNames(
    'form-group__label',
    {
      'form-group__label--floated': floatedLabel,
    }
  );

  const inputSelectClassNames = classNames(
    'input__select',
    {
      'input__select--no-value': (floatedLabel && value === ''),
    }
  );

  return (
    <div className={groupClassNames}>
      <label className={labelClassNames} htmlFor={name}>{label}</label>
      <div className={inputSelectClassNames}>
        <select
          className="input"
          id={name}
          autoComplete={autocomplete}
          value={value}
          onChange={handleChange}
          {...otherProps}
        >
          {options.map((option, index) => renderOption(option, index))}
        </select>
      </div>
      {hasErrors &&
        <div className="form-group__error">{errors.join(',')}</div>
      }
    </div>
  );
};
