// @flow

import React, { Component } from 'react';
import Layout from '../components/Layout';
import { Card } from '../components/Card';

type Props = {
  lookerDashboardUrl: string,
};

class StatsPage extends Component<Props> {
  componentDidMount() {
    document.title = 'Stats | Seller Dashboard';
  }

  render() {
    return (
      <Layout>
        <h1 className="u-mb2">Stats</h1>
        <Card>
          <div className="looker-dashboard">
            <div className="looker-dashboard__container">
              <iframe
                src={this.props.lookerDashboardUrl}
                className="looker-dashboard__iframe"
                frameBorder="0"
                title="Looker Stats"
              />
            </div>
          </div>
        </Card>
      </Layout>
    );
  }
}

export { StatsPage };
