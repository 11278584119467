import React, { Component } from 'react';

import PaneAddPayment from '~/public/bid/components/PaneAddPayment';

import { useStripe, Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

class PaneAddPaymentContainer extends Component {
  // the purpose of this container
  // is to wrap the PaneAddPayment with Stripe Elements HOC
  // and a functional component (StripeWrapper) for Stripe hooks
  render() {
    const stripePromise = loadStripe(this.props.stripe_api_key);

    return (
      <Elements stripe={stripePromise}>
        <StripeWrapper {...this.props} />
      </Elements>
    );
  }
}

const StripeWrapper = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  // inject the new stripe token func into the old PaneAddPayment
  return <PaneAddPayment stripe={stripe} elements={elements} {...props} />;
};

export default PaneAddPaymentContainer;
