// @flow

import React, { Component, Fragment } from 'react';
import { Button } from '~/public/shared/components';

type Props = {
  value: string
};

type State = {
  copied: boolean,
};

class ClipboardInput extends Component<Props, State> {
  state = {
    copied: false,
  }

  // $FlowFixMe
  inputRef = React.createRef<HTMLInputElement>()

  selectInput = () => {
    if (this.inputRef.current) {
      this.inputRef.current.select();
    }
  }

  handleClick = () => {
    this.selectInput();
    document.execCommand('copy');
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  render() {
    const { value } = this.props;
    const buttonText = this.state.copied ? 'Copied!' : 'Copy';

    return (
      <Fragment>
        <input
          ref={this.inputRef}
          onFocus={this.selectInput}
          type="text"
          className="input input-group__input input--borderless u-bg-gray-100"
          value={value}
          readOnly
        />
        <Button
          className="input-group__btn"
          buttonStyle="primary"
          onClick={this.handleClick}
        >
          {buttonText}
        </Button>
      </Fragment>
    );
  }
}

export default ClipboardInput;
