import update from 'immutability-helper';
import { get } from 'lodash';

import PaymentMethodTokenizer from '~/utils/PaymentMethodTokenizer';
import transformErrorsToChanges from '~/utils/transformErrorsToChanges';
import AddressHelper from '~/public/shared/utils/AddressHelper';

const MONTH_OPTIONS = [
  { label: '01', value: '1' },
  { label: '02', value: '2' },
  { label: '03', value: '3' },
  { label: '04', value: '4' },
  { label: '05', value: '5' },
  { label: '06', value: '6' },
  { label: '07', value: '7' },
  { label: '08', value: '8' },
  { label: '09', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
];

const YEAR_OPTIONS = (() => {
  const currentYear = new Date().getFullYear();
  const options = [];

  for (let year = currentYear; year <= currentYear + 20; year += 1) {
    const yearStr = year.toString();
    options.push({ label: yearStr, value: yearStr });
  }

  return options;
})();

const CREDIT_CARD_PAYMENT_TYPE = {
  label: 'Credit Card',
  value: 'creditCard',
  showSubmitButton: true,
  submitButtonText: 'Add Payment Method',
};

const PLAID_PAYMENT_TYPE = {
  label: 'Link Bank Account',
  value: 'plaid',
  showSubmitButton: false,
  submitButtonText: '',
};

const GIFT_CARD = {
  label: 'Gift Card',
  value: 'giftCard',
  showSubmitButton: true,
  submitButtonText: 'Redeem a Gift Card',
};

const PAYMENT_METHOD_TYPES = {
  creditCard: CREDIT_CARD_PAYMENT_TYPE,
  plaid: PLAID_PAYMENT_TYPE,
  giftCard: GIFT_CARD,
};

function paymentMethodTypes() {
  return [
    CREDIT_CARD_PAYMENT_TYPE,
    PLAID_PAYMENT_TYPE,
    GIFT_CARD,
  ];
}

function parseErrorsFromRails(errors) {
  const parsedErrors = { address: {} };

  parsedErrors.name = get(errors, 'name_on_card', []);
  parsedErrors.expMonth = get(errors, 'exp_month', []);
  parsedErrors.expYear = get(errors, 'exp_year', []);
  parsedErrors.expYear = get(errors, 'exp_year', []);
  parsedErrors.address.id = get(errors, 'address.id', []);
  parsedErrors.address.name = get(errors, 'address.name', []);
  parsedErrors.address.line1 = get(errors, 'address.address', []);
  parsedErrors.address.line2 = get(errors, 'address.floor_suite', []);
  parsedErrors.address.city = get(errors, 'address.city', []);
  parsedErrors.address.state = get(errors, 'address.state', []);
  parsedErrors.address.country = get(errors, 'address.country', []);
  parsedErrors.address.zip = get(errors, 'address.zip', []);

  return parsedErrors;
}

function applyServerErrors(paymentMethod, serverErrors) {
  const changes = transformErrorsToChanges(parseErrorsFromRails(serverErrors));

  changes.hasErrors = { $set: true };

  if (serverErrors.base) {
    changes.base = { errors: { $set: serverErrors.base } };
    // Don't reuse token for a card that errored
    changes.token = { value: { $set: '' } };
  }

  return update(paymentMethod, changes);
}

function destroy(id) {
  return fetch(`/api/v1/payment_methods/${id}`, {
    method: 'delete',
    credentials: 'include',
  });
}

async function getToken(paymentMethod, paymentForm, options) {
  try {
    const { token, last4 } = await PaymentMethodTokenizer.registerPaymentMethod(
      paymentMethod,
      paymentForm,
      options
    );
    paymentMethod = update(paymentMethod, {
      token: { value: { $set: token } },
      last4: { value: { $set: last4 } },
    });
  } catch ({ key, message }) {
    paymentMethod = update(paymentMethod, {
      hasErrors: { $set: true },
      [key]: { errors: { $set: [message] } },
    });
  }

  return paymentMethod;
}

function initialize(addresses = []) {
  const paymentMethod = {
    hasErrors: false,
    base: {
      errors: [],
    },
    id: {
      value: null,
      errors: [],
    },
    last4: {
      value: '',
    },
    name: {
      value: '',
      errors: [],
    },
    number: {
      value: '',
      errors: [],
    },
    expMonth: {
      value: '1',
      errors: [],
    },
    expYear: {
      value: new Date().getFullYear().toString(),
      errors: [],
    },
    cvv: {
      value: '',
      errors: [],
    },
    token: {
      value: null,
    },
    primary: {
      value: false,
      errors: [],
    },
    giftCardNumber: {
      value: '',
      errors: [],
    },
    address: AddressHelper.initialize(),
  };

  paymentMethod.address.isAddingAddress = addresses.length === 0;

  return paymentMethod;
}

function makePrimary(id) {
  return fetch(`/api/v1/payment_methods/${id}`, {
    method: 'put',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      primary: true,
    }),
  });
}

async function savePaymentMethod(paymentMethod) {
  try {
    const response = await fetch('/api/v1/payment_methods', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name_on_card: paymentMethod.name.value,
        vendor_code: paymentMethod.token.value,
        last4: paymentMethod.last4.value,
        exp_month: paymentMethod.expMonth.value,
        exp_year: paymentMethod.expYear.value,
        primary: paymentMethod.primary.value,
        address: {
          id: paymentMethod.address.id.value,
          name: paymentMethod.address.name.value,
          address: paymentMethod.address.line1.value,
          floor_suite: paymentMethod.address.line2.value,
          city: paymentMethod.address.city.value,
          state: paymentMethod.address.state.value,
          zip: paymentMethod.address.zip.value,
          country: paymentMethod.address.country.value,
        },
      }),
    });

    const responseBody = await response.json();

    if (response.status === 422) {
      paymentMethod = update(paymentMethod, {
        hasErrors: { $set: true },
        base: { errors: { $set: responseBody.errors.base } },
      });
    } else if (!response.ok && response.status !== 401) {
      paymentMethod = applyServerErrors(paymentMethod, responseBody.errors);
    } else {
      paymentMethod = update(paymentMethod, {
        id: { value: { $set: responseBody.id } },
        address: { id: { value: { $set: responseBody.address_id } } },
      });
    }
  } catch (_err) {
    // Communication error
    paymentMethod = update(paymentMethod, {
      hasErrors: { $set: true },
      base: { errors: { $set: ['Network error, please try again.'] } },
    });
  }

  return paymentMethod;
}

async function savePlaid(paymentMethod, publicToken, metadata) {
  let isSuccess = true;
  let newPaymentMethod = paymentMethod;

  try {
    const response = await fetch('/api/v1/payment_methods', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'plaid',
        plaid_public_token: publicToken,
        plaid_account_id: metadata.account_id,
        account_name: metadata.account.name,
        institution_name: metadata.institution.name,
      }),
    });

    const responseBody = await response.json();

    if (!response.ok && response.status !== 401) {
      isSuccess = false;
      newPaymentMethod = update(
        paymentMethod,
        { hasErrors: { $set: true }, base: { errors: { $set: responseBody.errors.base } } }
      );
    } else {
      newPaymentMethod = update(paymentMethod, {
        id: { value: { $set: responseBody.id } },
      });
    }
  } catch (_err) {
    isSuccess = false;
    newPaymentMethod = update(
      paymentMethod,
      { hasErrors: { $set: true }, base: { errors: { $set: ['Network error, please try again.'] } } }
    );
  }

  return {
    isSuccess: isSuccess,
    paymentMethod: newPaymentMethod,
  };
}

async function redeemGiftCard(paymentMethod) {
  let newPaymentMethod = paymentMethod;

  try {
    const response = await fetch('/api/v1/payment_methods', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'giftCard',
        number: paymentMethod.giftCardNumber,
      }),
    });

    const responseBody = await response.json();
    if (!response.ok && response.status !== 401) {
      newPaymentMethod = update(
        paymentMethod,
        { hasErrors: { $set: true }, base: { errors: { $set: responseBody.errors.base } } }
      );
    }
  } catch (_err) {
    newPaymentMethod = update(
      paymentMethod,
      { giftCardNumber: { value: '', errors: [] }, hasErrors: { $set: true }, base: { errors: { $set: ['Network error, please try again.'] } } }
    );
  }

  return newPaymentMethod;
}

function validate_gift_card(paymentMethod) {
  const errors = {};
  let hasErrors = false;

  if (paymentMethod.giftCardNumber.value.length > 0) {
    errors.giftCardNumber = { errors: { $set: [] } };
  } else {
    hasErrors = true;
    errors.giftCardNumber = { errors: { $set: ['Gift Card number is required'] } };
  }
  errors.hasErrors = { $set: hasErrors };
  return update(paymentMethod, errors);
}

function validate_credit_card(paymentMethod) {
  const errors = {};
  let hasErrors = false;

  if (paymentMethod.name.value.length > 0) {
    errors.name = { errors: { $set: [] } };
  } else {
    hasErrors = true;
    errors.name = { errors: { $set: ['Cardholder’s name required'] } };
  }
  if (paymentMethod.address.isAddingAddress) {
    const updatedAddress = AddressHelper.validate(paymentMethod.address);
    if (updatedAddress.hasErrors) {
      hasErrors = true;
    }
    errors.address = { $set: updatedAddress };
  } else if (paymentMethod.address.id.value !== null) {
    errors.address = {
      id: { errors: { $set: [] } },
    };
  } else {
    hasErrors = true;
    errors.address = {
      hasErrors: { $set: true },
      id: { errors: { $set: ['Select an existing address or create a new one'] } },
    };
  }

  errors.hasErrors = { $set: hasErrors };

  return update(paymentMethod, errors);
}

function validate(paymentMethod) {
  const paymentMethodTypeId = paymentMethod.paymentMethodTypeId || 1;

  switch (paymentMethodTypeId) {
    case 1:
      return validate_credit_card(paymentMethod);
    case 4:
      return validate_gift_card(paymentMethod);
    default:
      return validate_credit_card(paymentMethod);
  }
}

export default {
  MONTH_OPTIONS,
  PAYMENT_METHOD_TYPES,
  YEAR_OPTIONS,
  applyServerErrors,
  destroy,
  getToken,
  initialize,
  makePrimary,
  paymentMethodTypes,
  savePaymentMethod,
  savePlaid,
  redeemGiftCard,
  validate,
};
