// @flow

import { camelizeKeys } from 'humps';
import { flatten, values } from 'lodash';

import DirectPhotoUploader from '../../../utils/DirectPhotoUploader';

import type { PhotoUploadResult } from '../../../utils/DirectPhotoUploader';

const HTTPPhotoUploadClient = {
  getPhotoUploadUrl: async (file: File): Promise<PhotoUploadResult> => {
    const filename = file.name;

    const response = await fetch('/value-estimator/get_photo_upload_url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filename, size: file.size }),
    });

    if (!response.ok) {
      if (response.status === 422 && response.body) {
        const { errors }: { errors: { [string]: Array<String> } } = await response.json();
        const errArray = flatten(values(errors));
        throw new Error(errArray.join('\n')); // reject promise with errors
      } else {
        throw new Error('There was a problem uploading');
      }
    } else {
      const responseJson = await response.json();
      return camelizeKeys(responseJson);
    }
  },
};

export default new DirectPhotoUploader(HTTPPhotoUploadClient);
