import React from 'react';

import { ApolloProvider } from 'react-apollo-hooks';
// We're using both here, answer found in: https://github.com/apollographql/apollo-client/issues/2042
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';

import TextUpdates from './components/TextUpdates';
import EmailUpdates from './components/EmailUpdates';

const httpLink = new HttpLink({ uri: '/anon-graphql', credentials: 'same-origin' });

const authMiddleWare = new ApolloLink((operation, forward) => {
  // Check if there's the user_id and key present
  const userIdElem = document.getElementById('user_id');
  const keyElem = document.getElementById('key');

  if (userIdElem && keyElem) {
    operation.setContext({
      headers: {
        'X-USER-ID': userIdElem.value,
        'X-USER-KEY': keyElem.value,
      },
    });
  }

  return forward(operation);
});

const cache = new InMemoryCache();
const client = new ApolloClient({
  link: concat(authMiddleWare, httpLink),
  cache: cache,
});

const Application = () => (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <EmailUpdates />
      <hr className="hr hr--spaced" />
      <TextUpdates />
    </ApolloHooksProvider>
  </ApolloProvider>
);

export default Application;
