// @flow

import {
  OPEN_MODAL,
  CLOSE_MODAL,
} from '../actions/modal';

import type { Actions } from '../actions/modal';
import type { ModalState } from '../types';

const initialState = {};

const modalReducer = (state: ModalState = initialState, action: Actions): ModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        [action.id]: 'open',
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [action.id]: 'closed',
      };
    default:
      return state;
  }
};

export default modalReducer;
