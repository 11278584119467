// @flow

import React, { type StatelessFunctionalComponent } from 'react';

type Props = {
  id: string,
  label: string,
  onChange: (newValue: boolean) => void,
  value: boolean,
  className?: string,
};

export const Checkbox: StatelessFunctionalComponent<Props> = ({
  onChange, label, value, id, className,
}) => {
  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  let classes = 'custom-control custom-control--checkbox u-m0 ';
  classes += className || '';

  return (
    <div className="form-control__input u-mb0">
      <label className={classes}>
        <input
          id={id}
          type="checkbox"
          className="custom-control__input"
          checked={value}
          onChange={handleChange}
        />
        <div className="custom-control__label">{label}</div>
      </label>
    </div>
  );
};
