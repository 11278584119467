import update from 'immutability-helper';

import { CardElement } from '@stripe/react-stripe-js';

const getStripeToken = async (paymentMethod, stripe, elements) => {
  // this replaces the old PaymentMethodHelper.getToken
  // using only the Stripe elements and hooks makes us
  // automatically PCI compliant
  try {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // clear out any stripe errors
    paymentMethod = update(paymentMethod, {
      hasErrors: { $set: false },
      base: { errors: { $set: [] } },
    });

    const cardElement = elements.getElement(CardElement);

    // the address object comes in two shapes
    // based on if it is a new address or not
    // duct tape them together!
    // if each is an obj, take the value prop
    // else take the regular string value
    const line1 = paymentMethod.address.line1.value
      ? paymentMethod.address.line1.value
      : paymentMethod.address.line1;
    let line2 = paymentMethod.address.line2.value
      ? paymentMethod.address.line2.value
      : paymentMethod.address.line2;
    // line2 is a special case and can be blank
    if (line2.value === '') {
      line2 = '';
    }
    const city = paymentMethod.address.city.value
      ? paymentMethod.address.city.value
      : paymentMethod.address.city;
    const state = paymentMethod.address.state.value
      ? paymentMethod.address.state.value
      : paymentMethod.address.state;
    const zip = paymentMethod.address.zip.value
      ? paymentMethod.address.zip.value
      : paymentMethod.address.zip;
    const country = paymentMethod.address.country.value
      ? paymentMethod.address.country.value
      : paymentMethod.address.country;

    const tokenResp = await stripe.createToken(cardElement, {
      name: paymentMethod.name.value,
      address_line1: line1,
      address_line2: line2,
      address_city: city,
      address_state: state,
      address_zip: zip,
      address_country: country,
    });

    // set any stripe errors and return prematurely
    if (tokenResp && tokenResp.error) {
      paymentMethod = update(paymentMethod, {
        hasErrors: { $set: true },
        base: { errors: { $set: [tokenResp.error.message] } },
      });
      return paymentMethod;
    }

    const { id, card } = tokenResp.token;
    const { last4, exp_month, exp_year } = card;

    // update our payment method and preserve immutability
    paymentMethod = update(paymentMethod, {
      token: { value: { $set: id } },
      last4: { value: { $set: last4 } },
      expMonth: { value: { $set: exp_month } },
      expYear: { value: { $set: exp_year } },
    });
  } catch (resp) {
    // catch any errors and add them to paymentMethod
    paymentMethod = update(paymentMethod, {
      hasErrors: { $set: true },
      base: { errors: { $set: [resp] } },
    });
  }
  return paymentMethod;
};
export default getStripeToken;
