// @flow

import React, { Component } from 'react';

import ShippingContainer from '../containers/fulfillment_types/ShippingContainer';
import ShippingBidContainer from '../containers/fulfillment_types/ShippingBidContainer';
import Pickup from './fulfillment_types/Pickup';
import Unavailable from './fulfillment_types/Unavailable';
import { isShippingUnavailable, isPickupUnavailable } from '../utils/fulfillmentTypeHelpers';

import type { FulfillmentType } from '../types';
import { FeatureFlagConsumer } from '../../dashboard/contexts/FeatureFlagContext';

type Props = {
  fulfillmentTypes: Array<FulfillmentType>,
  isPlacingBid?: boolean,
  onFulfillmentTypeChange?: (FulfillmentType) => void,
  onShippingAddressChange?: (string) => void,
  selectedfulfillmentType?: ?FulfillmentType,
  changePane?: (string) => void,
};

class FulfillmentTypes extends Component<Props> {
  getComponentByType = (type: string) => {
    const components = {
      shipping: ShippingContainer,
      pickup: Pickup,
      warehouse: Pickup,
    };

    if (type === 'shipping') {
      return this.props.isPlacingBid ? ShippingBidContainer : ShippingContainer;
    } else {
      return components[type];
    }
  }

  render() {
    const {
      fulfillmentTypes,
      isPlacingBid,
      onFulfillmentTypeChange,
      onShippingAddressChange,
      selectedfulfillmentType,
      changePane,
    } = this.props;

    return (
      <FeatureFlagConsumer>
        {(featureFlags) => (
          <table className="bid-fulfillment-options">
            <tbody>
              {fulfillmentTypes.map((fulfillmentType: FulfillmentType) => {
                const { type, id } = fulfillmentType;
                const FulfillmentTypeComponent = this.getComponentByType(type);
                const isSelected = !!(selectedfulfillmentType && selectedfulfillmentType.id === id);
                return (
                  <FulfillmentTypeComponent
                    key={fulfillmentType.id}
                    fulfillmentType={fulfillmentType}
                    onFulfillmentTypeSelect={onFulfillmentTypeChange}
                    onShippingAddressSelect={onShippingAddressChange}
                    isSelected={isSelected}
                    changePane={changePane}
                    isPlacingBid={isPlacingBid}
                    internationalShippingQuotesEnabled={featureFlags.internationalShippingQuotes}
                  />
                );
              })}
              {isShippingUnavailable(fulfillmentTypes) &&
                <Unavailable type="Shipping" />
              }
              {isPickupUnavailable(fulfillmentTypes) &&
                <Unavailable type="Pickup" />
              }
            </tbody>
          </table>
        )}
      </FeatureFlagConsumer>
    );
  }
}

export default FulfillmentTypes;
