
import { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { get } from 'lodash';

const PAYMENTS_ITEMS_QUERY = gql`
  query currentUserWithItemsAwaitingSellerInvoice($first: Int, $after: String, $status: String) {
    currentUser {
      id
      itemsAwaitingSellerInvoice(first: $first, after: $after, sortColumn: "sale_ends_at", sortDirection: DESC, status: $status) {
        edges {
          node {
            id
            name
            barcode
            highBidAmount
            commissionTotal
            aasmState
            saleEndsAt

            mainAttachment {
              imageUrl
            }
            quickpicAttachment {
              imageUrl
            }
          }
        }

        totalCount

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

class PaymentsItemsQuery extends Component<Props> {
  render() {
    const {
      items,
      initialLoading,
      loading,
      hasNextPage,
      loadMore,
    } = this.props;

    return this.props.children({
      items,
      initialLoading,
      loading,
      hasNextPage,
      loadMore,
    });
  }
}

const mapDataToProps = ({ data }) => {
  const {
    loading,
    error,
    fetchMore,
  } = data;

  const items = get(data, 'currentUser.itemsAwaitingSellerInvoice.edges', []).map((e) => e.node);
  const initialLoading = loading && items.length === 0;
  const hasNextPage = get(data, 'currentUser.itemsAwaitingSellerInvoice.pageInfo.hasNextPage', false);
  const endCursor = get(data, 'currentUser.itemsAwaitingSellerInvoice.pageInfo.endCursor', null);

  const loadMore = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        const {
          currentUser: {
            itemsAwaitingSellerInvoice: { totalCount, pageInfo, __typename },
          },
        } = fetchMoreResult;

        const newEdges = [
          ...previousResult.currentUser.itemsAwaitingSellerInvoice.edges,
          ...fetchMoreResult.currentUser.itemsAwaitingSellerInvoice.edges,
        ];

        return {
          currentUser: {
            ...previousResult.currentUser,
            itemsAwaitingSellerInvoice: {
              __typename,
              edges: newEdges,
              pageInfo,
              totalCount,
            },
          },
        };
      },
    });
  };

  return {
    initialLoading,
    loading,
    error,
    hasNextPage,
    loadMore,
    items,
  };
};

const mapPropsToOptions = (props) => ({
  variables: {
    status: props.status || 'paid',
    first: props.first || 10,
    after: null,
  },
  notifyOnNetworkStatusChange: true,
});

export default graphql(PAYMENTS_ITEMS_QUERY, {
  options: mapPropsToOptions,
  props: mapDataToProps,
})(PaymentsItemsQuery);
