
import { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { get } from 'lodash';

const PAYMENTS_QUERY = gql`
  query currentUserWithSellerInvoices($first: Int, $after: String) {
    currentUser {
      id
      sellerInvoices(first: $first, after: $after, sortColumn: "created_at", sortDirection: DESC) {
        edges {
          node {
            id
            number
            createdAt
            paymentDate
            totalToSeller
            aasmState
          }
        }

        totalCount

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

class PaymentsQuery extends Component<Props> {
  render() {
    const {
      payments,
      initialLoading,
      loading,
      hasNextPage,
      loadMore,
    } = this.props;

    return this.props.children({
      payments,
      initialLoading,
      loading,
      hasNextPage,
      loadMore,
    });
  }
}

const mapDataToProps = ({ data }) => {
  const {
    loading,
    error,
    fetchMore,
  } = data;

  const payments = get(data, 'currentUser.sellerInvoices.edges', []).map((e) => e.node);
  const initialLoading = loading && payments.length === 0;
  const hasNextPage = get(data, 'currentUser.sellerInvoices.pageInfo.hasNextPage', false);
  const endCursor = get(data, 'currentUser.sellerInvoices.pageInfo.endCursor', null);

  const loadMore = () => {
    fetchMore({
      variables: {
        after: endCursor,
      },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        const {
          currentUser: {
            sellerInvoices: { totalCount, pageInfo, __typename },
          },
        } = fetchMoreResult;

        const newEdges = [
          ...previousResult.currentUser.sellerInvoices.edges,
          ...fetchMoreResult.currentUser.sellerInvoices.edges,
        ];

        return {
          currentUser: {
            ...previousResult.currentUser,
            sellerInvoices: {
              __typename,
              edges: newEdges,
              pageInfo,
              totalCount,
            },
          },
        };
      },
    });
  };

  return {
    initialLoading,
    loading,
    error,
    hasNextPage,
    loadMore,
    payments,
  };
};

const mapPropsToOptions = (props) => ({
  variables: {
    first: props.first || 21,
    after: null,
  },
  notifyOnNetworkStatusChange: true,
});

export default graphql(PAYMENTS_QUERY, {
  options: mapPropsToOptions,
  props: mapDataToProps,
})(PaymentsQuery);
