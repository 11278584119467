import update from 'immutability-helper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputField, SelectField } from '~/public/shared/components';
import CheckboxField from '~/public/shared/components/CheckboxField';
import CreditCardFormFields from '~/public/shared/components/CreditCardFormFields';
import AddressFormFields from '~/public/shared/components/AddressFormFields';

class BuyerRegistrationFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender_options: [],
      income_options: [],
    };
  }

  componentDidMount() {
    fetch('/api/v1/demograpics_options')
      .then((response) => response.json())
      .then((data) => {
        const gender_options = [
          {
            label: 'Gender (Opt)',
            value: '',
            isDefault: true,
            isDisabled: true,
          },
          ...data.gender_options,
        ];
        const income_options = [
          {
            label: 'Income Range (opt)',
            value: '',
            isDefault: true,
            isDisabled: true,
          },
          ...data.income_options,
        ];
        this.setState({
          gender_options,
          income_options,
        });
      });
  }

  handleChange = (key) => {
    return (change) => {
      const { registration } = this.props;
      const newRegistration = update(registration, { [key]: { value: { $set: change } } });
      this.props.onChange(newRegistration);
    };
  }

  handlePaymentMethodChange = (paymentMethod) => {
    const { registration } = this.props;
    const newRegistration = update(registration, { paymentMethod: { $set: paymentMethod } });
    this.props.onChange(newRegistration);
  }

  handleAddressChange = (address) => {
    const { registration } = this.props;
    const newRegistration = update(registration, { paymentMethod: { address: { $set: address } } });
    this.props.onChange(newRegistration);
  }

  renderVantivFields = () => {
    if (this.props.useVantiv) {
      const { number, cvv } = this.props.registration.paymentMethod;

      return (
        <div>
          <input type="hidden" id="vantiv-account-num" value={number.value} />
          <input type="hidden" id="vantiv-cvv" value={cvv.value} />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { registration } = this.props;
    const { paymentMethod } = registration;
    const { address } = paymentMethod;

    return (
      <div className="l-form-grid">
        <div className="l-form-grid__row">
          <div className="l-form-grid__item l-form-grid__item--6-col">
            <InputField
              label="First Name"
              floatedLabel
              name="firstName"
              autocomplete="given-name"
              {...registration.firstName}
              onChange={this.handleChange('firstName')}
            />
          </div>
          <div className="l-form-grid__item l-form-grid__item--6-col">
            <InputField
              label="Last Name"
              floatedLabel
              name="lastName"
              autocomplete="family-name"
              {...registration.lastName}
              onChange={this.handleChange('lastName')}
            />
          </div>
        </div>

        <div className="l-form-grid__row">
          <div className="l-form-grid__item l-form-grid__item--3-col">
            <InputField
              inputType="tel"
              label="Phone Number (opt)"
              floatedLabel
              name="phone"
              autocomplete="tel"
              {...registration.phone}
              onChange={this.handleChange('phone')}
            />
          </div>
          <div className="l-form-grid__item l-form-grid__item--2-col">
            <SelectField
              label="Gender"
              name="gender_selection"
              autocomplete="gender"
              floatedLabel
              {...registration.gender}
              options={this.state.gender_options || []}
              onChange={this.handleChange('gender')}
            />
          </div>
          <div className="l-form-grid__item l-form-grid__item--4-col">
            <SelectField
              label="Income (opt)"
              name="income_range_selection"
              autocomplete="income"
              floatedLabel
              {...registration.income}
              options={this.state.income_options || []}
              onChange={this.handleChange('income')}
            />
          </div>
          <div className="l-form-grid__item l-form-grid__item--3-col">
            <InputField
              inputType="date"
              label="Birthday (opt)"
              floatedLabel
              name="birthdate"
              autocomplete="birthday"
              {...registration.birthdate}
              onChange={this.handleChange('birthdate')}
            />
          </div>
        </div>
        <div className="u-mb4">
          <CheckboxField
            label="Send me text updates regarding my wins."
            name="smsOptin"
            {...registration.smsOptin}
            onChange={this.handleChange('smsOptin')}
          />
        </div>

        <h4 className="modal__content--buyer-registration-subheading">What credit card would you like to use when bidding?</h4>
        <p>You can pay with a different card or bank account after winning.</p>

        {this.renderVantivFields()}

        <CreditCardFormFields
          paymentMethod={paymentMethod}
          onChange={this.handlePaymentMethodChange}
        />

        <h4 className="modal__content--buyer-registration-subheading u-mb1">Billing Address</h4>

        <AddressFormFields
          address={address}
          onChange={this.handleAddressChange}
        />
      </div>
    );
  }
}

BuyerRegistrationFields.propTypes = {
  registration: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  useVantiv: PropTypes.bool.isRequired,
};

export default BuyerRegistrationFields;
