// @flow

import React, { Component } from 'react';

import QuestionCarouselContainer from '../containers/QuestionCarouselContainer';

type Props = {};

class FormPage extends Component<Props> {
  onComplete = () => {
    window.location = '/value-estimator/thank-you';
  }

  render() {
    return (
      <QuestionCarouselContainer onComplete={this.onComplete} />
    );
  }
}

export default FormPage;
