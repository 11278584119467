// @flow

const formatThousandsToK = (val: number | string | null): string => {
  const num = Number(val) || 0;
  const absNum = Math.abs(num);
  const signNum = Math.sign(num);

  if (absNum > 999) {
    const reduced = signNum * (absNum / 1000);
    return `${reduced.toFixed(1)}k`;
  } else {
    return (signNum * absNum).toString();
  }
};

export default formatThousandsToK;
