// @flow
/* eslint-disable import/prefer-default-export */

import { stringify, parse } from 'query-string';
import type { Location } from 'react-router';

const BRACKET_FORMAT = { arrayFormat: 'bracket' };

export const stringifyQuery = (params: {}): string => (
  stringify(params, BRACKET_FORMAT)
);

export const parseQuery = (location: Location): Object => (
  parse(location.search, BRACKET_FORMAT)
);
