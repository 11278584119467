// @flow
import type { PaymentMethod } from '../types';

export const paymentMethodSvgId = (pm: ?PaymentMethod): ?string => {
  if (!pm) return null;
  const { paymentMethodTypeId, cardType } = pm;

  if (paymentMethodTypeId === 2) return 'payment_cash';
  if (paymentMethodTypeId === 3) return 'payment_bank';

  if (!cardType) return 'payment_generic_card';

  const paramCardType = cardType.toLowerCase().replace(' ', '_');

  switch (paramCardType) {
    case 'american_express':
    case 'visa':
    case 'discover':
    case 'mastercard':
      return `payment_${paramCardType}`;
    default:
      return 'payment_generic_card';
  }
};

export const paymentMethodText = (pm: ?PaymentMethod): ?string => {
  if (!pm) return null;
  const {
    paymentMethodTypeId,
    cardType,
    last4,
    nameOnCard,
  } = pm;

  switch (paymentMethodTypeId) {
    case 1:
      return `${cardType || 'Account'} Ending\xa0in\xa0${last4}`;
    case 2:
      return 'Cash';
    case 3:
      return `${cardType || 'Account'} ${nameOnCard} Ending\xa0in\xa0${last4}`;
    default:
      return null;
  }
};

export const serviceText = (service: number) => {
  if (!service) return null;

  switch (service) {
    case 1:
      return 'The ' +
      /* EE_DISPLAY_NAME */'Everything Expedited Service' +
      ' payment is processed via your credit card with' +
      ' immediate verification and your purchase of the item is secured. Your purchase is' +
      ' prioritized and the processing begins immediately. Your purchase will be available' +
      ' for pickup or shipment sooner than the Everything Standard option. A ' +
      /* EE_DISPLAY_AMOUNT */'3.5%' +
      ' charge applies.' +
      ' You can opt out of the ' +
      /* EE_DISPLAY_NAME */'Everything Expedited Service' +
      ' by setting up ACH payment option' +
      ' in your profile settings.';
    case 3:
      return 'Everything Standard Service payment is processed via ACH Payments.' +
        ' We will not begin processing this item generally for 5-10 business days' +
        ' after the sale closes. If you would like to secure your purchase sooner,' +
        ' please choose the Everything Expedited Option.';
    default:
      return null;
  }
};
