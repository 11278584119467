// @flow
/* eslint-disable import/prefer-default-export */

import type { Tree } from '../types';

export const findSubTree = (tree: Array<Tree>,
  slug: number | string,
  lineage: Array<Tree> = [],
  depth: number = 0): Array<Tree> => {
  for (const branch of tree) { // eslint-disable-line
    // Make a copy of current category to work from
    const copy = Object.assign({}, branch);

    // Clear out the tree if depth is zero. This means we finished looking
    // in one tree and are onto the next L1 category.
    if (depth === 0) {
      lineage = [];
    }

    // Found the item we're looking for! Push it onto the tree.
    if (copy.fullSlugPath === slug) {
      // Strip children from anything below this level, we only want to render
      // this item's children, not its childrens children.
      copy.children = copy.children.map((children) => ({ ...children, children: [] }));

      lineage.push(copy);

      // Rebuild the nested structure, each element in the
      // tree array represents another category layer
      return [lineage.reduceRight((acc, curr) => {
        curr.children = [acc];
        return curr;
      })];
    } else if (copy.children.length !== 0) {
      const next = copy.children;

      // We only care about the children of the active category
      copy.children = [];

      // We're going deeper so push this category onto the stack
      lineage.push(copy);

      const found = findSubTree(next, slug, lineage, depth + 1);

      if (found.length !== 0) {
        return found;
      } else {
        lineage.pop();
      }
    }
  }

  // Return empty array if nothing was found
  return [];
};

export const findLeaf = (tree: Array<Tree>, slug: string): ?Object => {
  for (const leaf of tree) { // eslint-disable-line
    if (leaf.fullSlugPath === slug) {
      return leaf;
    }

    const found = findLeaf(leaf.children, slug);

    if (found != null) {
      return found;
    }
  }
};

export const stripEmpties = (tree: Array<Tree>, counts: Object, checked: ?string): Array<Tree> => {
  return tree.filter((item) => {
    if (item.children) {
      item.children = stripEmpties(item.children, counts, checked);
    }

    const itemCount = counts[item.id] || 0;
    const isSelected = checked && checked === item.fullSlugPath;

    return isSelected || itemCount !== 0;
  });
};
