// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import type { Node } from 'react';

type ColumnType<RowType> = {
  // A unique key for the column - used for React rendering
  key: string,
  // Header label for the column
  header?: ?string,
  // Class name for header
  headerClass?: string,
  // Render function for the cell contents
  cell: (RowType) => Node,
  // Class for the cell <td> element
  cellClass?: string,
  // Footer label for the column
  footer?: ?string,
  // Class name for footer
  footerClass?: string,
  // % width for column
  width?: string | number,
};

type Props<RowType> = {|
  className?: string,
  columns: $ReadOnlyArray<ColumnType<RowType>>,
  rows: $ReadOnlyArray<RowType>,
  rowKey: (RowType) => string,
  rowClassName?: (RowType) => ?string,
  modifiers?: string | $ReadOnlyArray<string>,
|};

export class RuledTable<RowType> extends Component<Props<RowType>> {
  maybeRenderHeader = () => {
    const { columns } = this.props;

    if (columns.some((column) => !!column.header)) {
      return (
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key} className={column.headerClass} style={{ width: column.width }}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
      );
    } else {
      return null;
    }
  };

  maybeRenderFooter = () => {
    const { columns } = this.props;

    if (columns.some((column) => !!column.footer)) {
      return (
        <tfoot>
          <tr>
            {columns.map((column) => (
              <td key={column.key} className={column.footerClass}>
                {column.footer}
              </td>
            ))}
          </tr>
        </tfoot>
      );
    } else {
      return null;
    }
  };

  renderBody = () => {
    const {
      columns, rows, rowKey, rowClassName,
    } = this.props;

    return (
      <tbody>
        {rows.map((row) => (
          <tr key={rowKey(row)} className={rowClassName ? rowClassName(row) : null}>
            {columns.map((column) => (
              <td key={column.key} className={column.cellClass}>
                {column.cell(row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  render = () => {
    const { modifiers } = this.props;
    let modifierClasses = null;

    if (Array.isArray(modifiers)) {
      modifierClasses = modifiers.map((m) => `ruled-table--${m}`).join(' ');
    } else if (modifiers) {
      modifierClasses = `ruled-table--${modifiers}`;
    }

    const classes = classNames('ruled-table', this.props.className, modifierClasses);

    return (
      <table className={classes}>
        {this.maybeRenderHeader()}
        {this.renderBody()}
        {this.maybeRenderFooter()}
      </table>
    );
  };
}
