/* globals LitlePayPage, Stripe */

const PARAM_MAPPINGS = {
  '': 'base',
  name: 'name',
  number: 'number',
  cvc: 'cvv',
  exp_year: 'expYear',
  exp_month: 'expMonth',
};

const VANTIV_ERROR_MESSAGES = {
  871: 'Invalid Card Number.',
  872: 'Invalid Card Number.',
  873: 'Invalid Card Number.',
  874: 'Invalid Card Number.',
  875: 'We are experiencing technical difficulties. Please try again later.',
  876: 'We are experiencing technical difficulties. Please try again later.',
  881: 'Invalid Card Security Code.',
  882: 'Invalid Card Security Code.',
  883: 'Invalid Card Security Code.',
};

function registerWithStripe(paymentMethod) {
  return new Promise((resolve, reject) => {
    Stripe.createToken(
      {
        name: paymentMethod.name.value,
        number: paymentMethod.number.value,
        cvc: paymentMethod.cvv.value,
        exp_month: paymentMethod.expMonth.value,
        exp_year: paymentMethod.expYear.value,
      },
      (_status, response) => {
        if (response.error) {
          // eslint-disable-next-line
          reject({ key: PARAM_MAPPINGS[response.error.param], message: response.error.message });
        } else {
          resolve({ token: response.id, last4: '' });
        }
      }
    );
  });
}

function onVantivSuccess(data) {
  const { lastFour, paypageRegistrationId } = data;
  return { token: paypageRegistrationId, last4: lastFour };
}

function onVantivFailure(data) {
  const message = VANTIV_ERROR_MESSAGES[data.response] || 'Unknown Error.';

  return {
    message,
    key: 'base',
  };
}

function onVantivTimeout() {
  return {
    key: 'base',
    message: 'We are experiencing technical difficulties. Please try again later.',
  };
}

function registerWithVantiv(_paymentMethod, paymentForm, { paypageId, paypageUrl }) {
  const requestId = Date.now();
  const params = {
    id: requestId,
    paypageId: paypageId,
    reportGroup: 'cc_registration',
    orderId: `ebth_reg_${requestId}`,
    url: paypageUrl,
  };
  const formInputs = {
    accountNum: paymentForm.querySelector('#vantiv-account-num'),
    cvv2: paymentForm.querySelector('#vantiv-cvv'),
    paypageRegistrationId: null,
    bin: null,
  };

  return new Promise((resolve, reject) => {
    return new LitlePayPage().sendToLitle(
      params,
      formInputs,
      (data) => resolve(onVantivSuccess(data)),
      (data) => reject(onVantivFailure(data)),
      () => reject(onVantivTimeout()),
      15000
    );
  });
}

const PaymentMethodTokenizer = {
  registerPaymentMethod(paymentMethod, paymentForm, options) {
    if (options.useVantiv) {
      return registerWithVantiv(paymentMethod, paymentForm, options);
    } else {
      return registerWithStripe(paymentMethod);
    }
  },
};

export default PaymentMethodTokenizer;
