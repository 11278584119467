// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import formatCurrency from '~/utils/formatCurrency';
import ModalContainer from '../containers/ModalContainer';

import type { Item } from '../types';

type Props = {
  item: Item,
};

class ModalBidIncrements extends Component<Props> {
  render() {
    const increments = [
      {
        lower: 1,
        upper: 9,
        increment: 1,
      },
      {
        lower: 10,
        upper: 24,
        increment: 2,
      },
      {
        lower: 25,
        upper: 99,
        increment: 5,
      },
      {
        lower: 100,
        upper: 249,
        increment: 10,
      },
      {
        lower: 250,
        upper: 999,
        increment: 25,
      },
      {
        lower: 1000,
        upper: 4999,
        increment: 50,
      },
      {
        lower: 5000,
        upper: 9999,
        increment: 100,
      },
      {
        lower: 10000,
        upper: 24999,
        increment: 250,
      },
      {
        lower: 25000,
        upper: 49999,
        increment: 500,
      },
      {
        lower: 50000,
        upper: 99999,
        increment: 1000,
      },
      {
        lower: 100000,
        upper: 100000000,
        increment: 2000,
      },
    ];

    const { item: { highBidAmount } } = this.props;

    return (
      <ModalContainer name="bidIncrements" {...this.props}>
        <table className="table table--striped">
          <thead>
            <tr>
              <th>Current Bid ({formatCurrency(highBidAmount)})</th>
              <th>Next Required Bid</th>
            </tr>
          </thead>
          <tbody>
            {increments.map((i) => {
              const isCurrentRow = highBidAmount >= i.lower && highBidAmount <= i.upper;

              const tableRowClass = classNames(
                {
                  'table__row--highlight': isCurrentRow,
                }
              );

              return (
                <tr key={i.increment} className={tableRowClass}>
                  <td>{formatCurrency(i.lower)}–{formatCurrency(i.upper)}</td>
                  <td>Current Bid +&nbsp;{formatCurrency(i.increment)}{isCurrentRow && ` = ${formatCurrency(highBidAmount + i.increment)}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </ModalContainer>
    );
  }
}

const mapStateToProps = ({ item }) => ({ ...item });

export default connect(mapStateToProps)(ModalBidIncrements);
