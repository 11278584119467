// @flow

import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import type { Location, RouterHistory, Match } from 'react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import { stringifyQuery, parseQuery } from '~/public/shared/utils/url';
import Layout from '../components/Layout';
import { Button, Icon, LoadingDots } from '~/public/shared/components';
import Dropdown, { DropdownToggle, DropdownContent } from '../components/Dropdown';
import { ItemHeader } from '../components/ItemHeader';
import { ItemListing } from '../components/ItemListing';
import ContractsQuery from '../queries/ContractsQuery';
import ItemsSearchQuery from '../queries/ItemsSearchQuery';

// $FlowFixMe
import { UserConsumer } from '~/shared/contexts/UserContext.tsx';

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
};

type State = {
  q: string,
  selectedContractIds: Array<string>,
};

const STATUS_COUNT_QUERY = gql`
  query sellerItemStatusCounts($contractIds: [ID!]) {
    sellerItemStatusCounts(contractIds: $contractIds) {
      processing
      preview
      live
      ended
      omitted
    }
  }
`;

type StatusFilter = {
  param: string,
  label: string,
  count: string,
  checked: boolean,
};

const STATUS_FILTERS: Array<StatusFilter> = [
  {
    param: 'processing', label: 'Processing', count: '?', checked: false,
  },
  {
    param: 'preview', label: 'Preview', count: '?', checked: false,
  },
  {
    param: 'live', label: 'Live', count: '?', checked: false,
  },
  {
    param: 'ended', label: 'Ended', count: '?', checked: false,
  },
  {
    param: 'omitted', label: 'Omitted', count: '?', checked: false,
  },
];

const SORT_MAP = {
  price_asc: {
    sortColumn: 'high_bid_amount',
    sortOrder: 'ASC',
  },
  price_desc: {
    sortColumn: 'high_bid_amount',
    sortOrder: 'DESC',
  },
  bids: {
    sortColumn: 'bids_count',
    sortOrder: 'DESC',
  },
  follows: {
    sortColumn: 'following_users_count',
    sortOrder: 'DESC',
  },
  starting: {
    sortColumn: 'sale_starts_at',
    sortOrder: 'ASC',
  },
  ending: {
    sortColumn: 'sale_ends_at',
    sortOrder: 'ASC',
  },
  newest: {
    sortColumn: 'created_at',
    sortOrder: 'DESC',
  },
  oldest: {
    sortColumn: 'created_at',
    sortOrder: 'ASC',
  },
};

class ItemsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const queryParams = parseQuery(this.props.location);
    this.state = {
      q: parseQuery(this.props.location).q || '',
      selectedContractIds: queryParams.contracts || [],
    };
  }

  componentDidMount() {
    document.title = 'Items | Seller Dashboard';
  }

  handleStatusFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { history, location } = this.props;
    const { value, checked } = e.target;
    const queryParams = parseQuery(location);
    const activeStatusFilters = new Set(queryParams.statuses);

    if (checked) {
      activeStatusFilters.add(value);
    } else {
      activeStatusFilters.delete(value);
    }

    queryParams.statuses = Array.from(activeStatusFilters);

    history.push(`?${stringifyQuery(queryParams)}`);
  }

  renderStatusFilter = () => {
    const { selectedContractIds } = this.state;
    const queryParams = parseQuery(this.props.location);
    const statusesQueryParam = queryParams.statuses || [];
    const activeStatuses = STATUS_FILTERS.filter((status) => (
      statusesQueryParam.includes(status.param)
    ));

    const labelText = () => {
      if (activeStatuses.length === 0 || activeStatuses.length === STATUS_FILTERS.length) {
        return 'All';
      }
      if (activeStatuses.length > 1) {
        return `(${activeStatuses.length})`;
      }
      return activeStatuses.map((s) => s.label).join(', ');
    };

    return (
      <Query
        query={STATUS_COUNT_QUERY}
        variables={{ contractIds: selectedContractIds }}
      >
        {({ data, loading, error }) => {
          if (loading) return <div>Loading...</div>;
          if (error) return <div>Error: {error.message}</div>;

          const filters = STATUS_FILTERS.map((filter) => {
            const checked = statusesQueryParam.includes(filter.param);
            let { count } = filter;

            if (data && data.sellerItemStatusCounts) {
              count = data.sellerItemStatusCounts[filter.param] || '0';
            }

            return {
              ...filter,
              count,
              checked,
            };
          });

          return (
            <Dropdown menuPositionLeft className="u-ml1">
              <DropdownToggle>
                <Button
                  buttonSize="sm"
                  buttonStyle={activeStatuses.length ? 'primary' : 'secondary'}
                  className="u-px1"
                >
                  <span className="u-font-weight-normal">Status:</span> {labelText()}
                  <Icon icon="arrow-down" className="btn__icon btn__icon--right" />
                </Button>
              </DropdownToggle>
              <DropdownContent>
                <ul className="items-filter u-mx1">
                  {filters.map((filter) => {
                    return (
                      <li key={filter.param} className="items-filter-item">
                        <input
                          id={`status-${filter.param}`}
                          type="checkbox"
                          className="items-filter-item__input"
                          onChange={this.handleStatusFilterChange}
                          value={filter.param}
                          checked={filter.checked}
                          disabled={filter.count === '0'}
                        />
                        <label htmlFor={`status-${filter.param}`} className="items-filter-item__label">
                          <span className="items-filter-item__count">{filter.count}</span>{filter.label}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </DropdownContent>
            </Dropdown>
          );
        }}
      </Query>
    );
  }

  handleContractFilterChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { history, location } = this.props;
    const { value, checked } = e.target;
    const queryParams = parseQuery(location);
    const activeContractFilters = new Set(queryParams.contracts || []);

    if (checked) {
      activeContractFilters.add(value);
    } else {
      activeContractFilters.delete(value);
    }

    queryParams.contracts = Array.from(activeContractFilters);
    this.setState({ selectedContractIds: Array.from(activeContractFilters) });

    history.push(`?${stringifyQuery(queryParams)}`);
  }

  renderContractFilter = () => {
    return (
      <ContractsQuery first={Infinity}>
        {({ contracts }) => {
          const query = parseQuery(this.props.location);
          const selectedContracts = query.contracts || [];
          const hasSelectedContracts = selectedContracts.length > 0;
          const count = contracts.length;
          const labelText = () => {
            if (selectedContracts.length === 0 || selectedContracts.length === count) {
              return 'All';
            }
            if (selectedContracts.length === 1) {
              return selectedContracts[0];
            } else {
              return `(${selectedContracts.length})`;
            }
          };

          return (
            <Dropdown menuPositionLeft>
              <DropdownToggle>
                <Button
                  buttonSize="sm"
                  buttonStyle={hasSelectedContracts ? 'primary' : 'secondary'}
                  className="u-px1"
                >
                  <span className="u-font-weight-normal">Contract:</span> {labelText()}
                  <Icon icon="arrow-down" className="btn__icon btn__icon--right" />
                </Button>
              </DropdownToggle>
              <DropdownContent maxHeight="200px">
                <ul className="items-filter u-mx1">
                  {
                    contracts.map((contract) => {
                      const {
                        id,
                        number,
                        itemsCount,
                      } = contract;

                      const checked = selectedContracts.includes(number);

                      return (
                        <li key={id} className="items-filter-item">
                          <input
                            id={`contract-${id}`}
                            type="checkbox"
                            className="items-filter-item__input"
                            value={number}
                            onChange={this.handleContractFilterChange}
                            checked={checked}
                            disabled={itemsCount === 0}
                          />
                          <label htmlFor={`contract-${id}`} className="items-filter-item__label">
                            <span className="items-filter-item__count">{itemsCount}</span>{number}
                          </label>
                        </li>
                      );
                    })
                  }
                </ul>
              </DropdownContent>
            </Dropdown>
          );
        }}
      </ContractsQuery>
    );
  }

  handleSearchChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { location } = this.props;
    const queryParams = parseQuery(location);
    const q = e.target.value || '';

    queryParams.q = q;

    if (!queryParams.q) {
      delete queryParams.q;
    }

    this.setState({ q });
    this.debouncedHistoryPush(queryParams);
  }

  debouncedHistoryPush = debounce((queryParams: {}) => {
    const { history } = this.props;
    history.push(`?${stringifyQuery(queryParams)}`);
  }, 1000)

  handleSortChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { history, location } = this.props;
    const queryParams = parseQuery(location);

    queryParams.sort = e.target.value;

    if (!queryParams.sort) {
      delete queryParams.sort;
    }

    history.push(`?${stringifyQuery(queryParams)}`);
  }

  render() {
    const gridStyle = '100px 3fr 2fr 1fr';
    const { location } = this.props;
    const search = parseQuery(location);
    const sortType = search.sort || 'newest';
    const sortParams = SORT_MAP[sortType];
    const variables = {
      statuses: search.statuses,
      contracts: search.contracts,
      query: search.q,
      sortColumn: sortParams.sortColumn,
      sortOrder: sortParams.sortOrder,
    };

    return (
      <UserConsumer>
        {({ user }) => (
          <Layout>
            <div className="u-flex u-flex-sb u-mb2 l-align-center">
              <h1>Items</h1>
              {user.authorizedSelfLister &&
                <Link to="/items/submitted">
                  <Button className="btn btn--primary">
                    View Submitted Items
                  </Button>
                </Link>
              }
            </div>
            <div className="row between-xs u-mb2">
              <div className="col-xs-12 col-sm-7 col-md-4 u-mt1 u-pr1">
                {this.renderContractFilter()}
                {this.renderStatusFilter()}
              </div>
              <div className="col-xs-12 col-sm-5 col-md-3 u-mt1">
                <input
                  type="search"
                  className="input input--sm input--borderless"
                  placeholder="Search"
                  aria-label="Search"
                  autoComplete="off"
                  value={this.state.q}
                  onChange={this.handleSearchChange}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 u-mt1">
                <div className="sort-select u-pull-right">
                  <label className="sort-select__label" htmlFor="items-sort">Sort By</label>
                  <select
                    id="items-sort"
                    aria-controls="items_grid"
                    className="sort-select__select"
                    value={sortType}
                    onChange={this.handleSortChange}
                  >
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="starting">Starting Soonest</option>
                    <option value="ending">Ending Soonest</option>
                    <option value="price_desc">Highest Bid</option>
                    <option value="price_asc">Lowest Bid</option>
                    <option value="bids">Most Bids</option>
                    <option value="follows">Most Followed</option>
                  </select>
                </div>
              </div>

            </div>

            <ItemsSearchQuery variables={variables}>
              {({
                items,
                loading,
                initialLoading,
                hasNextPage,
                loadMore,
              }) => {
                if (initialLoading) {
                  return (
                    <div className="u-mt8 u-text-center">
                      <LoadingDots />
                    </div>
                  );
                }

                return (
                  <>
                    <ItemHeader gridStyle={gridStyle} />

                    { items.length ?
                      <>
                        {items.map((item) => {
                          return (
                            <div key={item.id} className="expandable-listing">
                              <Link to={`${this.props.match.url}/${item.barcode}`} className="expandable-listing__link">
                                <ItemListing gridStyle={gridStyle} item={item} />
                              </Link>
                            </div>
                          );
                        })}
                        { hasNextPage &&
                          <div className="u-mt2 u-text-center">
                            <Button
                              buttonStyle="secondary"
                              inFlight={loading}
                              onClick={loadMore}
                            >
                              Show More
                            </Button>
                          </div>
                        }
                      </>
                      :
                      <div className="zero-state">
                        <h2>No Items</h2>
                        <p>There are no items available to show.</p>
                      </div>
                    }
                  </>
                );
              }}
            </ItemsSearchQuery>
          </Layout>
        )}
      </UserConsumer>
    );
  }
}

export { ItemsPage };
