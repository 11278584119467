
import React, { Component } from 'react';
import { flatMap, pick } from 'lodash';

import type { RadioOption } from '~/admin/shared/components/RadioInput';
import type { SelectOption } from '~/admin/shared/components/SelectInput';
import type { CheckboxOption } from '~/admin/shared/components/CheckboxGroupInput';
import type { MultiSelectOption } from '~/admin/shared/components/CreateableMultiSelectInput';
import TextInput from '~/admin/shared/components/TextInput';
import CreateableMultiSelectInput from '~/admin/shared/components/CreateableMultiSelectInput';
import MultiSelectInput from '~/admin/shared/components/MultiSelectInput';
import RadioInput from '~/admin/shared/components/RadioInput';
import CheckboxGroupInput from '~/admin/shared/components/CheckboxGroupInput';
import SelectInput from '~/admin/shared/components/SelectInput';

import ActionSvgIcon from '~/admin/shared/svgs/ActionSvgIcon';

type SelectOptions<T> = Array<SelectOption<T>>;

// type ItemPropertiesType = {
//   [string]: Array<ItemProperty>,
// };

type Props = {
  onChange: (properties: any) => void,
  showPropertiesNotice: boolean,
  properties: Array<any>,
  itemProperties: any,
  // errors: { [string]: string[] },
  errors: any
};

const optionFromVariant = (variant: any): MultiSelectOption<string> => (
  {
    value: variant.id,
    label: variant.name,
  }
);
const optionsFromVariants = (variants: Array<any>): Array<MultiSelectOption<string>> => {
  return variants.map(optionFromVariant);
};

const radioOptionsFromVariants = (variants: Array<any>): Array<RadioOption<string>> => {
  return variants.map((variant): RadioOption<string> => {
    return {
      label: variant.name,
      value: variant.id,
    };
  });
};

const checkboxOptionsFromVariants = (variants: Array<any>): Array<CheckboxOption<string>> => {
  return variants.map((variant): CheckboxOption<string> => {
    return {
      label: variant.name,
      value: variant.id,
    };
  });
};

const formatPropertiesForServer =
  (itemProperties: any) => (
    flatMap(itemProperties, (itemProperty, _key) => itemProperty).map((v) => pick(v, ['propertyId', 'type', 'value']))
  );

class Properties extends Component<Props> {
  static defaultProps = {
    showPropertiesNotice: false,
  }

  onChange = (itemProperties: any) => {
    this.props.onChange(formatPropertiesForServer(itemProperties));
  }

  handleOtherChange = (propertyId: string) => (value: string) => {
    this.onChange({
      ...this.props.itemProperties,
      [propertyId]: [{ propertyId, type: 'other', value }],
    });
  }

  handleMultiValueChange = (propertyId: string) => (values: Array<MultiSelectOption<string>>) => {
    this.onChange({
      ...this.props.itemProperties,
      [propertyId]: values.map((value): SelectOption<string> => ({ ...value, propertyId })),
    });
  }

  handleCheckboxChange = (propertyId: string) => (values: any) => {
    this.onChange({
      ...this.props.itemProperties,
      [propertyId]: values.map((value) => ({ propertyId, type: 'id', value })),
    });
  }

  handleRadioChange = (propertyId: string) => (value: string) => {
    this.onChange({
      ...this.props.itemProperties,
      [propertyId]: [{ propertyId, type: 'id', value }],
    });
  }

  handleSingleValueChange =
    (propertyId: string) => (
      result,
      { isNewOption }: { isNewOption: boolean },
    ) => {
      const type = isNewOption ? 'other' : 'id';
      const value = result && result.value;

      this.onChange({
        ...this.props.itemProperties,
        [propertyId]: [{ propertyId, type, value }],
      });
    }

  renderProperty = (property: any) => {
    const itemProperty: Array<any> = this.props.itemProperties[property.id];
    const standardProps = {
      errors: this.errorsFor(property),
      id: `property-${property.id}`,
      key: property.id,
      label: property.name,
    };
    let value = null;

    switch (property.fieldType) {
      case 'multi':
        if (itemProperty) {
          value = itemProperty.map((p: any): MultiSelectOption<string> => {
            // have to use the spread operator here to allow flow to preserve
            // propertyId and type.  Otherwise items creation is broken.
            return {
              ...p,
              value: p.value,
              label: p.value,
            };
          });
        } else {
          value = [];
        }

        return (
          <CreateableMultiSelectInput
            {...standardProps}
            options={optionsFromVariants(property.variants)}
            value={value}
            onChange={this.handleMultiValueChange(property.id)}
          />
        );
      case 'multi_fixed':
        if (itemProperty) {
          value = itemProperty.map((p: any) => {
            return { value: p.value, label: p.value };
          });
        } else {
          value = [];
        }

        return (
          <MultiSelectInput
            {...standardProps}
            options={optionsFromVariants(property.variants)}
            value={value}
            onChange={this.handleMultiValueChange(property.id)}
          />
        );

      case 'single_fixed':
      case 'single': {
        if (itemProperty) {
          [{ value }] = itemProperty;
        }

        const { variants } = property;
        let options: SelectOptions<string> = variants.map((variant): SelectOption<string> => (
          { label: variant.name, value: variant.id }
        ));

        if (value && itemProperty && itemProperty[0].type === 'other') {
          options = [
            ...options,
            { label: value, value },
          ];
        }

        return (
          <SelectInput
            allowCreate={property.fieldType === 'single'}
            {...standardProps}
            onChange={this.handleSingleValueChange(property.id)}
            options={options}
            value={value}
          />
        );
      }

      case 'radio':
        if (itemProperty) {
          [{ value }] = itemProperty;
        }

        return (
          <RadioInput
            {...standardProps}
            options={radioOptionsFromVariants(property.variants)}
            value={value}
            onChange={this.handleRadioChange(property.id)}
          />
        );

      case 'checkbox':
        if (itemProperty) {
          value = itemProperty.map((v) => v.value);
        } else {
          value = [];
        }

        return (
          <CheckboxGroupInput
            {...standardProps}
            options={checkboxOptionsFromVariants(property.variants)}
            value={value}
            onChange={this.handleCheckboxChange(property.id)}
          />
        );

      default:
        if (itemProperty) {
          [{ value }] = itemProperty;
        } else {
          value = '';
        }

        return (
          <TextInput
            {...standardProps}
            value={value}
            onChange={this.handleOtherChange(property.id)}
          />
        );
    }
  }

  errorsFor = (property: any) => {
    return this.props.errors[property.id];
  }

  render() {
    if (this.props.properties.length === 0) {
      return null;
    } else {
      return (
        <>
          {this.props.properties.map(this.renderProperty)}
        </>
      );
    }
  }
}

export default Properties;
