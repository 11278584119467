// @flow

import React, { type StatelessFunctionalComponent, type Node } from 'react';
import classNames from 'classnames';

export const Card: StatelessFunctionalComponent<{
  className?: string,
  children?: Node,
  header?: Node | string,
  headerClassName?: string,
  hasHero?: boolean,
  footer?: Node,
  wrapHeader?: boolean,
  contentClassName?: string,
}> = ({
  wrapHeader,
  header,
  headerClassName,
  hasHero,
  className,
  contentClassName,
  children,
  footer,
}) => {
  const cardClasses = classNames('card', className);
  const headerClasses = classNames(
    { card__header: !hasHero },
    { card__hero: hasHero },
    headerClassName
  );
  const contentClasses = classNames('card__content', contentClassName);

  return (
    <div className={cardClasses}>
      {header && wrapHeader ? <div className={headerClasses}>{header}</div> : header}
      <div className={contentClasses}>{children}</div>
      {footer && <div className="card__footer">{footer}</div>}
    </div>
  );
};
