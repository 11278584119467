// @flow

import React, { type StatelessFunctionalComponent } from 'react';

export const ContractHeader: StatelessFunctionalComponent<{
  gridStyle: string,
}> = ({
  gridStyle,
}) => {
  return (
    <div className="index-listing__heading-row u-mt2" style={{ gridTemplateColumns: gridStyle }}>
      <span className="index-listing__cell index-listing__cell--heading">ID</span>
      <span className="index-listing__cell index-listing__cell--heading" />
      <span className="index-listing__cell index-listing__cell--heading">Item Status</span>
      <span className="index-listing__cell index-listing__cell--heading">Signed Date</span>
      <span className="index-listing__cell index-listing__cell--heading">Bid Total</span>
    </div>
  );
};
