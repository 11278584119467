// @flow

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import PubNub from 'pubnub';

import HomePage from './pages/HomePage';
import SalePage from './pages/SalePage';
import SaleEvents from './utils/SaleEvents';

const client = new ApolloClient({
  assumeImmutableResults: true,
  link: createHttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
  }),
  cache: new InMemoryCache({ freezeResults: true }),
});

type Props = {
  pubnub: {
    subscribeKey: string,
    ssl: boolean,
    suppressLeaveEvents: boolean,
    heartbeatInterval: number,
    keepAlive: boolean,
  },
  iPad: boolean,
};

class Application extends Component<Props> {
  componentWillMount() {
    if (document && document.body) {
      document.body.classList.toggle('ipad', this.props.iPad);
    }
  }

  render() {
    const pubnub = new PubNub(this.props.pubnub);
    const saleEvents = new SaleEvents(pubnub);

    return (
      <ApolloProvider client={client}>
        <BrowserRouter>
          <div>
            <Route exact path="/auction-watch" component={HomePage} />
            <Switch>
              <Route
                path="/auction-watch/:saleId/items/:itemId"
                render={(props) => <SalePage {...props} saleEvents={saleEvents} />}
              />
              <Route
                path="/auction-watch/:saleId"
                render={(props) => <SalePage {...props} saleEvents={saleEvents} />}
              />
            </Switch>
          </div>
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default Application;
