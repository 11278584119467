// @flow
import { type Dispatch as ReduxDispatch } from 'redux';

export type SidebarAction = {
  id: string,
  open: boolean,
  type: string,
  sidebarType: string,
};

type ToggleSidebarProps = {
  id: string,
  open: boolean,
  sidebarType: string,
}

export const toggleSidebar = ({
  id,
  open,
  sidebarType,
}: ToggleSidebarProps) => (dispatch: ReduxDispatch) => {
  dispatch({
    open,
    id,
    sidebarType,
    type: 'SIDEBAR_TOGGLE',
  });
};

type ToggleItemDetailsProps = {
  pendingItemUploadId: string,
  sidebarType: string,
}

export const toggleUploadSidebar = ({
  pendingItemUploadId,
  sidebarType,
}: ToggleItemDetailsProps) => (dispatch: ReduxDispatch) => {
  dispatch({
    id: pendingItemUploadId,
    open: true,
    sidebarType,
    type: 'UPLOAD_DETAILS_SIDEBAR_TOGGLE',
  });
};
