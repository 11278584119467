// @flow

import React, { Component } from 'react';
import { includes, uniq, without } from 'lodash';
import type { Node } from 'react';
import classNames from 'classnames';
import basicInputWrapper from './basicInputWrapper';

export type CheckboxOption<T> = {
  label: string,
  value: T,
  disabled?: boolean,
}

type Props<T> = {
  id: string,
  className?: string,
  controlClassName?: string,
  labelClassName?: string,
  onChange: (Array<T>) => void,
  options: Array<CheckboxOption<T>>,
  value: Array<T>,
};

class CheckboxGroupInput<T> extends Component<Props<T>> {
  handleChange(event: SyntheticInputEvent<HTMLInputElement>, option: CheckboxOption<T>) {
    const { checked } = event.target;
    let { value } = this.props;

    if (checked) {
      value = uniq([...value, option.value]);
    } else {
      value = without(value, option.value);
    }

    this.props.onChange(value);
  }

  renderCheckboxOption(option: CheckboxOption<T>, index: number): Node {
    const classes = classNames(
      'custom-control',
      'custom-control--checkbox',
      this.props.controlClassName,
    );
    const labelClasses = classNames(
      'custom-control__label',
      this.props.labelClassName,
    );

    const key = `${this.props.id}__${index}`;
    const isSelected = includes(this.props.value, option.value);
    return (
      <label key={key} htmlFor={key} className={classes}>
        <input
          id={key}
          type="checkbox"
          name="checkbox"
          className="custom-control__input"
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => this.handleChange(e, option)}
          checked={isSelected}
          disabled={option.disabled}
        />
        <div className={labelClasses}>{option.label}</div>
      </label>
    );
  }

  render() {
    const classes = classNames(
      'form-control__input',
      'u-mb0',
      this.props.className,
    );

    const renderOption = (option: CheckboxOption<T>, index: number) => {
      return this.renderCheckboxOption(option, index);
    };

    const options: Array<Node> = this.props.options.map(renderOption);
    return (
      <div className={classes}>{options} </div>
    );
  }
}

export default basicInputWrapper(CheckboxGroupInput);
