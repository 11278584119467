// @flow

import { type Action } from '~/admin/shared/actions/analytics';

export type State = {
  sessionId: ?number,
};

const initialState: State = { sessionId: null };

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'CREATE_SESSION':
      return {
        ...state,
        sessionId: action.sessionId,
      };
    default:
      return state;
  }
};
