// @flow

import React, { Component } from 'react';

import type { ComponentType } from 'react';

import type { EstimateRequest } from '../types';

type Props = {
  slides: Array<ComponentType<*>>,
  changeActiveSlide: (number) => any,
  estimateRequest: EstimateRequest,
  onChange: (key: string, value: string) => any,
  activeSlide: number,
  onSubmit: () => any,
};

class QuestionCarouselForm extends Component<Props> {
  onSlideSubmit = (slideNumber: number) => {
    const maxSlide = this.props.slides.length - 1;
    if (slideNumber < maxSlide) {
      this.props.changeActiveSlide(slideNumber + 1);
    } else if (slideNumber === maxSlide) {
      this.props.onSubmit();
    }
  }

  render() {
    const { onChange, activeSlide, slides } = this.props;
    const SlideComponent = slides[activeSlide];

    return (
      <div className="value-estimator__body">
        <SlideComponent
          slideNumber={activeSlide}
          onChange={onChange}
          onSubmit={() => this.onSlideSubmit(activeSlide)}
        />
      </div>
    );
  }
}

export default QuestionCarouselForm;
