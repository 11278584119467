// @flow

import React, { Component } from 'react';

import QuestionSlide from './QuestionSlide';
import TextAreaInput from './TextAreaInput';
import { validateDescription } from '../util/validators';

import type { EstimateRequest } from '../types';

type Props = {
  estimateRequest: EstimateRequest,
  onChange: (string, string) => any,
  onSubmit: () => any,
};

type State = {
  errors: Array<string>
};

class DescriptionSlide extends Component<Props, State> {
  state = {
    errors: [],
  }

  onSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { valid, errors } = validateDescription(this.props.estimateRequest.description);
    if (valid) {
      this.props.onSubmit();
    }
    this.setState({ errors });
  }

  render() {
    return (
      <div>
        <TextAreaInput
          id="estimate_request_description"
          rows="4"
          value={this.props.estimateRequest.description}
          placeholder="Description"
          label="Can you tell us a little about the item you would like us to evaluate?"
          onChange={(value) => this.props.onChange('description', value)}
          autoFocus
          helperText="Please include as much information as you can, including size, material, origin, history, special markings, and any other noteworthy details."
          errors={this.state.errors}
        />
        <button
          onClick={this.onSubmit}
          className="btn btn--primary-important btn--lg value-estimator__slide-action"
          type="button"
        >
          Next Step
        </button>
      </div>
    );
  }
}

export default QuestionSlide(DescriptionSlide);
