// @flow

import React, { Fragment } from 'react';
import { type Dispatch as ReduxDispatch } from 'redux';

import { promiseDispatcher } from './common';
import { itemCatalogingSaved } from '../analytics';
import { displayNotice } from '../notice.js';

type SupportedTaskType = 'CATALOGING';

const messages = {
  CATALOGING: {
    success: (itemName: string) => `Item ${itemName} was saved.`,
    failure: (itemName: string) => `Item ${itemName} could not be saved.`,
  },
};

type ActionProps = {|
  taskType: SupportedTaskType,

  xhr: Promise<any>,

  itemId: ID,
  itemName: string,
|};

const onSuccess = ({ taskType, itemId, itemName }: ActionProps, dispatch: ReduxDispatch) => {
  switch (taskType) {
    case 'CATALOGING':
      dispatch(itemCatalogingSaved({ id: itemId }));
      break;
    // no default
  }

  dispatch(
    displayNotice({
      message: messages[taskType].success(itemName),
      type: 'success',
      autoDismiss: true,
    })
  );
};

const onFailure = ({ taskType, itemName }: ActionProps, dispatch: ReduxDispatch, e: Error) => {
  dispatch(
    displayNotice({
      message: (
        <Fragment>
          {messages[taskType].failure(itemName)}
          <br />
          {e.message}
        </Fragment>
      ),
      type: 'danger',
    })
  );
};

const saveItem = (params: ActionProps) => (dispatch: ReduxDispatch) => {
  const { xhr } = params;

  dispatch(promiseDispatcher('SAVE')({ xhr }));

  xhr.then(() => onSuccess(params, dispatch)).catch((e: Error) => onFailure(params, dispatch, e));
};

export const saveCatalogingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(saveItem({ ...params, taskType: 'CATALOGING' }));
};
