// @flow

import { findLastIndex } from 'lodash';
import type { ItemChange } from '../types';

const keyFor = (itemChange: ItemChange) => [
  itemChange.id,
  itemChange.highBidAmount,
  itemChange.aasmState,
].join('-');

class ActivityFeed {
  collection: Array<ItemChange>;

  limit: number;

  constructor({ limit }: { limit: number }) {
    this.limit = limit;
    this.collection = [];
  }

  add(newItemChange: ItemChange) {
    const key = keyFor(newItemChange);

    const index = findLastIndex(
      this.collection,
      (itemChange) => key === keyFor(itemChange)
    );

    this.collection = [
      ...this.collection.slice(0, index < 0 ? 0 : index),
      newItemChange,
      ...this.collection.slice(index + 1, this.collection.length),
    ].slice(0, this.limit);
  }
}

export { keyFor };
export default ActivityFeed;
