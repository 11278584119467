// @flow

import { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { get } from 'lodash';

const QUERY = gql`
  query sellerItemSearch($query: String, $statuses: [String!], $contracts: [String!], $startCursor: String, $sortColumn: String, $sortOrder: SearchSortDirection) {
    sellerItemSearch(query: $query, statuses: $statuses, contracts: $contracts, startCursor: $startCursor, sortColumn: $sortColumn, sortOrder: $sortOrder) {
      edges {
        node {
          id
          name
          barcode
          saleStartsAt
          saleEndsAt
          aasmState
          highBidAmount
          bidsCount
          saleId
          mainAttachment {
            imageUrl
            isInternal
          }
          quickpicAttachment {
            imageUrl
          }
          contract {
            number
            commissionRate
          }
          processingLocation {
            displayName
          }
          publicItemPath
          processStatus
          yankReasonDisplayName
          yankReasonDescription
          yankReasonExternalDisplayName
        }
      }

      totalCount

      pageInfo {
        endCursor
      }
    }
  }
`;

type Props = {
  items: Array<any>,
  initialLoading: boolean,
  loading: boolean,
  hasNextPage: boolean,
  loadMore: () => {},
  children: React.Node,
};

class ItemsSearchQuery extends Component<Props> {
  render() {
    const {
      items,
      initialLoading,
      loading,
      hasNextPage,
      loadMore,
    } = this.props;

    return this.props.children({
      items,
      initialLoading,
      loading,
      hasNextPage,
      loadMore,
    });
  }
}

const mapDataToProps = ({ data }) => {
  const {
    loading,
    error,
    fetchMore,
  } = data;

  const items = get(data, 'sellerItemSearch.edges', []).map((e) => e.node);
  const initialLoading = loading && items.length === 0;
  const totalCount = get(data, 'sellerItemSearch.totalCount', 0);
  const endCursor = get(data, 'sellerItemSearch.pageInfo.endCursor', null);
  const hasNextPage = items.length < totalCount;

  const loadMore = () => {
    fetchMore({
      variables: {
        startCursor: endCursor,
      },

      updateQuery: (previousResult, { fetchMoreResult }) => {
        const {
          sellerItemSearch: {
            pageInfo,
            __typename,
          },
        } = fetchMoreResult;

        const newEdges = [
          ...previousResult.sellerItemSearch.edges,
          ...fetchMoreResult.sellerItemSearch.edges,
        ];

        return {
          sellerItemSearch: {
            __typename,
            edges: newEdges,
            pageInfo,
            totalCount,
          },
        };
      },
    });
  };

  return {
    initialLoading,
    loading,
    error,
    hasNextPage,
    loadMore,
    items,
  };
};


const mapPropsToOptions = (props) => ({
  variables: {
    ...props.variables,
    startCursor: null,
  },
  notifyOnNetworkStatusChange: true,
});

export default graphql(QUERY, {
  options: mapPropsToOptions,
  props: mapDataToProps,
})(ItemsSearchQuery);
