// @flow

export type CatalogingState = {
  itemTimerEnabled: boolean,
  itemsCurated: {
    [string]: boolean;
  },
  items: {
    [string]: number;
  }
}

export const SET_TIMER_ENABLED = 'SET_TIMER_ENABLED';
export type SetTimerAction = {
  type: 'SET_TIMER_ENABLED',
  payload: boolean,
};

// Id here is the id of the item
export type SetItemTimerPayload = {
  id: string,
  time: number
}

export const SET_ITEM_TIMER = 'SET_ITEM_TIMER';
export type SetItemTimerAction = {
  type: 'SET_ITEM_TIMER',
  payload: SetItemTimerPayload,
};

export const SET_SELECTED_ITEM_CURATED = 'SET_SELECTED_ITEM_CURATED';
export type SetItemCuratedPayload = {
  id: string,
  lowValueProcessing: boolean
}

export type setSelectedItemCuratedAction = {
  type: 'SET_SELECTED_ITEM_CURATED',
  payload: {
    item: {
      id: string,
      lowValueProcessing: boolean,
    },
  },
};

export type Actions =
  SetTimerAction | SetItemTimerAction | setSelectedItemCuratedAction;

export const setSelectedItemCurated = (payload: SetItemCuratedPayload): setSelectedItemCuratedAction => (
  {
    type: SET_SELECTED_ITEM_CURATED,
    payload: {
      item: payload,
    },
  }
);

export const setTimerEnabled = (payload: boolean): SetTimerAction => (
  {
    type: SET_TIMER_ENABLED,
    payload,
  }
);

export const setItemTimer = (payload: SetItemTimerPayload): SetItemTimerAction => (
  {
    type: SET_ITEM_TIMER,
    payload,
  }
);
