// @flow
/* eslint-disable import/prefer-default-export */

import type { Dispatch } from 'redux';
import search from '../utils/search';
import type { ItemChange } from '../types';

// ------------------------- RESTORE

export const restore = (state: Object) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: 'RESTORE', state });
  };
};

// ------------------------- PARAMS

export const setParams = (params: Object) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: 'SET_PARAMS', params });
  };
};

// ------------------------- ITEMS API

// Takes parameters and formats them for the items v1 search API
function formatSearchParams(frozenComponents, params) {
  const searchParams = {};

  Object.keys(params).forEach((key) => {
    if (frozenComponents.includes(key)) {
      searchParams[key] = params[key];
      return;
    }

    const value = params[key];

    // must check type this way since value is not a primitive String
    if (typeof value === 'string' && key !== 'q') {
      searchParams[key] = value.split(',');
      return;
    }

    searchParams[key] = value;
  });

  return searchParams;
}

export const fetchItems = (params: Object) => {
  // Which params are not to be edited
  const frozenComponents = [
    'status',
    'miles',
    'zip_code',
    'sort',
    'delivery_options',
    'location',
    'path',
    'sale_id',
    'category_slug',
    'page',
    'seller_id',
  ];

  return async (dispatch: Dispatch, getState: () => Object) => {
    const itemsRequestId = Date.now();

    dispatch({ type: 'FETCH_ITEMS_REQUEST', itemsRequestId });

    const searchParams = formatSearchParams(frozenComponents, params);

    try {
      const results = await search(searchParams);
      const currentRequestId = getState().items.itemsRequestId;

      if (itemsRequestId === currentRequestId) {
        const analytics = ['page'];
        const { pathParams } = getState().items.params;
        const viewingCategory = !pathParams.sale_slug && pathParams.category_slug;

        if (viewingCategory) {
          analytics.push('Viewed Product Category');
        }

        dispatch({
          type: 'FETCH_ITEMS_SUCCESS',
          results,
          meta: {
            cache: true,
            analytics,
          },
        });
      } else {
        throw Error('Request is stale');
      }
    } catch (error) {
      dispatch({
        type: 'FETCH_ITEMS_FAILURE',
        error: error.toString(),
      });
    }
  };
};

// ------------------------- UPDATE ITEM (PUBNUB)

export const updateItem = (itemChange: ItemChange) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'UPDATE_ITEM',
      ...itemChange,
      meta: { cache: true },
    });
  };
};

// ------------------------- FOLLOW/UNFOLLOW ITEMS

const followItemRequest = () => ({ type: 'FOLLOW_ITEM_REQUEST' });

const followItemSuccess = (id) => ({
  id,
  type: 'FOLLOW_ITEM_SUCCESS',
  meta: { analytics: ['Item Followed'] },
});

const followItemUnsuccessful = (id) => ({ id, type: 'FOLLOW_ITEM_UNSUCCESSFUL' });

const followItemFailure = (flashMessage) => ({ flashMessage, type: 'FOLLOW_ITEM_FAILURE' });

const unfollowItemRequest = () => ({ type: 'UNFOLLOW_ITEM_REQUEST' });

const unfollowItemSuccess = (id) => ({ id, type: 'UNFOLLOW_ITEM_SUCCESS' });

const unfollowItemFailure = (flashMessage) => ({ flashMessage, type: 'UNFOLLOW_ITEM_FAILURE' });

export const loadFollowedItems = (followedItems: Array<number>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      followedItems,
      type: 'LOAD_FOLLOWED_ITEMS',
    });
  };
};

const followItem = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(followItemRequest());

    try {
      const response = await fetch(`/api/v1/items/${id}/follow`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token token=7a2ab11cfe70777231c82cf4325c6e96',
        },
      });

      if (response.ok) {
        if (window.appboy) {
          window.appboy.logCustomEvent('prime-for-push');
        }
        dispatch(followItemSuccess(id));
      } else if (response.status === 401) {
        dispatch(followItemUnsuccessful(id)); // User is logged out
      } else {
        dispatch(followItemFailure('There was a problem following this item.'));
      }
    } catch (error) {
      dispatch(followItemFailure('There was a problem following this item.'));
    }
  };
};

const unfollowItem = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(unfollowItemRequest());

    try {
      const response = await fetch(`/api/v1/items/${id}/follow`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token token=7a2ab11cfe70777231c82cf4325c6e96',
        },
      });

      if (response.ok) {
        dispatch(unfollowItemSuccess(id));
      } else if (response.status === 406) {
        dispatch(unfollowItemFailure('You cannot unfollow items you are bidding on.'));
      } else {
        dispatch(unfollowItemFailure('There was a problem unfollowing this item.'));
      }
    } catch (error) {
      dispatch(followItemFailure('There was a problem unfollowing this item.'));
    }
  };
};

export const followItemIntentDisplayed = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: 'FOLLOW_ITEM_INTENT_DISPLAYED' });
  };
};

export const followItemToggle = (id: number) => {
  return async (dispatch: Dispatch, getState: Function) => {
    const willFollow = getState().items.followedItems.includes(id) === false;

    if (willFollow) {
      dispatch(followItem(id));
    } else {
      dispatch(unfollowItem(id));
    }
  };
};

// ------------------------- MODALS

export const openFilterModal = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: 'OPEN_FILTER_MODAL' });
  }
);

export const closeFilterModal = () => (
  (dispatch: Dispatch) => {
    dispatch({ type: 'CLOSE_FILTER_MODAL' });
  }
);

// ------------------------- FLASH MESSAGES

export const flashMessageDisplayed = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: 'FLASH_MESSAGE_DISPLAYED' });
  };
};

// ------------------------- FOLLOW SEARCHES

const followSearchRequest = () => (
  {
    type: 'FOLLOW_SEARCH_REQUEST',
  }
);

const followSearchSuccess = (savedSearches) => (
  {
    savedSearches,
    type: 'FOLLOW_SEARCH_SUCCESS',
  }
);

const unFollowSearchFailure = (error) => (
  {
    error,
    type: 'UNFOLLOW_SEARCH_FAILURE',
  }
);

const unFollowSearchRequest = () => (
  {
    type: 'UNFOLLOW_SEARCH_REQUEST',
  }
);

const unFollowSearchSuccess = (savedSearches) => (
  {
    savedSearches,
    type: 'UNFOLLOW_SEARCH_SUCCESS',
  }
);

const followSearchFailure = (error) => (
  {
    error,
    type: 'FOLLOW_SEARCH_FAILURE',
  }
);

const followSearchLoggedOutFailure = (params) => (
  {
    params,
    type: 'FOLLOW_SEARCH_LOGGED_OUT_FAILURE',
  }
);

export const loadSavedSearches = (savedSearches: Array<any>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      savedSearches,
      type: 'LOAD_SAVED_SEARCHES',
    });
  };
};

export const followSearch = (params: Object) => {
  return async (dispatch: Dispatch) => {
    dispatch(followSearchRequest());

    try {
      const response = await fetch('/api/v1/item_searches', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ search: params }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: 'Token token=7a2ab11cfe70777231c82cf4325c6e96',
        },
      });

      if (response.ok) {
        const json = await response.json();
        if (window.analytics) {
          window.analytics.track('Saved Search Added', {
            saved_search_id: json.id,
            context: 'search_page',
            query: params,
          });
        }
        dispatch(followSearchSuccess(json.savedSearches));
      } else if (response.status === 401) {
        dispatch(followSearchLoggedOutFailure(JSON.stringify(params)));
      } else {
        const json = await response.json();
        dispatch(followSearchFailure(json.error));
      }
    } catch (ex) {
      dispatch(followSearchFailure(ex.toString()));
    }
  };
};

export const unFollowSearch = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch(unFollowSearchRequest());

    try {
      const response = await fetch(`/api/v1/item_searches/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: 'Token token=7a2ab11cfe70777231c82cf4325c6e96',
        },
      });

      if (response.ok) {
        const json = await response.json();
        if (window.analytics) {
          window.analytics.track('Saved Search Deleted', {
            saved_search_id: id,
            context: 'search_page',
          });
        }
        dispatch(unFollowSearchSuccess(json.savedSearches));
      } else {
        const json = await response.json();
        dispatch(unFollowSearchFailure(json.error));
      }
    } catch (ex) {
      dispatch(unFollowSearchFailure(ex.toString()));
    }
  };
};

// Clear category selection
export const clearCategory = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: 'CLEAR_CATEGORY' });
  };
};
