// @flow

import React, { Component } from 'react';
import { Modal } from './Modal';

import { Button } from '../Button';

type Props = {
  item: ?{
    yankReason: string | null,
    aasmState: string,
  },
  onResult: (boolean) => any,
};

type State = {
  isOpen: boolean,
};

const getModalParent = (): HTMLElement => ((document.querySelector('.js-modal-parent'): any): HTMLElement);

class ItemYankedModal extends Component<Props, State> {
  state = {
    isOpen: true,
  };

  confirm = () => {
    this.props.onResult(true);
    this.setState({ isOpen: false });
  };

  reject = () => {
    this.props.onResult(false);
    this.setState({ isOpen: false });
  };

  render() {
    const { item } = this.props;
    if (!item) {
      return null;
    }
    // voided and held items will have a 'yank' reason.
    // so if its voided, its not yanked
    if (['voided', 'held'].includes(item.aasmState)) {
      return null;
    }

    if (!item.yankReason) {
      return null;
    }

    const confirmMessage = `This item has been yanked (${item.yankReason ||
      'No reason'}). Are you sure you want to continue?`;
    return (
      <div className="js-modal-parent">
        <Modal parentSelector={getModalParent} isOpen={this.state.isOpen}>
          <div className="modal-box">
            <div className="u-text-center">
              <h2>{confirmMessage}</h2>
              <Button
                buttonStyle="success"
                size="jumbo"
                className="qa-modal-confirm u-ml1 u-mr1"
                onClick={this.confirm}
              >
                Yes
              </Button>
              <Button
                buttonStyle="secondary-inverse"
                size="jumbo"
                className="qa-modal-reject u-ml1 u-mr1"
                onClick={this.reject}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export { ItemYankedModal };
