// @flow

import React, { Component } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Spinner } from '~/admin/shared/components';
import PhotoGrid from '../components/PhotoGrid';
import type { AttachmentType, ConnectionType } from '~/admin/photo_editing/types';

const SortablePhotoGrid = SortableContainer(
  ({
    photos, deletePhoto, editPhoto, isReorderPhotosInFlight, itemId, photosUploading,
  }) => (
    <div className="l-content">
      <div className="l-main-content">
        <header className="section-header">
          {isReorderPhotosInFlight && (
            <div className="section-header__detail">
              <Spinner className="u-pull-right" label="Reordering..." />
            </div>
          )}
        </header>

        <PhotoGrid
          itemId={itemId}
          photos={photos}
          editPhoto={editPhoto}
          deletePhoto={deletePhoto}
          photosUploading={photosUploading}
          showUpload
        />
      </div>
    </div>
  )
);

type Props = {
  itemId: string,
  attachments: ConnectionType<AttachmentType>,
  isReorderPhotosInFlight: boolean,
  deletePhoto: (photoId: ID) => any,
  editPhoto: (photoId: ID) => any,
  reorderPhotos: (reorderedAttachments: ConnectionType<AttachmentType>) => any,
  photosUploading: (boolean) => any,
};

class PhotoGridContainer extends Component<Props> {
  handleSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    if (oldIndex === newIndex) return;

    const { attachments, reorderPhotos } = this.props;
    const reorderedEdges = arrayMove(attachments.edges, oldIndex, newIndex);
    reorderPhotos({ ...attachments, edges: reorderedEdges });
  };

  render() {
    const {
      attachments, deletePhoto, editPhoto, isReorderPhotosInFlight, itemId, photosUploading,
    } = this.props;

    let photos = [];
    if (attachments && attachments.edges) {
      photos = attachments.edges.map((edge) => edge.node);
    }

    return (
      <SortablePhotoGrid
        itemId={itemId}
        photos={photos}
        deletePhoto={deletePhoto}
        editPhoto={editPhoto}
        isReorderPhotosInFlight={isReorderPhotosInFlight}
        onSortEnd={this.handleSortEnd}
        photosUploading={photosUploading}
        axis="xy"
        helperClass="is-dragged"
        useDragHandle
      />
    );
  }
}

export default PhotoGridContainer;
