// @flow
import React, { Component } from 'react';

import { Button, InputField, SelectField } from '~/public/shared/components';
import AddressHelper from '~/public/shared/utils/AddressHelper';
import UpdateContractPayableToMutation from '../mutations/UpdateContractPayableToMutation';

import type { PayableToAddress } from '../types';
import type { ContractPayableToParams } from '../mutations/UpdateContractPayableToMutation';

type FieldErrors = {
  payable_to_street: string[],
  payable_to_city: string[],
  payable_to_state: string[],
  payable_to_zip: string[],
  payable_to_country: string[],
}

type Props = {
  contractId: string,
  payableToAddress: PayableToAddress,
  redirectToPath: string,
}

type State = {
  address: PayableToAddress,
  errors: FieldErrors,
  savingAddress: boolean
}

class PayableToFormContainer extends Component<Props, State> {
  state = {
    address: this.props.payableToAddress,
    savingAddress: false,
    errors: {
      payable_to_street: [],
      payable_to_city: [],
      payable_to_state: [],
      payable_to_zip: [],
      payable_to_country: [],
    },
  };

  handleChange = (key: string) => (value: any) => {
    this.setState({
      address: {
        ...this.state.address,
        [key]: value,
      },
    });
  }

  handleCountryChange = (country: string) => {
    this.setState({
      address: {
        ...this.state.address,
        payableToCountry: country,
        payableToState: '',
      },
    });
  }

  handleSubmit = (updatePayableTo: (ContractPayableToParams) => any) => (
    async (e: SyntheticEvent<HTMLButtonElement>) => {
      e.preventDefault();
      this.setState({
        savingAddress: true,
      });

      try {
        await updatePayableTo({
          contractId: this.props.contractId,
          payableToAddress: this.state.address,
        });
        window.location = this.props.redirectToPath;
      } catch (error) {
        const fieldsErrors = error.graphQLErrors[0].extensions.fieldErrors;
        this.setState({
          errors: fieldsErrors,
          savingAddress: false,
        });
      }
    }
  )

  renderStateInput = () => {
    const { address, errors } = this.state;

    if (address.payableToCountry === 'US') {
      return (
        <SelectField
          label="State"
          name="address_state"
          autocomplete="billing address-level1"
          required
          value={address.payableToState}
          errors={errors.payable_to_state}
          options={AddressHelper.STATE_OPTIONS}
          onChange={this.handleChange('payableToState')}
        />
      );
    } else {
      return (
        <InputField
          label="State or Province"
          name="address_state"
          autocomplete="billing address-level1"
          maxLength={200}
          required
          value={address.payableToState}
          errors={errors.payable_to_state}
          onChange={this.handleChange('payableToState')}
        />
      );
    }
  }

  renderPayableToAddressForm = (updatePayableTo: any) => {
    const { address, errors } = this.state;

    return (
      <form>
        <div className="l-form-grid u-mt2 u-width-constrain">
          <div>
            <div className="l-form-grid__row">
              <div className="l-form-grid__item">
                <InputField
                  label="Attention/Company (optional)"
                  name="address_name"
                  autocomplete="billing name"
                  maxLength={40}
                  value={address.payableToAttention || ''}
                  onChange={this.handleChange('payableToAttention')}
                />
              </div>
            </div>
            <div className="l-form-grid__row">
              <div className="l-form-grid__item l-form-grid__item--6-col">
                <InputField
                  label="Address"
                  name="address_line1"
                  autocomplete="billing address-line1"
                  maxLength={50}
                  value={address.payableToStreet}
                  errors={errors.payable_to_street}
                  onChange={this.handleChange('payableToStreet')}
                />
              </div>
              <div className="l-form-grid__item l-form-grid__item--6-col">
                <InputField
                  label="Address Line 2 (optional)"
                  name="address_line2"
                  autocomplete="billing address-line2"
                  maxLength={50}
                  value={address.payableToFloorSuite || ''}
                  onChange={this.handleChange('payableToFloorSuite')}
                />
              </div>
            </div>

            <div className="l-form-grid__row">
              <div className="l-form-grid__item">
                <InputField
                  label="City"
                  name="address_city"
                  autocomplete="billing address-level2"
                  maxLength={200}
                  value={address.payableToCity}
                  errors={errors.payable_to_city}
                  onChange={this.handleChange('payableToCity')}
                />
              </div>
            </div>

            <div className="l-form-grid__row">
              <div className="l-form-grid__item l-form-grid__item--6-col">
                <SelectField
                  label="Country"
                  name="address_country"
                  autocomplete="billing country"
                  value={address.payableToCountry}
                  errors={errors.payable_to_country}
                  options={AddressHelper.COUNTRY_OPTIONS}
                  onChange={this.handleCountryChange}
                />
              </div>
              <div className="l-form-grid__item l-form-grid__item--3-col">
                {this.renderStateInput()}
              </div>
              <div className="l-form-grid__item l-form-grid__item--3-col">
                <InputField
                  label="Zip Code"
                  name="address_zip"
                  autocomplete="billing postal-code"
                  maxLength={40}
                  value={address.payableToZip}
                  errors={errors.payable_to_zip}
                  onChange={this.handleChange('payableToZip')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="u-mt4">
          <Button
            type="submit"
            id="qa-submit"
            inFlight={this.state.savingAddress}
            onClick={this.handleSubmit(updatePayableTo)}
            buttonStyle="primary"
          >
            Update Payment Address
          </Button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <UpdateContractPayableToMutation>
        {
          (updatePayableTo) => this.renderPayableToAddressForm(updatePayableTo)
        }
      </UpdateContractPayableToMutation>
    );
  }
}

export default PayableToFormContainer;
