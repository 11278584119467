// @flow

import Queue from 'simple-promise-queue';
import { EventEmitter } from 'fbemitter';

import type { Photo } from '../types';

type PhotoUploaderOptions = {
  uploadFn: (File) => any,
};

type Emitter = {
  +addListener: (string, Function) => void,
  +emit: (string, Photo | Error, Object | void) => void,
}

type TaskQueue = {
  pushTask: ((Function, Function) => any) => any,
};

export default class PhotoUploadQueue {
  emitter: Emitter

  uploadFn: (File) => any

  uploadQueue: TaskQueue

  dataUriQueue: TaskQueue

  constructor(opts: PhotoUploaderOptions) {
    this.emitter = new EventEmitter();
    this.uploadFn = opts.uploadFn;
    this.uploadQueue = new Queue({ autoStart: true, concurrency: 2 });
    this.dataUriQueue = new Queue({ autoStart: true, concurrency: 2 });
  }

  // since we're loading images into memory we only want to process 2 at a time
  loadImageDataUrl = (file: File): Promise<ArrayBuffer | string> => {
    const task = (resolve) => {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        resolve(fileReader.result);
      });
      fileReader.readAsDataURL(file);
    };

    return this.dataUriQueue.pushTask(task);
  };

  performFileUpload = async (photo: Photo): Promise<{ uploadKey: string }> => {
    const task = async (resolve, reject) => {
      try {
        const result = await this.uploadFn(photo.file);
        resolve(result);
      } catch (e) {
        reject(e);
      }
    };
    return this.uploadQueue.pushTask(task);
  }

  enqueue = async (photos: Array<Photo>) => {
    const processingPromises = photos.map(async (photo) => {
      try {
        this.loadImageDataUrl(photo.file).then((imageDataUrl) => {
          this.emitter.emit('update', photo, { imageUrl: imageDataUrl });
        });
        // read in the images as data strings here so we can preview/show them
        // on screen before actually uploading

        this.emitter.emit('update', photo, { status: 'UPLOADING' });
        const { uploadKey } = await this.performFileUpload(photo);
        this.emitter.emit('update', photo, { uploadKey, status: 'SUCCESS' });
      } catch (e) {
        this.emitter.emit('error', e);
        this.emitter.emit('update', photo, { status: 'UPLOAD_FAILURE' });
      }
    });

    await Promise.all(processingPromises);
  }

  on = (event: string, callback: Function) => {
    this.emitter.addListener(event, callback);
  }
}
