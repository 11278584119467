// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FilterWrapper from './FilterWrapper';
import FilterMultiSelectColor from './FilterMultiSelectColor';
import FilterMultiSelectItem from './FilterMultiSelectItem';
import { nextAppliedParams, paramsToUrl } from '../utils/url';
import type { Filter } from '../types';

type Props = {
  filter: Filter,
  isModalContext: boolean,
  appliedParams: Object,
  history: Object,
}

type State = {
  isTruncated: boolean,
}

const COLOR = 'color_hex';
const MAX_OPTIONS_BEFORE_TRUNCATION = 9;
const OPTIONS_SHOWN_WHEN_TRUNCATED = 6;

class FilterMultiSelect extends Component<Props, State> {
  state = {
    isTruncated: true,
  }

  handleTruncationToggle = (e: SyntheticEvent<>) => {
    e.preventDefault();
    this.setState({
      isTruncated: !this.state.isTruncated,
    });
  }

  render() {
    const { filter, isModalContext, appliedParams } = this.props;
    const {
      component,
      data,
      name,
      query,
      filterType,
    } = filter;
    const { isTruncated } = this.state;

    // If it's a property, it should look inside the `properties` key in
    // `appliedParams`. Otherwise, just use `appliedParams`.
    let params = appliedParams;
    if (filterType === 'property') {
      params = appliedParams.props;
    }

    // Define the checked based on the params
    let checked = (params[query]) ? params[query] : [];
    if (typeof checked === 'string') {
      checked = [checked];
    }

    // No options, do not render
    if (data.length === 0) {
      return null;
    }

    // Only truncate if 10 or more options
    const overTruncationLimit = data.length > MAX_OPTIONS_BEFORE_TRUNCATION;
    // We won't truncate if an option within the filter is selected
    const hasNoSelectedOptions = checked.length === 0;
    // We won't truncate color filters
    const isNotColorFilter = component !== COLOR;
    const willTruncate = overTruncationLimit && hasNoSelectedOptions && isNotColorFilter;

    // When truncated, only Show first 5 options
    const visibleOptions = willTruncate && isTruncated ?
      data.slice(0, OPTIONS_SHOWN_WHEN_TRUNCATED - 1)
      : data;

    return (
      <FilterWrapper title={name}>
        <ul className="items-filter">
          {
            visibleOptions.map((option) => {
              const { history } = this.props;
              const optionVal = (option.slug) ? option.slug : option.id;

              // Figure out the next params if this were selected
              const nextParams = nextAppliedParams(appliedParams, (current) => {
                let p = current;
                if (filterType === 'property') {
                  p = { ...current.props };
                }

                if (checked.includes(optionVal)) {
                  // It's checked, so the next state would have it removed
                  if (typeof params[query] === 'string') {
                    p[query] = [params[query]];
                  }
                  p[query] = p[query].filter((el) => el !== optionVal);
                  if (p[query].length === 0) {
                    delete p[query];
                  }
                } else {
                  // It's not checked, so the next state would have it added
                  if (typeof p[query] === 'string') {
                    p[query] = [p[query]];
                  } else if (!params[query]) {
                    // Initialize the array if it doesn't have other values
                    p[query] = [];
                  }

                  p[query].push(optionVal);
                }

                if (filterType === 'property') {
                  current.props = p;
                  return current;
                }

                return p;
              });
              nextParams.page = 1;
              const url = paramsToUrl(nextParams);

              const callback = () => {
                history.push(url);
              };

              switch (component) {
                case COLOR:
                  return (
                    <FilterMultiSelectColor
                      key={option.id}
                      option={option}
                      component={component}
                      checked={checked.includes(optionVal)}
                      onChange={callback}
                    />
                  );
                default:
                  return (
                    <FilterMultiSelectItem
                      key={option.id}
                      option={option}
                      component={component}
                      checked={checked.includes(optionVal)}
                      onChange={callback}
                      isModalContext={isModalContext}
                      linkLocation={url}
                    />
                  );
              }
            })
          }
          {willTruncate &&
            <li>
              <button
                onClick={this.handleTruncationToggle}
                className="items-filter__truncator-action"
                title={`Show ${isTruncated ? 'more' : 'fewer'} options`}
                type="button"
              >
                {isTruncated ? '+ Show More' : '- Show Less'}
              </button>
            </li>
          }
        </ul>
      </FilterWrapper>
    );
  }
}

export default withRouter(FilterMultiSelect);
