/* eslint-disable import/prefer-default-export */
import hash from 'object-hash';

// Cache state using params as a key
export const saveState = (params, state) => {
  try {
    const key = hash(params);
    const serialized = JSON.stringify(state);
    sessionStorage.setItem(`ebth.items.${key}`, serialized);
  } catch (_error) {
    // ¯\_(ツ)_/¯
  }
};

// Retreive a cached state via params
export const loadState = (params) => {
  try {
    const key = hash(params);
    const state = sessionStorage.getItem(`ebth.route.${key}`);

    if (state === null) {
      return undefined;
    }

    return JSON.parse(state);
  } catch (_error) {
    return undefined;
  }
};
