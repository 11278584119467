// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Badge } from '~/public/shared/components';

const STATUS_COUNT_QUERY = gql`
  query sellerItemStatusCounts($contractId: ID) {
    sellerItemStatusCounts(contractId: $contractId) {
      processing
      preview
      live
      ended
    }
  }
`;

export const ContractStatusBadge: StatelessFunctionalComponent<{
  contractId: number,
  aasmState: string,
}> = ({
  contractId,
  aasmState,
}) => {
  if (aasmState === 'cancelled') {
    return <Badge status="negative">Cancelled</Badge>;
  }

  return (
    <Query query={STATUS_COUNT_QUERY} variables={{ contractId: contractId }}>
      {({ loading, error, data: statusCounts }) => {
        if (loading || error) return null;

        const counts = { ...statusCounts.sellerItemStatusCounts };
        const sum = counts.processing + counts.preview + counts.live + counts.ended;

        if (!sum) return null;

        let badgeStyle;
        let badgeText;

        if (counts.ended === sum) {
          badgeText = 'Ended';
        } else if (counts.live + counts.ended) {
          badgeStyle = 'positive';
          badgeText = 'Live';
        } else {
          badgeStyle = 'neutral';
          badgeText = 'Processing';
        }

        return (
          <Badge status={badgeStyle}>{badgeText}</Badge>
        );
      }}
    </Query>
  );
};
