// @flow

import React, { Component } from 'react';

import QuestionSlide from './QuestionSlide';
import TextInput from './TextInput';
import { validateZip } from '../util/validators';

import type { EstimateRequest } from '../types';

type Props = {
  estimateRequest: EstimateRequest,
  onChange: (string, string) => any,
  onSubmit: () => any,
};

type State = {
  errors: Array<string>
};

class ZipSlide extends Component<Props, State> {
  state = {
    errors: [],
  }

  onSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { valid, errors } = validateZip(this.props.estimateRequest.zip);
    if (valid) {
      this.props.onSubmit();
    }
    this.setState({ errors });
  }

  render() {
    return (
      <div>
        <TextInput
          id="estimate_request_zip"
          value={this.props.estimateRequest.zip}
          placeholder="e.g. 90210"
          label="What is your zip code?"
          onChange={(value) => this.props.onChange('zip', value)}
          errors={this.state.errors}
          autoFocus
          type="tel"
        />
        <button
          onClick={this.onSubmit}
          className="btn btn--primary-important btn--lg value-estimator__slide-action"
          type="button"
        >
          Next Step
        </button>
      </div>
    );
  }
}

export default QuestionSlide(ZipSlide);
