// @flow

import React, { PureComponent } from 'react';

type SingleDropdownOption = {
  id: string,
  name: string,
};

type Props = {
  option: SingleDropdownOption,
  isModalContext: boolean,
}

class FilterDropdownSelectItem extends PureComponent<Props> {
  render() {
    const { option } = this.props;
    const { id, name } = option;

    return (
      <option
        id={id}
        value={id}
        className="items-filter-item__input"
      >
        {name}
      </option>
    );
  }
}

export default FilterDropdownSelectItem;
