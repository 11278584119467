// @flow

import { CHANGE_ACTIVE_SLIDE, UPDATE_ESTIMATE_REQUEST } from './actions';

import type { ChangeActiveSlideAction, UpdateEstimateAction } from './actions';
import type { EstimateRequest } from './types';

export type AppState = {
  estimateRequest: EstimateRequest,
  activeSlide: number,
};

type ValidActions = ChangeActiveSlideAction | UpdateEstimateAction;

const initialState = {
  activeSlide: 0,
  estimateRequest: {
    email: '',
    zip: '',
    description: '',
    firstName: '',
    lastName: '',
  },
};

const reducer = (state: AppState = initialState, action: ValidActions): AppState => {
  switch (action.type) {
    case CHANGE_ACTIVE_SLIDE: {
      return {
        ...state,
        activeSlide: action.activeSlide,
      };
    }
    case UPDATE_ESTIMATE_REQUEST: {
      return {
        ...state,
        estimateRequest: {
          ...state.estimateRequest,
          ...action.estimateRequest,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
