import React, { useState, ReactChild } from 'react';
import Modal from './Modal';
import {
  ModalHeader,
  ModalFooter,
} from '~/admin/shared/components';
import { Button } from '~/public/shared/components';

export const ConfirmationModal: React.FC<{
  isModalOpen: boolean,
  message: ReactChild,
  onConfirm: () => Promise<any>,
  onReject: () => void,
}> = ({
  isModalOpen,
  message,
  onConfirm,
  onReject,
}) => {
  const [inFlight, setInFlight] = useState(false)

  const handleConfirm = () => {
    setInFlight(true);
    // Ensure inFlight is reset on success and failure
    onConfirm().then(() => setInFlight(false)).catch(() => setInFlight(false));
  }

  return (
    <div className="js-modal-parent">
      <Modal isOpen={isModalOpen}>
        <div className="modal-box u-p0">
          <ModalHeader
            title="Delete Item"
            close={onReject}
          />
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <ModalFooter>
            <Button
              buttonStyle="secondary"
              size="jumbo"
              className="qa-modal-confirm u-mr1"
              onClick={onReject}
            >
              No
            </Button>
            <Button
              inFlight={inFlight}
              buttonStyle="primary"
              size="jumbo"
              className="qa-modal-confirm"
              onClick={handleConfirm}
            >
              Yes, Delete It
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  )
};

export default ConfirmationModal;
