// @flow

import React, { type StatelessFunctionalComponent, Fragment } from 'react';
import moment from 'moment';
import { Badge } from '~/public/shared/components';
import type { SellerInvoiceAasmStates } from '../types';

export const PaymentStatus: StatelessFunctionalComponent<{
  aasmState: SellerInvoiceAasmStates,
  paymentDate: ?string,
}> = ({
  aasmState,
  paymentDate,
}) => {
  let badgeStyle;
  let badgeText = aasmState;
  let statusDetails;

  const formattedDate = paymentDate ? moment(paymentDate).format('M/DD/YYYY') : null;

  if (aasmState === 'paid') {
    badgeStyle = 'positive';
    statusDetails = formattedDate ? `Check released ${formattedDate}` : null;
  }

  if (aasmState === 'hold_payment') {
    badgeText = 'Processing';
  }

  if (aasmState === 'created') {
    badgeText = 'Processing';
    badgeStyle = 'neutral';
    statusDetails = formattedDate ? `Check releasing ${formattedDate}` : null;
  }

  return (
    <Fragment>
      <Badge
        status={badgeStyle}
        style={{ textTransform: 'capitalize' }}
        className={statusDetails ? 'u-mr1' : ''}
      >
        {badgeText}
      </Badge>
      {statusDetails &&
        <span className="u-text-muted u-no-wrap">{statusDetails}</span>
      }
    </Fragment>
  );
};
