// @flow

import React, { type StatelessFunctionalComponent, Fragment } from 'react';

interface LocationAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
}

export const Address: StatelessFunctionalComponent<{
  address: LocationAddress,
}> = ({
  address: {
    line1, line2, city, state, zip,
  },
}) => (
  <p>
    {line1}
    <br />
    {line2 && (
      <Fragment>
        {line2}
        <br />
      </Fragment>
    )}
    {city}, {state} {zip}
  </p>
);
