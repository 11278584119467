// @flow

import React, { Component } from 'react';
import ReactModal from 'react-modal';

import { Icon } from '~/public/shared/components';
import type { ModalState } from '../types';

type Props = {
  children: ?any,
  closeModal: (string) => void,
  modal: ModalState,
  name: string,
  onAfterOpen: () => void,
};

class Modal extends Component<Props> {
  static defaultProps = {
    onAfterOpen: () => {},
  };

  // Update redux store when modal is closed via ESC or overlay click.
  // Also used to close the modal from the close button.
  handleRequestClose = () => {
    this.props.closeModal(this.props.name);
  }

  render() {
    const { modal, name, children } = this.props;

    return (
      <ReactModal
        bodyOpenClassName="body-modal-open"
        htmlOpenClassName="html-modal-open"
        overlayClassName="modal modal--open"
        className="modal__body"
        isOpen={modal[name] === 'open'}
        onRequestClose={this.handleRequestClose}
        onAfterOpen={this.props.onAfterOpen}
        contentLabel="Modal"
        ariaHideApp={false}
      >
        <button
          onClick={this.handleRequestClose}
          className="modal__close"
          aria-label="Close"
          type="button"
        >
          <Icon icon="close" size="40" />
        </button>
        <div className="modal__content">
          {children}
        </div>
      </ReactModal>
    );
  }
}

export default Modal;
