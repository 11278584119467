// @flow

import React, { Component, type Node } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import type { Photo } from '../types';
import { initPhotoFromFile } from '../util/PhotoFactory';
import PhotoUploadIcon from '../svgs/PhotoUploadIcon';

type Props = {
  onDrop: (Array<Photo>) => any,
  children: ?Node,
};

class PhotoUploadDropArea extends Component<Props> {
  onDrop = (files: Array<File>) => {
    const photos: Array<Photo> = files.map(initPhotoFromFile);
    this.props.onDrop(photos);
  }

  handleSelectFiles = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);
    this.onDrop(files);
  }

  renderDefaultArea() {
    return (
      <div className="photo-upload__content">
        <p className="u-mb6 u-text-muted"><strong>Upload Photos</strong></p>
        <div className="photo-upload__illustration">
          <PhotoUploadIcon />
        </div>
        <div className="photo-upload__copy">
          Drag and Drop or&nbsp;
          <span className="custom-file-control custom-file-control--link">
            <input
              type="file"
              id="upload-photos"
              name="upload-photos"
              className="custom-file-control__input"
              multiple
              onChange={this.handleSelectFiles}
              value={''}
            />
            <label htmlFor="upload-photos" className="custom-file-control__label">Select Files</label>
          </span>
        </div>
      </div>
    );
  }

  renderChildren = () => {
    return (
      <div className="dropzone__grid">
        {this.props.children}
        <div className="photo-upload photo-upload--small">
          {this.renderDefaultArea()}
        </div>
      </div>
    );
  }

  render() {
    const hasChildren = React.Children.count(this.props.children) > 0;
    const classes = classNames({
      dropzone: true,
      'dropzone--empty photo-upload': !hasChildren,
    });

    return (
      <Dropzone
        onDrop={this.onDrop}
        disableClick
        className={classes}
        activeClassName="dropzone dropzone--active"
        rejectClassName="dropzone dropzone--reject"
      >
        {
          hasChildren
            ? this.renderChildren()
            : this.renderDefaultArea()
        }
      </Dropzone>
    );
  }
}

export default PhotoUploadDropArea;
