// @flow

import {
  BOOTSTRAP,
} from '../actions/bootstrap';

import type { Actions } from '../actions/bootstrap';
import type { Config } from '../types';

const initialState = {};

const configReducer = (state: Config = initialState, action: Actions): Config => {
  switch (action.type) {
    case BOOTSTRAP:
      return {
        ...state,
        ...action.config,
      };
    default:
      return state;
  }
};

export default configReducer;
