import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from 'react-apollo-hooks';
import { Item, Category } from '~/shared/interfaces';
import {
  Modal,
  Status,
  ModalHeader,
  ModalFooter,
} from '~/admin/shared/components';

import ContractsQuery from '~/public/dashboard/queries/ContractsQuery';
import SelectInput from '~/admin/shared/components/SelectInput';
import container from '~/shared/containers/inventoryItemContainer';
import { CREATE_PENDING_ITEM_MUTATION } from '~/shared/graphql/PendingItem/PendingItemMutations';


export const NewItemModal: React.FC<{
  item: any;
  isOpen: boolean;
  setModalOpen: (value: boolean) => void;
  history: any;
}> = ({
  isOpen,
  setModalOpen,
  history
}) => {
  const [item, setItem] = useState({
    additionalCategories: [],
    additionalInformationLinks: [],
  } as Item);

  const [
    createPendingItem,
    {
      loading,
      error,
    },
  ] = useMutation(CREATE_PENDING_ITEM_MUTATION)

  const onCreate = () => {
    if (!item.name || !item.contract) {
      // TODO: better handling when required fields empty
      return;
    }

    createPendingItem({
      variables: {
        input: {
          name: item.name,
          contractId: item.contract.id
        }
      }
    }).then(({ data }) => {
      if (data.createPendingItem) {
        setModalOpen(false);
        history.push(`/items/submitted/${data.createPendingItem.pendingItem.id}`)
      }
    })
  }

  const onChange = (changes: Object) => {
    setItem((state) => ({ ...state, ...changes }))
  }

  const onPropertyChange = (property: string) => (value: string | boolean) => onChange({[property]: value })

  return (
    <div className="js-modal-parent">
      <Modal isOpen={isOpen}>
          <div className="modal-box u-p0">
            <ModalHeader
              title="Submit an Item"
              close={() => setModalOpen(false)}
            />
            <div className="modal-body">
              <p>
                Use the fields below to name your item and add it to a contract.<br />
                On the next screen you will be able to add images and other information.
              </p>
              <label
                htmlFor="item-name"
                className="form-control__label"
              >
                Item Name
              </label>
              <input
                id="item-name"
                onChange={(event) => onPropertyChange('name')(event.target.value)}
                autoFocus
                type="text"
                className="form-input u-mb2"
              />

              <label
                htmlFor="item-name"
                className="form-control__label"
              >
                Contracts
              </label>
              <ContractsQuery>
                {({ loading, contracts }) => {
                  if (loading) {
                    return <p className="helper-text u-mb6">Fetching contracts...</p>
                  } else {
                    return <SelectInput
                      labelKey="number"
                      valueKey="id"
                      onChange={(value) => onPropertyChange('contract')(value)}
                      options={contracts}
                      value={item.contract}
                      className="u-mb6"
                    />
                  }
                }}
              </ContractsQuery>
            </div>
            <ModalFooter
              actionText="Submit Item and Edit Details"
              action={onCreate}
            />
          </div>
      </Modal>
    </div>
  )
}

export default withRouter(NewItemModal);
