// @flow

import moment from 'moment';

const formatPickupTime = ({ startsAtLocalized, endsAtLocalized }: {
  startsAtLocalized: ?string,
  endsAtLocalized: ?string,
}): string => {
  const s = moment(startsAtLocalized).utcOffset(startsAtLocalized);
  const sTime = s.format('h:mm').replace(':00', '');
  const sTimeMeridiem = s.format('a');
  const pickupString = [''];

  if (startsAtLocalized && endsAtLocalized) {
    const e = moment(endsAtLocalized).utcOffset(endsAtLocalized);
    const eTime = e.format('h:mma').replace(':00', '');
    const eTimeMeridiem = e.format('a');
    const sameDay = s.isSame(endsAtLocalized, 'day');
    const sameMonth = s.isSame(endsAtLocalized, 'month');
    const sameMeridiem = sTimeMeridiem === eTimeMeridiem;

    if (sameDay) pickupString.push(`${s.format('dddd')}, `);// 'Thursday, '
    pickupString.push(s.format('MMMM D'));// 'March 20'
    if (!sameDay) pickupString.push('-');// '-'
    if (!sameMonth) pickupString.push(`${e.format('MMMM')} `);// 'April '
    if (!sameDay) pickupString.push(e.format('D'));// '24'
    pickupString.push(`, ${sTime}${sameMeridiem ? '' : sTimeMeridiem}-${eTime}`);// ', 9am-5pm' or ', 9:30-11am' or ', 2-5:30pm'
  } else if (startsAtLocalized && !endsAtLocalized) {
    pickupString.push(s.format('dddd, MMMM D, ha'));// 'Thursday, March 20, 1pm'
  }
  return pickupString.join('');
};

export default formatPickupTime;
