import { OPEN_COMMENTS, CLOSE_COMMENTS } from '../actions/comments';

export default (state = { isOpen: false }, action) => {
  switch (action.type) {
    case OPEN_COMMENTS:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_COMMENTS:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
