// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { range } from 'lodash';

import type { ComponentType, Node } from 'react';

import BackArrowContainer from '../containers/BackArrowContainer';

type Props = {
  slides: Array<ComponentType<*>>,
  activeSlide: number,
};

class QuestionCarouselHeader extends Component<Props> {
  render() {
    const indicators = range(this.props.slides.length).map((i: number): Node => {
      const classes = classNames({
        'step-indicator__item': true,
        'step-indicator__item--complete': i < this.props.activeSlide,
        'step-indicator__item--active': i === this.props.activeSlide,
      });

      return (
        <li key={`step-indicator-${i}`} className={classes} />
      );
    });

    return (
      <div className="value-estimator__header">
        <div className="layout">
          <BackArrowContainer activeSlide={this.props.activeSlide} />
          <h2 className="value-estimator__heading u-text-center u-mb0">Value Estimator</h2>
          <ul className="step-indicator">
            {indicators}
          </ul>
        </div>
      </div>
    );
  }
}

export default QuestionCarouselHeader;
