import gql from 'graphql-tag';

export const USER_SESSION_QUERY = gql`
  {
    currentUser {
      id
      firstName
      lastName
      authorizedSelfLister
    }
  }
`;
