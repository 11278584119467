// @flow

import React, { Component, createContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import type { Node } from 'react';

const context = createContext({
  isExpanded: false,
  toggleContent: () => {},
  positionLeft: false,
});

const { Provider, Consumer } = context;

const DropdownToggle = ({ children }: {children: Node}) => (
  <Consumer>
    {({ toggleContent }) => <span onClick={() => toggleContent()}>{children}</span>}
  </Consumer>
);

const menuClasses = (positionLeft) => (
  classNames(
    'dropdown__menu',
    'dropdown__menu--open',
    {
      'dropdown__menu--left': positionLeft,
    }
  )
);

const DropdownContent = ({ children, maxHeight }: {children: Node, maxHeight?: string}) => (
  <Consumer>
    {({ isExpanded, positionLeft }) => (
      <CSSTransition
        in={isExpanded}
        mountOnEnter
        unmountOnExit
        timeout={0}
        classNames="transition"
      >
        <div className={menuClasses(positionLeft)} style={{ maxHeight: maxHeight }}>
          {children}
        </div>
      </CSSTransition>
    )}
  </Consumer>
);

type Props = {
  className?: string,
  menuPositionLeft?: boolean,
  children: Node,
}

type State = {
  isExpanded: boolean,
}

class Dropdown extends Component<Props, State> {
  state = {
    isExpanded: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.closeModalOnEsc);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.closeModalOnEsc);
  }

  toggleContent = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  closeContent = () => {
    this.setState({ isExpanded: false });
  }

  dropdownWrapper: ?HTMLSpanElement;

  handleClick = (e: MouseEvent) => {
    const target: HTMLElement = (e.target: any);
    if (this.dropdownWrapper && !this.dropdownWrapper.contains(target)) {
      this.closeContent();
    }
  }

  closeModalOnEsc = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.closeContent();
    }
  }

  render() {
    const {
      className,
      children,
      menuPositionLeft,
      ...otherProps
    } = this.props;

    const wrapperClasses = classNames(
      'dropdown',
      className,
    );

    return (
      <Provider
        value={{
          isExpanded: this.state.isExpanded,
          toggleContent: this.toggleContent,
          positionLeft: menuPositionLeft,
        }}
      >
        <span
          ref={(ref) => { this.dropdownWrapper = ref; }}
          className={wrapperClasses}
          {...otherProps}
        >
          {children}
        </span>
      </Provider>
    );
  }
}

export default Dropdown;
export { DropdownToggle, DropdownContent };
