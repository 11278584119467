// @flow

import React, { type StatelessFunctionalComponent, type Node } from 'react';
import classnames from 'classnames';

export const ActionsBar: StatelessFunctionalComponent<{
  children: Node,
  className?: string,
}> = ({ children, className }) => {
  const classNames = classnames('actions', className);

  return <div className={classNames}>{children}</div>;
};
