// @flow

import React, { Component } from 'react';
import update from 'immutability-helper';

import { Button, Icon } from '~/public/shared/components';
import PaymentMethodHelper from '~/public/shared/utils/PaymentMethodHelper';
import PaymentMethodFields from '~/public/shared/components/PaymentMethodFields';
import getStripeToken from '~/public/shared/utils/GetStripeToken';

type Props = {
  addresses: Array<Object>,
  changePane: (string, Object) => void,
  paymentType: number,
  paypageId: string,
  paypageUrl: string,
  plaidEnvironment: string,
  useVantiv: boolean,
  stripe: Object,
  elements: Object,
};

type State = {
  isSaving: boolean,
  paymentMethodType: () => void,
  paymentMethod: Object,
}

class PaneAddPayment extends Component<Props, State> {
  static defaultProps = {
    addresses: [],
    paymentType: 0,
  }

  constructor(props: Props, ...args: Array<Object>) {
    super(props, ...args);
    this.paymentMethodTypes = PaymentMethodHelper.paymentMethodTypes();
    this.paymentType = this.props.paymentType;
    this.state = {
      isSaving: false,
      paymentMethodType: this.paymentMethodTypes[this.paymentType],
      paymentMethod: PaymentMethodHelper.initialize(props.addresses),
    };
  }

  paymentForm: any;

  paymentType: any;

  paymentMethodTypes: [];

  returnToPayments = (paymentAdded: boolean) => {
    this.props.changePane('payment', { paymentAdded: paymentAdded });
  };

  onPlaidSuccess = async (publicToken: string, metadata: Object) => {
    this.setState({ isSaving: true });

    const { isSuccess, paymentMethod } = await PaymentMethodHelper.savePlaid(
      this.state.paymentMethod,
      publicToken,
      metadata
    );

    if (isSuccess) {
      window.analytics.track('Payment Method Added', {
        payment_method_id: paymentMethod.id.value,
        payment_method_type: 'echeck',
        was_marked_primary: false,
      });
      this.returnToPayments(true);
    } else {
      this.setState({
        paymentMethod,
        isSaving: false,
      });
    }
  }

  handleSubmit = async (event: SyntheticEvent<HTMLButtonElement>) => {
    const { stripe, elements } = this.props;

    event.preventDefault();
    this.setState({ isSaving: true });

    let { paymentMethod } = this.state;

    // clear out any errors
    paymentMethod = update(paymentMethod, {
      hasErrors: { $set: false },
      base: { errors: { $set: [] } },
    });

    paymentMethod = PaymentMethodHelper.validate(paymentMethod);
    if (paymentMethod.hasErrors) {
      this.setState({ paymentMethod, isSaving: false });
      return;
    }

    // BEGIN - GIFT CARD
    if (paymentMethod.paymentMethodTypeId && paymentMethod.paymentMethodTypeId === 4) {
      paymentMethod = await PaymentMethodHelper.redeemGiftCard(paymentMethod);
      if (paymentMethod.hasErrors) {
        this.setState({ paymentMethod, isSaving: false });
        window.EBTH.flash.show(paymentMethod.base.errors[0], 'error');
      } else {
        window.EBTH.flash.show('Gift Card Redeemed Successfully');
        this.props.changePane('bid', paymentMethod);
      }
      return;
    }
    // END - GIFT CARD
    paymentMethod = await getStripeToken(paymentMethod, stripe, elements);

    if (paymentMethod.hasErrors) {
      this.setState({ paymentMethod, isSaving: false });
      return;
    } else {
      // re-render so that we clear errors
      this.setState({ paymentMethod });
    }

    paymentMethod = await PaymentMethodHelper.savePaymentMethod(paymentMethod);
    if (paymentMethod.hasErrors) {
      this.setState({ paymentMethod, isSaving: false });
    } else {
      // - Reload if we were successful in saving to view the new list of payment methods.
      // - Reload if we got a 401 (Unauthorized) response, to show the sign in screen that
      //   will send the user back to payment methods after they authenticate
      if (window.analytics) {
        window.analytics.track('Payment Method Added', {
          payment_method_id: paymentMethod.id.value,
          payment_method_type: 'credit card',
          was_marked_primary: paymentMethod.primary.value,
        });

        if (paymentMethod.address.isAddingAddress) {
          window.analytics.track('Address Added', {
            address_id: paymentMethod.address.id.value,
            context: 'payment_methods_page',
            was_marked_primary: false, // User can't set address as primary in this context
          });
        }
      }
      this.setState({ isSaving: false });
      window.EBTH.flash.show('Successfully added payment method.');
      this.returnToPayments(true);
    }
  }

  handlePaymentMethodChange = (paymentMethod: Object) => {
    this.setState({ paymentMethod });
  }

  handlePaymentMethodTypeChange = (value: number) => {
    const { paymentMethod } = this.state;
    const newPaymentMethod = update(paymentMethod, {
      base: { errors: { $set: [] } },
    });

    this.setState({
      paymentMethodType: PaymentMethodHelper.PAYMENT_METHOD_TYPES[value],
      paymentMethod: newPaymentMethod,
    });
  }

  render() {
    const {
      isSaving,
      paymentMethod,
      paymentMethodType,
    } = this.state;
    const {
      addresses,
      paypageId,
      paypageUrl,
      plaidEnvironment,
      useVantiv,
    } = this.props;
    return (
      <form className="u-my4" onSubmit={this.handleSubmit} ref={(paymentForm) => { this.paymentForm = paymentForm; }}>
        <PaymentMethodFields
          onPlaidSuccess={this.onPlaidSuccess}
          addresses={addresses}
          isSaving={isSaving}
          paymentMethod={paymentMethod}
          onPaymentMethodChange={this.handlePaymentMethodChange}
          paymentMethodType={paymentMethodType}
          onPaymentMethodTypeChange={this.handlePaymentMethodTypeChange}
          paymentMethodTypes={this.paymentMethodTypes}
          paypageId={paypageId}
          paypageUrl={paypageUrl}
          plaidEnvironment={plaidEnvironment}
          useVantiv={useVantiv}
        />
        { paymentMethodType.showSubmitButton &&
          <Button
            type="submit"
            buttonDisplay="block"
            buttonStyle="primary"
            inFlight={isSaving}
          >
            { paymentMethodType.submitButtonText }
          </Button>
        }
        <Button
          onClick={this.returnToPayments}
          buttonDisplay="block"
          buttonStyle="secondary"
          disabled={isSaving}
          className="u-mt4"
        >
          <Icon icon="chevron-left" className="btn__icon" />
          Back
        </Button>
      </form>
    );
  }
}

PaneAddPayment.defaultProps = {
  addresses: [],
  paymentType: 0,
};

export default PaneAddPayment;
