import React, { useState } from 'react';
import classNames from 'classnames';
import SidebarLink from './SidebarLink';
import { Icon } from '~/public/shared/components';

function Sidebar(props) {
  const [isOpen, toggle] = useState(props.open);

  const sidebarClasses = classNames(
    'accordion-body',
    { open: isOpen },
  );
  return (
    <div className="nav-accordion u-hide-from-webviews">
      <div className="accordion-header hidden-md-up" onClick={() => toggle(!isOpen)}>
        <Icon icon={isOpen ? 'remove' : 'add'} />
        <p>Account Sections</p>
      </div>
      <div className={sidebarClasses}>
        <ul className="sidebar-nav u-mb0">
          <SidebarLink
            text="Account Info"
            link="/users/edit"
          />
          <SidebarLink
            text="Addresses"
            link="/users/addresses"
          />
          <SidebarLink
            text="Payment Methods"
            link="/users/payment_methods"
          />
          <SidebarLink
            text="Notification Preferences"
            link="/users/notification_preference/edit"
          />
          <SidebarLink
            text="Saved Searches"
            link="/users/item_searches"
          />
          <SidebarLink
            text="Won Items"
            link="/users/invoices"
          />
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
