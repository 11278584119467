// @flow

import * as Item from './Item';

export * from './Input';
export * from './InputNumber';
export * from './Radio';
export * from './TextArea';
export * from './Select';
export * from './Switch';
export * from './Checkbox';
export * from './CheckboxGroup';

export const Form = {
  ...Item,
};
