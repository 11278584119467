/* globals Plaid */

class PlaidHelper {
  constructor({ environment, onSuccess }) {
    this.environment = environment;
    this.initPromise = null;

    this.initPromise = (async () => {
      const tokenResponse = await fetch('/api/v1/payment_methods/plaid-token');
      const { token } = await tokenResponse.json();
      this.plaidHandler = Plaid.create({
        clientName: 'EBTH',
        env: environment,
        token,
        product: ['auth'],
        selectAccount: true,
        onSuccess: onSuccess,
      });
    })();
  }

  async openPlaidModal(institutionId) {
    await this.initPromise;
    this.plaidHandler.open(institutionId);
  }

  async search(query) {
    return fetch(`/api/v1/payment_methods/plaid-institutions-search?query=${encodeURIComponent(query)}`);
  }
}

export default PlaidHelper;
