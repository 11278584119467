// @flow

import React, { Fragment, Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import classNames from 'classnames';
import DashboardSvgSprite from '~/public/svgs/DashboardSvgSprite';
import Notice from '~/admin/shared/Notice';
import { Button, Icon, LoadingDots } from '~/public/shared/components';
import { FeatureFlagConsumer } from '../contexts/FeatureFlagContext';
import Dropdown, { DropdownToggle, DropdownContent } from './Dropdown';

type Props = {
  children: ?any,
  contentHeader?: any,
};

type State = {
  navOpen: boolean,
}

const CURRENT_USER_QUERY = gql`
  {
    currentUser {
      id
      firstName
      authorizedSelfLister
    }
  }
`;

class Layout extends Component<Props, State> {
  state = {
    navOpen: false,
  }

  toggleNav = () => {
    this.setState({
      navOpen: !this.state.navOpen,
    });
  }

  handleSignOutClick = async (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    await fetch('/users/sign_out', {
      method: 'delete',
      credentials: 'include',
    });

    window.location = '/';
  }

  render() {
    const { navOpen } = this.state;
    const { contentHeader, children } = this.props;
    const menuIconClasses = classNames(
      'menu-icon',
      {
        'menu-icon--open': navOpen,
      },
    );
    const navClasses = classNames(
      'dashboard__navigation',
      {
        'dashboard__navigation--open': navOpen,
      },
    );

    return (
      <Fragment>
        <div className="dashboard__header">
          <div>
            <Button
              onClick={this.toggleNav}
              buttonStyle={navOpen ? 'primary' : 'text-navigation'}
              buttonDisplay="icon-only"
              className="dashboard__nav-toggle u-px1"
            >
              <span className={menuIconClasses} />
            </Button>
            <Link to="/" title="Dashboard Home">
              <DashboardSvgSprite icon="logo" className="dashboard__logo" />
            </Link>
          </div>
          <Query query={CURRENT_USER_QUERY}>
            {({ loading, error, data }) => {
              if (loading) return <LoadingDots className="u-pull-right u-mr2" />;
              if (error) return `Error! ${error.message}`;

              return (
                <Dropdown>
                  <DropdownToggle>
                    <Button buttonStyle="text-navigation" className="u-px1">
                      <Icon icon="account-circle" className="btn__icon" />
                      <span className="hidden-sm-down">{data.currentUser.firstName}</span>
                      <Icon icon="chevron-down" className="btn__icon btn__icon--right u-ml0" />
                    </Button>
                  </DropdownToggle>
                  <DropdownContent>
                    <ul className="dropdown__list">
                      <li>
                        <a
                          href="/"
                          className="btn btn--text-navigation dropdown__btn"
                        >
                          <Icon icon="open-in-new" className="btn__icon" />
                          EBTH Marketplace
                        </a>
                      </li>
                      <li className="dropdown__divider" />
                      <li>
                        <a
                          href="/users/item_searches?ref=hdr_user_menu"
                          className="btn btn--text-navigation dropdown__btn"
                        >
                          <Icon icon="bookmark" className="btn__icon" />
                          Saved Searches
                        </a>
                      </li>
                      <li>
                        <a
                          href="/users/invoices?ref=hdr_user_menu"
                          className="btn btn--text-navigation dropdown__btn"
                        >
                          <Icon icon="trophy" className="btn__icon" />
                          Won Items
                        </a>
                      </li>
                      <li>
                        <a
                          href="/users/edit?ref=hdr_user_menu"
                          className="btn btn--text-navigation dropdown__btn"
                        >
                          <Icon icon="settings" className="btn__icon" />
                          Account Settings
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://support.ebth.com/"
                          className="btn btn--text-navigation dropdown__btn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon icon="help" className="btn__icon" />
                          Help
                        </a>
                      </li>
                      <li className="dropdown__divider" />
                      <li>
                        <a
                          href="/users/sign_out"
                          className="btn btn--text-navigation dropdown__btn"
                          rel="nofollow"
                          onClick={this.handleSignOutClick}
                        >
                          <span className="btn__icon" />
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </DropdownContent>
                </Dropdown>
              );
            }}
          </Query>
        </div>
        <div className="dashboard__body">
          <nav className={navClasses}>
            <NavLink to="/" exact className="dashboard__navigation-link" activeClassName="dashboard__navigation-link--active">
              <Icon icon="home" className="dashboard__navigation-link-icon" />
              Home
            </NavLink>
            <NavLink to="/items" className="dashboard__navigation-link" activeClassName="dashboard__navigation-link--active">
              <Icon icon="tag" className="dashboard__navigation-link-icon" />
              Items
            </NavLink>
            <FeatureFlagConsumer>
              {(featureFlags) => (
                <Fragment>
                  {featureFlags.sellerData &&
                    <NavLink to="/stats" className="dashboard__navigation-link" activeClassName="dashboard__navigation-link--active">
                      <Icon icon="stats" className="dashboard__navigation-link-icon" />
                      Stats
                    </NavLink>
                  }
                </Fragment>
              )}
            </FeatureFlagConsumer>
            <NavLink to="/contracts" className="dashboard__navigation-link" activeClassName="dashboard__navigation-link--active">
              <Icon icon="contract" className="dashboard__navigation-link-icon" />
              Contracts
            </NavLink>
            <NavLink to="/payments" className="dashboard__navigation-link" activeClassName="dashboard__navigation-link--active">
              <Icon icon="receipt" className="dashboard__navigation-link-icon" />
              Payments
            </NavLink>
            <a href="https://support.ebth.com/s/topic/0TO1K000001kH1XWAU/current-sellers" className="dashboard__navigation-link" target="_blank" rel="noopener noreferrer">
              <Icon icon="help-outline" className="dashboard__navigation-link-icon" />
              Support
            </a>
          </nav>
          <div className="dashboard__content">

            {contentHeader &&
              <div className="dashboard__content-header">
                <div className="dashboard__content-padding u-pb0">
                  {contentHeader}
                </div>
              </div>
            }
            <div className="dashboard__content-padding notice__wrapper">
              <Notice />
              {children}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Layout;
