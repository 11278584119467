// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';

import {
  changeActiveSlide,
  updateEstimateRequest,
} from '../actions';

import type { AppState } from '../reducer';
import type { EstimateRequest, EstimateRequestParams } from '../types';

import DescriptionSlide from '../components/DescriptionSlide';
import EmailSlide from '../components/EmailSlide';
import NameSlide from '../components/NameSlide';
import PhotosSlide from '../components/PhotosSlide';
import QuestionCarouselForm from '../components/QuestionCarouselForm';
import QuestionCarouselHeader from '../components/QuestionCarouselHeader';
import ZipSlide from '../components/ZipSlide';

type Props = {
  updateEstimateRequest: (EstimateRequestParams) => any,
  changeActiveSlide: (number) => any,
  estimateRequest: EstimateRequest,
  activeSlide: number,
  onComplete: () => any,
};

const slides = [
  EmailSlide,
  NameSlide,
  ZipSlide,
  DescriptionSlide,
  PhotosSlide,
];

class QuestionCarouselContainer extends Component<Props> {
  onChange = (key: string, value: *) => {
    this.props.updateEstimateRequest({ [key]: value });
  }

  render() {
    return (
      <div className="value-estimator">
        <QuestionCarouselHeader
          slides={slides}
          activeSlide={this.props.activeSlide}
        />
        <QuestionCarouselForm
          slides={slides}
          changeActiveSlide={this.props.changeActiveSlide}
          estimateRequest={this.props.estimateRequest}
          onChange={this.onChange}
          activeSlide={this.props.activeSlide}
          onSubmit={this.props.onComplete}
        />
      </div>
    );
  }
}

type ReduxState = { priceEstimatorTool: AppState }
type ReduxProps = {
  estimateRequest: EstimateRequest,
  activeSlide: number,
};
const mapStateToProps = ({ priceEstimatorTool }: ReduxState): ReduxProps => {
  return {
    ...priceEstimatorTool,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeActiveSlide,
  updateEstimateRequest,
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose(connector)(QuestionCarouselContainer);
