import update from 'immutability-helper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AddressFormFields from '~/public/shared/components/AddressFormFields';
import AddressOption from '~/public/shared/components/AddressOption';
import ExpandButton from '~/public/shared/components/ExpandButton';

class SelectAddressForm extends Component {
  handleAddNewAddress = () => {
    const address = this.props.field;
    const newAddress = update(address, {
      id: { value: { $set: null } },
      isAddingAddress: { $set: !address.isAddingAddress },
    });
    this.props.onChange(newAddress);
  }

  handleSelection = (address) => {
    const newAddress = update(this.props.field, {
      id: { value: { $set: address.id } },
      isAddingAddress: { $set: false },
      city: { $set: address.city },
      country: { $set: address.country },
      line1: { $set: address.line1 },
      line2: { $set: address.line2 },
      state: { $set: address.state },
      zip: { $set: address.zip },
      name: { $set: address.name },
    });
    this.props.onChange(newAddress);
  }

  handleAddressChange = (address) => {
    this.props.onChange(address);
  }

  renderNewAddressForm = () => {
    const address = this.props.field;

    if (!address.isAddingAddress) return null;

    return (
      <div className="load-expansion">
        <div className="l-form-grid u-mt2">
          <AddressFormFields
            address={address}
            onChange={this.handleAddressChange}
          />
        </div>
      </div>
    );
  }

  render() {
    const { addresses, field } = this.props;

    if (addresses.length === 0) {
      return this.renderNewAddressForm();
    } else {
      return (
        <div>
          <div className="gallery gallery--three">
            {addresses.map(
              (address) => (
                <AddressOption
                  address={address}
                  isSelected={address.id === field.id.value}
                  key={address.id}
                  onChange={this.handleSelection}
                />
              )
            )}
          </div>
          <hr className="hr" />
          <ExpandButton
            text="Add a new billing address"
            expanded={field.isAddingAddress}
            onClick={this.handleAddNewAddress}
          />
          {this.renderNewAddressForm()}
        </div>
      );
    }
  }
}

SelectAddressForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  addresses: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
};

SelectAddressForm.defaultProps = {};

export default SelectAddressForm;
