import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BuyerInvoiceHelper from '~/public/buyer_invoices/utils/BuyerInvoiceHelper';
import refreshPage from '~/utils/refreshPage';
import { Button } from '~/public/shared/components';

class ApplyGiftCardForm extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      isSaving: false,
      giftCardNumber: '',
      error: null,
    };
  }

  handleGiftCardChange = (e) => {
    this.setState({
      error: null,
      giftCardNumber: e.target.value,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { giftCardNumber } = this.state;
    const { buyerInvoiceId } = this.props;

    if (giftCardNumber === '') {
      this.setState({ error: 'Please enter a gift card number' });
      return;
    }

    this.setState({ isSaving: true });

    const response = await BuyerInvoiceHelper.applyGiftCard({
      buyerInvoiceId: buyerInvoiceId,
      giftCardNumber: giftCardNumber,
    });

    if (response.success) {
      if (response.amountDue === 0) {
        window.location = `/users/invoices/${buyerInvoiceId}/thank_you`;
      } else {
        refreshPage();
      }
    } else {
      this.setState({
        error: response.error,
        isSaving: false,
      });
    }
  }

  render() {
    const { error, giftCardNumber, isSaving } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label htmlFor="invoice_adjustment_gift_card_number">Apply a gift card</label>

          <div className="input-group">
            <input
              className="input input-group__input"
              type="text"
              id="invoice_adjustment_gift_card_number"
              value={giftCardNumber}
              onChange={this.handleGiftCardChange}
            />
            <Button
              type="submit"
              buttonStyle="primary"
              className="input-group__btn"
              inFlight={isSaving}
            >
              Apply
            </Button>
          </div>

          {error &&
            <div className="form-group__error">
              {error}
            </div>
          }

          <div className="form-group__hint">
            If the gift card amount is less than the grand total, a partial payment will be applied.
            &nbsp;<a target="blank" href="/gift_cards">Check gift card balance</a>
          </div>
        </div>
      </form>
    );
  }
}

ApplyGiftCardForm.propTypes = {
  buyerInvoiceId: PropTypes.number.isRequired,
};

ApplyGiftCardForm.defaultProps = {
};

export default ApplyGiftCardForm;
