// @flow

import React, { Component, type Node } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Dropzone from 'react-dropzone';
import { displayNotice } from '~/admin/shared/actions/notice';
import CreateAttachmentsMutation from '../mutations/CreateAttachmentsMutation';

type Props = {
  itemId: string,
  displayNotice: typeof displayNotice,
  children: (
    dropzoneProps: {},
    onFileChange: (SyntheticInputEvent<HTMLInputElement>) => any
  ) => Node,
  photosUploading: (boolean) => any,
};

class UploadCard extends Component<Props> {
  handleDrop = (createPhotos: Function) => async (files: Array<File>) => {
    createPhotos(this.props.itemId, files);
  }

  handleDropRejected = () => {
    this.props.displayNotice({
      type: 'danger',
      message: 'The file type is not supported. Please use a .jpeg',
      autoDismiss: true,
    });
  }

  onCreateError = (error) => {
    this.props.displayNotice({
      message: `Could not upload photos: ${error}`,
      type: 'danger',
    });
  }

  handleSelectFiles = (createPhotos: Function) => (event: SyntheticInputEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);
    this.handleDrop(createPhotos)(files);
  }

  render() {
    return (
      <CreateAttachmentsMutation setPhotosUploading={this.props.photosUploading} onError={this.onCreateError}>
        {(createPhotos) => (
          <Dropzone onDrop={this.handleDrop(createPhotos)} onDropRejected={this.handleDropRejected} accept={['image/jpeg']}>
            {(dropzoneProps) => this.props.children(dropzoneProps, this.handleSelectFiles(createPhotos)) }
          </Dropzone>
        )}
      </CreateAttachmentsMutation>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayNotice,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(UploadCard);
