// @flow

import { type ItemSearchParams } from '../queries/ItemSearchQuery';
import { type FilterStore, type FilterKey, type SortKey } from '~/admin/shared/actions/merchandising';

// pretty much the same as the presale ES index, except excluding held items
const PRESALE_STATES = ['created', 'editing', 'approved'];

const REMOVE_OLDER_ITEMS_FROM_ADMIN_SEARCH_FLAG = 'REMOVE_OLDER_ITEMS_FROM_ADMIN_SEARCH_FLAG';

const filterMapper: Map<FilterKey | string, string> = new Map([
  // NAME is omitted as it is a 'query' argument
  ['CATEGORY', 'category_ids_for_filtering'],
  ['PROCESSING_LOCATION_TYPES', 'processing_location_type_id'],
  ['CONTRACT_REGION', 'sale_region_id'],
  ['CONTRACT_TYPE', 'contract_type_id'],
  ['HELD_FOR_SELECT', 'held_for_select'],
]);

const sortMapper: Map<SortKey, { sortColumn: string, sortDirection: 'ASC' | 'DESC' }> = new Map([
  ['CREATED_AT_DESC', { sortColumn: 'created_at', sortDirection: 'DESC' }],
  ['CREATED_AT_ASC', { sortColumn: 'created_at', sortDirection: 'ASC' }],
  ['ESTIMATED_VALUE_DESC', { sortColumn: 'combined_estimated_value', sortDirection: 'DESC' }],
  ['ESTIMATED_VALUE_ASC', { sortColumn: 'combined_estimated_value', sortDirection: 'ASC' }],
  ['CATEGORY_DESC', { sortColumn: 'primary_category_lineage', sortDirection: 'DESC' }],
  ['CATEGORY_ASC', { sortColumn: 'primary_category_lineage', sortDirection: 'ASC' }],
  ['NAME_DESC', { sortColumn: 'name', sortDirection: 'DESC' }],
  ['NAME_ASC', { sortColumn: 'name', sortDirection: 'ASC' }],
]);

type ConstraintOptions = {
  saleId: ?string,
  isSelectSale: boolean,
};

function buildConstraintString(filters: FilterStore, { saleId }: ConstraintOptions) {
  const constraints = Object.keys(filters).reduce((translatedFilters, filterKey) => {
    const esField = filterMapper.get(filterKey);
    const filterValues = filters[filterKey];

    if (esField && filterValues && filterValues.length > 0) {
      if (filterValues.length === 1) {
        const [filter] = filters[filterKey];
        translatedFilters[esField] = filter;
      } else {
        translatedFilters[esField] = filters[filterKey];
      }
    }

    return translatedFilters;
  }, {});

  // we always want to see presale items (excluding held) only. but we also want to pass the aasm state params that have been selected by the admin in Lasso
  if (!filters.ITEM_AASM_STATE_TYPE || filters.ITEM_AASM_STATE_TYPE.length === 0) {
    constraints.aasm_state = PRESALE_STATES;
  } else {
    constraints.aasm_state = filters.ITEM_AASM_STATE_TYPE;
  }

  if (saleId) {
    constraints.sale_id = saleId;
    constraints.lasso_sale_search = true;
  } else {
    constraints.has_sale = false;
    // only pull completed items for merch items (saleless)
    if (filters.ITEM_INCOMPLETE[0]) {
      constraints.progress_report_lasso = 0;
      constraints.REMOVE_OLDER_ITEMS_FROM_ADMIN_SEARCH_FLAG = REMOVE_OLDER_ITEMS_FROM_ADMIN_SEARCH_FLAG;
    } else {
      constraints.progress_report_lasso = 1;
    }
  }

  return JSON.stringify(constraints);
}

// eslint-disable-next-line import/prefer-default-export
export function buildItemSearchQueryParameters(
  filters: FilterStore,
  sort: SortKey,
  options: ConstraintOptions
): $Shape<ItemSearchParams> {
  // flow doesn't allow implicit coercion of booleans to strings
  // even though filters.NAME[0] will never be a boolean, some filters can be
  // so we need to explicitly cast as a String
  const query: string = filters.NAME ? `${String(filters.NAME[0])}` : '';
  const constraints = buildConstraintString(filters, options);
  const sortData = sortMapper.get(sort) || {};

  // TODO: wire up paginate cursor

  return {
    query,
    constraints,
    ...sortData,
  };
}
