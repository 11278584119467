// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import timeInWords from '~/public/shared/utils/timeInWords';

import type { ItemStates } from '~/public/shared/types/Item';
import type { SaleStates } from '~/public/shared/types/Sale';

type InputState = null | ItemStates | SaleStates;

type TimeRemainingState =
  | 'inactive'
  | 'running'
  | 'last_day'
  | 'extended'
  | 'ending'
  | 'ended'
  ;

type Props = {
  aasmState: InputState,
  endsAt: ?Date,
  extended?: boolean,
  getReferenceTime: () => number,
  className?: string,
};

type State = {
  timeRemainingInWords: string,
};

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

const inputStatesToTimeRemainingState = (
  inputState: InputState,
  timeRemaining: string,
  extended: ?boolean,
  endsAt: Date,
  getReferenceTime: () => number,
): TimeRemainingState => {
  const lessThan24Left = (endsAt - getReferenceTime()) <= DAY_IN_MILLISECONDS;

  switch (inputState) {
    case 'created':
    case 'editing':
    case 'approved':
      return 'inactive';
    case 'sold':
    case 'unsold':
    case 'invoiced':
    case 'paid':
    case 'ended':
      return 'ended';
    default:
      if (!timeRemaining) {
        return 'ending';
      } else if (extended) {
        return 'extended';
      } else if (lessThan24Left) {
        return 'last_day';
      } else {
        return 'running';
      }
  }
};

const formatTimeForDisplay = (state: TimeRemainingState, time: Date): ?string => {
  if (state === 'inactive') return null;
  return moment(time).format('dddd, MMMM Do YYYY @ h:mma');
};

const relativeTimeRemaining = (state: TimeRemainingState, timeRemaining: string): string => {
  switch (state) {
    case 'inactive':
      return 'Not Started';
    case 'running':
    case 'last_day':
    case 'extended':
      return `${timeRemaining} left`;
    case 'ending':
      return 'Ending';
    default:
      return 'Ended';
  }
};

const cssState = (state: TimeRemainingState): string => {
  switch (state) {
    case 'last_day':
      return 'ending';
    case 'ending':
      return 'ended';
    default:
      return state;
  }
};

class TimeRemaining extends Component<Props, State> {
  state = {
    timeRemainingInWords: '',
  }

  componentDidMount() {
    this.updateTimeRemaining();
    this.interval = setInterval(this.updateTimeRemaining, 500);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  interval: ?IntervalID

  updateTimeRemaining = () => {
    const { endsAt } = this.props;
    if (!endsAt) return;
    const now = new Date(this.props.getReferenceTime());

    this.setState({
      timeRemainingInWords: timeInWords(now, endsAt),
    });
  }

  render() {
    const {
      endsAt,
      aasmState,
      extended,
      getReferenceTime,
      className,
    } = this.props;
    const { timeRemainingInWords } = this.state;

    if (!endsAt) return null;

    const timeRemainingState = inputStatesToTimeRemainingState(
      aasmState,
      timeRemainingInWords,
      extended,
      endsAt,
      getReferenceTime,
    );
    const classes = classNames(
      'time-remaining',
      `time-remaining--${cssState(timeRemainingState)}`,
      className
    );
    const title = formatTimeForDisplay(timeRemainingState, endsAt);
    const displayText = relativeTimeRemaining(timeRemainingState, timeRemainingInWords);

    return <time className={classes} title={title}>{displayText}</time>;
  }
}

export { TimeRemaining };
