// @flow

export const SET_RUNNING_APP_REVISION = 'SET_RUNNING_APP_REVISION';
export type SetRunningAppVersionAction = {
  type: 'SET_RUNNING_APP_REVISION',
  revision: string,
};

export const UPDATE_APP_REVISION = 'UPDATE_APP_REVISION';
export type UpdateAppRevisionAction = {
  type: 'UPDATE_APP_REVISION',
  revision: string,
};

export const SNOOZE_APP_REVISION = 'SNOOZE_APP_REVISION';
export type SnoozeAppRevisionAction = {
  type: 'SNOOZE_APP_REVISION',
};

export const WAKE_APP_REVISION = 'WAKE_APP_REVISION';
export type WakeAppRevisionAction = {
  type: 'WAKE_APP_REVISION',
};


export type Actions = (
  | SetRunningAppVersionAction
  | UpdateAppRevisionAction
  | SnoozeAppRevisionAction
  | WakeAppRevisionAction
);


export const setRunningAppRevision = (revision: string): SetRunningAppVersionAction => (
  {
    type: SET_RUNNING_APP_REVISION,
    revision,
  }
);

export const updateAppRevision = (revision: string): UpdateAppRevisionAction => (
  {
    type: UPDATE_APP_REVISION,
    revision,
  }
);

export const snoozeAppRevision = (): SnoozeAppRevisionAction => (
  {
    type: SNOOZE_APP_REVISION,
  }
);

export const wakeUpAppRevision = (): WakeAppRevisionAction => (
  {
    type: WAKE_APP_REVISION,
  }
);
