import * as React from 'react';
import { connect } from 'react-redux';
import { User } from '~/shared/interfaces';
import { Route, Redirect, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import UserContext, { UserContextProps } from '~/shared/contexts/UserContext';
import { LoadingDots } from '~/public/shared/components';
const { useContext } = React;

export const RouteAccessControl: React.FC<{
  children: React.ReactNode,
  accessControl: (user: User) => boolean
}> = ({ accessControl, children }) => {
  const {user, loading, error} = useContext(UserContext)
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  if (loading) return <LoadingDots />
  if (error || !accessControl(user)) {
    return <Redirect to="/" />
  }

  const clonedChildren = React.Children.map(children, (child, index) => {
  	return React.cloneElement(child as React.ReactElement<any>, {
	    index,
	    location: location,
	    match: match,
      history: history,
	   });
  });

  return <>{clonedChildren}</>;
};

const ProtectedRoute: React.FC<{
  path: string,
  children: React.ReactNode,
  accessControl: (user: User) => boolean,
  exact: boolean,
}> = ({ path, children, accessControl }) => (
  <Route path={path} exact>
    <RouteAccessControl accessControl={accessControl} >
      {children}
    </RouteAccessControl>
  </Route>
)

export default ProtectedRoute;
