// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import humps from 'humps';
import { debounce } from 'lodash';

import formatCurrency from '~/utils/formatCurrency';
import { LoadingDots } from '~/public/shared/components';
import ModalContainer from '../containers/ModalContainer';

import type { Item, User } from '../types';

// 04/30/2017 at 04:30:05 PM
// TODO: Display the timezone
const formatTime = (value: string | Date): string => moment(value).format('MM/DD/YYYY [at] hh:mm:ss A');

type Bid = {
  amount: number,
  bidderNumber: string,
  createdAt: string,
  id: number,
};

type Props = {
  currentUser: ?User,
  item: Item,
};

type State = {
  bids: Array<Bid>,
  initialLoadComplete: boolean,
  loading: boolean,
};

class ModalBidHistory extends Component<Props, State> {
  state = {
    bids: [],
    initialLoadComplete: false,
    loading: false,
  };

  loadBids = async () => {
    this.setState({ loading: true });

    try {
      const response = await fetch(`/api/v1/items/${this.props.item.id}/bids`, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: 'Token token=7a2ab11cfe70777231c82cf4325c6e96',
        },
      });

      if (response.ok) {
        this.setState({
          initialLoadComplete: true,
          loading: false,
          bids: humps.camelizeKeys(await response.json()),
        });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  debouncedLoadBids = debounce(this.loadBids, 5000, { leading: true })

  renderBidRow = (bid: Bid) => {
    const { currentUser } = this.props;
    const isCurrentUser = !!(currentUser && currentUser.bidderNumber === bid.bidderNumber);

    const tableRowClass: string = classNames(
      {
        'table__row--highlight': isCurrentUser,
      }
    );

    return (
      <tr className={tableRowClass} key={bid.id}>
        <td>{formatCurrency(bid.amount)}</td>
        <td>{bid.bidderNumber}{isCurrentUser && ' (you)'}</td>
        <td>{formatTime(bid.createdAt)}</td>
      </tr>
    );
  }

  renderLoadingRow = () => {
    return (
      <tr>
        <td colSpan="3" className="u-text-center">
          <LoadingDots />
        </td>
      </tr>
    );
  }

  renderBids = () => {
    const { initialLoadComplete, bids } = this.state;

    if (initialLoadComplete) {
      if (bids.length) {
        return bids.map((bid) => this.renderBidRow(bid));
      } else {
        return (
          <tr>
            <td colSpan="3" className="u-text-center">
              <em>No bids have been placed.</em>
            </td>
          </tr>
        );
      }
    } else {
      return this.renderLoadingRow();
    }
  }

  render() {
    const { loading, initialLoadComplete } = this.state;

    return (
      <ModalContainer name="bidHistory" onAfterOpen={this.debouncedLoadBids} {...this.props}>
        <table className="table table--striped qa-bid-history-table">
          <thead>
            <tr>
              <th>Bid</th>
              <th>Bidder</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {this.renderBids()}
            {(initialLoadComplete && loading) &&
              this.renderLoadingRow()
            }
          </tbody>
        </table>
      </ModalContainer>
    );
  }
}

const mapStateToProps = ({ item, user }) => ({ ...item, currentUser: user.user });

export default connect(mapStateToProps)(ModalBidHistory);
