import update from 'immutability-helper';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { InputField, SelectField } from '~/public/shared/components';
import AddressHelper from '~/public/shared/utils/AddressHelper';

class AddressFormFields extends Component {
  handleChange = (key) => {
    return (change) => {
      const { address } = this.props;
      const newAddress = update(address, { [key]: { value: { $set: change } } });
      this.props.onChange(newAddress);
    };
  }

  renderStateInput = () => {
    const { address } = this.props;

    if (address.country.value === 'US') {
      return (
        <SelectField
          label="State"
          name="address_state"
          autocomplete="billing address-level1"
          floatedLabel
          {...address.state}
          options={AddressHelper.STATE_OPTIONS}
          onChange={this.handleChange('state')}
        />
      );
    } else {
      return (
        <InputField
          label="State or Province"
          name="address_state"
          autocomplete="billing address-level1"
          floatedLabel
          {...address.state}
          onChange={this.handleChange('state')}
        />
      );
    }
  }

  render() {
    const { address } = this.props;

    return (
      <Fragment>
        <div className="l-form-grid__row">
          <div className="l-form-grid__item">
            <InputField
              label="Full Name"
              name="address_name"
              autocomplete="billing name"
              floatedLabel
              {...address.name}
              onChange={this.handleChange('name')}
            />
          </div>
        </div>
        <div className="l-form-grid__row">
          <div className="l-form-grid__item l-form-grid__item--6-col">
            <InputField
              label="Street Address"
              name="address_line1"
              autocomplete="billing address-line1"
              floatedLabel
              {...address.line1}
              onChange={this.handleChange('line1')}
            />
          </div>
          <div className="l-form-grid__item l-form-grid__item--6-col">
            <InputField
              label="Apartment, suite, unit, floor, etc."
              name="address_line2"
              autocomplete="billing address-line2"
              floatedLabel
              {...address.line2}
              onChange={this.handleChange('line2')}
            />
          </div>
        </div>

        <div className="l-form-grid__row">
          <div className="l-form-grid__item">
            <InputField
              label="City"
              name="address_city"
              autocomplete="billing address-level2"
              floatedLabel
              {...address.city}
              onChange={this.handleChange('city')}
            />
          </div>
        </div>

        <div className="l-form-grid__row">
          <div className="l-form-grid__item l-form-grid__item--6-col">
            <SelectField
              label="Country"
              name="address_country"
              autocomplete="billing country"
              floatedLabel
              {...address.country}
              options={AddressHelper.COUNTRY_OPTIONS}
              onChange={this.handleChange('country')}
            />
          </div>
          <div className="l-form-grid__item l-form-grid__item--3-col">
            {this.renderStateInput()}
          </div>
          <div className="l-form-grid__item l-form-grid__item--3-col">
            <InputField
              label="Zip Code"
              name="address_zip"
              autocomplete="billing postal-code"
              floatedLabel
              {...address.zip}
              onChange={this.handleChange('zip')}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

AddressFormFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
};

export default AddressFormFields;
