import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Cookies from 'js-cookie';

import { Button } from '~/public/shared/components';
import FilterWrapper from './FilterWrapper';

import { nextAppliedParams, paramsToUrl } from '../utils/url';

const ANYWHERE = 'anywhere';
const WITHIN = 'within';

const ZIP_COOKIE = 'user_zip_code';
const MILEAGE_COOKIE = 'user_mileage';
const getZip = () => {
  return Cookies.get(ZIP_COOKIE) || 45242;
};

const getMileage = () => {
  return Cookies.get(MILEAGE_COOKIE) || 10;
};

class FilterLocation extends Component {
  state = {
    formVisible: !(getZip().length),
    isEditing: false,
  }

  componentWillReceiveProps(nextProps) {
    const currentlySet = (getZip(this.props) && getMileage(this.props));
    const willBeUnset = !(getZip(nextProps) && getMileage(nextProps));
    const mileage = getMileage();
    const zipCode = getZip();

    const formVisible = !(mileage && zipCode);

    if (currentlySet && willBeUnset) {
      this.setState({
        isEditing: false,
        formVisible: formVisible,
      });
    }
  }

  zipInput;

  handleFormFocus = () => {
    this.setState({
      isEditing: true,
    });
  }

  handleMileageChange = (e) => {
    this.setState({ ...this.state, bufferedMileage: e.target.value });
  }

  handleZipChange = (e) => {
    this.setState({ ...this.state, bufferedZipCode: e.target.value });
  }

  // Takes the bufffered state and persists it to the cookie
  // Also sets the next location
  handleLocationSubmit = (e) => {
    e.preventDefault();

    const { filter, appliedParams } = this.props;
    const mileage = this.state.bufferedMileage || filter.data.miles[0].id;
    const zipCode = this.state.bufferedZipCode;

    this.setState({
      isEditing: false,
      formVisible: false,
    });

    // Set the cookies as the permanent storage
    Cookies.set(MILEAGE_COOKIE, mileage, { expires: 365 });
    Cookies.set(ZIP_COOKIE, zipCode, { expires: 365 });

    // Push to history
    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        miles: mileage,
        zip_code: zipCode,
        page: 1,
      };
    });
    const url = paramsToUrl(nextParams);
    this.props.history.push(url);
  }

  handleLocationAnywhere = () => {
    const { appliedParams } = this.props;

    // Unset the location filter
    this.setState({
      isEditing: false,
    });

    // Push to history
    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        miles: null,
        zip_code: null,
        page: 1,
      };
    });
    const url = paramsToUrl(nextParams);
    this.props.history.push(url);
  }

  handleLocationWithin = () => {
    const mileage = getMileage();
    const zipCode = getZip();
    const { appliedParams } = this.props;

    if (!mileage || !zipCode || zipCode.length !== 5) {
      this.focusZipInput();
    }

    // Push to history
    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        miles: mileage,
        zip_code: zipCode,
        page: 1,
      };
    });
    const url = paramsToUrl(nextParams);
    this.props.history.push(url);
  }

  handleFormEditClick = (e) => {
    e.preventDefault();

    this.setState({
      isEditing: true,
      formVisible: true,
      bufferedZipCode: Cookies.get(ZIP_COOKIE) || this.zipInput.value,
    });

    this.focusZipInput();
  }

  focusZipInput = () => {
    // timeout required to wait for input to be rendered in DOM
    setTimeout(() => {
      if (this.zipInput != null) {
        this.zipInput.focus();
      }
    }, 0);
  }

  handleZipInputFocus = (e) => {
    e.target.select();
  }

  render() {
    const { filter, isModalContext, appliedParams } = this.props;
    const zipCode = getZip();
    const mileage = getMileage();
    const { name, data } = filter;
    const selectedOption = (appliedParams.miles && appliedParams.zip_code) ? WITHIN : ANYWHERE;
    const inputAttrPrefix = isModalContext ? 'mobile-' : '';

    if (!filter) {
      return null;
    }

    return (
      <FilterWrapper title={name}>
        <ul className="items-filter">
          <li className="items-filter__item">
            <input
              name={`${inputAttrPrefix}filter-location`}
              id={`${inputAttrPrefix}location-anywhere`}
              value="anywhere"
              onChange={this.handleLocationAnywhere}
              checked={selectedOption === ANYWHERE}
              type="radio"
              className="items-filter-item__input"
            />
            <label
              htmlFor={`${inputAttrPrefix}location-anywhere`}
              className="items-filter-item__label items-filter-item__label--radio"
            >
              Anywhere
            </label>
          </li>
          <li className="items-filter__item">
            <input
              name={`${inputAttrPrefix}filter-location`}
              id={`${inputAttrPrefix}location-within`}
              value="within"
              onChange={this.handleLocationWithin}
              checked={selectedOption === WITHIN}
              type="radio"
              className="items-filter-item__input"
            />
            <label
              htmlFor={`${inputAttrPrefix}location-within`}
              className="items-filter-item__label items-filter-item__label--radio"
            >
              Within
              {!this.state.formVisible &&
                <div className="items-filter-location__value">
                  <span><strong>{mileage}</strong> miles of <strong>{zipCode}</strong></span>
                  <button type="button" onClick={this.handleFormEditClick} className="items-filter-location__edit-btn">
                    Edit
                  </button>
                </div>
              }
              {this.state.formVisible &&
                <form
                  // $FlowFixMe
                  onSubmit={this.handleLocationSubmit}
                  onFocus={this.handleFormFocus}
                  className="items-filter-location__inputs"
                >
                  <div className="input-select items-filter-location__distance">
                    <select onChange={this.handleMileageChange} value={this.state.bufferedMileage || mileage} className="input-select__control items-filter-location__distance-select">
                      {/* $FlowFixMe */}
                      {data.miles.map((option) => {
                        return (
                          <option
                            key={option.id}
                            value={option.id.toString()}
                          >
                            {option.id} miles of
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <input
                    type="tel"
                    ref={(ref) => { this.zipInput = ref; }}
                    onFocus={this.handleZipInputFocus}
                    onChange={this.handleZipChange}
                    defaultValue={zipCode}
                    placeholder="Zip Code"
                    size="5"
                    title="5-digit US Zip Code"
                    pattern="(\d{5})"
                    required
                    className="input items-filter-location__zip"
                  />
                  <Button
                    name="button"
                    type="submit"
                    buttonDisplay="icon-only"
                    buttonStyle="primary"
                    className="items-filter-location__btn"
                    title="Find Items"
                  >
                    GO
                  </Button>
                </form>
              }
            </label>
          </li>
        </ul>
      </FilterWrapper>
    );
  }
}

export default withRouter(FilterLocation);
