// @flow

import React, { Component } from 'react';

type Props = {
  type: 'Shipping' | 'Pickup',
};

class Unavailable extends Component<Props> {
  render() {
    const { type } = this.props;

    return (
      <tr className="bid-fulfillment-options__option">
        <td className="bid-fulfillment-options__option-cell" colSpan="2">
          <span className="bid-fulfillment-options__option-label bid-fulfillment-options__option-label--unavailable">
            {type} is unavailable for this item.
          </span>
        </td>
      </tr>
    );
  }
}

export default Unavailable;
