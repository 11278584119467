import React, { Component } from 'react';

import BuyerRegistrationForm from '~/public/buyer_registration_form/components/BuyerRegistrationForm';

import { useStripe, Elements, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

class BuyerRegistrationFormContainer extends Component {
  // the purpose of this container
  // is to wrap the BuyerRegistrationForm with Stripe Elements HOC
  // and a functional component (StripeWrapper) for Stripe hooks
  render() {
    const stripePromise = loadStripe(this.props.stripe_api_key);

    return (
      <Elements stripe={stripePromise}>
        <StripeWrapper {...this.props} />
      </Elements>
    );
  }
}

const StripeWrapper = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  // inject the new stripe token func into the old BuyerRegistrationForm
  return (
    <BuyerRegistrationForm stripe={stripe} elements={elements} {...props} />
  );
};

export default BuyerRegistrationFormContainer;
