// @flow
import humps from 'humps';
import URI from 'urijs';

const initialState = {
  isApplicationInitialized: false,
  isError: false,
  isLoading: false,
  items: [],
  filters: [],
  sorts: {
    data: [],
    preset: '',
    query: '',
  },
  pages: {
    currentPage: 1,
    itemsPerPage: 24,
    totalItems: 0,
    totalPages: 0,
  },
  filterCounts: {},
  savedSearches: [],
  followedItems: [],
  params: {
    pathParams: {},
    queryParams: {},
    parsedParams: {},
  },
  filterModalIsOpen: false,
  followSearchInFlight: false,
  followSearchFailure: false,
  followSearchFailureMessage: null,
  followItemLoggedOut: null,
  followItemUnsuccessful: false,
  itemsRequestId: null,
  appliedParameters: {
    properties: {},
  },
};

const buildThumbnailUrl = (url: ?string): ?string => {
  if (url != null) {
    return URI(url)
      .query('')
      .query({
        fit: 'crop',
        auto: 'format',
        w: 400,
        h: 400,
        q: 25,
      })
      .toString();
  } else {
    return null;
  }
};

const formatItem = (item) => {
  return {
    ...item,
    publicUrl: URI(item.publicUrl).resource(),
    saleStartsAt: new Date(item.saleStartsAt),
    saleEndsAt: new Date(item.saleEndsAt),
    thumbnailUrl: buildThumbnailUrl(item.mainImage),
  };
};

const itemsReducer = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case 'LOAD_FOLLOWED_ITEMS':
      return {
        ...state,
        followedItems: action.followedItems,
      };

    case 'LOAD_SAVED_SEARCHES':
      return {
        ...state,
        savedSearches: action.savedSearches,
      };

    case 'UPDATE_ITEM':
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              aasmState: action.aasmState,
              highBidAmount: action.highBidAmount,
              extended: action.extended,
              saleEndsAt: action.saleEndsAt,
            };
          } else {
            return item;
          }
        }),
      };

    case 'RESTORE': {
      const items = action.state.items.map(formatItem);

      return {
        ...state,
        ...action.state,
        items,
        isApplicationInitialized: true,
        isError: false,
        isLoading: false,
      };
    }

    case 'SET_PARAMS': {
      const { params } = action;

      return {
        ...state,
        params,
      };
    }

    case 'CLEAR_CATEGORY': {
      return {
        ...state,
        pathCategorySlug: null,
      };
    }

    case 'FETCH_ITEMS_REQUEST':
    case 'FETCH_ITEMS_PAGE_REQUEST':
      return {
        ...state,
        itemsRequestId: action.itemsRequestId,
        isLoading: true,
      };

    case 'FETCH_ITEMS_SUCCESS': {
      const results = humps.camelizeKeys(action.results);
      const {
        pages,
        sorts,
        filters,
        filterCounts,
      } = results;
      const items = results.items.map(formatItem);
      const { applied_parameters: appliedParameters } = action.results;
      appliedParameters.category = humps.camelizeKeys(appliedParameters.category);

      return {
        ...state,
        items,
        filters,
        pages,
        sorts,
        filterCounts,
        isApplicationInitialized: true,
        isLoading: false,
        isError: false,
        appliedParameters: appliedParameters,
      };
    }

    case 'FETCH_ITEMS_PAGE_SUCCESS': {
      const results = humps.camelizeKeys(action.results);
      const { pages } = results;
      const newItems = results.items.map(formatItem);
      const items = [...state.items, ...newItems];

      return {
        ...state,
        items,
        pages,
        isLoading: false,
        isError: false,
      };
    }

    case 'FETCH_ITEMS_FAILURE':
    case 'FETCH_ITEMS_PAGE_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case 'OPEN_FILTER_MODAL':
      return {
        ...state,
        filterModalIsOpen: true,
      };

    case 'CLOSE_FILTER_MODAL':
      return {
        ...state,
        filterModalIsOpen: false,
      };

    case 'FLASH_MESSAGE_DISPLAYED':
      return {
        ...state,
        flashMessage: null,
      };

    case 'FOLLOW_ITEM_INTENT_DISPLAYED':
      return {
        ...state,
        unsuccessfulFollowedItemId: null,
      };

    case 'FOLLOW_ITEM_REQUEST':
    case 'UNFOLLOW_ITEM_REQUEST':
      return {
        ...state,
      };

    case 'FOLLOW_ITEM_SUCCESS':
      return {
        ...state,
        followedItems: [...state.followedItems, action.id],
      };

    case 'FOLLOW_ITEM_UNSUCCESSFUL':
      return {
        ...state,
        unsuccessfulFollowedItemId: action.id,
      };

    case 'UNFOLLOW_ITEM_SUCCESS':
      return {
        ...state,
        followedItems: state.followedItems.filter((id) => id !== action.id),
      };

    case 'UNFOLLOW_ITEM_FAILURE':
    case 'FOLLOW_ITEM_FAILURE':
      return {
        ...state,
        flashMessage: action.flashMessage,
      };

    case 'FOLLOW_SEARCH_REQUEST':
      return {
        ...state,
        followSearchInFlight: true,
      };

    case 'FOLLOW_SEARCH_SUCCESS':
      return {
        ...state,
        followSearchInFlight: false,
        savedSearches: action.savedSearches,
      };

    case 'FOLLOW_SEARCH_FAILURE':
      return {
        ...state,
        followSearchFailure: true,
        followSearchFailureMessage: action.error,
        followSearchInFlight: false,
      };

    case 'FOLLOW_SEARCH_FAILURE_ALERTED':
      return {
        ...state,
        followSearchFailure: false,
        followSearchFailureMessage: null,
      };

    case 'FOLLOW_SEARCH_LOGGED_OUT_FAILURE':
      return {
        ...state,
        followSearchLoggedOut: action.params,
        followSearchInFlight: false,
      };

    case 'FOLLOW_SEARCH_LOGGED_OUT_FAILURE_ALERTED':
      return {
        ...state,
        followSearchLoggedOut: null,
      };

    case 'UNFOLLOW_SEARCH_REQUEST':
      return {
        ...state,
        followSearchInFlight: true,
      };

    case 'UNFOLLOW_SEARCH_SUCCESS':
      return {
        ...state,
        followSearchInFlight: false,
        savedSearches: action.savedSearches,
      };

    case 'UNFOLLOW_SEARCH_FAILURE':
      return {
        ...state,
        followSearchFailure: true,
        followSearchFailureMessage: action.error,
        followSearchInFlight: false,
      };

    case 'UNFOLLOW_SEARCH_FAILURE_ALERTED':
      return {
        ...state,
        followSearchFailure: false,
        followSearchFailureMessage: null,
      };

    default:
      return state;
  }
};

export default itemsReducer;
