// @flow

import { type Dispatch as ReduxDispatch } from 'redux';
import ApolloClient from 'apollo-client';
import { debounce } from 'lodash';

import connectionToArray from '~/utils/connectionToArray';

import itemSearchQuery from '~/admin/lasso/queries/ItemSearchQuery';
import salesQuery from '~/admin/lasso/queries/SalesQuery';
import removeItemsFromSale from '~/admin/lasso/mutations/RemoveItemsFromSaleMutation';
import addItemsToSale from '~/admin/lasso/mutations/AddItemsToSaleMutation';
import renameSaleMutation from '~/admin/lasso/mutations/renameSaleMutation';
import approveAutoMerchSaleMutation from '~/admin/lasso/mutations/ApproveAutoMerchSaleMutation';
import { buildItemSearchQueryParameters } from '~/admin/lasso/utils/elasticSearch';
import { type MerchSale } from '~/admin/lasso/types';
import { type State } from '~/admin/shared/reducers/merchandising';
import { displayNotice } from './notice';

export type SortKey =
  | 'CREATED_AT_DESC'
  | 'CREATED_AT_ASC'
  | 'ESTIMATED_VALUE_DESC'
  | 'ESTIMATED_VALUE_ASC'
  | 'CATEGORY_DESC'
  | 'CATEGORY_ASC'
  | 'NAME_DESC'
  | 'NAME_ASC';

export type FilterTargetType =
  | 'MERCH_ITEMS'
  | 'SALE_ITEMS'
  | 'SALES_CONTAINER';

export type FilterKey =
  | 'NAME'
  | 'CATEGORY'
  | 'PROCESSING_LOCATION_TYPES'
  | 'CONTRACT_REGION'
  | 'CONTRACT_TYPE'
  | 'HELD_FOR_SELECT'
  | 'ITEM_AASM_STATE_TYPE'
  | 'ITEM_INCOMPLETE';

export type FilterStore = { [key: FilterKey]: Array<string | number | boolean> };

export type FilterAction = {
  type: 'FILTER_SET' | 'FILTER_CLEAR',
  origin: FilterTargetType,
  filters: FilterStore,
};

export type SaleAction = {
  type: 'SALE_SET' | 'SALE_RENAME' | 'AUTO_MERCH_SALE_APPROVE',
  sale: $Shape<MerchSale>,
}
export type SalesAction = {
  type: 'SALES_SET' | 'SET_DESTINATION_SALE_ID' | 'SET_REGION_ID' | 'SET_SALES_STATUS',
  payload: any[] | number | string;
  totalSize?: number;
  hasNextPage?: boolean;
  endCursor?: string;
  sales?: any[];
}

export type SortAction = {
  type: 'SORT_SET',
  origin: FilterTargetType,
  key: SortKey,
}

export type ItemsAction = {
  type: 'ITEMS_MOVE' | 'ITEMS_MOVED' | 'ITEMS_SELECT' | 'ITEMS_DESELECT',
  origin: FilterTargetType,
  items: any[]
}

export type QueryAction = {
  type: 'ITEMS_QUERY',
  origin: FilterTargetType,
  items: any[],
  totalSize: number,
  startCursor: string,
  endCursor: string,
  hasNextPage: ?boolean,
  loading: boolean,
  reset: boolean,
}

export type SalesQueryAction = {
  type: 'ITEMS_QUERY',
  origin: FilterTargetType,
  items: any[],
  totalSize: number,
  startCursor: string,
  endCursor: string,
  hasNextPage: ?boolean,
  loading: boolean,
  reset: boolean,
}

export type PageAction = {
  type: 'PAGE_SET' | 'PAGE_RESET',
  origin: FilterTargetType,
  cursor: ?string,
  reset: boolean,
}

export type Action = FilterAction
  | SortAction
  | ItemsAction
  | QueryAction
  | SaleAction
  | PageAction
  | SalesAction;

type SalesParams = {
  first: number,
  regionId: number,
  state: 'created' | 'ended' | 'running',
  before?: string,
  after?: string,
}

// to determine where to move and item to, from a specific target
// moveTargetMapper.get('SALE_ITEMS') => outputs target that sale items should move to
export const moveTargetMapper: Map<FilterTargetType, FilterTargetType> = new Map([
  ['MERCH_ITEMS', 'SALE_ITEMS'],
  ['SALE_ITEMS', 'MERCH_ITEMS'],
]);

export const PAGE_SIZE = 50;

type GetState = () => { merchandising: State };

export const queryItems = (
  apolloClient: ApolloClient,
  origin: FilterTargetType,
  reset: boolean = false,
) => async (
  dispatch: ReduxDispatch<QueryAction>,
  getCurrentState: GetState,
) => {
  const state = getCurrentState().merchandising;
  const { cursor, filters: currentFilters, sort: currentSort } = state[origin];

  dispatch({
    type: 'ITEMS_QUERY',
    origin,
    items: null,
    loading: true,
    reset: false,
  });

  let saleId = null;
  let pageSize = PAGE_SIZE;

  if (origin === 'SALE_ITEMS') {
    saleId = state.sale ? state.sale.id : null;
    pageSize = 500;
  }
  try {
    const {
      data,
    } = await itemSearchQuery(apolloClient)({
      ...buildItemSearchQueryParameters(
        currentFilters,
        currentSort,
        {
          saleId,
          isSelectSale: state.sale ? state.sale.isConsignmentSelect : false,
        }
      ),
      pageSize: pageSize,
      startCursor: cursor,
    });

    if (data && data.items) {
      dispatch({
        type: 'ITEMS_QUERY',
        origin,
        items: connectionToArray(data.items),
        totalSize: data.items.totalCount,
        startCursor: data.items.pageInfo.startCursor,
        endCursor: data.items.pageInfo.endCursor,
        loading: false,
        reset: reset,
      });
    }
  } catch (e) {
    displayNotice({
      type: 'danger',
      message: e.toString(),
    })(dispatch);

    // reset page state on failure to avoid undefined state
    if (origin === 'MERCH_ITEMS') {
      dispatch({
        type: 'ITEMS_QUERY',
        origin,
        loading: false,
        startCursor: cursor,
        endCursor: cursor,
        hasNextPage: true, // We were already loading a page, so allow it again
        reset: false,
      });
    } else {
      dispatch({
        type: 'PAGE_RESET',
        origin,
        reset: reset,
      });
    }
  }
};

export const querySales = (
  apolloClient: ApolloClient,
  origin: FilterTargetType,
  reset: boolean = false,
  navigate: string = 'none',
) => async (
  dispatch: ReduxDispatch<SalesQueryAction>,
  getCurrentState: GetState,
) => {
  const state = getCurrentState().merchandising;
  dispatch({
    type: 'SALES_QUERY',
    origin,
    sales: null,
    loading: true,
    reset: false,
  });

  // if (origin === 'SALE_ITEMS') {
  //   saleId = state.sale ? state.sale.id : null;
  // }
  const params: SalesParams = {
    first: 1500,
    regionId: 11,
    state: 'created',
  };
  if (navigate && state.salesPageInfo.endCursor !== '' && state.salesPageInfo.endCursor !== null) {
    if (navigate === 'previous') {
      params.after = state.salesPageInfo.endCursor;
    }
    if (navigate === 'next') {
      params.before = state.salesPageInfo.endCursor;
    }
  }
  if (state.salesPageInfo.regionId !== null) {
    params.regionId = state.salesPageInfo.regionId;
  }
  if (state.salesPageInfo.salesStatus !== null) {
    params.state = state.salesPageInfo.salesStatus;
  }
  try {
    const {
      data,
    } = await salesQuery(apolloClient)(params);

    if (data && data.sales) {
      dispatch({
        type: 'SALES_SET',
        origin,
        sales: data.sales.nodes,
        totalSize: data.sales.pageInfo.totalCount,
        hasNextPage: data.sales.pageInfo.hasNextPage,
        endCursor: data.sales.pageInfo.endCursor,
        loading: false,
        reset: reset,
      });
    }
  } catch (e) {
    displayNotice({
      type: 'danger',
      message: e.toString(),
    })(dispatch);

    dispatch({
      type: 'SALES_QUERY',
      origin,
      loading: false,
      hasNextPage: true,
      reset: false,
    });
  }
};

export const setSale =
  (sale: MerchSale) => (
    (dispatch: ReduxDispatch<SaleAction>) => {
      dispatch({
        type: 'SALE_SET',
        sale,
      });
    }
  );

export const setSales =
(sales: MerchSale[]) => (
  (dispatch: ReduxDispatch<SaleAction>) => {
    dispatch({
      type: 'SALES_SET',
      sales,
    });
  }
);

export const setPage = (
  apolloClient: ApolloClient,
  origin: FilterTargetType,
  cursor: ?string,
) => async (
  dispatch: ReduxDispatch<SaleAction>,
  getCurrentState: GetState,
) => {
  dispatch({
    type: 'PAGE_SET',
    cursor,
    origin,
  });

  await queryItems(apolloClient, origin)(dispatch, getCurrentState);
};

export const nextPage = (
  apolloClient: ApolloClient,
  origin: FilterTargetType
) => async (
  dispatch: ReduxDispatch<SaleAction>,
  getCurrentState: GetState,
) => {
  const state = getCurrentState().merchandising;
  const { nextCursor } = state[origin];

  await setPage(apolloClient, origin, nextCursor)(dispatch, getCurrentState);
};

const debouncedQueryItems = debounce(
  (
    apolloClient: ApolloClient,
    origin: FilterTargetType,
    reset: boolean,
    dispatch: ReduxDispatch<QueryAction>,
    getCurrentState: GetState
  ) => queryItems(apolloClient, origin, reset)(dispatch, getCurrentState),
  500
);

export const setFilter = (
  client: ApolloClient,
  origin: FilterTargetType,
  key: $Keys<FilterStore>,
  values: $Values<FilterStore>
) => async (
  dispatch: ReduxDispatch<FilterAction>,
  getCurrentState: GetState,
) => {
  dispatch({
    type: 'FILTER_SET',
    origin,
    filters: { [key]: values },
  });

  // go back to top of page then clear out client side items
  dispatch({
    type: 'PAGE_RESET',
    origin,
    reset: false,
  });

  await debouncedQueryItems(client, origin, true, dispatch, getCurrentState);
};

export const setSort = (
  client: ApolloClient,
  origin: FilterTargetType,
  key: SortKey,
) => async (
  dispatch: ReduxDispatch<SortAction>,
  getCurrentState: GetState,
) => {
  // go back to top of page then clear out client side items
  dispatch({
    type: 'PAGE_RESET',
    origin,
    reset: false,
  });

  dispatch({
    type: 'SORT_SET',
    origin,
    key,
  });

  await queryItems(client, origin, true)(dispatch, getCurrentState);
};

export const selectItems = (
  origin: FilterTargetType,
  itemIds: string[],
) => (dispatch: ReduxDispatch<ItemsAction>) => {
  dispatch({
    type: 'ITEMS_SELECT',
    origin,
    items: itemIds,
  });
};

export const deselectItems = (
  origin: FilterTargetType,
  itemIds: string[],
) => (dispatch: ReduxDispatch<ItemsAction>) => {
  dispatch({
    type: 'ITEMS_DESELECT',
    origin,
    items: itemIds,
  });
};

export const moveItems = (
  client: ApolloClient,
  origin: FilterTargetType,
  itemIds: string[],
) => async (
  dispatch: ReduxDispatch<ItemsAction>,
  getCurrentState: GetState,
) => {
  const to = moveTargetMapper.get(origin);
  if (!to) { throw new Error('Unsupported destination to move item into'); }

  const state = getCurrentState().merchandising;
  const saleId = state.sale ? state.sale.id : null;
  if (!saleId) { return; }

  // TODO: error handle
  let items = [];

  if (to === 'SALE_ITEMS') {
    // eslint-disable-next-line no-alert
    if (itemIds.length > 1 && !window.confirm(
      `You are attempting to move ${itemIds.length} items into this sale.\n
      Are you sure you want to proceed?`
    )) { return; }

    const { data } = await addItemsToSale(client)({ saleId, itemIds });
    if (data) {
      ({ addItemsToSale: { items } } = data);
    }
  } else if (to === 'MERCH_ITEMS') {
    // eslint-disable-next-line no-alert
    if (itemIds.length > 1 && !window.confirm(
      `You are attempting to remove ${itemIds.length} items from this sale.\n
      Are you sure you want to proceed?`
    )) { return; }

    const { data } = await removeItemsFromSale(client)({ itemIds });
    if (data) {
      ({ removeItemsFromSale: { items } } = data);
    }
  }

  dispatch({
    type: 'ITEMS_MOVED',
    origin,
    items,
  });
};

export const moveSelectedItems = (
  client: ApolloClient,
  origin: FilterTargetType,
) => async (
  dispatch: ReduxDispatch<ItemsAction>,
  getCurrentState: GetState,
) => {
  const state = getCurrentState().merchandising;
  const { pendingMoveItems } = state[origin];

  await moveItems(client, origin, pendingMoveItems)(dispatch, getCurrentState);
};


export const renameSale = (
  client: ApolloClient,
  newName: string,
) => async (
  dispatch: ReduxDispatch<SaleAction>,
  getCurrentState: GetState,
) => {
  const { sale } = getCurrentState().merchandising;
  if (!sale) { throw new Error('No sale to change'); }

  try {
    const { data } = await renameSaleMutation(client)({ saleId: sale.id, name: newName });

    dispatch({
      type: 'SALE_RENAME',
      sale: data.updateSale.sale,
    });
  } catch (e) {
    displayNotice({
      type: 'danger',
      message: e.toString(),
    })(dispatch);
  }
};

export const setDestinationSaleId = (saleId: string) => (dispatch: ReduxDispatch<SalesAction>) => dispatch(
  {
    type: 'SET_DESTINATION_SALE_ID',
    payload: saleId,
  }
);

export const clearDestinationSaleId = () => (dispatch: ReduxDispatch<SalesAction>) => dispatch(
  {
    type: 'SET_DESTINATION_SALE_ID',
    payload: null,
  }
);

export const setRegionId = (regionId: number) => (dispatch: ReduxDispatch<SalesAction>) => dispatch(
  {
    type: 'SET_REGION_ID',
    payload: regionId,
  }
);

export const approveAutoMerchSale = (
  client: ApolloClient,
) => async (
  dispatch: ReduxDispatch<SaleAction>,
  getCurrentState: GetState,
) => {
  const { sale } = getCurrentState().merchandising;
  if (!sale) { throw new Error('No sale to approve'); }

  try {
    const { data } = await approveAutoMerchSaleMutation(client)({ saleId: sale.id });

    dispatch({
      type: 'AUTO_MERCH_SALE_APPROVE',
      sale: data.approveAutoMerchSale.sale,
    });

    displayNotice({
      type: 'success',
      message: 'The collected sale was approved successfully',
    })(dispatch);
  } catch (e) {
    displayNotice({
      type: 'danger',
      message: e.toString(),
    })(dispatch);
  }
};

export const resetPage = (origin: string) => (dispatch: ReduxDispatch<SalesAction>) => dispatch(
  {
    type: 'PAGE_RESET',
    reset: true,
    origin,
  }
);
