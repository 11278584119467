// @flow

import {
  ADD_PHOTOS,
  REMOVE_PHOTO,
  UPDATE_PHOTO,
  type PhotosActions,
} from '../actions';

import type { Photo } from '../types';

export type PhotosState = {
  invalid: Array<Photo>,
  valid: Array<Photo>
};

const initialState = {
  invalid: [],
  valid: [],
};

const VALID_STATUSES = ['WAITING', 'UPLOADING', 'SUCCESS'];

const reducePhotosToState = (photos: Array<Photo>): PhotosState => {
  return photos.reduce((acc: PhotosState, photo: Photo): PhotosState => {
    if (VALID_STATUSES.includes(photo.status)) {
      acc.valid.push(photo);
    } else {
      acc.invalid.push(photo);
    }
    return acc;
  }, { valid: [], invalid: [] });
};

const photosReducer = (state: PhotosState = initialState, action: PhotosActions): PhotosState => {
  const { valid, invalid } = state;

  switch (action.type) {
    case ADD_PHOTOS: {
      const allPhotos = [
        ...valid,
        ...invalid,
        ...action.photos,
      ];
      return reducePhotosToState(allPhotos);
    }
    case REMOVE_PHOTO: {
      const allPhotos = valid.concat(invalid);
      const { localKey } = action;
      const index = allPhotos.findIndex((photo) => photo.localKey === localKey);

      if (index === -1) {
        return state;
      }

      const newPhotos = [
        ...allPhotos.slice(0, index),
        ...allPhotos.slice(index + 1),
      ];

      return reducePhotosToState(newPhotos);
    }
    case UPDATE_PHOTO: {
      const allPhotos = valid.concat(invalid);
      // Flow cannot figure out the type inside of findIndex...
      const { localKey } = action;
      const index = allPhotos.findIndex((photo) => photo.localKey === localKey);

      if (index === -1) {
        return state;
      }

      const newPhotos = [
        ...allPhotos.slice(0, index),
        {
          ...allPhotos[index],
          ...action.properties,
        },
        ...allPhotos.slice(index + 1),
      ];

      return reducePhotosToState(newPhotos);
    }
    default:
      return state;
  }
};

export default photosReducer;
