// @flow

import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';

import ImageLoader from '../components/ImageLoader';
import TimeRemaining from '../components/TimeRemaining';
import withItem from '../queries/withItem';
import formatCurrency from '~/utils/formatCurrency';
import imgix from '../utils/imgix';

import type { Item } from '../types';

const thumbnailUrl = (attachment) => (
  imgix(attachment.imageUrl, { w: '200', h: '200', fit: 'crop' })
);

const bidLabel = (item: Item) => (item.state === 'ended' ? 'Final Bid' : 'New Bid');

type Props = {
  data: {
    item: Item,
    loading: boolean,
  },
  highBidAmount: number,
  minimumBidAmount: number,
  bidsCount: number,
  isCurrent: boolean,
  now: Date,
};

class Bid extends Component<Props> {
  renderState = (item: Item) => {
    if (item.state === 'ended') {
      return (
        <div className="feed-item__status feed-item__status--ended">
          Ended
        </div>
      );
    } else if (item.state === 'extended') {
      return (
        <div className="feed-item__status feed-item__status--extended">
          Extended
        </div>
      );
    } else if (item.state === 'ending') {
      return (
        <div className="feed-item__status feed-item__status--ending">
          Ending
        </div>
      );
    }
  }

  renderCurrentIndicator = () => {
    if (this.props.isCurrent) {
      return (
        <div className="feed-item__current-indicator">
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path d="M0 0h48v48H0z" fill="none" /><path d="M24 9C14 9 5.46 15.22 2 24c3.46 8.78 12 15 22 15s18.54-6.22 22-15C42.54 15.22 34.01 9 24 9zm0 25c-5.52 0-10-4.48-10-10s4.48-10 10-10 10 4.48 10 10-4.48 10-10 10zm0-16c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z" /></svg>
        </div>
      );
    }
  }

  renderImage(areImagesLoaded) {
    if (areImagesLoaded) {
      const { item } = this.props.data;
      return <img alt="secondary" src={thumbnailUrl(item.mainAttachment)} />;
    } else {
      return <div className="feed-image-empty-state" />;
    }
  }

  render() {
    if (this.props.data.loading) {
      return null;
    }

    const { item } = this.props.data;

    return (
      <Link to={`/auction-watch/${item.saleId}/items/${item.id}`} className={classNames('feed-item', { 'feed-item--current': this.props.isCurrent })}>
        {this.renderState(item)}
        {this.renderCurrentIndicator()}
        <ImageLoader
          images={[thumbnailUrl(item.mainAttachment)]}
          render={({ areImagesLoaded }) => this.renderImage(areImagesLoaded)}
        />
        <div className="feed-item__data-container">
          <div className="feed-item__data">
            <div className="data">
              <div className="data__label">
                {bidLabel(item)}
              </div>
              <div className="data__value qa-high_bid_amount">
                {formatCurrency(this.props.highBidAmount)}
              </div>
            </div>
          </div>
          <div className="feed-item__data">
            <div className="data">
              <div className="data__label">Time Remaining</div>
              <div className="data__value">
                <TimeRemaining until={moment(item.saleEndsAt)} />
              </div>
            </div>
          </div>
          <div className="feed-item__data">
            <div className="data">
              <div className="data__label">Bid Number</div>
              <div className="data__value">
                {this.props.bidsCount}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default compose(
  withItem
)(Bid);
