// @flow

import { type Dispatch as ReduxDispatch } from 'redux';

export const toggleItemDeleteStarted = () => (dispatch: ReduxDispatch) => {
  dispatch({ type: 'ITEM_DELETED_STARTED' });
};

export const toggleItemDeleteCompleted = () => (dispatch: ReduxDispatch) => {
  dispatch({ type: 'ITEM_DELETED_COMPLETE' });
};
