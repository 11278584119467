import { findLeaf } from '../utils/tree';

const events = {
  page: (_action, state) => {
    const { itemsPerPage, currentPage } = state.pages;
    const page_number = currentPage;
    const item_ids = state.items.slice(-itemsPerPage).map((i) => i.id);
    return { item_ids, page_number };
  },

  'Viewed Product Category': (_action, state) => {
    const id = state.params.parsedParams.category_id;
    const categoryFilter = state.filters.find((filter) => filter.component === 'category');
    const selectedCategoryTree = categoryFilter &&
      findLeaf(categoryFilter.data, id);
    const category = (selectedCategoryTree && selectedCategoryTree.name) || '';

    return { id, category };
  },

  'Item Followed': (action, _state) => {
    return { id: action.id };
  },
};

const getPayloadFor = (event, action, state) => {
  return events[event](action, state);
};

const handleAction = (store, next, action, _options) => {
  const result = next(action);

  if (!window.analytics) return result;
  if (!action.meta || !action.meta.analytics) return result;

  const { analytics } = action.meta;

  analytics.forEach((event) => {
    const payload = getPayloadFor(event, action, store.getState().items);

    if (event === 'page') {
      window.analytics.page(payload);
    } else {
      window.analytics.track(event, payload);
    }
  });

  return result;
};

export default (options = {}) => {
  return (store) => (next) => (action) => handleAction(store, next, action, options);
};
