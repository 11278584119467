import React, { useState } from 'react';

import Checkbox from './Checkbox';
import EditableInput from './EditableInput';
import { LoadingDots, Button } from '~/public/shared/components';

// Apollo queries and mutations
import { getPrefs, GET_TEXT_PREFS } from '../queries/preferences';
import updatePreferences from '../mutations/updatePreferences';
import updateNumber from '../mutations/updateNumber';

interface Preference {
  outbidAlert: boolean;
  pickupReminders: boolean;
}

interface PreferencesProps {
  className?: string;
  pref: Preference;
  onSavePrefs(prefs: Preference): any;
  saving: boolean,
  error?: any,
}

const Preferences: React.FC<PreferencesProps> = ({
  className,
  pref,
  onSavePrefs,
  saving,
  error,
}) => {

  const [state, nextState] = useState({
    outbidAlert: pref.outbidAlert,
    pickupReminders: pref.pickupReminders,
  });

  const handlePickupChange = (checked: boolean) => {
    nextState({
      ...state,
      pickupReminders: checked,
    });
  };

  const handleOutbidChange = (checked: boolean) => {
    nextState({
      ...state,
      outbidAlert: checked,
    });
  };

  const handleSave = () => {
    onSavePrefs(state);
  };

  return (
    <div className={className}>
      <Checkbox
        label="Outbid Alert Text Message"
        helperText="If you're outbid, we'll notify you immediately via text message. Message and data rates may apply."
        checked={state.outbidAlert}
        onChange={handleOutbidChange}
      />
      <Checkbox
        label="Pickup Reminder Text Message"
        helperText="For your convenience, we send periodic text message reminders about item pickup deadlines. These reminders will help you avoid additional shipping and handling charges. Message and data rates may apply."
        checked={state.pickupReminders}
        onChange={handlePickupChange}
        className="u-mt2 u-mb2"
      />
      <Button
        buttonStyle="primary"
        buttonSize="sm"
        onClick={handleSave}
        inFlight={saving}
      >
        Update Text Alert Preferences
      </Button>
      <p className="u-mt2 u-flex l-align-center">
        <a href="https://www.ebth.com/ebth-mobile-marketing" target="_blank" rel="noopener noreferrer">
        Mobile Marketing Terms & Conditions
        </a>
      </p>
    </div>
  );
}

const TextUpdates: React.FC = () => {

  const [ state, nextState ] = useState({
    proposedNumber: '',
    liveMutationError: false, // Used so we can determine when to pay attention to the mutation error
    editing: false,
  });

  const { loading, error, data, refetch, client } = getPrefs();
  const [ updatePreferencesMn, { loading: prefSaving, error: savePrefError } ] = updatePreferences();
  const [ updateNum, { loading: saveLoading, error: saveError } ] = updateNumber((cache, { data: { updateUserPhone }}) => {
    const { currentUser } = cache.readQuery({ query: GET_TEXT_PREFS});

    cache.writeQuery({
      query: GET_TEXT_PREFS,
      data: {
        currentUser: {
          ...currentUser,
          mobilePhone: updateUserPhone.mobilePhone,
        },
      },
    });

    // The number has been saved, so just clear it out of the state
    nextState({
      ...state,
      proposedNumber: '',
      liveMutationError: false,
      editing: false,
    });
  });

  const handleUpdateNumber = (val: string) => {
    // Save the current input as the proposedNumber
    nextState({
      ...state,
      proposedNumber: val,
      liveMutationError: true,
    });

    // Try to update the number remotely
    updateNum({ variables: { input: { mobilePhone: val } } });
  };

  const handleCancel = () => {
    nextState({
      ...state,
      proposedNumber: '',
      liveMutationError: false,
      editing: false,
    });

    refetch();
  };

  const handleStartEditing = () => {
    nextState({
      ...state,
      proposedNumber: '',
      liveMutationError: false,
      editing: true,
    });
  }

  // Triggers the mutation to save the preferences
  const handleSavePrefs = (prefs) => {
    updatePreferencesMn({
      variables: {
        input: {
          receiveOutbidSms: prefs.outbidAlert,
          receiveSaleEndingSms: prefs.pickupReminders,
        }
      }
    });
  };

  if (error) {
    return (<div>Error getting preferences</div>);
  }

  if (loading) {
    return (<LoadingDots />);
  }

  // Use either the proposed value or fall back to the data
  const editableValue = (state.proposedNumber != '') ? state.proposedNumber : data?.mobilePhone;

  // Hide the error if we're not honoring the mutation at this time
  const editableError = (state.liveMutationError) ? saveError : null;

  // Massage the preference data into an object
  const pref = {
    outbidAlert: data?.receiveOutbidSms,
    pickupReminders: data?.receiveSaleEndingSms,
  };

  return (
    <section aria-labelledby="textmessage">
      <h2 id="textmessage" className="u-mb1">Text Message Alerts</h2>
      <EditableInput
        addText="Add a Phone Number"
        emptyText="No phone number provided"
        value={editableValue}
        onSave={handleUpdateNumber}
        error={editableError}
        onCancel={handleCancel}
        saving={saveLoading}
        editing={state.editing}
        onStartEditing={handleStartEditing}
      />
      { data?.mobilePhone && !state.editing && <Preferences
        className='u-mt2'
        onSavePrefs={handleSavePrefs}
        pref={pref}
        saving={prefSaving}
        error={savePrefError} /> }
    </section>
  );
}

export default TextUpdates;
