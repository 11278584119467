// @flow

import React, { Component } from 'react';
import { compose } from 'react-apollo';

import { Button } from '~/public/shared/components';
import PhotoUploadDropAreaContainer from '../containers/PhotoUploadDropAreaContainer';
import QuestionSlide from './QuestionSlide';
import SubmitRequestContainer from '../containers/SubmitRequestContainer';

type Props = {
  onSubmit: () => any,
  canSubmit: boolean,
  isSubmitting: boolean,
};

export class PhotosSlideComponent extends Component<Props> {
  onSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // validations are done on the server so they can't be tampered with
    this.props.onSubmit();
  }

  render() {
    const { canSubmit, isSubmitting } = this.props;

    return (
      <div className="form-group form-group--large">
        <label className="form-group__label" htmlFor="upload-photos">Can you share some pictures of the item?</label>
        <PhotoUploadDropAreaContainer />
        <ul className="u-text-muted u-pl4 u-mt2">
          <li className="u-mb2">Photograph each item individually</li>
          <li className="u-mb2">Photograph against a neutral background</li>
          <li className="u-mb2">Include close-ups of important markings, signatures, stamps or signs of item condition</li>
          <li className="u-mb2">Please keep in mind that the higher quality the lighting and photo, the more accurate your estimate will be</li>
        </ul>
        <Button
          onClick={this.onSubmit}
          disabled={!canSubmit}
          inFlight={isSubmitting}
          buttonStyle="primary-important"
          buttonSize="lg"
          className="value-estimator__slide-action qa-price-estimator-submit"
        >
          Submit
        </Button>
      </div>
    );
  }
}

export default compose(QuestionSlide, SubmitRequestContainer)(PhotosSlideComponent);
