// @flow
import {
  type FilterTargetType,
  type FilterStore,
  type SortKey,
  type Action,
  moveTargetMapper,
  PAGE_SIZE,
} from '~/admin/shared/actions/merchandising';

import {
  type MerchSale,
  type MerchItem,
} from '~/admin/lasso/types';

export type State = {
  sale: ?MerchSale,
  // raw items returned from api, keyed by id
  salesPageInfo: {
    destinationSaleId?: null | number,
    totalSize: number,
    hasNextPage: boolean,
    endCursor: string,
    regionId: null | number,
    salesStatus: 'running' | 'ended' | 'created',
  },
  items: Map<string, MerchItem>,
  sales: any[],
  [key: FilterTargetType]: {|
    cursor: string,
    nextCursor: ?string,
    hasMorePages: boolean,
    filters: FilterStore,
    filtered: boolean,
    sort: SortKey,
    loading?: boolean,
    // this is the composite/final item list. use this to get list of viable items
    // for (fetchedItems - pendingItems + (pendingItems of other targets))
    // this is needed since ES and the DB entries are not in sync when moving items around
    items?: MerchItem[],
    totalSize: number,
    // items to be moved out of this target. selected items
    pendingMoveItems: string[],
  |}
};

const initialState: State = {
  sale: null,
  items: new Map(),
  salesPageInfo: {
    destinationSaleId: null,
    totalSize: 0,
    hasNextPage: false,
    endCursor: '',
    regionId: 11,
    salesStatus: 'created',
  },
  sales: [],
  MERCH_ITEMS: {
    loading: true,
    cursor: '',
    nextCursor: null,
    hasMorePages: true,
    items: [],
    totalSize: 0,
    pendingMoveItems: [],
    filters: {
      NAME: [''],
      HELD_FOR_SELECT: [false],
      ITEM_INCOMPLETE: [false],
    },
    filtered: false,
    sort: 'CREATED_AT_ASC',
  },
  SALE_ITEMS: {
    loading: true,
    cursor: '',
    nextCursor: null,
    hasMorePages: true,
    items: [],
    totalSize: 0,
    pendingMoveItems: [],
    filters: {
      NAME: [''],
    },
    filtered: false,
    sort: 'CREATED_AT_DESC',
  },
};

function getItems(allItems: MerchItem[], type: FilterTargetType) {
  let items = [];
  switch (type) {
    case 'MERCH_ITEMS':
      items = allItems.filter(({ sale }) => !sale);
      break;
    case 'SALE_ITEMS':
      items = allItems.filter(({ sale }) => !!sale);
      break;
    // no default
  }

  return items;
}

function reconcileItemStates(target: FilterTargetType, state: State): State {
  const destination = moveTargetMapper.get(target);
  if (!destination) { return state; }

  const { items } = state;
  const itemArray = Array.from(items.values());

  return {
    ...state,
    [target]: {
      ...state[target],
      items: getItems(itemArray, target),
    },
    [destination]: {
      ...state[destination],
      items: getItems(itemArray, destination),
    },
  };
}

function isFiltered(target: FilterTargetType, state: State) {
  const { filters } = state[target];

  // merge all the filters' values into a flattened array
  // then we remove empty things and null values
  const filterValues = []
    .concat(...Object.values(filters))
    .filter((value) => value !== '' && value !== null && value !== undefined);

  // if anything remains in the list then there must be
  // valid filters left
  return filterValues.length > 0;
}

export default (
  state: State = initialState,
  action: Action
): State => {
  switch (action.type) {
    case 'PAGE_RESET': {
      const { items } = state;
      if (action.reset) {
        // wipe out old items as they should no longer be shown or be re-queried for
        const affectedItems = getItems(Array.from(state.items.values()), action.origin);
        affectedItems.forEach(({ id }) => items.delete(id));
      }

      return {
        ...state,
        items,
        [action.origin]: {
          ...state[action.origin],
          loading: false,
          cursor: '',
          nextCursor: null,
          items: [],
          totalSize: 0,
          pendingMoveItems: [],
          hasMorePages: true,
        },
      };
    }
    case 'PAGE_SET':
      return {
        ...state,
        [action.origin]: {
          ...state[action.origin],
          cursor: action.cursor,
          nextCursor: null,
        },
      };
    case 'SALES_SET':
      return {
        ...state,
        salesPageInfo: {
          ...state.salesPageInfo,
          totalSize: action.totalSize,
          hasNextPage: action.hasNextPage,
          endCursor: action.endCursor,
        },
        sales: action.sales,
      };
    case 'SET_REGION_ID':
      return {
        ...state,
        salesPageInfo: {
          ...state.salesPageInfo,
          regionId: action.payload,
        },
      };
    case 'SET_SALES_STATUS':
      return {
        ...state,
        salesPageInfo: {
          ...state.salesPageInfo,
          salesStatus: action.payload,
        },
      };
    case 'SALE_SET':
      return {
        ...state,
        sale: action.sale,
      };
    case 'SALE_RENAME':
      return {
        ...state,
        sale: {
          ...state.sale,
          name: action.sale.name,
        },
      };
    case 'AUTO_MERCH_SALE_APPROVE':
      return {
        ...state,
        sale: action.sale,
      };
    case 'ITEMS_QUERY': {
      const { items } = state;

      let itemCount = 0;

      if (action.items) {
        // we purposefully get the original server response instead of the count
        // after removing some
        itemCount = action.items.length;

        if (action.reset) {
          getItems(
            Array.from(items.values()),
            action.origin,
          ).forEach(
            ({ id }) => items.delete(id)
          );
        }
        // we filter this list of items just incase the results include
        // items that shouldn't. IE: we search merch items but it returns with sale items
        // this can happen if ES indices are out of date... really would love to
        // make this filter occur on the server side instead
        const returnedItems = getItems(action.items, action.origin);

        // append to master list with items returned from api
        returnedItems.forEach((item) => items.set(item.id, item));
      }

      const hasNextPage = action.hasNextPage || (itemCount >= PAGE_SIZE && action.endCursor);

      return reconcileItemStates(action.origin, {
        ...state,
        items,
        [action.origin]: {
          ...state[action.origin],
          loading: action.loading,
          cursor: action.startCursor,
          nextCursor: action.endCursor,
          // if we got a full page, then we can assume there might be another
          hasMorePages: hasNextPage,
          totalSize: action.totalSize,
        },
      });
    }
    case 'FILTER_SET': {
      const newState = {
        ...state,
        [action.origin]: {
          ...state[action.origin],
          filters: {
            ...state[action.origin].filters,
            ...action.filters,
          },
          // clear selection when re-filtering to avoid
          // having items selected that are no longer visible
          pendingMoveItems: [],
        },
      };

      newState[action.origin].filtered = isFiltered(action.origin, newState);

      return newState;
    }
    case 'FILTER_CLEAR':
      return {
        ...state,
        [action.origin]: {
          ...state[action.origin],
          filters: {},
          filtered: false,
        },
      };
    case 'SORT_SET':
      return reconcileItemStates(action.origin, {
        ...state,
        [action.origin]: {
          ...state[action.origin],
          sort: action.key,
        },
      });
    case 'ITEMS_SELECT': {
      const targetOrigin: FilterTargetType = action.origin;
      const untargetedOrigin: FilterTargetType = targetOrigin === 'SALE_ITEMS' ? 'MERCH_ITEMS' : 'SALE_ITEMS';

      return {
        ...state,
        [targetOrigin]: {
          ...state[targetOrigin],
          pendingMoveItems: [
            ...state[targetOrigin].pendingMoveItems,
            ...action.items,
          ],
        },
        // It is currently not possible to move both MERCH_ITEMS and SALE_ITEMS in the same query.
        // To make this clear to the user, we deselect all selected items from the other panel.
        [untargetedOrigin]: {
          ...state[untargetedOrigin],
          pendingMoveItems: [],
        },
      };
    }
    case 'ITEMS_DESELECT': {
      const unselectItems = new Set(action.items);
      const pendingMoveItems = state[action.origin].pendingMoveItems
        .filter((id) => !unselectItems.has(id));
      return {
        ...state,
        [action.origin]: {
          ...state[action.origin],
          pendingMoveItems,
        },
      };
    }
    case 'ITEMS_MOVED': {
      const destination = moveTargetMapper.get(action.origin);
      if (!destination) { return state; }

      let originCount = state[action.origin].totalSize;
      let destCount = state[destination].totalSize;

      // update or add existing items into our item store
      const { items } = state;
      if (action.items) {
        action.items.forEach((item) => items.set(item.id, item));

        // update counts locally. rather than re-fetching counts
        // we can just change the value client side. the next time
        // we query for items the counts will be correct since ES will have run
        originCount -= action.items.length;
        destCount += action.items.length;
      }

      return reconcileItemStates(action.origin, {
        ...state,
        items,
        [action.origin]: {
          ...state[action.origin],
          // clear selected items after moving them
          pendingMoveItems: [],
          totalSize: originCount,
        },
        [destination]: {
          ...state[destination],
          totalSize: destCount,
        },
      });
    }
    case 'SET_DESTINATION_SALE_ID': {
      return {
        ...state,
        salesPageInfo: {
          ...state.salesPageInfo,
          destinationSaleId: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
