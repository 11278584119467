import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const UPDATE_PREFERENCES = gql`
  mutation UpdatePreferences($input: NotificationPreferenceInputType!) {
    updateNotificationPreference(input: $input) {
      receiveSaleEndingSms
      receiveOutbidSms
    }
  }
`;

const updatePreferences = () => {
  return useMutation(UPDATE_PREFERENCES);
};

export default updatePreferences;
