// @flow

import type { Item, ItemState } from '../types';

const itemState = (item: Item): ItemState => {
  if (item.aasmState === 'sold') {
    return 'ended';
  } else if (item.isExtended) {
    return 'extended';
  } else if (item.saleEndsAt < new Date()) {
    return 'ending';
  } else {
    // This probably should not happen.
    return 'unknown';
  }
};

export default itemState;
