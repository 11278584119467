import React, { useState } from "react";

import { Button } from '~/public/shared/components';
import { LoadingDots } from '~/public/shared/components';

interface EditableInputProps {
  addText: string;          // The text that will show up to add a value
  emptyText: string;        // The text to display on the emtpy state
  value: string;
  onSave(val: string): any; // Called when saving the number
  error?: any;
  onCancel(): any;
  onStartEditing(): any;
  editing: boolean;
  saving: boolean;
}

interface NoValueProps {
  addText: string;
  emptyText: string;
  onAdd(): any;
}

const NoValue: React.SFC<NoValueProps> = ({
  addText,
  emptyText,
  onAdd,
}) => {
  return (
    <div>
      <div className="u-text-muted u-mb1"><em>{emptyText}</em></div>
      <Button
        buttonSize="sm"
        buttonStyle="primary"
        onClick={onAdd}
      >
        {addText}
      </Button>
    </div>
  );
}

interface EditingValueProps {
  value: string;
  onSave(string): any;
  onCancel(): any;
  error?: any;
}

const EditingValue: React.FC<EditingValueProps> = ({
  value,
  onSave,
  onCancel,
  error,
}) => {

  const [state, setState] = useState(value);

  const handleChange = (event) => {
    setState(event.target.value);
  };

  const handleSave = () => {
    onSave(state);
  };

  let errMsg;
  if (error) {
    errMsg = error.graphQLErrors[0].message
  }

  const errStyle = {
    color: 'red',
  };

  return (
    <div className="width-50">
      <input
        type="text"
        autoFocus
        onChange={handleChange}
        defaultValue={state}
        className="form-control__input input u-mb1"
      />
      <div className="u-mb1" style={errStyle}>{ errMsg }</div>
      <Button
        buttonStyle="primary"
        buttonSize="sm"
        onClick={handleSave}
      >
        Save Changes
      </Button>
      <Button
        buttonDisplay="link"
        className="u-ml1"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  );
}

interface ValueSetProps {
  value: string;
  onClick(): any;
}

const ValueSet: React.SFC<ValueSetProps> = ({ value, onClick }) => {
  return (
    <>
      <strong>{value}</strong>
      <Button
        buttonDisplay="link"
        className="u-ml1"
        onClick={onClick}
      >
        Edit
      </Button>
    </>
  );
}

const EditableInput: React.FC<EditableInputProps> = ({
  addText,
  emptyText,
  value,
  onSave,
  error,
  onCancel,
  onStartEditing,
  editing,
  saving,
}) => {

  // Opens up the editing portion
  const setEditing = () => {
    onStartEditing();
  };

  const setNotEditing = () => {
    onCancel();
  };

  const saveValue = (newVal: string) => {
    onSave(newVal);
  };

  if (saving) {
    return <LoadingDots />
  }

  if (editing || error) {
    return <EditingValue value={value} onSave={saveValue} onCancel={setNotEditing} error={error} />;
  }

  if (!value) {
    return <NoValue addText={addText} emptyText={emptyText} onAdd={setEditing} />;
  }

  return <ValueSet value={value} onClick={setEditing} />;
}

export default EditableInput;
