// @flow
/* eslint-disable react/no-danger */

import moment from 'moment';
import React, { Component } from 'react';
import SaleHeaderSvgSprite from '~/public/svgs/SaleHeaderSvgSprite';
import {
  Badge,
  Button,
  Icon,
  TimeRemaining,
} from '~/public/shared/components';
import formatItemCount from '../utils/formatItemCount';
import Truncator from './Truncator';
import type { SaleStates } from '~/public/shared/types/Sale';

type Props = {
  saleCode: string,
  name: string,
  descriptionHtml: ?string,
  saleEndsAt: ?Date,
  state: SaleStates,
  itemCount: number,

  twitterShareUrl: string,
  facebookShareUrl: string,
  pinterestShareUrl: string,
  emailShareUrl: string,

  mainImageUrl: ?string,
  addToCalendarUrl: ?string,

  onFollowSale: () => any,
  onUnfollowSale: () => any,
  isFollowingSale: boolean,

  getReferenceTime: () => number,
};

class SaleHeader extends Component<Props> {
  maybeRenderMainImage = () => {
    if (!this.props.mainImageUrl) return null;

    return (
      <div className="sale-header__image-container">
        <img src={this.props.mainImageUrl} className="sale-header__image" alt="" />
      </div>
    );
  }

  renderFollowButton = () => {
    if (this.props.isFollowingSale) {
      return (
        <Button
          onClick={this.props.onUnfollowSale}
          buttonSize="sm"
          buttonStyle="primary"
          className="qa-follow-sale sale-header__action"
        >
          <Icon icon="heart" className="btn__icon" />
          Following This Sale
        </Button>
      );
    } else {
      return (
        <Button
          onClick={this.props.onFollowSale}
          buttonSize="sm"
          buttonStyle="secondary"
          className="qa-follow-sale sale-header__action"
        >
          <Icon icon="heart-outline" className="btn__icon" />
          Follow This Sale
        </Button>
      );
    }
  }

  maybeRenderAddToCalendar = () => {
    if (!this.props.addToCalendarUrl) return null;

    return (
      <a href={this.props.addToCalendarUrl} className="btn btn--sm btn--secondary sale-header__action">
        <Icon icon="calendar" className="btn__icon" />
        Add to Calendar
      </a>
    );
  }

  render() {
    const {
      itemCount,
      saleCode,
      name,
      saleEndsAt,
      state,
      getReferenceTime,
      facebookShareUrl,
      twitterShareUrl,
      pinterestShareUrl,
      emailShareUrl,
      descriptionHtml,
    } = this.props;

    return (
      <div className="sale-header">
        <div className="sale-header__container">
          {this.maybeRenderMainImage()}

          <div className="sale-header__details">
            <div className="sale-header__meta u-text-uppercase">
              <Badge className="u-mr2">{formatItemCount(itemCount)}</Badge>
              <span>Sale #{saleCode}</span>
            </div>
            <h1 className="sale-header__title">{name}</h1>

            <div className="sale-header__time">
              <TimeRemaining
                endsAt={saleEndsAt}
                aasmState={state}
                getReferenceTime={getReferenceTime}
                className="sale-header__time-remaining"
              />
              {saleEndsAt &&
                <span className="sale-header__end-datetime">
                  {moment(saleEndsAt).format('dddd, MMMM Do YYYY @ h:mma')}
                </span>
              }
            </div>

            {state !== 'ended' &&
              <div className="sale-header__actions">
                {this.renderFollowButton()}
                {this.maybeRenderAddToCalendar()}
                <div className="sale-header__action sale-header__share">
                  <a className="sale-header__share-link" href={facebookShareUrl} target="_blank" rel="noopener noreferrer">
                    <SaleHeaderSvgSprite icon="ic_facebook_box_normalized_24px" className="sale-header__social-icon" />
                  </a>
                  <a className="sale-header__share-link" href={twitterShareUrl} target="_blank" rel="noopener noreferrer">
                    <SaleHeaderSvgSprite icon="ic_twitter_box_normalized_24px" className="sale-header__social-icon" />
                  </a>
                  <a className="sale-header__share-link" href={pinterestShareUrl} target="_blank" rel="noopener noreferrer">
                    <SaleHeaderSvgSprite icon="ic_pinterest_box_normalized_24px" className="sale-header__social-icon" />
                  </a>
                  <a className="sale-header__share-link" href={emailShareUrl}>
                    <SaleHeaderSvgSprite icon="ic_email_normalized_24px" className="sale-header__social-icon sale-header__social-icon--email" />
                  </a>
                </div>
              </div>
            }
            {descriptionHtml &&
              <div className="sale-header__description u-mt4">
                <Truncator>
                  <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                </Truncator>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default SaleHeader;
