// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { Icon } from '~/public/shared/components';
import formatPickupTime from '../../utils/formatPickupTime';
import addressMapUrl from '../../utils/addressMapUrl';
import type { FulfillmentType } from '../../types';

const formatCurrency = (amount: number): string => {
  if (amount === 0) {
    return 'Free';
  } else {
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
};

type Props = {
  isPlacingBid: boolean,
  isSelected: boolean,
  fulfillmentType: FulfillmentType,
  onFulfillmentTypeSelect: (FulfillmentType) => void,
};

type State = {
  isExpanded: boolean,
};

class Pickup extends Component<Props, State> {
  state = {
    isExpanded: false,
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.isPlacingBid || !nextProps.isSelected) {
      this.setState({
        isExpanded: false,
      });
    }
  }

  toggleDetails = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  handleRadioOptionChange = () => {
    this.props.onFulfillmentTypeSelect(this.props.fulfillmentType);
  }

  render() {
    const {
      isPlacingBid,
      isSelected,
      fulfillmentType: {
        id,
        price,
        label,
        location,
        startsAtLocalized,
        endsAtLocalized,
        pickupInfo,
      },
    } = this.props;
    const {
      displayName,
      line1,
      line2,
      city,
      state,
      zip,
    } = location || {};
    const { isExpanded } = this.state;

    const optionLabelClasses = classNames(
      'bid-fulfillment-options__option-label',
      {
        'bid-fulfillment-options__option-label--selectable qa-fulfillment-pickup': isPlacingBid,
      }
    );

    const optionCellClasses = classNames(
      'bid-fulfillment-options__option-cell',
      'bid-fulfillment-options__option-cell--pickup',
      {
        'bid-fulfillment-options__option-cell--pickup-expanded': isExpanded,
      }
    );

    const mapUrl = location ? addressMapUrl(location) : '';
    const mapImageUrl = location ? location.mapImage : '';
    const inputKey = `fulfillment-${id}`;
    const style = {};
    const hasProcessingLocation = pickupInfo != null;

    if (mapImageUrl) {
      style.backgroundImage = `url(${mapImageUrl})`;
    }

    return (
      <tr className="bid-fulfillment-options__option">
        <td className="bid-fulfillment-options__option-cell">
          {isPlacingBid &&
            <input
              name="fulfillment_option"
              id={inputKey}
              value={id}
              type="radio"
              className="bid-fulfillment-options__option-input qa-pickup"
              onChange={this.handleRadioOptionChange}
              checked={isSelected}
            />
          }
          <label htmlFor={inputKey} className={optionLabelClasses}>
            {label}
            <div className="bid-fulfillment-options__option-cost">{formatCurrency(price)}</div>
          </label>
        </td>
        <td className={optionCellClasses} style={style}>
          <button
            type="button"
            aria-expanded={isExpanded}
            onClick={this.toggleDetails}
            className="bid-fulfillment-options__pickup-overlay"
            title={isExpanded ? 'Close details' : 'View pick up details'}
          >
            <span className="bid-fulfillment-options__pickup-overlay-text">
              {isExpanded ? <Icon icon="close" className="bid-fulfillment-options__pickup-overlay-icon" /> : 'Details'}
            </span>
          </button>
          <div className="bid-fulfillment-options__pickup-details">
            <div className="bid-fulfillment-options__pickup-details-description">
              <strong>{hasProcessingLocation ? displayName : `${city}, ${state}`}</strong>
              <br />
              {hasProcessingLocation ?
                line1
                : formatPickupTime({ startsAtLocalized, endsAtLocalized })
              }
              {isExpanded &&
                <div className="bid-fulfillment-options__pickup-details-location">
                  {!hasProcessingLocation &&
                    <div className="u-mt2">{line1}</div>
                  }
                  {line2 &&
                    <div>{line2}</div>
                  }
                  <div>{city}, {state} {zip}</div>
                  <a
                    href={mapUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Directions
                  </a>
                  {hasProcessingLocation &&
                    <div className="u-mt2">
                      {pickupInfo}
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default Pickup;
