// @flow

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FilterWrapper from './FilterWrapper'; import FilterSingleSelectItem from './FilterSingleSelectItem';
import { nextAppliedParams, paramsToUrl } from '../utils/url';
import type { Filter } from '../types';

type Props = {
  filter: Filter,
  isModalContext: boolean,
  appliedParams: Object,
  history: Object,
}

class FilterSingleSelect extends Component<Props> {
  render() {
    const { filter, isModalContext, appliedParams } = this.props;
    const { name, data, query } = filter;
    const checked = appliedParams[query] || filter.preset;

    // No options, do not render
    if (data.length === 0) {
      return null;
    }

    return (
      <FilterWrapper title={name}>
        <ul className="items-filter">
          {
            data.map((option) => {
              // Make a callback for the button
              const handleOnChange = () => {
                const nextParams = nextAppliedParams(appliedParams, (current) => {
                  // Only set it if it's not active
                  let val = null;
                  if (option.id !== 'active') {
                    val = option.id;
                  }

                  current[query] = val;
                  current.page = 1;
                  return current;
                });
                const url = paramsToUrl(nextParams);
                this.props.history.push(url);
              };

              return (
                <FilterSingleSelectItem
                  key={option.id}
                  option={option}
                  checked={checked === option.id}
                  onChange={handleOnChange}
                  isModalContext={isModalContext}
                />
              );
            })
          }
        </ul>
      </FilterWrapper>
    );
  }
}

export default withRouter(FilterSingleSelect);
