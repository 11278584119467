// @flow

import React, { type StatelessFunctionalComponent } from 'react';

export const PaymentItemsHeader: StatelessFunctionalComponent<{
  gridStyle: string,
}> = ({
  gridStyle,
}) => {
  return (
    <div className="index-listing__heading-row" style={{ gridTemplateColumns: gridStyle }}>
      <span className="index-listing__cell index-listing__cell--heading">Item</span>
      <span className="index-listing__cell index-listing__cell--heading">Sold On</span>
      <span className="index-listing__cell index-listing__cell--heading">Status</span>
      <span className="index-listing__cell index-listing__cell--heading">Winning Bid</span>
      <span className="index-listing__cell index-listing__cell--heading u-text-right">Estimated Yield</span>
    </div>
  );
};
