// @flow

import React, { Component } from 'react';
import CountUp from 'react-countup';

type Props = {
  amount: number,
};

type State = {
  previousAmount: number,
};

class CountUpCurrency extends Component<Props, State> {
  state = {
    previousAmount: this.props.amount,
  }

  componentWillReceiveProps({ amount }: Props) {
    if (this.props.amount !== amount) {
      this.setState({ previousAmount: this.props.amount });
    }
  }

  render() {
    return (
      <CountUp
        start={this.state.previousAmount}
        end={this.props.amount}
        prefix="$"
        separator=","
        useEasing={false}
        duration={2}
      />
    );
  }
}

export default CountUpCurrency;
