// @flow
export const icons = {
  add:
    'M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z',
  'account-circle':
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z',
  'arrow-down':
    'M7 10l5 5 5-5z',
  blocked:
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z',
  bookmark:
    'M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2z',
  'bookmark-outline':
    'M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z',
  calendar:
    'M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z',
  camera:
    'M12,15.2 C10.2326888,15.2 8.8,13.7673112 8.8,12 C8.8,10.2326888 10.2326888,8.8 12,8.8 C13.7673112,8.8 15.2,10.2326888 15.2,12 C15.2,13.7673112 13.7673112,15.2 12,15.2 Z M9,2 L15,2 L16.83,4 L20,4 C21.1,4 22,4.9 22,6 L22,18 C22,19.1 21.1,20 20,20 L4,20 C2.9,20 2,19.1 2,18 L2,6 C2,4.9 2.9,4 4,4 L7.17,4 L9,2 Z M12,17 C14.76,17 17,14.76 17,12 C17,9.24 14.76,7 12,7 C9.24,7 7,9.24 7,12 C7,14.76 9.24,17 12,17 Z',
  'chevron-down':
    'M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z',
  'chevron-left':
    'M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z',
  'chevron-right':
    'M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z',
  'chevron-up':
    'M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z',
  clock:
    'M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
  close:
    'M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z',
  contract:
    'M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7zm12-4h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z',
  earth:
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z',
  email:
    'M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z',
  'error-outline':
    'M12,7L12,7c0.55,0,1,0.45,1,1v4c0,0.55-0.45,1-1,1h0c-0.55,0-1-0.45-1-1V8C11,7.45,11.45,7,12,7z M11.99,2C6.47,2,2,6.48,2,12s4.47,10,9.99,10C17.52,22,22,17.52,22,12S17.52,2,11.99,2z M12,20c-4.42,0-8-3.58-8-8s3.58-8,8-8s8,3.58,8,8S16.42,20,12,20z M13,17h-2v-2h2V17z',
  fullscreen:
    'M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z',
  filter:
    'M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z',
  heart:
    'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z',
  'heart-outline':
    'M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z',
  help:
    'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z',
  'help-outline':
    'M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z',
  home:
    'M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z',
  info:
    'M12,2.21428571 C6.57142857,2.21428571 2.21428571,6.57142857 2.21428571,12 C2.21428571,17.4285714 6.57142857,21.7857143 12,21.7857143 C17.4285714,21.7857143 21.7857143,17.4285714 21.7857143,12 C21.7857143,6.57142857 17.4285714,2.21428571 12,2.21428571 Z M11.9285714,4.92857143 C13,4.92857143 13.7142857,5.78571429 13.7142857,6.64285714 C13.7142857,7.5 13.0714286,8.35714286 11.9285714,8.35714286 C10.8571429,8.35714286 10.2142857,7.5 10.2142857,6.64285714 C10.2142857,5.78571429 10.8571429,4.92857143 11.9285714,4.92857143 Z M9.42857143,19.0714286 L9.5,18.7142857 L9.78571429,18.7142857 C10.3571429,18.6428571 10.5714286,18.6428571 10.5714286,18.3571429 L10.5714286,13.0714286 C10.5714286,11.7857143 10.4285714,11.6428571 9.71428571,11.4285714 L9.28571429,11.3571429 L9.28571429,11.0714286 L13.4285714,9.92857143 L13.4285714,18.4285714 C13.4285714,18.6428571 13.5714286,18.6428571 14.4285714,18.7857143 L14.6428571,18.7857143 L14.7142857,19.1428571 L9.42857143,19.1428571 L9.42857143,19.0714286 Z',
  'info-outline':
    'M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z',
  lock:
    'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z',
  'more-vert':
    'M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z',
  'open-in-new':
    'M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z',
  parcel:
    'M22 17.11c.004.41-.222.79-.585.98l-8.784 4.932a1.102 1.102 0 0 1-1.262 0L2.585 18.09A1.094 1.094 0 0 1 2 17.11v-10c-.004-.41.222-.789.585-.98l8.784-4.932a1.102 1.102 0 0 1 1.262 0l8.784 4.932c.363.191.59.57.585.98v10zM12 3.387L9.903 4.572l6.542 3.8 2.181-1.231L12 3.387zM5.374 7.11L12 10.834l2.18-1.224-6.534-3.8-2.28 1.277.008.023zm-1.155 9.347l6.672 3.753v-7.454L4.219 9.01v7.447zm15.562 0V9.01l-6.672 3.746v7.454l6.672-3.753z',
  phone:
    'M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z',
  'pick-up':
    'M12,18H6V14H12M21,14V12L20,7H4L3,12V14H4V20H14V14H18V20H20V14M20,4H4V6H20V4Z',
  'playlist-add':
    'M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z',
  print:
    'M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z',
  receipt:
    'M19.5 3.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5zM19 19.09H5V4.91h14v14.18zM6 15h12v2H6zm0-4h12v2H6zm0-4h12v2H6z',
  remove:
    'M19 13H5v-2h14v2z',
  settings:
    'M19.14 12.936c.036-.3.06-.612.06-.936 0-.324-.024-.636-.072-.936l2.028-1.584a.496.496 0 0 0 .12-.612l-1.92-3.324c-.12-.216-.372-.288-.588-.216l-2.388.96a7.03 7.03 0 0 0-1.62-.936l-.36-2.544a.479.479 0 0 0-.48-.408h-3.84a.467.467 0 0 0-.468.408l-.36 2.544a7.219 7.219 0 0 0-1.62.936l-2.388-.96a.475.475 0 0 0-.588.216l-1.92 3.324a.465.465 0 0 0 .12.612l2.028 1.584c-.048.3-.084.624-.084.936 0 .312.024.636.072.936L2.844 14.52a.496.496 0 0 0-.12.612l1.92 3.324c.12.216.372.288.588.216l2.388-.96a7.03 7.03 0 0 0 1.62.936l.36 2.544c.048.24.24.408.48.408h3.84c.24 0 .444-.168.468-.408l.36-2.544a7.219 7.219 0 0 0 1.62-.936l2.388.96c.216.084.468 0 .588-.216l1.92-3.324a.465.465 0 0 0-.12-.612l-2.004-1.584zM12 15.6A3.61 3.61 0 0 1 8.4 12c0-1.98 1.62-3.6 3.6-3.6s3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z',
  'star-outline':
    'M23 9.104l-7.909-.694L12 1 8.909 8.42 1 9.105l6.006 5.294-1.804 7.869L12 18.092l6.798 4.175-1.793-7.869L23 9.104zm-11 6.895l-4.136 2.54 1.1-4.79-3.652-3.224 4.818-.425L12 5.59l1.881 4.521 4.818.425-3.652 3.224 1.1 4.79L12 16z',
  stats:
    'M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6h-6z',
  'success-outline':
    'M12,2 C17.52,2 22,6.48 22,12 C22,17.52 17.52,22 12,22 C6.48,22 2,17.52 2,12 C2,6.48 6.48,2 12,2 Z M12,20 C16.41,20 20,16.41 20,12 C20,7.59 16.41,4 12,4 C7.59,4 4,7.59 4,12 C4,16.41 7.59,20 12,20 Z M16.59,7.58 L18,9 L10,17 L6,13 L7.41,11.59 L10,14.17 L16.59,7.58 Z',
  tag:
    'M21.41,11.58 C21.77,11.94 22,12.45 22,13 C22,13.55 21.78,14.05 21.41,14.41 L14.41,21.41 C14.05,21.78 13.55,22 13,22 C12.45,22 11.95,21.78 11.59,21.42 L2.59,12.42 C2.22,12.05 2,11.55 2,11 L2,4 C2,2.9 2.9,2 4,2 L11,2 C11.55,2 12.05,2.22 12.41,2.58 L21.41,11.58 Z M13,20.01 L20,12.99 L11,3.99 L11,4 L4,4 L4,11 L13,20.01 Z M6.5,8 C5.67157288,8 5,7.32842712 5,6.5 C5,5.67157288 5.67157288,5 6.5,5 C7.32842712,5 8,5.67157288 8,6.5 C8,7.32842712 7.32842712,8 6.5,8 Z',
  top:
    'M9,20 L15,20 L15,14 L19,14 L12,7 L5,14 L9,14 L9,20 Z M5,3 L19,3 L19,5 L5,5 L5,3 Z',
  trash:
    'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z',
  trophy:
    'M21.993 5.187c-.001-.126-.004-.237-.004-.329h-3.61c.07-1.545.027-2.746.027-2.746A.119.119 0 0 0 18.29 2h-6.25c-.015 0-.03 0-.045.002C11.981 2 11.965 2 11.95 2H5.683a.119.119 0 0 0-.116.112s-.042 1.199.027 2.746H2.007A.656.656 0 0 0 2 4.97v1.203a.418.418 0 0 0 .008.112c.037 1.165.222 2.723.998 4.319a8.964 8.964 0 0 0 2.812 3.366c1 .73 2.144 1.254 3.401 1.566.726.776 1.443 1.203 2.067 1.374v1.59c-.09.474-.63 2.072-4.408 2.072h-.477a.114.114 0 0 0-.112.113v1.203c0 .061.05.112.112.112h11.2a.114.114 0 0 0 .112-.113v-1.203a.114.114 0 0 0-.112-.112h-.475c-4.432.005-4.414-2.085-4.414-2.085l.002-.228v-1.347c.624-.171 1.327-.598 2.049-1.367a9.704 9.704 0 0 0 3.42-1.572 8.964 8.964 0 0 0 2.811-3.367c1.057-2.178 1.019-4.287.998-5.42zM4.29 9.982c-.644-1.329-.814-2.668-.85-3.695h2.252c.04.398.087.79.15 1.16.378 2.264.901 4.264 1.889 6.029a7.587 7.587 0 0 1-3.44-3.494H4.29zm15.42 0a7.591 7.591 0 0 1-3.468 3.508c.99-1.769 1.51-3.773 1.889-6.043.06-.37.11-.762.149-1.16h2.281c-.036 1.027-.204 2.366-.851 3.695z',
  truck:
    'M1 6.827A1.835 1.835 0 0 1 2.827 5H17.52v3.654h2.74L23 12.316v4.575h-1.827a2.748 2.748 0 0 1-5.489 0H8.316a2.748 2.748 0 0 1-5.489 0H1V6.827zm18.806 10.057a1.374 1.374 0 1 0-2.748 0 1.367 1.367 0 0 0 1.374 1.374c.759 0 1.374-.615 1.374-1.374zM19.8 10.02h-2.28v2.295h4.077L19.8 10.028v-.007zM6.838 17.408a1.374 1.374 0 1 0-2.54-1.048 1.374 1.374 0 0 0 2.54 1.048z',
  'truck-fast':
    'M3.456 13.842l-.695-1.398h4.882l-.552-1.398H2.557l-.695-1.39h7.255l-.56-1.399H1.757L1 6.86h3.393A1.867 1.867 0 0 1 6.253 5h11.17v3.718h2.788L23 12.444V17.1h-1.86a2.796 2.796 0 0 1-5.584 0h-3.718a2.796 2.796 0 0 1-5.586 0H4.393v-3.258h-.937zm14.896 4.648a1.398 1.398 0 1 0-1.398-1.398 1.39 1.39 0 0 0 1.398 1.398zm1.39-8.381h-2.32v2.335h4.15l-1.83-2.328v-.007zm-10.7 8.381a1.398 1.398 0 1 0 0-2.796 1.398 1.398 0 0 0 0 2.796z',
  visibility:
    'M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z',
};
