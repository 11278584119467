// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { displayNotice } from '~/admin/shared/actions/notice';
import UploadTargetContainer from '../containers/UploadTargetContainer';

type Props = {
  itemId: string,
  photosUploading: (boolean) => any,
};

class UploadCard extends Component<Props> {
  renderContent() {
    return (
      <UploadTargetContainer
        itemId={this.props.itemId}
        photosUploading={this.props.photosUploading}
      >
        {(_dropzoneProps, onFileChange) => (
          <div className="card__content--grow">
            <div className="photo-upload__content photo-upload__content--inline">
              <div className="photo-upload__copy">
                Drag and Drop or&nbsp;
                <span className="custom-file-control custom-file-control--link">
                  <input
                    type="file"
                    id="upload-photos"
                    name="upload-photos"
                    accept="image/jpeg"
                    className="custom-file-control__input"
                    onChange={onFileChange}
                    multiple
                    value={''}
                  />
                  <label
                    htmlFor="upload-photos"
                    className="custom-file-control__label"
                  >
                    Select Files
                  </label>
                </span>
              </div>
            </div>
          </div>
        )}
      </UploadTargetContainer>
    );
  }

  render() {
    return (
      <div id="Upload" className="l-gallery__item draggable-item">
        {this.renderContent()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      displayNotice,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(UploadCard);
