// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

type Props = {
  itemId: number,
  isFollowing: boolean,
  onFollowClick: (id: number) => void,
};

type State = {
  btnText: string,
  isHoveringOnFollow: boolean,
};

class ItemFollowBtn extends Component<Props, State> {
  state = {
    btnText: 'Follow',
    isHoveringOnFollow: false,
  };

  componentDidMount() {
    this.setInitialButtonState();
  }

  componentWillReceiveProps(next: Object) {
    if (this.props.isFollowing !== next.isFollowing) {
      if (next.isFollowing === true) {
        this.setState({
          btnText: 'Following',
          isHoveringOnFollow: true,
        });
      } else {
        this.setState({
          btnText: 'Follow',
          isHoveringOnFollow: false,
        });
      }
    }
  }

  setInitialButtonState = () => {
    this.setState({
      btnText: this.props.isFollowing ? 'Following' : 'Follow',
      isHoveringOnFollow: false,
    });
  }

  handleClick = (e: SyntheticEvent<HTMLSpanElement>): void => {
    e.preventDefault();
    this.props.onFollowClick(this.props.itemId);
  }

  handleMouseEnter = () => {
    if (this.props.isFollowing) {
      this.setState({
        btnText: 'Unfollow',
      });
    }
  }

  handleMouseLeave = () => {
    this.setInitialButtonState();
  }

  render() {
    const { isFollowing } = this.props;
    const { isHoveringOnFollow, btnText } = this.state;

    const btnClasses = classNames(
      'btn-follow',
      {
        'btn-follow--following': isFollowing,
        'cancel-hover-style': isHoveringOnFollow,
      }
    );

    const btnTitle = isFollowing ? 'Unfollow this item' : 'Get reminded about this item';

    return (
      <button
        onClick={this.handleClick}
        aria-label="Get reminded about this item"
        aria-pressed={isFollowing}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={btnClasses}
        title={btnTitle}
        type="button"
      >
        <span className="btn-follow__label">{btnText}</span>
        <div className="btn-follow__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className="btn-follow__icon-fill">
            <path d="M15,7.74s-1.81-4-6-4c-4.58,0-7,3.89-7,7.86.09,6.48,13,14.6,13,14.6s13-8,13-14.6c0-4-2.5-7.86-6.91-7.86a7,7,0,0,0-6.05,4Z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className="btn-follow__icon-path">
            <path d="M15,7.74s-1.81-4-6-4c-4.58,0-7,3.89-7,7.86.09,6.48,13,14.6,13,14.6s13-8,13-14.6c0-4-2.5-7.86-6.91-7.86a7,7,0,0,0-6.05,4Z" />
          </svg>
        </div>
      </button>
    );
  }
}

export default ItemFollowBtn;
