// @flow

import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import createAnalytics from '../middlewares/analytics';
import createCache from '../middlewares/cache';
import items from '../reducers/items';
import route from '../reducers/router.js';

function devToolEnhancer() {
  // The list of actions in the Redux tools
  const actionCreators = {
  };

  if (window.devToolsExtension) {
    return window.devToolsExtension({ actionCreators });
  } else {
    return (noop) => noop;
  }
}

export default function configureStore() {
  const reducers = combineReducers({
    items,
    route,
  });

  const middlewares = [
    thunk,
    createAnalytics(),
    createCache(),
  ];

  const enhancer = compose(
    applyMiddleware(...middlewares),
    devToolEnhancer(),
  );

  const store = createStore(reducers, {}, enhancer);

  return store;
}
