
// @flow

import { type SidebarAction } from '../actions/sidebarActions';
import { type UploadModalAction } from '../actions/modalActions';


export type PendingItemsState = {
  sidebar: {
    id: string,
    open: boolean,
    type: string,
  },
  modal: {
    id: string,
    open: boolean,
    type: string,
  },
  upload: {
    id: string,
    polling: boolean,
  },
  remove: {
    polling: boolean,
  }
}
const initialState = {
  sidebar: {
    id: '',
    type: '',
    open: false,
  },
  modal: {
    id: '',
    type: '',
    open: false,
  },
  upload: {
    id: '',
    polling: false,
  },
  remove: {
    polling: false,
  },
};

export default (
  state: PendingItemsState = initialState,
  action: SidebarAction | UploadModalAction
): PendingItemsState => {
  switch (action.type) {
    case 'SIDEBAR_TOGGLE': {
      return {
        ...state,
        sidebar: {
          id: action.id || '',
          type: action.sidebarType,
          open: action.open,
        },
      };
    }
    case 'UPLOAD_MODAL_TOGGLE': {
      return {
        ...state,
        modal: {
          id: '',
          type: 'UPLOAD',
          open: action.open,
        },
      };
    }
    case 'DOWNLOAD_MODAL_TOGGLE': {
      return {
        ...state,
        modal: {
          id: '',
          type: 'DOWNLOAD',
          open: action.open,
        },
      };
    }
    case 'ITEM_DETAILS_MODAL_TOGGLE': {
      return {
        ...state,
        modal: {
          id: action.id,
          type: 'ITEM_DETAILS',
          open: action.open,
        },
      };
    }
    case 'UPLOAD_SIDEBAR_TOGGLE': {
      return {
        ...state,
        sidebar: {
          id: action.id,
          type: action.sidebarType,
          open: action.open,
        },
      };
    }
    case 'ITEM_UPLOAD_SUCCESS': {
      return {
        ...state,
        modal: {
          id: '',
          open: false,
          type: '',
        },
        upload: {
          id: action.id,
          polling: true,
        },
      };
    }
    case 'ITEM_UPLOAD_COMPLETE': {
      return {
        ...state,
        upload: {
          id: '',
          polling: false,
        },
      };
    }
    case 'ITEM_DELETED_STARTED': {
      return {
        ...state,
        remove: {
          polling: true,
        },
      };
    }
    case 'ITEM_DELETED_COMPLETE': {
      return {
        ...state,
        remove: {
          polling: false,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};
