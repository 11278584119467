import React, { Component } from 'react';
import ModalContainer from '../containers/ModalContainer';

class ModalLearnMore extends Component {
  render() {
    const eeText = 'The ' +
    /* EE_DISPLAY_NAME */'Everything Expedited Service' +
      ' payment is processed via your credit' +
      ' card with immediate verification and your purchase of the item is secured.' +
      ' Your purchase is prioritized and the processing begins immediately.' +
      ' Your purchase will be available for pickup or shipment sooner than the' +
      ' Everything Standard option. A ' +
    /* EE_DISPLAY_AMOUNT */'3.5%' +
      ' charge applies. You can opt out of the ' +
    /* EE_DISPLAY_NAME */'Everything Expedited Service' +
      ' by setting up ACH payment option in your profile settings.';
    const esText = 'Everything Standard Service payment is processed via ACH Payments.' +
     ' We will not begin processing this item generally for 5-10 business days after the' +
     ' sale closes. If you would like to secure your purchase sooner, please choose the' +
    /* EE_DISPLAY_NAME */'Everything Expedited Service' +
     ' Option.';
    return (
      <ModalContainer name="learnMore">
        <p style={{ marginBottom: 0 }}><strong>Everything Expedited</strong></p>
        <p>{ eeText }</p>
        <p style={{ marginBottom: 0 }}><strong>Everything Standard</strong></p>
        <p>{ esText }</p>
      </ModalContainer>
    );
  }
}

export default ModalLearnMore;
