// @flow

import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import type { Location, RouterHistory, Match } from 'react-router';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { ContractHeader } from '../components/ContractHeader';
import { ContractListing } from '../components/ContractListing';
import { PaymentStatus } from '../components/PaymentStatus';
import { PayableAddress } from '../components/PayableAddress';
import {
  Button,
  Icon,
  LoadingDots,
  Tooltip,
} from '~/public/shared/components';
import formatCurrency from '~/utils/formatCurrency';

const CONTRACT_QUERY = gql`
  query contract($id: ID!, $after: String) {
    contract(id: $id) {
      id
      number
      aasmState
      commissionRate
      commissionTypeId
      createdAt
      contractTypeId
      itemsCount
      totalEarnings
      averageItemValue
      payableToName
      payableToAttention
      payableToStreet
      payableToFloorSuite
      payableToCity
      payableToState
      payableToZip
      payableToCountry
      popularItems {
        id
        name
        mainAttachment {
          merchThumbnailUrl
        }
      }
      inboundParcel {
        shippingLabelPath
        aasmState
      }
      sellerInvoices(first: 21, after: $after) {
        edges {
          node {
            id
            number
            aasmState
            paymentDate
            totalToSeller
          }
        }

        totalCount

        pageInfo {
          endCursor
          hasNextPage
        }
      }
      totalItemValue
    }
  }
`;

const STATUS_COUNT_QUERY = gql`
  query sellerItemStatusCounts($contractId: ID) {
    sellerItemStatusCounts(contractId: $contractId) {
      processing
      preview
      live
      ended
      omitted
    }
  }
`;

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
};

class ContractDetailPage extends Component<Props> {
  componentDidMount() {
    const { match: { params: { contractNumber } } } = this.props;
    document.title = `Contract ${contractNumber || ''} | Seller Dashboard`;
  }

  renderItemStatus({
    contractNumber, count, key, label,
  }: { contractNumber: string, count: number, key: string, label: string}) {
    const itemCountLabel = count === 1 ? 'Item' : 'Items';
    const isZero = count === 0;

    return (
      <li className="dashboard-status-counts__item">
        {isZero &&
          <span className="dashboard-status-counts__item-count">0</span>
        }
        {!isZero &&
          <Link
            to={`/items?contracts[]=${contractNumber}&statuses[]=${key}`}
            className="dashboard-status-counts__item-count"
            title={`View ${label} ${itemCountLabel}`}
          >
            {count}
          </Link>
        }
        {label}
      </li>
    );
  }

  render() {
    const gridStyle = 'calc(80px + 1rem) 1fr 1fr 1fr 1fr';
    const { history, match } = this.props;
    const { params: { contractNumber } } = match;
    const id = parseInt((contractNumber || '').replace(/\D/g, ''), 10);
    const variables = { id };

    return (
      <Layout>
        <div className="u-mb2">
          <Button
            buttonStyle="text"
            buttonSize="lg"
            onClick={() => { history.goBack(); }}
          >
            <Icon icon="chevron-left" className="btn__icon" />
            Back
          </Button>
        </div>

        <ContractHeader gridStyle={gridStyle} />

        <Query notifyOnNetworkStatusChange query={CONTRACT_QUERY} variables={variables}>
          {({ loading, data, fetchMore }) => {
            const { contract } = data;

            // Initial load
            if (loading && !contract) {
              return (
                <div className="u-mt8 u-text-center">
                  <LoadingDots />
                </div>
              );
            }

            const {
              contract: {
                sellerInvoices: {
                  edges,
                  pageInfo: {
                    hasNextPage,
                    endCursor,
                  },
                },
              },
            } = data;

            const sellerInvoices = edges.map((e) => e.node);

            const loadMore = () => {
              fetchMore({
                variables: {
                  after: endCursor,
                },

                updateQuery: (previousResult, { fetchMoreResult }) => {
                  const {
                    contract: {
                      sellerInvoices: { totalCount, pageInfo, __typename },
                    },
                  } = fetchMoreResult;

                  const newEdges = [
                    ...previousResult.contract.sellerInvoices.edges,
                    ...fetchMoreResult.contract.sellerInvoices.edges,
                  ];

                  return {
                    contract: {
                      ...previousResult.contract,
                      sellerInvoices: {
                        __typename,
                        edges: newEdges,
                        pageInfo,
                        totalCount,
                      },
                    },
                  };
                },
              });
            };

            const isMailIn = contract.contractTypeId === 4;
            const { inboundParcel } = contract;
            const mailInBooked = isMailIn && inboundParcel && inboundParcel.aasmState === 'booked';
            const displayShippingLabel = isMailIn && mailInBooked;
            const isFlatCommission = contract.commissionTypeId === '1';

            return (
              <div className="expandable-listing expandable-listing--expanded">
                <ContractListing
                  contract={contract}
                  key={contract.id}
                  gridStyle={gridStyle}
                />

                <div className="expandable-listing__details">
                  <div className="u-text-right">
                    {displayShippingLabel &&
                      <a href={contract.inboundParcel.shippingLabelPath} target="_blank" rel="noopener noreferrer" className="btn btn--text-navigation u-mb1">
                        <Icon icon="print" className="btn__icon" />
                        Print Shipping Label
                      </a>
                    }
                    {/* TODO: Figure out where to source this PDF –
                    its in salesforce somewhere, can we get to it? */}
                    {/*
                    <a href={''}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn--text-navigation u-mb1 u-ml1"
                    >
                      <DashboardSvgSprite icon="pdf-icon" className="btn__icon" />
                      Contract Terms &amp; Conditions
                    </a>
                    */}
                  </div>

                  <table className="table table--beta u-m0">
                    <tbody>
                      <tr>
                        <td className="u-color-gray-400">Contract Status</td>
                        <td style={{ textTransform: 'capitalize' }}>{contract.aasmState}</td>
                      </tr>
                      <tr>
                        <td className="u-color-gray-400">Total Items</td>
                        <td>
                          <Link
                            to={`/items?contracts[]=${contract.number}`}
                            title="View contract items"
                          >
                            {contract.itemsCount}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="u-color-gray-400">Average Item Value</td>
                        <td>{formatCurrency(contract.averageItemValue, { showZeroes: true })}</td>
                      </tr>
                      <tr>
                        <td className="u-color-gray-400">Commission</td>
                        <td>{isFlatCommission ? `Flat (${contract.commissionRate}%)` : 'Variable'}</td>
                      </tr>
                      <tr>
                        <td className="u-color-gray-400">
                          Total Earnings
                          <Tooltip content="Only includes items for which a payment invoice has been generated.">
                            <Icon icon="info" size="18" className="u-ml1 u-text-muted" />
                          </Tooltip>
                        </td>
                        <td>{formatCurrency(contract.totalEarnings, { showZeroes: true })}</td>
                      </tr>
                      <tr>
                        <td className="u-color-gray-400">Payable To</td>
                        <td>
                          <PayableAddress
                            name={contract.payableToName}
                            attention={contract.payableToAttention}
                            street={contract.payableToStreet}
                            floorSuite={contract.payableToFloorSuite}
                            city={contract.payableToCity}
                            state={contract.payableToState}
                            zip={contract.payableToZip}
                            country={contract.payableToCountry}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {contract.itemsCount > 0 &&
                    <Fragment>
                      <h5 className="u-mb2">Item Status</h5>

                      <Query query={STATUS_COUNT_QUERY} variables={{ contractId: contract.id }}>
                        {({ error, data: statusCounts }) => {
                          if (error) return null;

                          const itemStatusPlaceholders = {
                            processing: '?',
                            preview: '?',
                            live: '?',
                            ended: '?',
                          };

                          const counts = {
                            ...itemStatusPlaceholders,
                            ...statusCounts.sellerItemStatusCounts,
                          };

                          return (
                            <ul className="dashboard-status-counts">
                              {this.renderItemStatus({
                                contractNumber: contract.number, key: 'processing', label: 'Processing', count: counts.processing,
                              })}
                              {this.renderItemStatus({
                                contractNumber: contract.number, key: 'preview', label: 'Preview', count: counts.preview,
                              })}
                              {this.renderItemStatus({
                                contractNumber: contract.number, key: 'live', label: 'Live', count: counts.live,
                              })}
                              {this.renderItemStatus({
                                contractNumber: contract.number, key: 'ended', label: 'Ended', count: counts.ended,
                              })}
                              {this.renderItemStatus({
                                contractNumber: contract.number, key: 'omitted', label: 'Omitted', count: counts.omitted,
                              })}
                            </ul>
                          );
                        }}
                      </Query>
                    </Fragment>
                  }

                  <h5 className="u-mb1">Payments</h5>

                  <table className="table table--beta u-mb0">
                    <thead>
                      <tr>
                        <th>Invoice</th>
                        <th>Status</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!sellerInvoices.length &&
                        <tr>
                          <td colSpan="3" className="u-text-center u-text-muted">No payments yet.</td>
                        </tr>
                      }
                      {sellerInvoices.map((sellerInvoice) => {
                        return (
                          <tr key={sellerInvoice.id}>
                            <td><Link to={`/payments/${sellerInvoice.number}`}>{sellerInvoice.number}</Link></td>
                            <td>
                              <PaymentStatus
                                aasmState={sellerInvoice.aasmState}
                                paymentDate={sellerInvoice.paymentDate}
                              />
                            </td>
                            <td><strong>{formatCurrency(sellerInvoice.totalToSeller)}</strong></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {hasNextPage &&
                    <div className="u-my4 u-text-center">
                      <Button
                        buttonStyle="secondary"
                        inFlight={loading}
                        onClick={loadMore}
                      >
                        Load More
                      </Button>
                    </div>
                  }
                </div>
              </div>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export { ContractDetailPage };
