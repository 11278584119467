// @flow

import React, { Component } from 'react';

import QuestionSlide from './QuestionSlide';
import TextInput from './TextInput';
import { validateName } from '../util/validators';

import type { EstimateRequest } from '../types';

type Props = {
  estimateRequest: EstimateRequest,
  onChange: (string, string) => any,
  onSubmit: () => any,
};

type State = {
  errors: {
    firstName: Array<string>,
    lastName: Array<string>,
  },
};

class NameSlide extends Component<Props, State> {
  state = {
    errors: {
      firstName: [],
      lastName: [],
    },
  }

  onSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const firstNameValidations = validateName(this.props.estimateRequest.firstName);
    const lastNameValidations = validateName(this.props.estimateRequest.lastName);

    if (firstNameValidations.valid && lastNameValidations.valid) {
      this.props.onSubmit();
    }

    this.setState({
      errors: {
        firstName: firstNameValidations.errors,
        lastName: lastNameValidations.errors,
      },
    });
  }

  render() {
    return (
      <div className="form-group form-group--large">
        <label className="form-group__label" htmlFor="estimate_request_first_name">What is your name?</label>
        <div className="l-form-grid">
          <div className="l-form-grid__row">
            <div className="l-form-grid__item l-form-grid__item--6-col">
              <TextInput
                id="estimate_request_first_name"
                placeholder="First Name"
                value={this.props.estimateRequest.firstName}
                onChange={(value) => this.props.onChange('firstName', value)}
                autoFocus
                errors={this.state.errors.firstName}
              />
            </div>
            <div className="l-form-grid__item l-form-grid__item--6-col">
              <TextInput
                id="estimate_request_last_name"
                placeholder="Last Name"
                value={this.props.estimateRequest.lastName}
                onChange={(value) => this.props.onChange('lastName', value)}
                errors={this.state.errors.lastName}
              />
            </div>
          </div>
        </div>
        <button
          onClick={this.onSubmit}
          className="btn btn--primary-important btn--lg value-estimator__slide-action"
          type="button"
        >
          Next Step
        </button>
      </div>
    );
  }
}

export default QuestionSlide(NameSlide);
