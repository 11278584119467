// @flow

type ValidationResult = { valid: boolean, errors: Array<string> };

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

export const validateEmail = (email: string): ValidationResult => {
  const errors = [];

  if (!email.length) {
    errors.push('Required');
  } else if (!EMAIL_REGEX.test(email)) {
    errors.push('Must be a valid email address');
  }

  return { valid: !errors.length, errors };
};

export const validateName = (name: string) => {
  const errors = [];

  if (!name.length) {
    errors.push('Required');
  }

  return { valid: !errors.length, errors };
};

export const validateZip = (zip: string) => {
  const errors = [];

  if (!zip.length) {
    errors.push('Required');
  } else if (zip.length !== 5 || !/^[0-9]{5}$/.test(zip)) {
    errors.push('Must be a valid zip code');
  }

  return { valid: !errors.length, errors };
};

export const validateDescription = (description: string) => {
  const errors = [];

  if (!description.length) {
    errors.push('Required');
  }

  return { valid: !errors.length, errors };
};
