// @flow

import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import CategoryTile from './CategoryTile';

type Props = {
};

const categories = [
  {
    id: 3259,
    name: 'Fashion and Accessories',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/16/13/24/27/eeff5ed6-3c3e-45a0-b68d-b575df01f791/fashion_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'fashion-and-accessories',
  },
  {
    id: 3313,
    name: 'Jewelry and Watches',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/30/23/b47c33a6-c99d-484a-9351-78420df713b6/jewerly_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'jewelry-and-watches',
  },
  {
    id: 3595,
    name: 'Kitchen and Housewares',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/31/39/61370993-ef18-4429-a2c6-f5d3810bcc79/kitchen_housewares_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'kitchen-and-housewares',
  },
  {
    id: 3013,
    name: 'Art',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/16/13/23/03/838badb5-d3cd-4822-b768-8eced263ef74/art_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'art',
  },
  {
    id: 3187,
    name: 'Electronics and Computers',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/33/44/737ecfd3-200b-43ee-948e-ff759cc76a77/electronics_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'electronics-and-computers',
  },
  {
    id: 3094,
    name: 'Collectibles',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/35/08/afcfa30d-55a0-40d1-b492-a325f9727527/collectibles_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'collectibles',
  },
  {
    id: 3556,
    name: 'Décor',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/36/49/d6dccc7d-0293-49a2-ba3a-14b911283425/decor_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'decor',
  },
  {
    id: 3892,
    name: 'Sports, Travel and Leisure',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/38/37/c61f2a95-4765-481d-8e59-8b09de03527c/sportsandtravel_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'sports-and-entertainment',
  },
  {
    id: 3799,
    name: 'Music and Instruments',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/41/04/92a58106-4a36-4a83-a91e-d475d7dec7b9/musicinstrument_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'music-and-instruments',
  },
  {
    id: 4483,
    name: 'Outdoor, Garden and Garage',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/13/59/12/f2e66182-d8b1-4063-bffb-5e381d90ec88/outdoor_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'outdoor-and-garden',
  },
  {
    id: 3472,
    name: 'Furniture',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/02/50/51c3025f-8fbb-40fe-b72a-3bde8d51c8c7/furniture_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'furniture',
  },
  {
    id: 4084,
    name: 'Toys and Games',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/04/20/93c5f09c-5729-42c1-9d10-b307d5491c85/toysandgames_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'toys-and-games',
  },
  {
    id: 3064,
    name: 'Automotive',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/10/28/16/e221d25a-bcda-4580-9c00-03dbe6c8a463/automotive_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'automotive',
  },
  {
    id: 4498,
    name: 'Home Improvement',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/05/12/3d60c031-2ac2-4628-8cb7-98176ffe1462/homeimprovement_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'home-improvement',
  },
  {
    id: 3391,
    name: 'Appliances',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/11/58/926e22d5-9211-4d6e-ad45-f73b5901fdd4/appliances_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'appliances',
  },
  {
    id: 3082,
    name: 'Books, Maps, Manuscripts and Ephemera',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/13/33/7d46ccb8-4c73-4612-bca8-6dfbb90c3fde/books_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'books-maps-manuscripts-and-ephemera',
  },
  {
    id: 4489,
    name: 'Lighting',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/14/10/40be4185-add4-42d6-82a3-ee07bf183bd5/lighting_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'lighting',
  },
  {
    id: 4480,
    name: 'Bed and Bath',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/16/29/dc9c70c6-4afe-4647-9515-e13c2f01590c/bedroom_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'bed-and-bath',
  },
  {
    id: 4153,
    name: 'Entertainment',
    parentId: null,
    originalMerchandisingImage: 'https://ebth-com-production.imgix.net/2021/11/17/14/17/36/87faee6c-e210-481c-9b3c-1e4abff7a68e/entertainment_L1_category.jpg?ixlib=rb-3.1.0',
    fullSlugPath: 'entertainment',
  },
];

class CategoryTiles extends Component<Props> {
  render() {
    const gridClasses = classNames('items-grid');

    return (
      <Fragment>
        <div className={gridClasses}>
          {categories.map((category) => {
            return (
              <CategoryTile key={category.id} category={category} />
            );
          })}
        </div>
        <hr className="hr hr--lg" />
      </Fragment>
    );
  }
}

export default withRouter(CategoryTiles);
