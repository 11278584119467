// @flow
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import NavigationSvgIcon from '~/admin/shared/svgs/NavigationSvgIcon';
import * as NoticeActions from './actions/notice';

import type { NoticeMessage } from './actions/notice';

type Props = {
  notice: ?NoticeMessage,
  dismissNotice: typeof NoticeActions.dismissNotice,
  removeNotice: typeof NoticeActions.removeNotice,
};

const Notice = ({ notice, dismissNotice, removeNotice }: Props) => {
  return (
    <Transition
      in={notice && notice.active}
      onExited={removeNotice}
      timeout={{ enter: 300, exit: 300 }}
    >
      {
        (state) => (
          notice &&
          <div key={notice.key} className={classNames(`notice-animation--${state}`, 'notice__message', `notice__message--${notice.type}`)}>
            {notice.message}
            <a className="notice__close" onClick={dismissNotice} title="Dismiss Message">
              <NavigationSvgIcon icon="ic_close_24px" className="notice__close-icon icon" />
            </a>
          </div>
        )
      }
    </Transition>
  );
};

const mapStateToProps = (state) => {
  return { notice: state.notice };
};
const mapDispatchToProps = (dispatch) => bindActionCreators(NoticeActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
