// @flow

import React, { Component } from 'react';
import { omit } from 'lodash';

// This inserts the SVG collection at the end of the document body (out of the
// html flow/hidden) if it doesn't already exist. We only need to have the
// collection in the DOM once to use the symbols by id.
function insertCollectionElement(svg: string, id: string) {
  const collectionSvg = document.getElementById(id);

  if (!collectionSvg) {
    const wrapper = document.createElement('div');
    wrapper.className = 'u-hide';
    wrapper.id = id;
    wrapper.innerHTML = svg;
    if (document.body) {
      document.body.appendChild(wrapper);
    }
  }
}


type Props = {
  icon: string,
  svg: string,
  svgId: string,
  svgRef: ?() => void,
};


class SvgIcon extends Component<Props> {
  constructor(props: Props) {
    super(props);
    insertCollectionElement(this.props.svg, this.props.svgId);
  }

  render() {
    // Pass through all props we aren't using for the component
    const cleanedProps = omit(this.props, ['icon', 'svg', 'svgId', 'svgRef']);
    return (
      <svg {...cleanedProps} ref={this.props.svgRef}>
        <use xlinkHref={`#${this.props.icon}`} />
      </svg>
    );
  }
}

export default SvgIcon;
