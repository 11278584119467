import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const UPDATE_NUMBER = gql`
  mutation UpdateUserPhone($input: UpdateUserPhoneInput!) {
    updateUserPhone(input: $input) {
      mobilePhone
    }
  }
`;

const updateNumber = (updateFn) => {
  return useMutation(UPDATE_NUMBER, {
    update: updateFn,
  });
};

export default updateNumber;
