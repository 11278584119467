// @flow

import React, { Component } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import basicInputWrapper from './basicInputWrapper';

export type SelectOption<T> = {
  label: string,
  value: T,
  type?: string,
};

type Props<T> = {
  className?: string,
  id: string,
  onChange: (Array<SelectOption<T>>) => void,
  options: Array<SelectOption<T>>,
  value: Array<SelectOption<T>>,
};

type ReactSelectOption<T> = { label: T, value: T } | T;

export const formatMultiSelectValues =
  <X>(labelKey: string, valueKey: string, arr: Array<*>): Array<SelectOption<X>> => {
    return arr.map((item): SelectOption<X> => {
      return ({ label: (item[labelKey]: string), value: item[valueKey] }: SelectOption<X>);
    });
  };

class MultiSelectInput<T> extends Component<Props<T>> {
  static defaultProps = {
    className: '',
    options: [],
  };

  handleChange = (options: Array<SelectOption<T>>) => {
    this.props.onChange(options.map((option) => {
      const type = option.value === option.label ? 'other' : 'id';

      return {
        ...option,
        type,
      };
    }));
  }

  render() {
    const {
      id,
      value,
      ...otherProps
    } = this.props;

    const newValue: Array<ReactSelectOption<T>> = value.map((v): ReactSelectOption<T> => {
      if (v.type === 'other') {
        const result: { label: T, value: T } = { label: v.value, value: v.value };
        return result;
      } else {
        return v.value;
      }
    });

    const wrapperClasses = classNames(
      'custom-select',
      this.props.className,
    );

    return (
      <div id={id} className="form-control__input">
        <Select
          {...otherProps}
          className={wrapperClasses}
          multi
          onChange={this.handleChange}
          value={newValue}
        />
      </div>
    );
  }
}

export default basicInputWrapper(MultiSelectInput);
