// @flow

import { type Dispatch as ReduxDispatch } from 'redux';
import { type RouterHistory } from 'react-router';

import { type State } from '~/admin/shared/reducers/screen';
import { type IValidator } from '~/admin/shared/utils/Validator';

export type { State };
export type { IValidator };

export type GetState = () => { screen: State };

export const TIMEOUT_DELAY = 43200000; // 12 hours in ms

export type StartTimeoutAction = {|
  type: 'START_TIMEOUT',
  timeoutId: TimeoutID,
|};

export type CancelTimeoutAction = {|
  type: 'CANCEL_TIMEOUT',
|};

export type StartScreenAction = {|
  type: 'START',
  loading: boolean,
  itemId: ID,
  taskType: string,
  newItem: boolean,
|};

export type GenericScreenAction = {|
  type: 'START_CONTINUE' | 'COMPLETE' | 'SAVE_DRAFT' | 'SAVE' | 'SEND_TO_QUEUE' | 'CANCEL',
  loading: boolean,
|};

export type ValidateAction = {|
  type: 'VALIDATE_FORM',
  validator: IValidator,
|};

export type Action =
  | StartTimeoutAction
  | CancelTimeoutAction
  | StartScreenAction
  | GenericScreenAction
  | ValidateAction;

export const promiseDispatcher = <T: { type: string }>(actionType: $PropertyType<T, 'type'>) => ({
  xhr,
}: {
  xhr: Promise<any>,
}) => (dispatch: ReduxDispatch<T>) => {
  dispatch({
    type: actionType,
    loading: true,
  });

  xhr.then(
    () => dispatch({
      type: actionType,
      loading: false,
    }),
    () => dispatch({
      type: actionType,
      loading: false,
    })
  );
};

export const returnPath = (history: RouterHistory): string | null => {
  const { state } = history.location;
  // state is something we define (its not part of react-router itself).
  // return path is set on: scanner page, queues, and queue pages
  if (state) {
    if (state.returnPath) {
      return state.returnPath;
    }
    if (state.windowPath) {
      window.location.href = state.windowPath;
    }
  }

  return null;
};
