// @flow

export type SettingsState = {
  autoPrintShippingLabels: boolean,
};

export type SettingsKeys = $Keys<SettingsState>;

export const SET_SETTING = 'SET_SETTING';
export type SetSettingAction = {
  type: 'SET_SETTING',
  key: SettingsKeys,
  value: any,
};

export type Actions =
  SetSettingAction;

// eslint-disable-next-line
export const setSetting = (key: SettingsKeys, value: any): SetSettingAction => (
  {
    type: SET_SETTING,
    key,
    value,
  }
);
