// @flow

import type { Photo } from '../types';

let localKey = 0;

export const initPhotoFromFile = (file: File): Photo => {
  const { name } = file;
  const extension = name.split('.').pop();
  const mimeType = file.type;

  return {
    file: file,
    name,
    mimeType,
    extension,
    imageUrl: null,
    // eslint-disable-next-line no-plusplus
    localKey: localKey++,
    uploadKey: null,
    status: 'WAITING',
  };
};

export const initPhotoFromExistingPhoto = (photo: Photo) => {
  const newPhoto = {
    ...photo,
    localKey,
    file: new File([''], photo.name),
    status: 'SUCCESS',
  };
  localKey += 1;
  return newPhoto;
};
