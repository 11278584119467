// @flow

import React, { Component } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import type { PayableToAddress } from './types';
import PayableToFormContainer from './containers/PayableToFormContainer';

const client = new ApolloClient({
  assumeImmutableResults: true,
  link: createHttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
  }),
  cache: new InMemoryCache({ freezeResults: true }),
});

type Props = {
  contractId: string,
  payableToAddress: PayableToAddress,
  redirectToPath: string
}

class Application extends Component<Props> {
  render() {
    return (
      <ApolloProvider client={client}>
        <PayableToFormContainer {...this.props} />
      </ApolloProvider>
    );
  }
}

export default Application;
