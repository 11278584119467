// @flow

import React, { Component } from 'react';

import QuestionSlide from './QuestionSlide';
import TextInput from './TextInput';
import { validateEmail } from '../util/validators';

import type { EstimateRequest } from '../types';

type Props = {
  estimateRequest: EstimateRequest,
  onChange: (string, string) => any,
  onSubmit: () => any,
};

type State = {
  errors: Array<string>,
};

class EmailSlide extends Component<Props, State> {
  state = {
    errors: [],
  }

  onChange = (email: string) => {
    this.props.onChange('email', email);
  }

  onSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { valid, errors } = validateEmail(this.props.estimateRequest.email);
    if (valid) {
      this.props.onSubmit();
    }
    this.setState({ errors });
  }

  render() {
    return (
      <div>
        <div className="value-estimator__wide">
          <h1 className="value-estimator__sub-heading">
            With a few pieces of information, we will provide a value estimate for any item
          </h1>
          <p className="u-text-muted u-pb4">Please note this process doesn’t include item authentication — that part comes later!</p>
          <hr className="hr hr--spaced" />
        </div>
        <TextInput
          id="estimate_request_email"
          value={this.props.estimateRequest.email}
          placeholder="your@email.com"
          label="Let’s start with your email address. Where can we notify you when your estimate is ready?"
          onChange={this.onChange}
          errors={this.state.errors}
          autoFocus
          type="email"
        />
        <button
          onClick={this.onSubmit}
          className="btn btn--primary-important btn--lg value-estimator__slide-action"
          type="button"
        >
          Next Step
        </button>
      </div>
    );
  }
}

export default QuestionSlide(EmailSlide);
