import React, { useState } from 'react';
import classNames from 'classnames';
import { Icon } from '~/public/shared/components';

function RowActions(props) {
  const {
    children,
  } = props;

  const [show, setShow] = useState(false);

  const actionClasses = classNames(
    'actions',
    { visible: show },
  );
  return (
    <div className="row-actions">
      <div className="icon-block" onClick={() => setShow(true)}>
        <Icon icon="more-vert" />
      </div>
      <div className={actionClasses}>
        <button type="button" className="close-btn" onClick={() => setShow(false)}>
          <Icon icon="close" />
        </button>
        {children}
      </div>
    </div>
  );
}

export default RowActions;
