// @flow

import React, { Component, type Node } from 'react';
import { Mutation, type MutationFn, type ApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

const MUTATION = gql`
  mutation approveItem($input: ApproveItemInput!) {
    approveItem(input: $input) {
      item {
        id
      }
    }
  }
`;

export type Params = {
  input: {
    itemId: ID,
  },
};

export type MutateFn = MutationFn<any, Params>;

type Props = {
  children: (approveItemFn: MutateFn) => Node,
};

class ApproveItemMutation extends Component<Props> {
  render() {
    return <Mutation mutation={MUTATION}>{(endTaskFn) => this.props.children(endTaskFn)}</Mutation>;
  }
}

export const approveItem = (client: ApolloClient) => {
  return async (variables: Params) => client.mutate({
    mutation: MUTATION,
    variables,
  });
};

export default ApproveItemMutation;
