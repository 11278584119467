// @flow

import React, { type Node, type StatelessFunctionalComponent } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string,
  type?: 'info' | 'success' | 'warning' | 'danger',
  message?: string | Node,
  icon?: Node,
};

export const Alert: StatelessFunctionalComponent<Props> = ({
  type, className, icon, message,
}) => {
  const classes = classNames(
    'section-notice',
    {
      [`section-notice--${type || ''}`]: type,
    },
    className
  );

  return (
    <div className={classes}>
      {icon && <div className="section-notice__icon-container">{icon}</div>}
      <div className="section-notice__content">{message}</div>
    </div>
  );
};
