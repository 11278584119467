// @flow

import React, { Component } from 'react';
import { createBrowserHistory as createHistory } from 'history';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router';

import { dismissNotice } from '~/admin/shared/actions/notice';
import { loadFeatureFlags } from '~/admin/shared/actions/featureFlags';

import NoticePortal from './containers/NoticePortal';
import Notice from './components/Notice';

import FormPage from './pages/FormPage';
import configureStore from './store';

type Props = {
  featureFlags: {},
};

const history = createHistory();
const store = configureStore();

history.listen((_location) => {
  store.dispatch(dismissNotice());
});

class Application extends Component<Props> {
  constructor(props: Props) {
    super();
    store.dispatch(loadFeatureFlags(props.featureFlags));
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div>
            <NoticePortal>
              <Notice />
            </NoticePortal>
            <Route exact path="/value-estimator" component={FormPage} />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default Application;
