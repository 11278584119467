// @flow

import React, { Component, Fragment, type Node } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import HardwareSvgIcon from '~/admin/shared/svgs/HardwareSvgIcon';

type Props = {
  className?: string,
  children?: Node,
  header?: Node | string,
  footer?: Node,
  collapsable?: boolean,
  collapsed?: boolean,
  collapseToolbar?: Node,
};

type State = {
  collapsed: boolean,
};

export class FilterBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      collapsed: props.collapsed === undefined ? false : props.collapsed,
    };
  }

  handleCollapseClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  renderHeader = () => (
    <div className="filter-bar__header">
      {this.props.header}
      {this.props.collapsable && (
        <span className="filter-bar__header-action" onClick={this.handleCollapseClick}>
          <HardwareSvgIcon icon="ic_keyboard_arrow_left_24px" className="icon" />
        </span>
      )}
    </div>
  );

  renderInnerContent = () => <div className="filter-bar__content">{this.props.children}</div>;

  renderFooter = () => {
    if (!this.props.footer) {
      return null;
    }

    return <div className="filter-bar__footer">{this.props.footer}</div>;
  };

  renderCollapseToolbar = () => (
    <div className="filter-bar__collapse-toolbar" onClick={this.handleCollapseClick}>
      {this.props.collapseToolbar}
    </div>
  );

  renderContent = () => {
    if (!this.state.collapsed) {
      return (
        <Fragment>
          {this.renderHeader()}
          {this.renderInnerContent()}
          {this.renderFooter()}
        </Fragment>
      );
    }

    return <Fragment>{this.renderCollapseToolbar()}</Fragment>;
  };

  render() {
    const classes = classNames('filter-bar', this.props.className);

    return (
      <CSSTransition in={!this.state.collapsed} timeout={200} classNames="filter-bar">
        <div className={classes}>{this.renderContent()}</div>
      </CSSTransition>
    );
  }
}
