// @flow

import React, { Component, Fragment } from 'react';
import moment from 'moment';

import timeRemaining from '~/public/shared/utils/timeRemaining';

type Props = {
  until: moment,
}

class TimeRemaining extends Component<Props> {
  render() {
    const { until } = this.props;

    return (
      <div className="data__value">
        {timeRemaining({ from: moment(), to: until }).map(({ unit, value }) => (
          <Fragment key={unit}>
            <span id={`qa-${unit}`} className="u-tabular-nums">{value}</span>
            <span className="data__unit">{unit[0]}</span>
          </Fragment>
        ))}
      </div>
    );
  }
}

export default TimeRemaining;
