// @flow

import React, { Component, type Node } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import type { ApolloError } from '~/types/apolloHelpers';
import type { ConnectionType, AttachmentType } from '../types';

const REORDER_PHOTOS_MUTATION = gql`
  mutation reorderAttachments($input: UpdateItemInput!) {
    updateItem(input: $input) {
      item {
        id
        attachments {
          edges {
            node {
              id
              thumbnailUrl
              isInternal

              createdAt
              createdBy {
                displayName
              }

              updatedAt
              updatedBy {
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

export type ReorderPhotosParams = {
  itemId: ID,
  attachments: ConnectionType<AttachmentType>,
};

export type ReorderPhotosFn = (reorderPhotosParams: ReorderPhotosParams) => any;
export type MutationMetadata = {
  loading: boolean,
};

type Props = {
  children: (ReorderPhotosFn, MutationMetadata) => Node,
  onSuccess: () => any,
  onError: (string) => any,
};

class ReorderPhotoMutation extends Component<Props> {
  reorderPhotos =
  (reorderPhotosMutation: Function) => (
    (reorderPhotosParams: ReorderPhotosParams) => {
      const variables = {
        input: {
          id: reorderPhotosParams.itemId,
          photos: reorderPhotosParams.attachments.edges.map(
            (edge, index) => ({ id: edge.node.id, sortOrder: index })
          ),
        },
      };

      const optimisticResponse = {
        updateItem: {
          __typename: 'UpdateItemPayload',
          item: {
            __typename: 'Item',
            id: reorderPhotosParams.itemId,
            attachments: reorderPhotosParams.attachments,
          },
        },
      };

      return reorderPhotosMutation({
        variables,
        optimisticResponse,
      });
    }
  )

  handleSuccess = () => {
    this.props.onSuccess();
  }

  handleError = (error: ApolloError) => {
    this.props.onError(error.message);
  }

  render = () => {
    return (
      <Mutation
        mutation={REORDER_PHOTOS_MUTATION}
        onCompleted={this.handleSuccess}
        onError={this.handleError}
      >
        {
          (reorderPhotosMutation, { loading }) => {
            return this.props.children(this.reorderPhotos(reorderPhotosMutation), { loading });
          }
        }
      </Mutation>
    );
  }
}

export default ReorderPhotoMutation;
