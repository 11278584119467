// @flow

import React, { Component } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import PubNub from 'pubnub';
import { FeatureFlagProvider } from '../dashboard/contexts/FeatureFlagContext';
import { bootstrap } from './actions/bootstrap';
import { updateItem } from './actions/item';
import { updateBid } from './actions/bid';
import DeliveryDetailsContainer from './containers/DeliveryDetailsContainer';
import configureStore from './store/configureStore';
import ItemEvents from './utils/ItemEvents';

import type {
  User,
  Item,
  Config,
  Bid,
} from './types';

Modal.setAppElement('body');

const client = new ApolloClient({
  assumeImmutableResults: true,
  link: createHttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
  }),
  cache: new InMemoryCache({ freezeResults: true }),
});

type Props = {
  context: string,
  user: ?User,
  item: Item,
  config: Config,
  bid: Bid,
  pubnub: {
    subscribeKey: string,
    ssl: boolean,
    suppressLeaveEvents: boolean,
    heartbeatInterval: number,
    keepAlive: boolean,
  },
  featureFlags: Object,
};

class DeliveryOptions extends Component<Props> {
  componentWillMount() {
    const { pubnub, item, user } = this.props;
    // configureStore must be called inside of render() because we're relying
    // on DeliveryOptions being re-rendered when a turbolinks page transition
    // takes place. If you touch this, you should verify that the store is not
    // persisting between pages.
    this.store = configureStore();
    this.store.dispatch(bootstrap(this.props));

    const pn = new PubNub(pubnub);
    const itemEvents = new ItemEvents(pn);
    this.unsubscribe = itemEvents.listen(item.id, (itemChange) => {
      this.store.dispatch(updateItem(itemChange));
      let bidUpdate = {
        id: itemChange.highBidId,
        amount: itemChange.highBidAmount,
      };

      if (user && itemChange.highBidUserId !== user.id) {
        bidUpdate = { ...bidUpdate, id: null, max: null };
      }

      this.store.dispatch(updateBid(bidUpdate));
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  store: any;

  unsubscribe: () => void;

  render() {
    const { featureFlags } = this.props;
    return (
      <Provider store={this.store}>
        <FeatureFlagProvider value={featureFlags}>
          <ApolloProvider client={client}>
            {this.props.config.context === 'ItemDetail' &&
              <DeliveryDetailsContainer />
            }
          </ApolloProvider>
        </FeatureFlagProvider>
      </Provider>
    );
  }
}

export default DeliveryOptions;
