// @flow

import { connect } from 'react-redux';
import ItemGrid from '../components/ItemGrid';
import * as ItemsActions from '../actions/items';

const mapStateToProps = (state) => {
  const { items, isLoading, followedItems, pages } = state.items;

  return {
    items,
    isLoading,
    followedItems,
    pages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    followItemToggle: (id) => dispatch(ItemsActions.followItemToggle(id)),
    updateItem: (itemChange) => dispatch(ItemsActions.updateItem(itemChange)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemGrid);
