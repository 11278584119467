// @flow

import React from 'react';
import classNames from 'classnames';

type Props = {
  activeTab: string,
  setActiveTab: (any) => null,
};

const PaymentsTableNav = ({ activeTab, setActiveTab }: Props) => {
  const invoiceClassNames = classNames({
    'payments-table-nav__tab': true,
    'payments-table-nav__tab--active': activeTab === 'invoices',
  });

  const itemClassNames = classNames({
    'payments-table-nav__tab': true,
    'payments-table-nav__tab--active': activeTab === 'items',
  });

  return (
    <div className="payments-table-nav">
      <div className={invoiceClassNames} onClick={() => setActiveTab('invoices')}>
        <span>Invoices</span>
      </div>
      <div className={itemClassNames} onClick={() => setActiveTab('items')}>
        <span>Sold Items <span>(Awaiting&nbsp;Invoice)</span></span>
      </div>
    </div>
  );
};

export { PaymentsTableNav };
