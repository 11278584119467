import update from 'immutability-helper';
import { find } from 'lodash';
import React, { Component } from 'react';

import { Flash } from '~/public/shared/components';
import BuyerInvoiceHelper from '~/public/buyer_invoices/utils/BuyerInvoiceHelper';
import PaymentMethodComponent from '~/public/shared/components/PaymentMethod';
import PaymentMethodHelper from '~/public/shared/utils/PaymentMethodHelper';
import ExpandButton from '~/public/shared/components/ExpandButton';
import ContentExpand from '../../bid/components/ContentExpand';
import { serviceText } from '../../bid/utils/paymentMethodHelpers';

import PaymentMethodContainer from '~/public/payment_methods/components/PaymentMethodContainer';

class PaymentMethods extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    const paymentMethodTypes = PaymentMethodHelper.paymentMethodTypes();

    this.paymentMethodTypes = paymentMethodTypes;

    const buyerInvoice = BuyerInvoiceHelper.initialize(props.addresses);
    const paymentOptions = BuyerInvoiceHelper.availablePaymentOptions(props.paymentMethods, {
      requireWireTransfer: props.requireWireTransfer,
      ableToPayAtPickup: props.ableToPayAtPickup,
    });

    this.state = {
      buyerInvoice,
      paymentOptions,
      paymentMethodType: paymentMethodTypes[0],
      addresses: props.addresses,
    };
  }

  handleAddNewPaymentMethod = (change) => {
    const newBuyerInvoice = update(
      this.state.buyerInvoice, {
        paymentMethod: { isAddingPaymentMethod: { $set: change } },
        paymentOption: { $set: null },
      }
    );

    this.setState({
      buyerInvoice: newBuyerInvoice,
    });
  }

  selectPaymentOption = (e) => {
    const { value } = e.target;
    const newBuyerInvoice = update(
      this.state.buyerInvoice, {
        paymentMethod: { isAddingPaymentMethod: { $set: false } },
        paymentOption: { $set: value },
      }
    );

    this.setState({
      buyerInvoice: newBuyerInvoice,
    });

    // Optionally submit an event
    if (window && window.events) {
      window.events.publish('payment_option', {});
    }
  }

  handlePaymentMethodChange = (paymentMethod) => {
    const newBuyerInvoice = update(
      this.state.buyerInvoice,
      { paymentMethod: { $set: paymentMethod } }
    );
    this.setState({ buyerInvoice: newBuyerInvoice });
  }

  handlePaymentMethodTypeChange = (value) => {
    const { buyerInvoice } = this.state;
    const newBuyerInvoice = update(buyerInvoice, {
      paymentMethod: { base: { errors: { $set: [] } } },
    });

    this.setState({
      paymentMethodType: PaymentMethodHelper.PAYMENT_METHOD_TYPES[value],
      buyerInvoice: newBuyerInvoice,
    });
  }

  isEcheckSelected = () => {
    const { buyerInvoice, paymentOptions } = this.state;
    const paymentOption = find(
      paymentOptions,
      (option) => option.id === buyerInvoice.paymentOption
    );
    return paymentOption && paymentOption.paymentMethodTypeId === 3;
  }

  renderErrors = () => {
    const { errors } = this.state.buyerInvoice.base;

    if (errors.length === 0) return null;

    return (
      <Flash
        flashStyle="error"
        showIcon
        className="u-mb2"
      >
        {errors.join(',')}
      </Flash>
    );
  }

  getMethodsMap = (methods, type) => {
    if (methods) {
      if (typeof type === 'string') {
        return methods.filter((method) => method[type] === true);
      } else {
        return methods.filter((method) => method.paymentMethodTypeId === type);
      }
    }
    return null;
  }

  renderPaymentOption = (option) => {
    const selectedValue = this.state.buyerInvoice.paymentOption;
    const htmlId = `payment_option_${option.id}`;
    const value = option.id;

    return (
      <li key={htmlId} className="ruled-list__item u-p0">
        <label htmlFor={htmlId} className="custom-control custom-control--radio u-py2">
          <input
            className="custom-control__input"
            type="radio"
            id={htmlId}
            name="payment_option"
            value={value}
            checked={value === selectedValue}
            onChange={this.selectPaymentOption}
          />
          <div className="custom-control__label">
            {
              option.isSpecialPaymentOption ?
                option.label :
                <PaymentMethodComponent paymentMethod={option} showActions={false} />
            }
          </div>
        </label>
      </li>
    );
  }

  renderSpecialInstructions = () => {
    const { requireWireTransfer } = this.props;

    if (requireWireTransfer) {
      return (
        <div className="box u-mt4">
          <h6 className="heading--sub">Special Payment Instructions</h6>
          <p>
            <strong>Invoices over $5,000 or including automobiles must be paid via wire transfer or cashier&rsquo;s check.</strong> For additional information, please see the following support article: &ldquo;<a target="_blank" rel="noopener noreferrer" href="https://support.ebth.com/s/article/What-payment-forms-are-accepted">What payment forms are accepted?</a>&rdquo;
          </p>
        </div>
      );
    } else {
      return null;
    }
  }

  renderPaymentOptions = () => {
    const {
      addresses,
      buyerInvoice,
    } = this.state;
    const {
      paypageId,
      paypageUrl,
      plaidEnvironment,
      useVantiv,
      requireWireTransfer,
      stripe_api_key,
    } = this.props;
    const { isAddingPaymentMethod } = buyerInvoice.paymentMethod;
    const pm = this.state.paymentOptions;
    const ccMethods = this.getMethodsMap(pm, 1);
    const achMethods = this.getMethodsMap(pm, 3);
    const special = this.getMethodsMap(pm, 'isSpecialPaymentOption');
    const canAddPayment = pm.filter(
      (method) => method.id === BuyerInvoiceHelper.NEW_PAYMENT_OPTION.id
    );

    return (
      <div>
        <hr className="hr" />

        <h3 className="u-mb2">Choose a Payment Service</h3>

        {this.renderSpecialInstructions()}

        {this.renderErrors()}

        {!requireWireTransfer &&
          <div>
            <h4 className="heading--sub u-mb0">Everything Expedited</h4>
            <ContentExpand
              expandText="Show Details"
              collapseText="Hide Details"
              content={serviceText(1)}
              reducedSize
            />
            <ul className="ruled-list u-mb4">
              { pm && ccMethods &&
                ccMethods.map(this.renderPaymentOption)
              }
              { pm && special &&
                special.map(this.renderPaymentOption)
              }
            </ul>

            <h4 className="heading--sub u-mb0">Everything Standard</h4>
            <ContentExpand
              expandText="Show Details"
              collapseText="Hide Details"
              content={serviceText(3)}
              reducedSize
            />
            <ul className="ruled-list u-mb2">
              { pm && achMethods &&
                achMethods.map(this.renderPaymentOption)
              }
              { pm && canAddPayment &&
                <li className="ruled-list__item u-p0">
                  <ExpandButton
                    topSpacing
                    text="Add a new payment method"
                    expanded={isAddingPaymentMethod}
                    onClick={this.handleAddNewPaymentMethod}
                  />
                </li>
              }
            </ul>
          </div>
        }
        { pm && special &&
          <ul className="ruled-list u-mb4">
            {special.map(this.renderPaymentOption)}
          </ul>
        }
        {isAddingPaymentMethod &&
          <div className="load-expansion">
            <PaymentMethodContainer
              addresses={addresses}
              paypageId={paypageId}
              paypageUrl={paypageUrl}
              plaidEnvironment={plaidEnvironment}
              useVantiv={useVantiv}
              stripe_api_key={stripe_api_key}
            />
          </div>
        }

        {this.isEcheckSelected() &&
          <div className="box u-mt2">
            <p>
              By checking out, you authorize High Road Holdings, LLC to electronically debit the selected
              bank account and, if necessary, electronically credit that account to correct
              erroneous debits.
            </p>
          </div>
        }
      </div>
    );
  }

  render() {
    return this.renderPaymentOptions();
  }
}

export default PaymentMethods;
