// @flow

import React, { Component } from 'react';
import { compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import type { ApolloClient } from 'apollo-client';
import type { Match } from 'react-router';

import Bid from '../containers/Bid';
import PrimaryItem from '../containers/PrimaryItem';
import ActivityFeed, { keyFor } from '../utils/ActivityFeed';
import SaleEvents from '../utils/SaleEvents';

import type { ItemChange } from '../types';

const SIDEBAR_LIMIT = 100;

const formatDateForApollo = (value): ?string => {
  if (value) {
    return value.toISOString();
  } else {
    return null;
  }
};

type Props = {
  client: ApolloClient,
  match: Match,
  saleEvents: SaleEvents,
};

type State = {
  events: ItemChange[],
  now: Date,
};

class SalePage extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.itemChanges = new ActivityFeed({ limit: SIDEBAR_LIMIT });

    this.state = {
      events: [],
      now: new Date(),
    };
  }

  componentWillMount = () => {
    this.interval = setInterval(() => {
      this.setState({
        now: new Date(),
      });
    }, 100);

    if (this.props.match.params.saleId) {
      this.unsubscribe = this.props.saleEvents.listen(
        this.props.match.params.saleId,
        this.handleItemChange
      );
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);

    if (this.props.match.params.saleId) {
      this.unsubscribe();
    }
  }

  // Looks like Flow has an IntervalID but its not working
  // https://github.com/facebook/flow/issues/5627
  interval: any

  itemChanges: ActivityFeed;

  unsubscribe: () => void;

  handleItemChange = (itemChange: ItemChange) => {
    this.recordEvent(itemChange);
    this.updateApolloItemCache(itemChange);
  }

  recordEvent = (itemChange: ItemChange) => {
    this.itemChanges.add(itemChange);

    this.setState({
      events: this.itemChanges.collection,
    });
  }

  updateApolloItemCache = (item) => {
    this.props.client.writeFragment({
      id: `Item:${item.id}`,
      fragment: gql`
        fragment item on Item {
          aasmState
          bidsCount
          highBidAmount
          isExtended
          saleEndsAt
          __typename
        }
      `,
      data: {
        ...item,
        saleEndsAt: formatDateForApollo(item.saleEndsAt),
        __typename: 'Item',
      },
    });
  }

  render() {
    const { itemId } = this.props.match.params;

    return (
      <div className="l-content">
        <div className="l-content__main">
          {itemId && <PrimaryItem itemId={itemId} now={this.state.now} />}
        </div>

        <div className="l-content__secondary qa-sidebar">
          {this.state.events.map((itemChange) => (
            <Bid
              now={this.state.now}
              itemId={itemChange.id}
              highBidAmount={itemChange.highBidAmount}
              bidsCount={itemChange.bidsCount}
              key={keyFor(itemChange)}
              isCurrent={itemChange.id === itemId}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default compose(
  withApollo
)(SalePage);
