// @flow

import React, { Component } from 'react';

class HomePage extends Component<{}> {
  render() {
    return (
      <h1>Home</h1>
    );
  }
}

export default HomePage;
