// @flow

import config from 'config';
import buildAnalytics from '~/vendor/analytics.js-loader';

export type AnalyticsClient = {
  track: (eventName: string, params: Object) => any,
};

type AnalyticsClientBuilder = () => AnalyticsClient;
let instantiationCount = 0;

export function createAnalyticsClient(): AnalyticsClient {
  instantiationCount += 1;
  if (instantiationCount > 1) {
    console.error(`Analytics.js built ${instantiationCount} times. This can only be done once.`);
  }

  if (process.env.RAILS_ENV === 'production' || process.env.RAILS_ENV === 'staging') {
    return buildAnalytics({
      skipPageCall: true,
      ...config.segment,
    });
  } else {
    const analytics = {
      trackSubmit: () => {},
      trackClick: () => {},
      trackForm: () => {},
      pageview: () => {},
      identify: (userId, args = {}) => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line
          console.log(`
=== ANALYTICS IDENTIFY ===

User: ${userId}
Arguments: ${JSON.stringify(args, null, 2)}
          `);
        }
      },
      group: () => {},
      track: (eventName, args) => {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line
          console.log(`
=== ANALYTICS EVENT ===

Event: ${eventName}
Arguments: ${JSON.stringify(args, null, 2)}
          `);
        }
      },
      ready: () => {},
      reset: () => {},
      alias: () => {},
      page: () => {},
      once: () => {},
      off: () => {},
      on: () => {},
    };

    window.analytics = analytics;
    return analytics;
  }
}

type ObjectWithId = {
  id: ID,
};

type SimilarItemType = {
  +item: ObjectWithId,
};

type TrackEventOptions = {
  startNewSession?: boolean,
};

const generateSessionId = (): number => {
  const randomValues = new Uint32Array(1);
  window.crypto.getRandomValues(randomValues);
  return randomValues[0];
};

export class Analytics {
  client: AnalyticsClient

  sessionId: number

  constructor(clientBuilder: AnalyticsClientBuilder = createAnalyticsClient) {
    this.client = clientBuilder();
    this.resetSession();
  }

  resetSession = () => {
    this.sessionId = generateSessionId();
  }

  trackEvent = (eventName: string, params: Object, options: TrackEventOptions = {}) => {
    if (options.startNewSession) { this.resetSession(); }

    this.client.track(eventName, {
      ...params,
      sessionId: this.sessionId,
    });
  }

  itemAttributionCompleted(item: ObjectWithId): void {
    this.trackEvent(
      'Item Attribution Completed',
      { itemId: item.id }
    );
  }

  itemAttributionSaved(item: ObjectWithId): void {
    this.trackEvent(
      'Item Attribution Saved',
      { itemId: item.id }
    );
  }

  itemAttributionStarted(item: ObjectWithId): void {
    this.resetSession();

    this.trackEvent(
      'Item Attribution Started',
      { itemId: item.id },
      { startNewSession: true }
    );
  }

  itemCatalogingCategoryChanged(params: {
    item: ObjectWithId,
    oldCategory: ?ObjectWithId,
    newCategory: ?ObjectWithId,
  }): void {
    this.trackEvent(
      'Item Cataloging Category Changed',
      {
        itemId: params.item.id,
        oldCategoryId: params.oldCategory ? params.oldCategory.id : null,
        newCategoryId: params.newCategory ? params.newCategory.id : null,
      }
    );
  }

  itemCatalogingCompleted(item: ObjectWithId): void {
    this.trackEvent(
      'Item Cataloging Completed',
      { itemId: item.id }
    );
  }

  itemCatalogingApproved(item: ObjectWithId): void {
    this.trackEvent(
      'Item Approved',
      { itemId: item.id }
    );
  }

  itemCatalogingPreviewed(item: ObjectWithId): void {
    this.trackEvent(
      'Item Cataloging Previewed',
      { itemId: item.id }
    );
  }

  itemCatalogingSaved(item: ObjectWithId): void {
    this.trackEvent(
      'Item Cataloging Saved',
      { itemId: item.id }
    );
  }

  itemCatalogingStarted(item: ObjectWithId): void {
    this.trackEvent(
      'Item Cataloging Started',
      { itemId: item.id },
      { startNewSession: true }
    );
  }

  itemCreatedByBarcode(barcode: string): void {
    this.trackEvent(
      'Item Created By Barcode',
      { barcode }
    );
  }

  itemPackingBlocked(item: ObjectWithId): void {
    this.trackEvent(
      'Item Packing Blocked',
      { itemId: item.id }
    );
  }

  itemPackingCompleted(
    params: {
      item: ObjectWithId,
      parcelIds: $ReadOnlyArray<ID>,
    }
  ): void {
    this.trackEvent(
      'Item Packing Completed',
      {
        itemId: params.item.id,
        parcelIds: params.parcelIds,
      }
    );
  }

  itemPackingStarted(item: ObjectWithId): void {
    this.trackEvent(
      'Item Packing Started',
      { itemId: item.id },
      { startNewSession: true }
    );
  }

  itemPhotoEditingCompleted(item: ObjectWithId): void {
    this.trackEvent(
      'Item Photo Editing Completed',
      { itemId: item.id }
    );
  }

  itemPhotoEditingStarted(item: ObjectWithId): void {
    this.trackEvent(
      'Item Photo Editing Started',
      { itemId: item.id },
      { startNewSession: true }
    );
  }

  itemSimilarItemsNotFound(item: ObjectWithId): void {
    this.trackEvent(
      'Item Similar Items Not Found',
      { itemId: item.id }
    );
  }

  itemSimilarItemsRejected(
    params: {
      item: ObjectWithId,
      similarItems: $ReadOnlyArray<SimilarItemType>,
      suggestedCategory: ?ObjectWithId,
    }
  ): void {
    this.trackEvent(
      'Item Similar Items Rejected',
      {
        itemId: params.item.id,
        similarItemIds: params.similarItems.map((similarItem) => similarItem.item.id),
        suggestedCategoryId: params.suggestedCategory && params.suggestedCategory.id,
      }
    );
  }

  itemSimilarItemsSelected(
    params: {
      item: ObjectWithId,
      similarItems: $ReadOnlyArray<SimilarItemType>,
      suggestedCategory: ?ObjectWithId,
    }
  ): void {
    this.trackEvent(
      'Item Similar Items Selected',
      {
        itemId: params.item.id,
        similarItemIds: params.similarItems.map((similarItem) => similarItem.item.id),
        suggestedCategoryId: params.suggestedCategory && params.suggestedCategory.id,
      }
    );
  }

  parcelShippingStarted(parcel: ObjectWithId): void {
    this.trackEvent(
      'Parcel Shipping Started',
      { parcelId: parcel.id },
      { startNewSession: true }
    );
  }

  parcelShippingLabelPrinted(params: {
    item: ObjectWithId,
    parcel: ObjectWithId,
  }) {
    this.trackEvent(
      'Parcel Shipping Label Printed',
      {
        itemId: params.item.id,
        parcelId: params.parcel.id,
      }
    );
  }

  itemUnassigned(
    params: {
      item: ObjectWithId,
      state: string,
    }
  ): void {
    this.trackEvent(
      'Item Unassigned',
      {
        itemId: params.item.id,
        state: params.state,
      }
    );
  }
}

export default (new Analytics());
