
// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CategoryLink from './CategoryLink';
import { findSubTree, stripEmpties } from '../utils/tree';
import type { Filter } from '../types';

type Props = {
  filter: Filter,
  filters: Array<Filter>,
  checked: ?number,
  isLoading: boolean,
  filterCounts: Object,
  appliedParams: Object,
};

class FilterTreeCategoryMobile extends Component<Props> {
  shouldComponentUpdate(nextProps) {
    return !nextProps.isLoading;
  }

  renderTreeItem = (treeItems, checked, depth = 0) => {
    return (
      treeItems.map((item) => {
        const isSelected = checked && checked.toString() === item.fullSlugPath;
        const renderChildren = isSelected && item.children.length > 0;
        const itemClasses = classNames(
          'items-filter-tree-mobile__item',
          {
            'items-filter-tree-mobile__item--current': isSelected,
          }
        );

        return (
          <Fragment key={item.pathSlug}>
            <li className={itemClasses}>
              <CategoryLink
                category={item}
                appliedParams={this.props.appliedParams}
                className={`items-filter-tree-mobile__item-link items-filter-tree-mobile__item-link--l${depth + 1}`}
              >
                {item.shortName}
              </CategoryLink>
              { renderChildren &&
                <ul className="items-filter-tree-mobile items-filter-tree-mobile--children">
                  {this.renderTreeItem(item.children, checked, depth + 1)}
                </ul>
              }
            </li>
            {!isSelected &&
              this.renderTreeItem(item.children, checked, depth + 1)
            }
          </Fragment>
        );
      })
    );
  }

  render() {
    const { filter, appliedParams } = this.props;
    const { data } = filter;
    const checked = (appliedParams.category) ? appliedParams.category.fullSlugPath : null;
    let categories;

    if (checked) {
      categories = findSubTree(data, checked);
    } else {
      categories = data.map((tree) => ({ ...tree, children: [] }));
    }

    categories = stripEmpties(categories, this.props.filterCounts.category, checked);

    const allCategoriesClass = classNames(
      'items-filter-tree-mobile__item',
      {
        'items-filter-tree-mobile__item--current': !checked,
      }
    );

    const listClasses = classNames(
      'items-filter-tree-mobile',
      {
        'items-filter-tree-mobile--has-selection': checked,
      }
    );

    return (
      <ul className={listClasses}>
        <li className={allCategoriesClass}>
          <CategoryLink className="items-filter-tree-mobile__item-link" appliedParams={appliedParams}>
            All Categories
          </CategoryLink>
        </li>
        {this.renderTreeItem(categories, checked)}
      </ul>
    );
  }
}


const mapStateToProps = (state) => ({
  isLoading: state.items.isLoading,
  filters: state.items.filters,
  filterCounts: state.items.filterCounts,
});

export default connect(mapStateToProps)(FilterTreeCategoryMobile);
