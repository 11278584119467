// @flow

import React, { Fragment } from 'react';
import { type RouterHistory } from 'react-router';
import { type Dispatch as ReduxDispatch } from 'redux';
import { type ApolloClient } from 'apollo-client';

import { promiseDispatcher, returnPath } from './common';

import { itemCatalogingCompleted, itemPhotoEditingCompleted } from '../analytics';
import { displayNotice } from '../notice.js';
import { cancelScreen } from './cancelScreenActions';

import { endTask } from '~/admin/shared/mutations/EndTaskMutation';

type SupportedTaskType = 'CATALOGING' | 'PHOTO_EDITING' | 'PACKING';

const messages = {
  CATALOGING: {
    success: (itemName: string) => `Cataloging item ${itemName} was completed.`,
    failure: (itemName: string) => `Cataloging item ${itemName} could not be completed.`,
  },
  PHOTO_EDITING: {
    success: (itemName: string) => `Photo editing item ${itemName} was completed.`,
    failure: (itemName: string) => `Photo editing item ${itemName} could not be completed.`,
  },
  PACKING: {
    success: (itemName: string) => `Packing item ${itemName} was completed.`,
    failure: (itemName: string) => `Packing item ${itemName} could not be completed.`,
  },
};

const redirections = {
  CATALOGING: {
    afterCompleted: () => '/admin/cataloging/queues',
  },
  PHOTO_EDITING: {
    afterCompleted: () => '/admin/photography/queues',
  },
  PACKING: {
    afterCompleted: () => '/admin/packing/queues',
  },
};

type ActionProps = {|
  taskType: SupportedTaskType,

  client: ApolloClient,
  history: RouterHistory,

  itemId: ID,
  itemName: string,
|};

const onSuccess = (
  {
    taskType, itemId, history, itemName,
  }: ActionProps,
  dispatch: ReduxDispatch
) => {
  switch (taskType) {
    case 'CATALOGING':
      dispatch(itemCatalogingCompleted({ id: itemId }));
      break;
    case 'PHOTO_EDITING':
      dispatch(itemPhotoEditingCompleted({ id: itemId }));
      break;
    // no default
  }

  // redirect before showing notification
  history.push(returnPath(history) || (redirections[taskType].afterCompleted()));

  dispatch(cancelScreen());

  dispatch(
    displayNotice({
      message: messages[taskType].success(itemName),
      type: 'success',
      autoDismiss: true,
    })
  );
};

const onFailure = ({ taskType, itemName }: ActionProps, dispatch: ReduxDispatch, e: Error) => {
  dispatch(
    displayNotice({
      message: (
        <Fragment>
          {messages[taskType].failure(itemName)}
          <br />
          {e.message}
        </Fragment>
      ),
      type: 'danger',
    })
  );
};

const completeItem = (params: ActionProps) => (dispatch: ReduxDispatch) => {
  const { client, itemId, taskType } = params;

  const xhr = endTask(client)({
    input: {
      itemId,
      taskType,
      reason: 'COMPLETED',
    },
  });

  dispatch(promiseDispatcher('COMPLETE')({ xhr }));

  xhr.then(() => onSuccess(params, dispatch)).catch((e: Error) => onFailure(params, dispatch, e));
};

export const completeCatalogingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(completeItem({ ...params, taskType: 'CATALOGING' }));
};

export const completePhotoEditingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(completeItem({ ...params, taskType: 'PHOTO_EDITING' }));
};

export const completePackingItem = (params: $Diff<ActionProps, {| taskType: any |}>) => (
  dispatch: ReduxDispatch
) => {
  dispatch(completeItem({ ...params, taskType: 'PACKING' }));
};
