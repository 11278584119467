// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import type { Node } from 'react';

import { nextAppliedParams, paramsToUrl } from '../utils/url';


type Props = {
  category?: Object,
  className?: string,
  children: Node,
  appliedParams: Object,
};

const CategoryLink = ({
  category,
  className,
  children,
  appliedParams,
}: Props) => {
  // The next params for a category link is setting that category on the params
  const nextParams = nextAppliedParams(appliedParams, (current) => {
    return {
      ...current,
      category: category,
      page: 1,
    };
  });
  const url = paramsToUrl(nextParams);

  return (
    <Link to={url} className={className}>
      {children}
    </Link>
  );
};

export default CategoryLink;
