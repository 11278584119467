// @flow

import React from 'react';
import { Button, Spinner } from '~/admin/shared/components';
import DisplayAfter from '~/admin/shared/components/DisplayAfter';

export default function ItemLoadingComponent() {
  return (
    <div className="u-flex-fill u-flex-justify-center">
      <div className="u-align-center u-text-center">
        <Spinner />
        <DisplayAfter milliseconds={3500}>
          <div className="u-mt2 u-animate-in">
            <h2>It looks like it is taking a while to load</h2>
            <Button
              type="button"
              onClick={() => {
                window.location.reload();
              }}
            >
              Try Again
            </Button>
          </div>
        </DisplayAfter>
      </div>
    </div>
  );
}
