// @flow
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import type { NoticeMessage } from '~/admin/shared/actions/notice';
import * as NoticeActions from '~/admin/shared/actions/notice';

type Props = {
  notice: ?NoticeMessage,
  dismissNotice: typeof NoticeActions.dismissNotice,
  removeNotice: typeof NoticeActions.removeNotice,
};

const Notice = ({ notice, dismissNotice, removeNotice }: Props) => {
  return (
    <Transition
      in={notice && notice.active}
      onExited={removeNotice}
      timeout={{ enter: 300, exit: 300 }}
    >
      {
        (state) => (
          notice &&
          <div onClick={dismissNotice} key={notice.key} className={classNames(`notice-animation--${state}`, 'react-notice', 'notification', `notification--${notice.type}`)}>
            {notice.message}
          </div>
        )
      }
    </Transition>
  );
};

const mapStateToProps = (state) => {
  return { notice: state.notice };
};
const mapDispatchToProps = (dispatch) => bindActionCreators(NoticeActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
