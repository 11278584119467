// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import moment from 'moment';
import { ContractStatusBadge } from './ContractStatusBadge';
import { ContractThumbnail } from './ContractThumbnail';
import formatCurrency from '~/utils/formatCurrency';
import type { Contract } from '../types';

export const ContractListing: StatelessFunctionalComponent<{
  contract: Contract,
  gridStyle: string,
}> = ({
  contract,
  gridStyle,
}) => {
  return (
    <div className="index-listing__data-row" style={{ gridTemplateColumns: gridStyle }}>
      <span className="index-listing__cell index-listing__cell--thumbnail">
        <ContractThumbnail items={contract.popularItems} />
      </span>
      <span className="index-listing__cell">
        <strong>{contract.number}</strong>
        <div className="u-text-muted">{contract.itemsCount} {contract.itemsCount === 1 ? 'item' : 'items'}</div>
      </span>
      <span className="index-listing__cell">
        <ContractStatusBadge contractId={contract.id} aasmState={contract.aasmState} />
      </span>
      <span className="index-listing__cell">{moment(contract.createdAt).format('M/DD/YYYY')}</span>
      <span className="index-listing__cell">
        {contract.totalItemValue ? <h4>{formatCurrency(contract.totalItemValue)}</h4> : <span className="u-text-muted">N/A</span>}
      </span>
    </div>
  );
};
