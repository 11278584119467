// @flow

import React, { PureComponent } from 'react';

type Props = {
  title: string,
  children: ?any,
}

class FilterWrapper extends PureComponent<Props> {
  render() {
    const { title, children } = this.props;

    return (
      <fieldset className="items-sidebar__section">
        <legend>
          <h3 className="items-sidebar__heading">{title}</h3>
        </legend>
        {children}
      </fieldset>
    );
  }
}

export default FilterWrapper;
