import gql from 'graphql-tag';
import { PendingItemFragment } from '~/shared/graphql/PendingItem/fragments/PendingItem';

export const PENDING_ITEM_SEARCH_QUERY = gql`
  query sellerPendingItemSearch($query: String, $pageSize: Int, $startCursor: String, $sortColumn: String!, $sortOrder: SearchSortDirection!) {
    items: sellerPendingItemSearch(query: $query, pageSize: $pageSize, startCursor: $startCursor, sortColumn: $sortColumn, sortOrder: $sortOrder) {
      pageInfo {
        startCursor
        endCursor
      }

      totalCount

      edges {
        node {
          ...PendingItemFragment
        }
      }
    }
  }
  ${PendingItemFragment}
`;

export default function (client) {
  return async (itemSearchParams) => (
    client.query({
      query: PENDING_ITEM_SEARCH_QUERY,
      variables: itemSearchParams,
      fetchPolicy: 'no-cache',
    })
  );
}
