import gql from 'graphql-tag';

export const PendingItemUploadFragment = gql`
  fragment PendingItemUploadFragment on PendingItemUpload {
    id
    contractId
    aasmState
    insertedCount
    processedCount
    rowCount
    exceptionCount
    errorMessage
    originalFilename
    createdAt
    updatedAt
    userId
    exceptions {
      edges {
        node {
          id
          reason
          externalId
          exceptionType
          createdAt
        }
      }
    }
  }
`;

export const PendingItemFragment = gql`
  fragment PendingItemFragment on PendingItem {
    id
    name
    barcode
    externalId
    itemId
    aasmState
    conditionDetails
    measurementDetails
    estimatedValue
    purchasePrice
    targetPrice
    description
    lengthInches
    widthInches
    heightInches
    weightOz
    createdAt
    shippable
    itemKeywords
    valuePurchasedAt
    mainImage {
      id
      imageUrl
      thumbnailUrl
    }
    contract {
      id
    }
    processingLocation {
      id
      name
    }

    additionalInformationLinks {
      id
      title
      href
    }
    disclaimers {
      id
      name
    }
    category {
      id
      lineage
    }
    properties {
      propertyId
      type
      value
    }
    upload {
      ...PendingItemUploadFragment
    }
    attachments {
      edges {
        node {
          id
          imageUrl
          thumbnailUrl
        }
      }
    }
  }

  ${PendingItemUploadFragment}
`;
