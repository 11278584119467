// @flow

import React, { Component } from 'react';
import { Spinner } from '~/admin/shared/components';

type Props = {
  isLoading: boolean,
};

class UploadProgress extends Component<Props> {
  render() {
    const { isLoading } = this.props;

    if (!isLoading) {
      return null;
    }

    return (
      <div className="upload-progress">
        <div className="u-text-center"><Spinner /></div>
        <p>
          Uploading Photos, please wait
        </p>
      </div>
    );
  }
}

export default UploadProgress;
