import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Layout from '../components/Layout';
import { Card } from '../components/Card';
import formatCurrency from '~/utils/formatCurrency';
import { Statistic } from '../components/Statistic';
import { PaymentHeader } from '../components/PaymentHeader';
import { PaymentListing } from '../components/PaymentListing';
import { Button, LoadingDots } from '~/public/shared/components';
import PaymentsQuery from '../queries/PaymentsQuery';
import { PaymentsTableNav } from '../components/PaymentTableNav';
import PaymentsItemsQuery from '../queries/PaymentsItemsQuery';
import { PaymentItemsHeader } from '../components/PaymentItemsHeader';
import { PaymentItemListing } from '../components/PaymentItemListing';

const SELLER_TOTAL_TAKEHOME_QUERY = gql`
  query currentUser {
    currentUser {
      id
      totalSoldTakehome
    }
  }
`;

const PaymentsPage = ({ match }) => {
  useEffect(() => {
    document.title = 'Payments | Seller Dashboard';
  });

  const [activeTab, setActiveTab] = useState('invoices');

  // style variables
  const gridStyle = '1fr 1fr 1fr 1fr';

  const gridItemsStyle = '1fr 1fr 1fr 1fr 1fr';

  return (
    <Layout>
      <h1 className="u-mb2">Payments</h1>

      <div className="row">
        <div className="col-xs-12 col-md-6 u-mb2">
          <Card>
            <Query query={SELLER_TOTAL_TAKEHOME_QUERY}>
              {({ loading, error, data }) => {
                if (error) return `Error! ${error.message}`;

                if (loading) {
                  return (
                    <div className="u-my2">
                      <LoadingDots />
                    </div>
                  );
                }

                return (
                  <Statistic value={formatCurrency(data.currentUser.totalSoldTakehome)} label="Total Earnings" />
                );
              }}
            </Query>
          </Card>
        </div>
      </div>

      <PaymentsTableNav activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 'invoices' &&
        <PaymentsQuery>
          {({
            payments,
            loading,
            initialLoading,
            hasNextPage,
            loadMore,
          }) => {
            if (initialLoading) {
              return (
                <div className="u-mt8 u-text-center">
                  <LoadingDots />
                </div>
              );
            }

            return (
              <Fragment>
                <PaymentHeader gridStyle={gridStyle} />

                {payments.map((payment) => {
                  return (
                    <div key={payment.id} className="expandable-listing">
                      <Link to={`${match.url}/${payment.number}`} className="expandable-listing__link">
                        <PaymentListing invoice={payment} gridStyle={gridStyle} />
                      </Link>
                    </div>
                  );
                })}

                {hasNextPage &&
                  <div className="u-mt2 u-text-center">
                    <Button
                      buttonStyle="secondary"
                      inFlight={loading}
                      onClick={loadMore}
                    >
                      Load More
                    </Button>
                  </div>
                }
              </Fragment>
            );
          }}
        </PaymentsQuery>
      }

      {activeTab === 'items' &&
        <Fragment>
          <PaymentsItemsQuery>
            {({
              items,
              loading,
              initialLoading,
              hasNextPage,
              loadMore,
            }) => {
              if (initialLoading) {
                return (
                  <div className="u-mt8 u-text-center">
                    <LoadingDots />
                  </div>
                );
              }

              return (
                <Fragment>
                  <h2 className="payments-heading">Scheduled to be Invoiced</h2>
                  <PaymentItemsHeader gridStyle={gridItemsStyle} />
                  {
                    items.length > 0 ?
                      <>
                        {items.map((item) => (
                          <div key={item.id} className="expandable-listing no-click">
                            <PaymentItemListing item={item} gridStyle={gridItemsStyle} />
                          </div>
                        ))}
                      </>
                      :
                      <span className="empty-state"><em>No Items Scheduled to be Invoiced</em></span>
                  }
                  {hasNextPage &&
                    <div className="u-mt2 u-text-center">
                      <Button
                        buttonStyle="secondary"
                        inFlight={loading}
                        onClick={loadMore}
                      >
                        Load More
                      </Button>
                    </div>
                  }
                </Fragment>
              );
            }}
          </PaymentsItemsQuery>
          <PaymentsItemsQuery status="sold">
            {({
              items,
              loading,
              initialLoading,
              hasNextPage,
              loadMore,
            }) => {
              if (initialLoading) {
                return (
                  <div className="u-mt8 u-text-center">
                    <LoadingDots />
                  </div>
                );
              }

              return (
                <Fragment>
                  <h2 className="payments-heading">Awaiting Buyer Payment</h2>
                  <PaymentItemsHeader gridStyle={gridItemsStyle} />
                  {
                    items.length > 0 ?
                      <>
                        {items.map((item) => (
                          <div key={item.id} className="expandable-listing no-click">
                            <PaymentItemListing item={item} gridStyle={gridItemsStyle} />
                          </div>
                        ))}
                      </>
                      :
                      <span className="empty-state"><em>No Items Awaiting Buyer Payment</em></span>
                  }
                  {hasNextPage &&
                    <div className="u-mt2 u-text-center">
                      <Button
                        buttonStyle="secondary"
                        inFlight={loading}
                        onClick={loadMore}
                      >
                        Load More
                      </Button>
                    </div>
                  }
                </Fragment>
              );
            }}
          </PaymentsItemsQuery>
        </Fragment>
      }
    </Layout>
  );
};

export { PaymentsPage };
