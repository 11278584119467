// @flow

import gql from 'graphql-tag';
import ApolloClient from 'apollo-client';

export type SalesSearchParams = {
  regionId: number;
  state: 'running' | 'ended' | 'created';
  first: number;
  after?: string;
  before?: string;
}

export const QUERY = gql` 
query($regionId: ID!, $first: Int, $after: String, $before: String, $state: String){
sales(regionId: $regionId, first: $first, after: $after, before: $before, state: $state){
    nodes {
      id
      name
      itemCount
      saleCode
    }
    pageInfo {
      endCursor
      hasNextPage
      totalCount
    }
  }
}
`;

export default function Sales(client: ApolloClient) {
  return async (params: SalesSearchParams) => (
    client.query({
      query: QUERY,
      variables: params,
      fetchPolicy: 'no-cache',
    })
  );
}
