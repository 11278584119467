// @flow

import React from 'react';
import { isEqual } from 'lodash';

type Props = {
  images: string[],
  render: ({ areImagesLoaded: boolean }) => any,
};

type State = {
  imagesLoadedCount: number,
};

class ImageLoader extends React.Component<Props, State> {
  state = {
    imagesLoadedCount: 0,
  };

  componentDidMount() {
    this.props.images.forEach((image) => {
      this.loadImage(image);
    });
  }

  componentWillReceiveProps({ images }: { images: string[] }) {
    // Component was previously mounted and we need to reset so the loading
    // state displays.

    if (!isEqual(images, this.props.images)) {
      this.setState({
        imagesLoadedCount: 0,
      });

      images.forEach((image) => {
        this.loadImage(image);
      });
    }
  }

  loadImage = (src: string) => {
    const image = new Image();

    image.onload = () => {
      this.setState({
        imagesLoadedCount: this.state.imagesLoadedCount + 1,
      });
    };

    image.src = src;
  }

  areImagesLoaded = () => this.state.imagesLoadedCount >= this.props.images.length;

  render() {
    return this.props.render({ areImagesLoaded: this.areImagesLoaded() });
  }
}

export default ImageLoader;
