// @flow

/* eslint-disable react/no-danger */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Truncator from '../../sale_header/components/Truncator';
import type { Filter } from '../types';

type Props = {
  filters: Array<Filter>,
  appliedParams: Object,
};

class CategoryText extends Component<Props> {
  render() {
    const { appliedParams } = this.props;

    const { category } = appliedParams;

    return (
      <Fragment>
        {category && (
          <div className="seo-category-text">
            <Truncator>
              <h4>{category.name}</h4>
              <p dangerouslySetInnerHTML={{ __html: category.description }} />
            </Truncator>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => ({
  filters: store.items.filters,
  appliedParams: store.items.appliedParameters,
});

export default withRouter(connect(mapStateToProps)(CategoryText));
