import gql from 'graphql-tag';
import { PendingItemFragment } from '~/shared/graphql/PendingItem/fragments/PendingItem';

export const CREATE_PENDING_ITEM_MUTATION = gql`
  mutation CreatePendingItem($input: CreatePendingItemInput!) {
    createPendingItem(input: $input) {
      pendingItem {
        ...PendingItemFragment
      }
    }
  }

  ${PendingItemFragment}
`;

export const UPDATE_PENDING_ITEM_MUTATION = gql`
  mutation UpdatePendingItem($input: UpdatePendingItemInput!) {
    updatePendingItem(input: $input) {
      pendingItem {
        ...PendingItemFragment
      }
    }
  }

  ${PendingItemFragment}
`;

export const DELETE_PENDING_ITEM_MUTATION = gql`
  mutation DeletePendingItem($input: DeletePendingItemInput!) {
    deletePendingItem(input: $input) {
      pendingItem {
        ...PendingItemFragment
      }
    }
  }

  ${PendingItemFragment}
`;
