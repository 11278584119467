import { cloneDeep } from 'lodash';

// Takes the location and turns it into params for fetching items
export function locationToParams(l) {
  const params = {
    path: l.pathname,
  };

  if (l.search) {
    const searchStr = l.search.replace('?', '');
    const pairs = searchStr.split('&');

    pairs.forEach((pair) => {
      const keyVal = pair.split('=');
      if (keyVal.length === 1) {
        return;
      }

      let val = keyVal[1];
      if (val.includes(',')) {
        val = val.split(',');
      }
      params[keyVal[0]] = val;
    });
  }
  return params;
}

// Takes the current applied parameters and adjusts them for the next
// state using the supplied function, f
export function nextAppliedParams(current, f) {
  // Clone it so that modifications do not affect the current object
  const copy = cloneDeep(current);
  return f(copy);
}

// Takes the state and figures out what corresponding link would be
export function paramsToUrl(params) {
  // Copy first since it'll be modified
  const copy = { ...params };
  const queryPairs = [];
  const { sale, category, props: { ...properties } } = copy;

  // Determine the path part of the url
  let path = '';
  // If there's a sale, that's the slug
  if (sale) {
    path += `/sales/${copy.sale_path}`;
  } else if (category) { // If there's no sale but a category selected that's the beginning of the slug
    path += category.fullSlugPath;
  }

  // If there's a single property selected and it's one value, tack, that onto the path
  if (!sale && properties && Object.keys(properties).length === 1 && properties[Object.keys(properties)[0]].length === 1) {
    // Catgegory path + one property variant
    const key = Object.keys(properties)[0];
    const val = properties[key];
    path += `/${key}/${val}`;
    // Delete the property out since it'll already be represented by the path
    delete properties[key];
  }

  // If the path is blank, we go to browse
  if (path === '') {
    path = '/browse';
  }

  // Some parameters need to be handled specifically
  if (copy.status) {
    queryPairs.push(['status', copy.status]);
  }
  if (copy.delivery_options && copy.delivery_options.length > 0) {
    queryPairs.push(['delivery_options', params.delivery_options]);
  }
  if (copy.days_left) {
    queryPairs.push(['days_left', copy.days_left]);
  }
  if (copy.category && copy.sale) {
    queryPairs.push(['category_slug', copy.category.pathSlug || copy.category.slug]);
  }
  if (copy.color) {
    queryPairs.push(['color', copy.color]);
  }
  if (copy.q) {
    const encodedCopyQ = encodeURIComponent(copy.q);
    queryPairs.push(['q', encodedCopyQ]);
  }
  if (copy.miles && copy.zip_code) {
    queryPairs.push(['miles', copy.miles]);
    queryPairs.push(['zip_code', copy.zip_code]);
  }
  if (copy.sort && copy.sort !== 'recommended') {
    queryPairs.push(['sort', copy.sort]);
  }
  if (copy.page && copy.page !== 1 && copy.page !== '1') {
    queryPairs.push(['page', copy.page]);
  }
  if (properties) {
    Object.keys(properties).forEach((key) => {
      const val = properties[key];
      queryPairs.push([key, val]);
    });
  }
  if (copy.utms) {
    Object.keys(copy.utms).forEach((key) => {
      const val = copy.utms[key];
      queryPairs.push([key, val]);
    });
  }
  if (copy.seller_id) {
    queryPairs.push(['seller_id', copy.seller_id]);
  }
  if (copy.additional) {
    Object.keys(copy.additional).forEach((key) => {
      queryPairs.push([key, copy.additional[key]]);
    });
  }

  if (queryPairs.length === 0) {
    return path;
  }

  // Append all of the query params
  const queryStrings = queryPairs.map((pair) => {
    return `${pair[0]}=${pair[1]}`;
  });

  return `${path}?${queryStrings.join('&')}`;
}
