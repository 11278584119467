// @flow

import React, { Component, type Node } from 'react';
import { Mutation, type MutationFn, type ApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

import { type TaskType } from '~/types/graphql';

const MUTATION = gql`
  mutation enqueueTask($input: EnqueueTaskInput!) {
    enqueueTask(input: $input) {
      task {
        id
      }
    }
  }
`;

export type Params = {
  input: {
    itemId: ID,
    taskType: TaskType,
    queueId: ?string,
  },
};

export type MutateFn = MutationFn<any, Params>;

type Props = {
  children: (enqueueTaskFn: MutateFn) => Node,
};

class EnqueueTaskMutation extends Component<Props> {
  render() {
    return (
      <Mutation mutation={MUTATION}>
        {(enqueueTaskFn) => this.props.children(enqueueTaskFn)}
      </Mutation>
    );
  }
}

export const enqueueTask = (client: ApolloClient) => {
  return async (variables: Params) => client.mutate({
    mutation: MUTATION,
    variables,
  });
};

export default EnqueueTaskMutation;
