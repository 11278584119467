// @flow

import React, { Component } from 'react';
import Select from 'react-select';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { sortBy } from 'lodash';

import type { ApolloQueryResult } from 'apollo-client';

import Connection from '~/admin/shared/utils/Connection';
import basicInputWrapper from '~/admin/shared/components/basicInputWrapper';

// FIXME: dont depend on another screens type
import { type Disclaimer } from '~/admin/cataloging/types';

import type { ConnectionType } from '~/types/graphql';

type Props = {
  loading: boolean,
  selected: Array<Disclaimer>,
  disclaimers: Array<Disclaimer>,
  onChange: (Array<Disclaimer>) => void,
};

class DisclaimerSelect extends Component<Props> {
  render() {
    if (this.props.loading) {
      return null;
    }

    const MultiSelect = basicInputWrapper(Select);

    return (
      <MultiSelect
        className="qa-disclaimer-select custom-select"
        label="Disclaimers"
        multi
        labelKey="name"
        valueKey="id"
        onChange={this.props.onChange}
        options={this.props.disclaimers}
        value={this.props.selected}
      />
    );
  }
}

const disclaimerQuery = gql`
  query fetchDisclaimers {
    disclaimers {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type ApolloProps = { loading: boolean, disclaimers: Array<Disclaimer> };
type ApolloData = ApolloQueryResult<ConnectionType<Disclaimer>>;

const withDisclaimers = graphql(disclaimerQuery, {
  props: ({ data: { loading, disclaimers } }: ApolloData): ApolloProps => {
    if (loading) {
      return { loading, disclaimers: [] };
    }

    const { nodes } = new Connection(disclaimers);
    return { loading, disclaimers: sortBy(nodes, 'name') };
  },
});

export default compose(
  withDisclaimers,
)(DisclaimerSelect);
