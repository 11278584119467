// @flow

import React from 'react';
import classNames from 'classnames';

type Props = {
  onPageChange: (number) => any,
  currentPage: number,
  totalPages: number,
  width?: number,
  showAdjacentButtons?: boolean,
  showEndButtons?: boolean,
};

const PaginationFooter = ({
  currentPage,
  totalPages,
  width = 3,
  onPageChange,
  showAdjacentButtons = false,
  showEndButtons = false,
}: Props) => {
  const onClickFor = (i: number) => {
    return (e: SyntheticEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onPageChange(i);
    };
  };

  if (totalPages <= 1) {
    return null;
  }

  // Create an alternating array of page numbers starting from the
  // center until we hit the width. This will allow us to build the
  // page array with max `width` page numbers and it will overflow
  // to either side of `currentPage` if near the first or last page.
  const pages = [currentPage];
  for (let page = 1; pages.length < Math.min(width, totalPages); page += 1) {
    const even = page % 2 === 0;
    const displacement = Math.ceil(page / 2) * (even ? 1 : -1);
    const nextPage = currentPage + displacement;
    if (nextPage <= totalPages && nextPage > 0) {
      pages.push(nextPage);
    }
  }

  const links = pages.sort((a, b) => a - b).map((i: number) => {
    const pageClasses = classNames('pagination_button pagination_button--link pagination__link', {
      'is-active': i === currentPage,
    });

    return (
      <li key={`pagination-link-${i}`} className="pagination__item">
        <button type="button" className={pageClasses} onClick={onClickFor(i)}>{i}</button>
      </li>
    );
  });

  const AdjacentPageButton = ({ previous = false }: { previous?: boolean }) => {
    if (showAdjacentButtons) {
      const atEndPage = previous ? currentPage === 1 : currentPage === totalPages;
      return (
        <li className="pagination__item">
          <button
            type="button"
            className="pagination_button pagination_button--link pagination__link pagination__adjacent-button"
            onClick={onClickFor(previous ? currentPage - 1 : currentPage + 1)}
            disabled={atEndPage}
          >
            {previous ? 'Previous' : 'Next'}
          </button>
        </li>
      );
    }
    return null;
  };

  const EndPageButton = ({ first = false }: { first?: boolean }) => {
    if (showEndButtons) {
      const atEndPage = first ? currentPage === 1 : currentPage === totalPages;
      return (
        <li className="pagination__item">
          <button
            type="button"
            className="pagination_button pagination_button--link pagination__link pagination__end-button"
            onClick={onClickFor(first ? 1 : totalPages)}
            disabled={atEndPage}
          >
            {first ? 'First' : 'Last'}
          </button>
        </li>
      );
    }
    return null;
  };

  return (
    <nav className="pagination u-text-center">
      <ul className="pagination__list">
        <EndPageButton first />
        <AdjacentPageButton previous />
        {links}
        <AdjacentPageButton />
        <EndPageButton />
      </ul>
    </nav>
  );
};

export default PaginationFooter;
