// @flow

import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import type { Location, RouterHistory, Match } from 'react-router';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import { PaymentHeader } from '../components/PaymentHeader';
import { PaymentListing } from '../components/PaymentListing';
import { PayableAddress } from '../components/PayableAddress';
import { Button, Icon, LoadingDots } from '~/public/shared/components';
import formatCurrency from '~/utils/formatCurrency';

const SELLER_INVOICE_QUERY = gql`
  query sellerInvoice($id: ID!, $after: String) {
    sellerInvoice(id: $id) {
      id
      number
      createdAt
      aasmState
      contractNumber
      contract {
        payableToName
        payableToAttention
        payableToStreet
        payableToFloorSuite
        payableToCity
        payableToState
        payableToZip
        payableToCountry
      }
      paymentDate
      totalSales
      commissionTotalPercent
      totalCommission
      totalFees
      totalCredits
      totalToSeller
      payments
      amountDue
      items(first: 21, after: $after) {
        edges {
          node {
            id
            barcode
            name
            highBidAmount
            commissionTotal
          }
        }

        totalCount

        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

type Props = {
  location: Location,
  history: RouterHistory,
  match: Match,
};

class PaymentDetailPage extends Component<Props> {
  render() {
    const gridStyle = '1fr 1fr 1fr 1fr';
    const { history, match } = this.props;
    const { params: { invoiceNumber } } = match;
    const id = parseInt((invoiceNumber || '').replace(/\D/g, ''), 10);
    const variables = { id };

    return (
      <Layout>
        <div className="u-mb2">
          <Button
            buttonStyle="text"
            buttonSize="lg"
            onClick={() => { history.goBack(); }}
          >
            <Icon icon="chevron-left" className="btn__icon" />
            Back
          </Button>
        </div>

        <PaymentHeader gridStyle={gridStyle} />

        <Query notifyOnNetworkStatusChange query={SELLER_INVOICE_QUERY} variables={variables}>
          {({ loading, data, fetchMore }) => {
            const { sellerInvoice } = data;

            // Initial load
            if (loading && !sellerInvoice) {
              return (
                <div className="u-mt8 u-text-center">
                  <LoadingDots />
                </div>
              );
            }

            const {
              sellerInvoice: {
                contract,
                items: {
                  edges,
                  pageInfo: {
                    hasNextPage,
                    endCursor,
                  },
                },
              },
            } = data;

            const items = edges.map((item) => item.node);

            const loadMore = () => {
              fetchMore({
                variables: {
                  after: endCursor,
                },

                updateQuery: (previousResult, { fetchMoreResult }) => {
                  const {
                    sellerInvoice: {
                      items: { totalCount, pageInfo, __typename },
                    },
                  } = fetchMoreResult;

                  const newEdges = [
                    ...previousResult.sellerInvoice.items.edges,
                    ...fetchMoreResult.sellerInvoice.items.edges,
                  ];

                  return {
                    sellerInvoice: {
                      ...previousResult.sellerInvoice,
                      items: {
                        __typename,
                        edges: newEdges,
                        pageInfo,
                        totalCount,
                      },
                    },
                  };
                },
              });
            };

            return (
              <div className="expandable-listing expandable-listing--expanded">
                <PaymentListing
                  invoice={sellerInvoice}
                  key={sellerInvoice.id}
                  gridStyle={gridStyle}
                />

                <div className="expandable-listing__details">
                  <table className="table table--beta u-m0">
                    <tbody>
                      <tr>
                        <td className="u-color-gray-400">Contract</td>
                        <td><Link to={`/contracts/${sellerInvoice.contractNumber}`}>{sellerInvoice.contractNumber}</Link></td>
                      </tr>
                      <tr>
                        <td className="u-color-gray-400">Payable To</td>
                        <td>
                          <PayableAddress
                            name={contract.payableToName}
                            attention={contract.payableToAttention}
                            street={contract.payableToStreet}
                            floorSuite={contract.payableToFloorSuite}
                            city={contract.payableToCity}
                            state={contract.payableToState}
                            zip={contract.payableToZip}
                            country={contract.payableToCountry}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h5 className="u-mb1">Items</h5>
                  {items.length === 0 &&
                    <h5 className="u-my6 u-text-center u-text-muted">No items found.</h5>
                  }

                  {items.length > 0 &&
                    <Fragment>
                      <table className="table table--beta u-mb0">
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th>Bid</th>
                            <th>Commission</th>
                            <th>Yield</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((item) => {
                            const itemYield = item.highBidAmount - item.commissionTotal;

                            return (
                              <tr key={item.id}>
                                <td><Link to={`/items/${item.barcode}`}>{item.name}</Link></td>
                                <td>{formatCurrency(item.highBidAmount)}</td>
                                <td>
                                  {formatCurrency(item.commissionTotal * -1, { showZeroes: true })}
                                </td>
                                <td>{formatCurrency(itemYield, { showZeroes: true })}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>

                      {hasNextPage &&
                        <div className="u-mt2 u-text-center">
                          <Button
                            buttonStyle="secondary"
                            inFlight={loading}
                            onClick={loadMore}
                          >
                            Load More
                          </Button>
                        </div>
                      }
                    </Fragment>
                  }

                  <table className="table table--borderless u-bg-gray-100">
                    <tbody>
                      <tr>
                        <td className="u-text-right">Total</td>
                        <td className="u-text-right">{formatCurrency(sellerInvoice.totalSales, { showZeroes: true })}</td>
                      </tr>
                      <tr>
                        <td className="u-text-right">Total Commission
                          <br /><small className="u-text-muted">({sellerInvoice.commissionTotalPercent}% avg)</small>
                        </td>
                        <td className="u-text-right">{formatCurrency(sellerInvoice.totalCommission * -1, { showZeroes: true })}</td>
                      </tr>
                      {!!sellerInvoice.totalFees &&
                        <tr>
                          <td className="u-text-right">Total Fees</td>
                          <td className="u-text-right">{formatCurrency(sellerInvoice.totalFees, { showZeroes: true })}</td>
                        </tr>
                      }
                      {!!sellerInvoice.totalCredits &&
                        <tr>
                          <td className="u-text-right">Total Credits</td>
                          <td className="u-text-right">{formatCurrency(sellerInvoice.totalCredits, { showZeroes: true })}</td>
                        </tr>
                      }
                      <tr>
                        <td className="u-text-right">Subtotal</td>
                        <td className="u-text-right">{formatCurrency(sellerInvoice.totalToSeller, { showZeroes: true })}</td>
                      </tr>
                      {!!sellerInvoice.payments &&
                        <tr>
                          <td className="u-text-right">Payments</td>
                          <td className="u-text-right">{formatCurrency(sellerInvoice.payments * -1, { showZeroes: true })}</td>
                        </tr>
                      }
                      <tr>
                        <td className="u-text-right">Amount Due</td>
                        <td className="u-text-right">
                          <strong className="table__cell-title">{formatCurrency(sellerInvoice.amountDue, { showZeroes: true })}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export { PaymentDetailPage };
