import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckboxField extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.checked);
  }

  render() {
    const {
      errors,
      label,
      name,
      value,
    } = this.props;
    const hasErrors = (errors.length > 0);

    return (
      <div>
        <label htmlFor={name} className="custom-control custom-control--checkbox">
          <input id={name} className="custom-control__input" type="checkbox" onChange={this.handleChange} checked={value} />
          <div className="custom-control__label">{label}</div>
        </label>
        {
          hasErrors ?
            <div className="form-group__error">{errors.join(',')}</div> :
            null
        }
      </div>
    );
  }
}

CheckboxField.propTypes = {
  errors: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

CheckboxField.defaultProps = {
  errors: [],
};

export default CheckboxField;
