// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import { Card } from '../Card';

export const Contact: StatelessFunctionalComponent<{}> = () => {
  return (
    <Card header="Question about your sale?">
      <div className="u-flex">
        <a href="/ebth-representatives">find your contact here</a>
      </div>
    </Card>
  );
};
