// @flow

import React, { type StatelessFunctionalComponent } from 'react';

type Props = {
  id: string,
  label: string,
  onChange: (newValue: boolean) => void,
  value: boolean,
};

export const Checkbox: StatelessFunctionalComponent<Props> = ({
  onChange, label, value, id,
}) => {
  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label className="custom-control custom-control--checkbox">
      <input
        id={id}
        type="checkbox"
        className="custom-control__input"
        checked={value}
        onChange={handleChange}
      />
      <div className="custom-control__label">{label}</div>
    </label>
  );
};
