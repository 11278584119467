// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import { Icon } from '~/public/shared/components';
import ModalContainer from '../containers/ModalContainer';

type Props = {}

type State = {
  expanded: {
    a: boolean,
    b: boolean,
    c: boolean,
    d: boolean,
    e: boolean,
    f: boolean,
  },
}

class ModalDeliveryOptions extends Component<Props, State> {
  state = {
    expanded: {
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
    },
  }

  getToggleState = (option: string, index: string) => {
    // Toggle the state of the clicked option, set others to false
    if (option === index) {
      return !this.state.expanded[option];
    } else {
      return false;
    }
  }

  handleOptionClickToggle = (option: string) => {
    this.setState({
      expanded: {
        a: this.getToggleState(option, 'a'),
        b: this.getToggleState(option, 'b'),
        c: this.getToggleState(option, 'c'),
        d: this.getToggleState(option, 'd'),
        e: this.getToggleState(option, 'e'),
        f: this.getToggleState(option, 'f'),
      },
    });
  }

  renderOptionTableClasses = (option: string) => {
    const tableClassNames = classNames(
      'table-shipping-info__option-table',
      {
        'table-shipping-info__option-table--expanded': this.state.expanded[option],
      }
    );
    return tableClassNames;
  }

  render() {
    return (
      <ModalContainer name="deliveryOptions" {...this.props}>
        <div className="modal__header">
          <h2 id="delivery_options" className="u-mb2">Delivery Options</h2>
        </div>

        <table aria-labelledby="delivery_options" className="table--beta table-shipping-info">
          <thead>
            <tr>
              <th scope="col">Method</th>
              <th scope="col">Details</th>
              <th scope="col">Cost</th>
              <th scope="col">Timing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="4">
                <table className={this.renderOptionTableClasses('a')}>
                  <tbody>
                    <tr>
                      <th scope="row" onClick={() => { this.handleOptionClickToggle('a'); }}>
                        <Icon icon="parcel" />
                        Shipping
                        <Icon icon="chevron-down" size="30" className="table-shipping-info__option-table-toggle" />
                      </th>
                      <td>
                        All parceled items are shipped via FedEx Ground or FedEx Express.<br />
                        <a
                          href="https://support.ebth.com/s/article/What-shipping-methods-are-available"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          More Info about Shipping
                        </a>
                      </td>
                      <td>
                        Varies. Rate is determined by size, weight, value, and
                        destination.
                      </td>
                      <td>
                        3-10 business days after payment for continental US. Items
                        shipping from a home may require additional packing time.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <table className={this.renderOptionTableClasses('b')}>
                  <tbody>
                    <tr>
                      <th scope="row" onClick={() => { this.handleOptionClickToggle('b'); }}>
                        <Icon icon="pick-up" />
                        Pickup
                        <Icon icon="chevron-down" size="30" className="table-shipping-info__option-table-toggle" />
                      </th>
                      <td>
                        Most large items and a selection of small items can be
                        picked up at seller homes or local distribution centers.<br />
                        <a
                          href="https://support.ebth.com/s/global-search/pick%20up"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          More Info about Pickup
                        </a>
                      </td>
                      <td>
                        Free! At your request, certain items can be relocated
                        from a home to a local warehouse for a small fee.
                      </td>
                      <td>
                        Pickup date, time, and location are posted on item pages
                        and invoices.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <table className={this.renderOptionTableClasses('e')}>
                  <tbody>
                    <tr>
                      <th scope="row" onClick={() => { this.handleOptionClickToggle('e'); }}>
                        <Icon icon="star-outline" />
                        Custom Shipping
                        <Icon icon="chevron-down" size="30" className="table-shipping-info__option-table-toggle" />
                      </th>
                      <td>
                        Items that are heavy or difficult to move occasionally
                        require custom logistics.
                      </td>
                      <td>
                        Varies.
                      </td>
                      <td>
                        Varies based on custom logistics.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="u-flex-apart">
          <div><a className="btn btn--text u-mb1" href="https://support.ebth.com/s/topic/0TO1K000001kH1SWAU/shipping">Shipping FAQs</a></div>
          <div><a className="btn btn--text u-mb1" href="tel:888-862-8750"><Icon icon="phone" className="btn__icon" /> (888) 862-8750</a></div>
        </div>
      </ModalContainer>
    );
  }
}

export default ModalDeliveryOptions;
