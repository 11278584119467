// @flow

import {
  BOOTSTRAP,
} from '../actions/bootstrap';

import type { Actions } from '../actions/bootstrap';
import type { User } from '../types';

type UserState = {
  user: ?User,
};

const initialState = {
  user: null,
};

const userReducer = (state: UserState = initialState, action: Actions): UserState => {
  switch (action.type) {
    case BOOTSTRAP:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

export default userReducer;
