// @flow

import React, { type StatelessFunctionalComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LoadingDots } from '~/public/shared/components';
import { Card } from '../Card';
import { PaymentStatus } from '../PaymentStatus';
import formatCurrency from '~/utils/formatCurrency';
import PaymentsQuery from '../../queries/PaymentsQuery';

export const Payments: StatelessFunctionalComponent<{}> = () => {
  return (
    <Card
      header={
        <Fragment>
          Recent Payments
          <Link to="/payments" className="u-pull-right">View All</Link>
        </Fragment>
      }
    >
      <table className="table table--beta u-mb0">
        <tbody>
          <PaymentsQuery first={5}>
            {({ payments, initialLoading }) => {
              if (initialLoading) {
                return (
                  <tr>
                    <td colSpan="3" className="u-text-center">
                      <LoadingDots />
                    </td>
                  </tr>
                );
              }

              if (payments.length === 0) {
                return (
                  <tr>
                    <td colSpan="3" className="u-text-center u-text-muted">No payments yet.</td>
                  </tr>
                );
              }

              return (
                <Fragment>
                  {payments.map((payment) => {
                    return (
                      <tr key={payment.id}>
                        <td><strong><Link to={`/payments/${payment.number}`}>{payment.number}</Link></strong></td>
                        <td>
                          <PaymentStatus
                            aasmState={payment.aasmState}
                            paymentDate={payment.paymentDate}
                          />
                        </td>
                        <td>
                          <strong>
                            {formatCurrency(payment.totalToSeller, { showZeroes: true })}
                          </strong>
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            }}
          </PaymentsQuery>
        </tbody>
      </table>
    </Card>
  );
};
