// @flow

import { SET_SETTING } from '../actions/settings';
import type { Actions, SettingsState } from '../actions/settings';

const DEFAULT_SETTINGS = ({
  autoPrintShippingLabels: false,
}: SettingsState);

const notice = (state: Object = {}, action: Actions): SettingsState => {
  switch (action.type) {
    case SET_SETTING:
      return {
        ...DEFAULT_SETTINGS,
        ...state,
        [action.key]: action.value,
      };
    default:
      return {
        ...DEFAULT_SETTINGS,
        ...state,
      };
  }
};

export default notice;
