// @flow

import React, { Component } from 'react';

import type { Node } from 'react';

import CustomSvgIcon from '~/admin/shared/svgs/CustomSvgIcon';

type State = {
  isBarcodeFormVisible: boolean,
  barcode: string,
};

type Props = {
  children?: Node,
  onSubmit: (string) => any,
};

export class Scanner extends Component<Props, State> {
  state = {
    barcode: '',
    isBarcodeFormVisible: false,
  };

  handleSubmit = (event: SyntheticEvent<HTMLFormElement>): void => {
    event.preventDefault();
    this.props.onSubmit(this.state.barcode);
  };

  handleBarcodeChange = (event: SyntheticInputEvent<HTMLInputElement>): void => {
    this.setState({
      barcode: event.target.value.toUpperCase(),
    });
  };

  toggleBarcodeForm = (): void => {
    this.setState({
      isBarcodeFormVisible: !this.state.isBarcodeFormVisible,
    });
  };

  blockBarcodeScanner = (event: SyntheticInputEvent<HTMLInputElement>): void => {
    // Stop the keypress event from propogating to the barcode scanner, or it will act on
    // the characters they type here (hope you type fast!)
    event.stopPropagation();
  };

  render() {
    return (
      <div className="card card--spaced-lg">
        <div className="card__content u-text-center">
          <CustomSvgIcon
            icon="ic_qr_code_scan_24px"
            className="icon icon--jumbo icon--faded u-mt1 u-mb2"
          />

          {this.props.children}
          <label htmlFor="barcode">
            Or, <a onClick={this.toggleBarcodeForm}>Manually Enter a Barcode</a>
          </label>

          {this.state.isBarcodeFormVisible && (
            <form onSubmit={this.handleSubmit}>
              <div className="input-group u-mt3">
                <input
                  id="barcode"
                  type="text"
                  className="input-group__input"
                  placeholder="e.g. 14CIN262-106"
                  value={this.state.barcode}
                  onChange={this.handleBarcodeChange}
                  onKeyPress={this.blockBarcodeScanner}
                />
                <button type="submit" className="input-group__button">
                  Find
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}
