// @flow

import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

import PaymentMethodSvgSprite from '~/public/svgs/PaymentMethodSvgSprite';
import {
  Badge,
  Button,
  Icon,
  Flash,
  LoadingDots,
} from '~/public/shared/components';
import ContentExpand from './ContentExpand';
import { paymentMethodSvgId, paymentMethodText, serviceText } from '../utils/paymentMethodHelpers';
import { withPaymentMethods } from '../queries/PaymentMethodsQuery';

import type { Bid, PaymentMethod } from '../types';

type Props = {
  bid: Bid,
  changePane: (string) => void,
  onPaymentChange: (Object) => void,
  onBidChange: (Object) => void,
  bustCache: boolean,
  refetch: () => void,
  addPayment: (number, string) => void,
  loading: boolean,
  error: Object,
  paymentMethods: Array<Object>,
};

class PaneSelectPayment extends Component<Props> {
  componentDidMount = () => {
    // Refetch if we need to bust the cache
    if (this.props.bustCache) this.props.refetch();
  }

  returnToPlaceBid = () => {
    this.props.changePane('bid');
  }

  goToAddPayment = (type) => {
    this.props.addPayment(type, 'addpayment');
  }

  handlePaymentSelection = (pm: PaymentMethod) => {
    const { bid, onPaymentChange } = this.props;

    if (pm.aasmState !== 'active') return null;

    if (bid.paymentMethod && (bid.paymentMethod.id !== pm.id)) {
      onPaymentChange(pm);
    } else {
      // Close payment pane when clicking the already selected option
      this.returnToPlaceBid();
    }
  }

  handleDiscountUpdate = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onBidChange({ hasPaymentDiscount: e.target.checked });
    this.returnToPlaceBid();
  }

  getMethodsMap = (methods: Array<PaymentMethod>, type: number) => {
    if (methods) {
      return methods.filter((method) => method.paymentMethodTypeId === type);
    }
    return null;
  }

  renderPaymentMethod = (pm: PaymentMethod) => {
    const { id, aasmState } = pm;
    const { paymentMethod } = this.props.bid;
    const isExpired = aasmState === 'expired';
    const isDeclined = aasmState === 'declined';
    const unavailable = isExpired || isDeclined;
    const optionClasses = classNames(
      'bid-options__option',
      'qa-payment-option',
      {
        'bid-options__option--unavailable': unavailable,
        'bid-options__option--selected': paymentMethod && (paymentMethod.id === id),
      }
    );

    return (
      <div
        key={id}
        className={optionClasses}
        onClick={() => { this.handlePaymentSelection(pm); }}
        title={unavailable ? 'Unavailable for selection' : 'Select for payment'}
      >
        <PaymentMethodSvgSprite
          icon={paymentMethodSvgId(pm)}
          className="bid__bid-selection-section bid__bid-selection-section--payment-icon"
        />
        <span>{paymentMethodText(pm)}</span>
        {isExpired &&
          <Badge status="negative" className="ml-auto">Expired</Badge>
        }
        {isDeclined &&
          <Badge status="negative" className="ml-auto">Declined</Badge>
        }
      </div>
    );
  }

  render() {
    const { loading, error } = this.props;
    const { /* bid, */ paymentMethods } = this.props;
    const ccMethods = this.getMethodsMap(paymentMethods, 1);
    const achMethods = this.getMethodsMap(paymentMethods, 3);
    const cash = this.getMethodsMap(paymentMethods, 2);

    if (loading) {
      return (
        <div className="box u-pb4 u-text-center">
          <h5>
            <LoadingDots className="u-mr2" /> Just a minute, fetching your payment methods
          </h5>
        </div>
      );
    } else if (error) {
      return (
        <Fragment>
          <Flash
            flashStyle="error"
            showIcon
            className="u-mb2"
          >
            { error.message }
          </Flash>
          <Button
            onClick={this.returnToPlaceBid}
            buttonDisplay="block"
            buttonStyle="secondary"
            className="u-mt4"
          >
            <Icon icon="chevron-left" className="btn__icon" />
            Back
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <h3 className="bid-modal__headline">Choose a Payment Service</h3>
          <h3 className="bid-modal__subhead">Everything Expedited</h3>
          <ContentExpand
            expandText="Show Details"
            collapseText="Hide Details"
            content={serviceText(1)}
          />
          <div className="bid-options">
            {paymentMethods && ccMethods &&
              ccMethods.map(this.renderPaymentMethod)
            }
            {paymentMethods && cash &&
              cash.map(this.renderPaymentMethod)
            }
            <Button
              onClick={() => { this.goToAddPayment(0); }}
              buttonDisplay="block"
              buttonStyle="text-navigation"
              className="bid-options__option--button bid-options__option"
            >
              Add Credit Card Payment Method
            </Button>
          </div>
          <h3 className="bid-modal__subhead">Everything Standard</h3>
          <ContentExpand
            expandText="Show Details"
            collapseText="Hide Details"
            content={serviceText(3)}
          />
          <div className="bid-options">
            {paymentMethods && achMethods &&
              achMethods.map(this.renderPaymentMethod)
            }
            {!paymentMethods &&
              <div className="bid-options__option bid-options__option--unavailable">No payment methods</div>
            }
            <Button
              onClick={() => { this.goToAddPayment(1); }}
              buttonDisplay="block"
              buttonStyle="text-navigation"
              className="bid-options__option--button bid-options__option"
            >
              Add ACH Payment Method
            </Button>
          </div>

          {/* begin - deprecated */}
          {/* <input
            id="discount"
            type="checkbox"
            className="bid-input-form__confirm-input qa-discount-checkbox"
            checked={bid.hasPaymentDiscount}
            onChange={this.handleDiscountUpdate}
          />
          <label
            htmlFor="discount"
            className="bid-input-form__confirm-label qa-discount-label"
          >
            I have an EBTH gift card or coupon code I would like to use.
          </label> */}
          {/* end - deprecated */}
          <Button
            onClick={this.returnToPlaceBid}
            buttonDisplay="block"
            buttonStyle="secondary"
            className="u-mt4"
          >
            <Icon icon="chevron-left" className="btn__icon" />
            Back
          </Button>
        </Fragment>
      );
    }
  }
}

export default withPaymentMethods(PaneSelectPayment);
