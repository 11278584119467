// @flow

import URI from 'urijs';

type Options = {|
  fit?: string,
  auto?: string,
  w?: ?number,
  h?: ?number,
  q?: number,
  mark64?: string,
  markpad?: number,
  markalign?: string,
  markscale?: number,
|}

export default (url: ?string, options: Options): ?string => {
  if (!url) return null;

  const defaults: Options = {
    fit: 'clip',
    auto: 'format',
    w: null,
    h: null,
    q: 75,
  };

  const settings = { ...defaults, ...options };

  return URI(url)
    .query('')
    .query(settings)
    .toString();
};
