export const OPEN_COMMENTS = 'OPEN_COMMENTS';
export const CLOSE_COMMENTS = 'CLOSE_COMMENTS';

export const openComments = () => (
  {
    type: OPEN_COMMENTS,
  }
);

export const closeComments = () => (
  {
    type: CLOSE_COMMENTS,
  }
);
