import update from 'immutability-helper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { InputField, Flash } from '~/public/shared/components';

class PaymentMethodGiftCardForm extends Component {
  handleChange = (key) => {
    return (change) => {
      const { paymentMethod } = this.props;
      const newPaymentMethod = update(paymentMethod, { [key]: { value: { $set: change } } });
      this.props.onPaymentMethodChange(newPaymentMethod);
    };
  }

  renderBaseError = () => {
    const { errors } = this.props.paymentMethod.base;

    if (errors.length === 0) return null;

    return (
      <Flash
        flashStyle="error"
        showIcon
        className="u-mb2"
      >
        {errors.join(',')}
      </Flash>
    );
  }

  render() {
    const { paymentMethod } = this.props;
    paymentMethod.paymentMethodTypeId = 4;

    return (
      <div>
        <div className="l-form-grid__row">
          <div className="l-form-grid__item">
            <InputField
              label="Gift Card Number"
              floatedLabel
              name="giftCardNumber"
              autocomplete="off"
              onChange={this.handleChange('giftCardNumber')}
              {...paymentMethod.giftCardNumber}
            />
          </div>
        </div>
      </div>
    );
  }
}

PaymentMethodGiftCardForm.propTypes = {
  onPaymentMethodChange: PropTypes.func.isRequired,
  paymentMethod: PropTypes.object.isRequired,
};

PaymentMethodGiftCardForm.defaultProps = {
};

export default PaymentMethodGiftCardForm;
