import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '~/public/shared/components';

const FormActions = ({
  primaryText,
  secondaryText,
  primaryAction,
  secondaryAction,
  cancelAction,
}) => (
  <div className="form-actions">
    { typeof cancelAction === 'string' ?
      <Link to={cancelAction}>
        <Button
          buttonSize="sm"
          buttonStyle="secondary-danger"
        >
          Cancel
        </Button>
      </Link>
      :
      <Button
        buttonSize="sm"
        buttonStyle="secondary-danger"
        onClick={cancelAction}
      >
        Cancel
      </Button>}
    {secondaryText &&
      <Button
        buttonSize="sm"
        buttonStyle="secondary"
        onClick={secondaryAction}
      >
        {secondaryText}
      </Button>}

    {primaryText &&
      <Button
        buttonSize="sm"
        buttonStyle="primary"
        onClick={primaryAction}
      >
        {primaryText}
      </Button>}
  </div>
);

export { FormActions };
