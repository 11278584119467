// @flow

import { Component } from 'react';
import { createPortal } from 'react-dom';

import type { Node } from 'react';

type Props = {
  children: Node,
};

class NoticePortal extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
    this.notificationRoot = document.getElementById('notifications');
  }

  componentDidMount() {
    if (this.notificationRoot) {
      this.notificationRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.notificationRoot) {
      this.notificationRoot.removeChild(this.el);
    }
  }

  el: HTMLElement

  notificationRoot: ?HTMLElement

  render() {
    return createPortal(
      this.props.children,
      this.el,
    );
  }
}

export default NoticePortal;
