// @flow

import React, { Fragment, PureComponent } from 'react';
import { InView } from 'react-intersection-observer';

import { Badge, Icon, TimeRemaining } from '~/public/shared/components';
import timeInWords from '~/public/shared/utils/timeInWords';
import formatCurrency from '~/utils/formatCurrency';
import ItemFollowBtn from './ItemFollowBtn';
import type { Item } from '../types';

type Props = {
  item: Item,
  isFollowing: boolean,
  onFollowClick: (id: number) => void,
  onVisibilityChange: (boolean) => void,
};

type State = {
  interval: ?IntervalID,
  timeRemaining: string,
  tileViewed: boolean,
};

class ItemTile extends PureComponent<Props, State> {
  state = {
    // TODO: If item isn't ending any time soon interval could be much longer
    interval: setInterval(this.updateTimeRemaining, 500),
    timeRemaining: '',
    tileViewed: false,
  }

  componentWillUnmount() {
    this.props.onVisibilityChange(false);

    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
  }

  endedStates = ['sold', 'unsold', 'invoiced', 'paid'];

  soldStates = ['sold', 'invoiced', 'paid'];

  unsoldStates = ['unsold', 'return_to_seller']

  updateTimeRemaining = (): void => {
    const { saleEndsAt } = this.props.item;
    // TODO: Import this in some way that doesn't increase the amount of /timesync requests
    const now = new Date(window.EBTH.timesync.now());

    this.setState({
      timeRemaining: timeInWords(now, saleEndsAt),
    });
  }

  renderTimeRemaining = () => {
    const { timeRemaining } = this.state;
    const { aasmState } = this.props.item;

    if (this.endedStates.includes(aasmState)) {
      return 'Ended';
    } else if (timeRemaining) {
      return `${timeRemaining} left`;
    } else {
      return 'Ending';
    }
  }

  handleVisibilityChange = (inView: boolean): void => {
    if (this.state.tileViewed === false && inView) {
      this.setState({ tileViewed: true });
    }
    this.props.onVisibilityChange(inView);
  }

  getBidInfo() {
    const {
      bidsCount,
      typeName,
      highBidAmount,
      minimumBidAmount,
    } = this.props.item;

    if (typeName === 'buy_now') {
      return <Fragment>
        <div className="item__bid-label">
          {this.getBidLabel()}
        </div>
        <span className="item__bid-amount">{formatCurrency(minimumBidAmount)}</span>
      </Fragment>;
    } else {
      return <Fragment>
        <div className="item__bid-label">{this.getBidType()} Bid</div>
        <span className="item__bid-amount">{formatCurrency(typeName === 'reserve' && bidsCount === 0 ? minimumBidAmount : highBidAmount)}</span>
      </Fragment>;
    }
  }

  getBidLabel() {
    const {
      aasmState,
    } = this.props.item;

    if (this.soldStates.includes(aasmState)) {
      return <Badge margin="shrunk" size="mini" backgroundcolor="$gray-400">BUY NOW - SOLD</Badge>;
    } else if (this.unsoldStates.includes(aasmState)) {
      return <Badge margin="shrunk" size="mini" backgroundcolor="$gray-400">BUY NOW - UNSOLD</Badge>;
    } else {
      return <Badge margin="shrunk" size="mini" status="positive">BUY NOW</Badge>;
    }
  }

  getBidType() {
    const {
      bidsCount,
      typeName,
      itemState,
    } = this.props.item;

    if (itemState === 'ended') {
      return 'Final';
    }

    if (typeName === 'reserve' && bidsCount === 0) {
      return 'Opening';
    }

    return 'Current';
  }

  render() {
    const { item, isFollowing, onFollowClick } = this.props;
    const {
      id,
      aasmState,
      extended,
      saleEndsAt,
      publicUrl,
      thumbnailUrl,
      name,
      anyPickupOptions,
      pickupLocationName,
    } = item;

    /* eslint-disable-next-line no-unused-vars */
    let itemState = '';
    const lessThan24Left = (saleEndsAt - window.EBTH.timesync.now()) / 1000 / 60 / 60 <= 24;

    if (this.endedStates.includes(aasmState)) {
      itemState = 'ended';
    } else if (extended) {
      itemState = 'extended';
    } else if (lessThan24Left) {
      itemState = 'ending';
    }

    const imgSrc = this.state.tileViewed ? thumbnailUrl : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

    return (
      <InView onChange={this.handleVisibilityChange}>
        {({ ref }) => {
          return (
            <a ref={ref} className="items-grid__item item" itemProp="image" href={publicUrl} id={`item_${id}`}>
              <div className="item__image-wrap">
                <img
                  src={imgSrc}
                  className="item__image"
                  alt={name}
                />
                {(anyPickupOptions && aasmState === 'for_sale') &&
                  <div className="item__fulfillment-option">
                    <Icon
                      icon="pick-up"
                      className="item__fulfillment-option-icon"
                      role="presentation"
                    />
                    <div className="item__fulfillment-option-body">
                      Pickup Available
                      <br /><strong>{pickupLocationName}</strong>
                    </div>
                  </div>
                }
              </div>
              <h4 className="item__title" title={name}>{name}</h4>
              <div className="item__bid">
                <div className="item__bid-status">
                  {this.getBidInfo()}
                </div>
                {!['sold', 'invoiced', 'paid'].includes(aasmState) &&
                  <ItemFollowBtn
                    itemId={id}
                    isFollowing={isFollowing}
                    onFollowClick={onFollowClick}
                  />
                }
              </div>
              <div className="status-bar item__status-bar">
                <TimeRemaining
                  extended={extended}
                  endsAt={saleEndsAt}
                  aasmState={aasmState}
                  getReferenceTime={window.EBTH.timesync.now}
                />
              </div>
            </a>
          );
        }}
      </InView>
    );
  }
}

export default ItemTile;
