// @flow

import gql from 'graphql-tag';
import { ApolloClient } from 'react-apollo';

const MUTATION = gql`
  mutation updateSaleName($saleId: ID!, $name: String!) {
    updateSale(input: { id: $saleId, name: $name }) {
      sale {
        id
        name
      }
    }
  }
`;

export type RenameSaleParams = {
  saleId: ID,
  name: string,
}

export default function (client: ApolloClient) {
  return async (variables: RenameSaleParams) => (
    client.mutate({
      mutation: MUTATION,
      variables,
    })
  );
}
