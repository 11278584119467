// @flow

import React, { Component } from 'react';
import { Modal } from './Modal';

import { Button } from '../Button';

type Props = {
  item: ?{ aasmState: string },
  onResult: (boolean) => any,
};

type State = {
  isOpen: boolean,
};

export class ItemLiveModal extends Component<Props, State> {
  state = {
    isOpen: true,
  };

  confirm = () => {
    this.props.onResult(true);
    this.setState({ isOpen: false });
  };

  reject = () => {
    this.props.onResult(false);
    this.setState({ isOpen: false });
  };

  isLive = () => {
    const { item } = this.props;
    if (!item) {
      return false;
    }

    if (item.aasmState !== 'for_sale') {
      return false;
    }

    return true;
  };

  render() {
    if (!this.props.item || !this.isLive()) {
      return null;
    }

    const confirmMessage = 'This item is live. Are you sure you want to continue?';
    return (
      <div className="js-modal-parent">
        <Modal isOpen={this.state.isOpen}>
          <div className="modal-box">
            <div className="u-text-center">
              <h2>{confirmMessage}</h2>
              <Button
                buttonStyle="success"
                size="jumbo"
                className="qa-modal-confirm u-ml1 u-mr1"
                onClick={this.confirm}
              >
                Yes
              </Button>
              <Button
                buttonStyle="secondary-inverse"
                size="jumbo"
                className="qa-modal-reject u-ml1 u-mr1"
                onClick={this.reject}
              >
                No
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
