// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';

import type { Dispatch } from 'redux';

import HardwareSvgIcon from '~/admin/shared/svgs/HardwareSvgIcon';
import { changeActiveSlide } from '../actions';

type Props = {
  changeActiveSlide: (number) => any,
  activeSlide: number,
};

class BackArrowContainer extends Component<Props> {
  onClick = () => {
    const previousSlideNumber = Math.max(this.props.activeSlide - 1, 0);
    this.props.changeActiveSlide(previousSlideNumber);
  }

  render() {
    const isDisabled = this.props.activeSlide === 0;
    return (
      <button
        disabled={isDisabled}
        onClick={this.onClick}
        className="value-estimator__back"
        type="button"
      >
        <HardwareSvgIcon icon="ic_keyboard_arrow_left_24px" />
      </button>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  changeActiveSlide,
}, dispatch);

const connector = connect(null, mapDispatchToProps);

export default compose(connector)(BackArrowContainer);
