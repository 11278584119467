// @flow

import React, { type StatelessFunctionalComponent, type Node } from 'react';
import classNames from 'classnames';

type Props = {
  children: Node,
  errors?: string[],
  helperText?: string,
  label?: string,
  labelHelper?: string,
  priorityLabel?: string,
  forId: string,
};

export const Item: StatelessFunctionalComponent<Props> = ({
  errors = [],
  helperText,
  forId,
  label,
  labelHelper,
  priorityLabel,
  children,
}) => {
  const hasErrors = errors.length > 0;
  const shouldBuildLabel = label || labelHelper || priorityLabel;
  const wrapperClasses = classNames('form-control', {
    'is-errored': hasErrors,
  });

  return (
    <div className={wrapperClasses}>
      {shouldBuildLabel && (
        <label htmlFor={forId} className="form-control__label">
          {label}
          {labelHelper && <span className="form-control__label-helper">({labelHelper})</span>}
          {priorityLabel && <span className="form-control__priority">{priorityLabel}</span>}
        </label>
      )}

      {children}

      {helperText && <span className="form-control__helper">{helperText}</span>}

      {hasErrors && <div className="form-control__error">{errors.join(', ')}</div>}
    </div>
  );
};
