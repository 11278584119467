// @flow

import gql from 'graphql-tag';
import { ApolloClient } from 'react-apollo';

export type ItemSearchParams = {
  query: string,
  constraints?: string,
  startCursor: string,
  pageSize: number,
  sortColumn: string,
  sortDirection: string,
}

export const MerchItemsFragment = gql`
  fragment merchItemsFields on Item {
    id
    name
    barcode
    expectedSalePrice
    combinedEstimatedValue
    isFlexItem
    createdAt
    updatedAt
    reservedForSelect
    isComplete

    # we need to fetch item sale as this determines which item goes where
    sale {
      id
    }

    contract {
      id
      number
      sellerEmail
      seller{
        firstName
        lastName
      }
    }

    mainAttachment {
      merchThumbnailUrl
    }

    quickpicAttachment {
      merchThumbnailUrl
    }

    primaryCategory {
      id
      name
      lineage
      active
    }

    valuation {
      id
      shortText
      longText
      estimatedValue
    }
  }
`;

const QUERY = gql`
  query searchItems($query: String, $constraints: Json, $pageSize: Int, $startCursor: String, $sortColumn: String!, $sortDirection: SearchSortDirection!) {
    items: searchItems(query: $query, constraints: $constraints, pageSize: $pageSize, startCursor: $startCursor, sortColumn: $sortColumn, sortOrder: $sortDirection) {
      pageInfo {
        startCursor
        endCursor
      }

      totalCount

      edges {
        node {
          ...merchItemsFields
        }
      }
    }
  }
  ${MerchItemsFragment}
`;

export default function (client: ApolloClient) {
  return async (itemSearchParams: ItemSearchParams) => (
    client.query({
      query: QUERY,
      variables: itemSearchParams,
      fetchPolicy: 'no-cache',
    })
  );
}
