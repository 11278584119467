// @flow

import { BOOTSTRAP } from '../actions/bootstrap';
import { UPDATE_ITEM } from '../actions/item';

import type { Actions as BootstrapActions } from '../actions/bootstrap';
import type { Actions as ItemActions } from '../actions/item';
import type { Item } from '../types';

type Actions = BootstrapActions | ItemActions;

type ItemState = {
  item: ?Item,
};

const initialState = {
  item: null,
};

const itemReducer = (state: ItemState = initialState, action: Actions): ItemState => {
  switch (action.type) {
    case BOOTSTRAP:
      return {
        ...state,
        item: action.item,
      };

    case UPDATE_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          aasmState: action.itemChange.aasmState,
          bidderIds: action.itemChange.bidderIds,
          bidsCount: action.itemChange.bidsCount,
          extended: action.itemChange.extended,
          highBidAmount: action.itemChange.highBidAmount,
          highBidUserId: action.itemChange.highBidUserId,
          saleEndsAt: action.itemChange.saleEndsAt,
        },
      };
    default:
      return state;
  }
};

export default itemReducer;
