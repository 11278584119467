import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { RouteComponentProps, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';

import Layout from '../../../public/dashboard/components/Layout';
import { Card } from '~/public/dashboard/components/Card';
import { FormActions } from '~/public/dashboard/components/FormActions';
import { Button, Icon, LoadingDots } from '~/public/shared/components';
import { displayNotice } from '~/admin/shared/actions/notice';
import { Checkbox } from '~/public/shared/components/Checkbox/Checkbox';
import PhotoUpload from '~/public/dashboard/components/PhotoUpload';
import ItemCategory from '~/shared/components/Form/PendingItem/ItemCategory';
import EstimatedValueSelect from '~/admin/shared/components/EstimatedValueSelect';
import DisclaimerSelect from '~/admin/shared/components/DisclaimerSelect';
import AdditionalInformationLink from '~/admin/shared/components/AdditionalInformationLinksForm';
import ProcessingLocationSelect from '~/admin/shared/containers/ProcessingLocationSelectContainer';
import Properties from '~/shared/components/Form/Property/Properties';
import buildItemProperties from '~/utils/buildItemProperties';
import { PendingItem } from '~/shared/interfaces';
import { PENDING_ITEM } from "~/shared/graphql/PendingItem/queries/PendingItemQuery";
import { UPDATE_PENDING_ITEM_MUTATION } from '~/shared/graphql/PendingItem//PendingItemMutations';
import { PROPERTY_QUERY } from '~/shared/graphql/Property/queries/PropertyQuery';
import CreateableMultiSelectInput from '~/admin/shared/components/CreateableMultiSelectInput';

export const InventoryEdit: React.FC<{
  pendingItemId: string,
  featureFlags: object,
} & RouteComponentProps> = (props) => {
  const [item, setItem] = useState({} as PendingItem);

  const [requiredProperties, setRequiredProperties] = useState([])
  const [optionalProperties, setOptionalProperties] = useState([])

  const itemQuery = useQuery(PENDING_ITEM, {
    variables: { id: props.match.params.pendingItemId },
  });

  const propertyQuery = useQuery(PROPERTY_QUERY, {
    variables: { categoryId: item.category ? item.category.id : null },
    skip: !item.category
  })

  const [savePendingItem, updateMutationRequest ] = useMutation(UPDATE_PENDING_ITEM_MUTATION)

  useEffect(() => {
    if (itemQuery.data && !itemQuery.loading && !itemQuery.error ) {
      // Only store necessary property attributes
      const properties = itemQuery.data.pendingItem.properties.map((property) => ({
        propertyId: property.propertyId,
        type: property.type,
        value: property.value
      }))

      // Item gets added last so current properties in state do not get overwritten by unsaved data
      // Attachments are saved via separate query, therefore refetched data needs to take priority
      setItem({
        ...itemQuery.data.pendingItem,
        ...item,
        properties,
        attachments: itemQuery.data.pendingItem.attachments
      })
    }
  }, [itemQuery.data])

  useMemo(() => {
    if (propertyQuery.data && propertyQuery.data.properties) {
      setRequiredProperties(propertyQuery.data.properties.filter((property) => property.priority === 'required'))

      // Any fields not considered requied will be optional, this can be more granular to handle important or other props
      setOptionalProperties(propertyQuery.data.properties.filter((property) => property.priority !== 'required'))
    }
  }, [propertyQuery.data])

  const onChange = (changes: Object) => {
    setItem((state) => ({ ...state, ...changes }))
  }

  const onPropertyChange = (property: string) => (value: string | boolean) => onChange({[property]: value })

  const saveItem = () => {
    const disclaimers = item.disclaimers.map((disclaimer) => ({ id: disclaimer.id, name: disclaimer.name }))
    const additionalInformationLinks = item.additionalInformationLinks
      .map((infoLink) => ({ href: infoLink.href, title: infoLink.title }))

    const properties = item.properties.map((property) => ({
      propertyId: property.propertyId,
      type: property.type,
      value: property.value
    }))

    const input = {
      id: item.id,
      externalId: item.externalId,
      name: item.name,
      categoryId: item.category ? item.category.id : null,
      processingLocationId: item.processingLocation ? item.processingLocation.id : null,
      properties,
      conditionDetails: item.conditionDetails,
      measurementDetails: item.measurementDetails,
      estimatedValue: item.estimatedValue,
      description: item.description,
      disclaimers,
      additionalInformationLinks,
      itemKeywords: item.itemKeywords,
      purchasePrice: +item.purchasePrice,
      targetPrice: +item.targetPrice,
      heightInches: +item.heightInches,
      lengthInches: +item.lengthInches,
      widthInches: +item.widthInches,
      weightOz: +item.weightOz,
      shippable: item.shippable,
      valuePurchasedAt: item.valuePurchasedAt == null ? null : +item.valuePurchasedAt,
    }

    savePendingItem({ variables: { input }}).then(() => {
      props.displayNotice({
        message: 'Save successful',
        type: 'success',
        autoDismiss: true,
      });
      props.history.push('/items/submitted');
    }).catch(() => {
      props.displayNotice({
        message: 'Error saving',
        type: 'error'
      })
    })
  }

  const renderBasicInformationInputs = () => (
    <>
      <label
        htmlFor="item-name"
        className="form-control__label"
      >
        Item Name
      </label>
      <input
        id="item-name"
        value={item.name ? item.name : 'Fetching data...'}
        onChange={(event) => onPropertyChange('name')(event.target.value)}
        disabled={!item.name}
        autoFocus
        type="text"
        className="form-input u-mb2"
      />

      <label
        htmlFor="item-name"
        className="form-control__label"
      >
        Seller Item ID
        <span className="helper-text"> (Optional)</span>
      </label>
      <input
        id="item-external-id"
        value={item.externalId || ''}
        onChange={(event) => onPropertyChange('externalId')(event.target.value)}
        type="text"
        className="form-input"
      />
      <span className="helper-text u-mb2 u-b">If you use your own identification numbers to track items, insert it here</span>
      {/* <ProcessingLocationSelect
        contractId={item.contract ? item.contract.id : null}
        value={item.processingLocation}
        onChange={(value) => onPropertyChange('processingLocation')(value)}
      /> */}
      <ItemCategory
        item={item}
        updateItem={setItem}
        onPropertyChange={onPropertyChange}
      />
    </>
  )

  const renderDescriptionInput = () => (
    <>
      <label
        htmlFor="description"
        className="form-control__label"
      >
        Description
      </label>
      <textarea
        value={item.description !== null ? item.description : ''}
        onChange={(event) => onPropertyChange('description')(event.target.value)}
        id="description"
        rows={3}
        className="form-input"
      />
    </>
  );

  const renderPropertyInputs = () => {
    if (!item.category) {
      return null;
    }

    if (propertyQuery.loading) {
      return <LoadingDots />
    }

    return (
      <>
        { requiredProperties.length !== 0 &&
          <Card className="u-mb2"
            header="Required Fields"
            accordion
            open={true}
          >
            <Properties
              showPropertiesNotice={item.category.showItemProperties}
              itemProperties={buildItemProperties(item.properties)}
              properties={requiredProperties}
              // errors={this.props.validator ? this.props.validator.errors : {}}
              errors={[]}
              onChange={onPropertyChange('properties')}
            />
          </Card>
        }

        { optionalProperties.length !== 0 &&
          <Card
            className="u-mb2"
            header="Optional Fields"
            accordion
            open={false}
          >
            <Properties
              showPropertiesNotice={item.category.showItemProperties}
              itemProperties={buildItemProperties(item.properties)}
              properties={optionalProperties}
              // errors={this.props.validator ? this.props.validator.errors : {}}
              errors={[]}
              onChange={onPropertyChange('properties')}
            />
          </Card>
        }

      </>
    )
  };

  const renderOtherInformationInputs = () => (
    <>
      <DisclaimerSelect
        selected={item.disclaimers}
        onChange={(value) => onPropertyChange('disclaimers')(value)}
      />
      <AdditionalInformationLink
        additionalInformationLinks={item.additionalInformationLinks}
        onChange={(value) => onPropertyChange('additionalInformationLinks')(value)}
      />
    </>
  );

  const renderPhotoSection = () => {
    return <PhotoUpload item={item} refetch={itemQuery.refetch}/>
  }

  const renderConditionDetailsInput = () => (
    <>
      <label htmlFor="condition" className="form-control__label">
        Condition Details
      </label>
      <textarea
        id="condition"
        value={item.conditionDetails !== null ? item.conditionDetails : ''}
        onChange={(event) => onPropertyChange('conditionDetails')(event.target.value)}
        rows={3}
        className="form-input"
      />
      <span className="helper-text">Describe any flaws with the item like scratches, worn areas, non-functioning components, etc.</span>
    </>
  );

  const renderEstimatedValueInput = () => (
    <div className="u-block">
      <div className="form-control__label u-mb1 u-flex">
        <label htmlFor="purchase-price" className="form-control__label u-mr2 u-flex-1">
          Purchase Price<span className="helper-text"> (USD)</span>
          <input
            id="purchase-price"
            value={item.purchasePrice}
            onChange={(event) => onPropertyChange('purchasePrice')(event.target.value)}
            type="number"
            className="form-input u-mt1"
          />
        </label>
        <label htmlFor="target-price" className="form-control__label u-flex-1">
          Target Price<span className="helper-text"> (USD)</span>
          <input
            id="target-price"
            value={item.targetPrice}
            onChange={(event) => onPropertyChange('targetPrice')(event.target.value)}
            type="number"
            className="form-input u-mt1"
          />
        </label>
      </div>
      <EstimatedValueSelect
        item={item}
        onChange={(value) => onPropertyChange('estimatedValue')(value.estimatedValue)}
      />
    </div>
  );

  const renderMeasurementInputs = () => (
    <>
      <div className="u-flex u-mb2">
        <label htmlFor="measurement-length" className="form-control__label u-mr1 u-flex-1">
          Length<span className="helper-text"> (Inches)</span>
          <input
            id="measurement-length"
            value={item.lengthInches}
            onChange={(event) => onPropertyChange('lengthInches')(event.target.value)}
            type="number"
            className="form-input u-mt1"
          />
        </label>
        <label htmlFor="measurement-width" className="form-control__label u-mr1 u-flex-1">
          Width<span className="helper-text"> (Inches)</span>
          <input
            id="measurement-width"
            value={item.widthInches}
            onChange={(event) => onPropertyChange('widthInches')(event.target.value)}
            type="number"
            className="form-input u-mt1"
          />
        </label>
        <label htmlFor="measurement-height" className="form-control__label u-flex-1">
          Height<span className="helper-text"> (Inches)</span>
          <input
            id="measurement-height"
            value={item.heightInches}
            onChange={(event) => onPropertyChange('heightInches')(event.target.value)}
            type="number"
            className="form-input u-mt1"
          />
        </label>
      </div>
      <label htmlFor="measurement-details" className="form-control__label">
        Measurement Details
      </label>
      <textarea
        id="measurement-details"
        value={item.measurementDetails !== null ? item.measurementDetails : ''}
        onChange={(event) => onPropertyChange('measurementDetails')(event.target.value)}
        rows={3}
        className="form-input u-mb2"
      />
      <div className="u-flex u-mb4">
        <label
          className="form-control__label u-mr1 u-flex-1"
        >
          Weight<span className="helper-text"> (Ounces)</span>
          <input
            id="measurement-weight"
            value={item.weightOz}
            onChange={(event) => onPropertyChange('weightOz')(event.target.value)}
            type="number"
            className="form-input u-mt1"
          />
        </label>
        {/* Spacers to keep weight same length as above inputs */}
        <span className="u-mr1 u-flex-1"></span>
        <span className="u-flex-1"></span>
      </div>
      <Checkbox
        id="unshippable"
        value={!item.shippable}
        onChange={(value) => onPropertyChange('shippable')(!value)}
        label="Unshippable?"
      />
      <span className="helper-text u-b">This item will not be shipped</span>
    </>
  );

  const renderKeywords = () => {
    const values = item.itemKeywords == undefined ? [] : item.itemKeywords.filter((word) => word !== '').map((keyword) => {
      return {
        label: keyword,
        value: keyword,
      };
    });
    const onChange = (values) => {
      onPropertyChange('itemKeywords')(values.map(value => value.value));
    };
    return (<CreateableMultiSelectInput
             id="keywords"
             className="qa-multi-category-select"
             value={values}
             options={values}
             name="keywords"
             onChange={onChange}
           />);
  };

  const renderValuePurchased = () => (
    <div className="u-block">
      <div className="form-control__label u-mb1 u-flex">
        <label htmlFor="purchase-price" className="form-control__label u-mr2 u-flex-1">
          Purchase Price<span className="helper-text"> (USD)</span>
          <input
            id="purchase-price"
            value={item.valuePurchasedAt}
            onChange={(event) => onPropertyChange('valuePurchasedAt')(event.target.value)}
            type="number"
            className="form-input u-mt1"
            step="0.01"
          />
        </label>
      </div>
    </div>
  );

  const checkDescriptionRequirement = () => {
    const estimatedValue = item.estimatedValue;
    const requiredLow = item.category.lowValueRequireDescription;
    const requiredMid = item.category.midValueRequireDescription;
    const requiredHigh = item.category.highValueRequireDescription;
    if (requiredLow && estimatedValue >= 1) return null;
    if (requiredMid && estimatedValue >= 2) return null;
    if (requiredHigh && estimatedValue >= 3) return null;

    return (
      "(Optional)"
    )
  };

  return (
    <Layout>
      <Link to="/items/submitted">
        <Button
          buttonStyle="text"
          buttonSize="lg"
          className="u-pl0"
        >
          <Icon icon="chevron-left" className="btn__icon" />
          Back to Submitted Items
        </Button>
      </Link>
      <h1 className="u-mb2">Submit an Item</h1>
      <div className="row u-mb4">
        <div className="col-xs-12 col-md-6">
          <section>
            <Card header="Basic Information" className="u-mb2">
              { renderBasicInformationInputs() }
            </Card>
            { item.category &&
              <>
                <Card
                  className="u-mb2"
                  header="Description"
                  optionalText={checkDescriptionRequirement()}
                >
                  { renderDescriptionInput() }
                </Card>
                { renderPropertyInputs() }
                <Card header="Other Information" className="u-mb2" >
                  { renderOtherInformationInputs() }
                </Card>
              </>
            }

          </section>
        </div>
        <div className="col-xs-12 col-md-6">
          <section>
            <Card header="Item Photos" className="u-mb2">
              { renderPhotoSection() }
            </Card>
            <Card header="Condition" className="u-mb2">
              { renderConditionDetailsInput() }
            </Card>
            { item.category &&
              <>
                { props.featureFlags.role != 'superuser' &&
                  <Card header="Item Valuation" className="u-mb2" >
                    { renderEstimatedValueInput() }
                  </Card>
                }
                { props.featureFlags.role == 'superuser' &&
                  <Card header="Purchased Value" className="u-mb2">
                    { renderValuePurchased() }
                  </Card>
                }
                <Card
                  className="u-mb2"
                  header="Measurements"
                  optionalText={!item.category.measurementsRequired ? '(Optional)' : null}
                >
                  { renderMeasurementInputs() }
                </Card>
                <Card
                  className="u-mb2"
                  header="Search Keywords"
                  optionalText='(Optional)'
                >
                  { renderKeywords() }
                </Card>
              </>
            }
          </section>
        </div>
      </div>
      <FormActions
        cancelAction="/items/submitted"
        primaryText="Save Item Details"
        primaryAction={saveItem}
      />
    </Layout>
  );
}

export default compose(
  connect(
    null,
    (dispatch) => bindActionCreators({ displayNotice }, dispatch)
  )
)(InventoryEdit);
