// @flow

import type { BidChange } from '../types';

export const UPDATE_BID_NEW = 'UPDATE_BID_NEW';

export type UpdateBidNewAction = {
  type: 'UPDATE_BID_NEW',
  bid: BidChange,
};

export type Actions = UpdateBidNewAction;

export const updateBidNew = (bid: BidChange): UpdateBidNewAction => (
  {
    type: 'UPDATE_BID_NEW',
    bid,
  }
);
