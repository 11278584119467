// @flow
import React from 'react';
import classNames from 'classnames';

type CarouselDotProps = {
  index: number,
  isActive?: boolean,
  onClick: () => any,
};

export const CarouselDot = ({ index, isActive, onClick }: CarouselDotProps) => {
  const dotClasses = classNames('image-carousel__dot', {
    'is-active': isActive,
  });

  return (
    <button type="button" className={dotClasses} onClick={onClick}>
      {index}
    </button>
  );
};

CarouselDot.defaultProps = {
  isActive: false,
};
