// @flow

import React from 'react';
import { Button } from '~/admin/shared/components';

export const ModalFooter = ({
  action,
  actionText = 'Close',
  actionDisabled = false,
  cancelText = 'Cancel',
  cancel,
  children,
  danger,
}: {
  action: (any) => null,
  actionText: string,
  actionDisabled?: boolean,
  cancelText?: string,
  cancel?: (any) => null,
  children?: (any) => null,
  danger?: boolean,
}) => (
  <div className="modal-footer">
    <div className="u-flex u-flex-end">
      { children ?
        <>
          {children}
        </>
        :
        <>
          { cancel &&
            <Button
              buttonStyle="secondary"
              size="jumbo"
              className="qa-modal-confirm u-mr1"
              onClick={() => cancel({ open: false })}
            >
              {cancelText}
            </Button>}
          <Button
            buttonStyle={danger ? 'danger' : 'success'}
            size="jumbo"
            className="qa-modal-confirm"
            onClick={() => action({ open: false })}
            disabled={actionDisabled}
          >
            {actionText}
          </Button>
        </>}
    </div>
  </div>
);

export default ModalFooter;
