// @flow

export type FeatureFlagsType = {
  [string]: boolean
};

class FeatureFlags {
  featureFlags: FeatureFlagsType;

  constructor(featureFlags: FeatureFlagsType) {
    this.featureFlags = featureFlags;
  }

  isEnabled = (flagKey: string): boolean => (
    !!this.featureFlags && !!this.featureFlags[flagKey]
  )
}

export default FeatureFlags;
