// @flow

import React, { type StatelessFunctionalComponent } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string,
  id: string,
  label: string,
  onChange: (newValue: boolean) => void,
  value: boolean,
  labelAfter?: boolean,
};

export const Switch: StatelessFunctionalComponent<Props> = ({
  className,
  id,
  label,
  onChange,
  value,
  labelAfter = false,
}) => {
  const toggleClasses = classNames('toggle', className);

  const labelClasses = classNames('toggle__label', labelAfter ? 'label-after' : '');

  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label className={toggleClasses}>
      <input
        id={id}
        type="checkbox"
        className="toggle__input"
        checked={value}
        onChange={handleChange}
      />
      <div className={labelClasses}>{label}</div>
    </label>
  );
};
