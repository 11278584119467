import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';

import { nextAppliedParams, paramsToUrl } from '../utils/url';

class FilterCrumbs extends Component {
  handleRemoveAllFiltersClick = () => {
    const { appliedParams } = this.props;
    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        props: {},
        zip_code: null,
        miles: null,
      };
    });
    const url = paramsToUrl(nextParams);
    this.props.history.push(url);
  }

  mapFilterToCrumbs = (filter) => {
    switch (filter.type) {
      case 'multi_select':
      {
        const { filterType, query } = filter;
        const { appliedParams } = this.props;

        let params = appliedParams;
        if (filterType === 'property') {
          params = appliedParams.props;
        }

        let selectedOptions = params[query] || [];
        if (typeof selectedOptions === 'string') {
          selectedOptions = [selectedOptions];
        }

        const crumbs = selectedOptions.map((option) => {
          // Make a name on the spot
          const p = option.split('-');
          const p2 = p.reduce(
            (acc, val) => {
              const parts = val.split('_');
              acc.push(...parts);
              return acc;
            },
            []
          );
          const mapped = p2.map((part) => part.charAt(0).toUpperCase() + part.slice(1));
          const name = mapped.join(' ');

          // Figure out the next state if this were selected
          const nextParams = nextAppliedParams(appliedParams, (current) => {
            let prms = current;
            if (filterType === 'property') {
              prms = { ...current.props };
            }
            let selected = prms[query] || [];
            if (typeof selected === 'string') {
              selected = [selected];
            }

            // Remove the value from the array
            prms[query] = selected.filter((el) => el !== option);
            if (prms[query].length === 0) {
              delete prms[query];
            }

            if (filterType === 'property') {
              current.props = prms;
              return current;
            }

            return prms;
          });
          const url = paramsToUrl(nextParams);
          const callback = () => {
            this.props.history.push(url);
          };

          return {
            type: filter.type,
            component: filter.component,
            query: filter.query,
            id: option,
            name: name,
            count: 1,
            remove: callback,
          };
        });

        return crumbs;
      }
      case 'location':
      {
        const { appliedParams } = this.props;
        const { miles, zip_code } = appliedParams;
        if (!miles || !zip_code) {
          break;
        }

        // Figure out the next state if this were selected
        const nextParams = nextAppliedParams(appliedParams, (current) => {
          return {
            ...current,
            miles: null,
            zip_code: null,
          };
        });
        const url = paramsToUrl(nextParams);
        const callback = () => {
          this.props.history.push(url);
        };

        return [{
          type: filter.type,
          component: filter.component,
          query: filter.query,
          id: [zip_code, miles],
          name: `${miles} miles of ${zip_code}`,
          count: null,
          remove: callback,
        }];
      }
      default:
        break;
    }

    return [];
  };

  mapKeywordToCrumb(keyword) {
    const { appliedParams, history } = this.props;
    // Figure out the next state if this were selected
    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        q: null,
      };
    });
    // Name will come in with '+' in it, need to capitalize each word as well
    const name = keyword.split('+').map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
    const url = paramsToUrl(nextParams);
    const callback = () => {
      history.push(url);
    };

    return {
      type: 'query',
      component: 'q',
      query: 'q',
      id: `q_${keyword}`,
      name: name,
      count: 1,
      remove: callback,
    };
  }

  renderCrumb = (appliedFilter) => {
    const {
      component,
      id,
      name,
      count,
      remove,
    } = appliedFilter;
    const { selectFilter, setLocation } = this.props;

    const crumbTextClasses = classNames(
      'items-filter-crumbs__item-text',
      {
        'items-filter-crumbs__item-text--color': component === 'color_hex',
        'items-filter-crumbs__item-text--color-zero-count': count === 0 && component === 'color_hex',
        'items-filter-crumbs__item-text--zero-count': count === 0 && component !== 'color_hex',
      }
    );

    const inlineStyle = component === 'color_hex' ? { backgroundColor: id } : {};

    return (
      <span key={`${component}_${id.toString()}`} className="items-filter-crumbs__item">
        <span className={crumbTextClasses} style={inlineStyle}>{name}</span>
        <span onClick={() => remove(selectFilter, setLocation)} className="items-filter-crumbs__item-remove" title="Remove filter">×</span>
      </span>
    );
  }

  render() {
    const { filters, appliedParams } = this.props;
    const { mapFilterToCrumbs } = this;
    if (!filters) {
      return null;
    }

    const start = (appliedParams.q) ? [this.mapKeywordToCrumb(appliedParams.q)] : [];

    // Map each filter to an array of crumbs and then spread with accumulator
    const appliedFilters = filters.reduce((acc, filter) => {
      const crumbs = mapFilterToCrumbs(filter);
      return [...acc, ...crumbs];
    }, start);

    if (appliedFilters.length === 0) { return null; }

    return (
      <div className="items-filter-crumbs">
        <span className="items-filter-crumbs__items">
          {appliedFilters.map((filter) => this.renderCrumb(filter))}
        </span>
        {appliedFilters.length > 1 &&
          <a onClick={this.handleRemoveAllFiltersClick} className="items-filter-crumbs__clear-all">Clear All</a>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.items.filters,
  appliedParams: state.items.appliedParameters,
});

export default withRouter(connect(mapStateToProps)(FilterCrumbs));
