// @flow

import moment from 'moment';

const timeRemaining = ({ from, to }: { from: moment, to: moment }) => {
  const difference = to.diff(from, 'seconds');

  if (difference < 0) {
    return [
      {
        unit: 'minutes',
        value: 0,
      },
      {
        unit: 'seconds',
        value: 0,
      },
    ];
  } else if (difference > 3600) {
    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor((difference % 3600) / 60);
    const seconds = Math.floor((difference % 3600) % 60);

    return [
      {
        unit: 'hours',
        value: hours,
      },
      {
        unit: 'minutes',
        value: minutes,
      },
      {
        unit: 'seconds',
        value: seconds,
      },
    ];
  } else {
    const minutes = Math.floor(difference / 60);
    const seconds = difference % 60;

    return [
      {
        unit: 'minutes',
        value: minutes,
      },
      {
        unit: 'seconds',
        value: seconds,
      },
    ];
  }
};

export default timeRemaining;
