// @flow
import sleep from '~/utils/sleep';
import { type Node } from 'react';
import { uniqueId } from 'lodash';

export type NoticeType = 'success' | 'danger' | 'info';

export type NoticeMessage = {
  key: string,
  message: string | Node,
  type: NoticeType,
  autoDismiss: boolean,
  active: boolean,
};

export type DisplayNoticeParams = {
  message: string | Node,
  type: NoticeType,
  key?: string,
  autoDismiss?: boolean,
};

export const DISPLAY_NOTICE = 'DISPLAY_NOTICE';
export type DisplayNoticeAction = {
  type: 'DISPLAY_NOTICE',
  notice: NoticeMessage,
};

export const DISMISS_NOTICE = 'DISMISS_NOTICE';
export type DismissNoticeAction = {
  type: 'DISMISS_NOTICE',
};

export const REMOVE_NOTICE = 'REMOVE_NOTICE';
export type RemoveNoticeAction = {
  type: 'REMOVE_NOTICE',
};

export type Actions =
  DisplayNoticeAction |
  DismissNoticeAction |
  RemoveNoticeAction;

export const dismissNotice = (): DismissNoticeAction => (
  {
    type: DISMISS_NOTICE,
  }
);

export const displayNotice = (noticeParams: DisplayNoticeParams): Thunk<NoticeMessage> => (
  async (dispatch) => {
    const notice = {
      active: true,
      autoDismiss: false,
      key: uniqueId('notice'),
      ...noticeParams,
    };

    dispatch({
      type: DISPLAY_NOTICE,
      notice,
    });

    if (notice.autoDismiss) {
      await sleep(5000);
      dispatch(dismissNotice());
    }

    return notice;
  }
);

export const removeNotice = (): RemoveNoticeAction => (
  {
    type: REMOVE_NOTICE,
  }
);
